import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem, Typography} from '@material-ui/core';
import {withFormik} from 'formik';
import {Formik} from '@kbi/component-library';
import {containerCodes} from 'components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
const {SelectField, WeightField, TextField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const NewContainerForm = props => {
  const {values, getChildErrors} = props;
  const types = useSelector(state => state.firestore.types);

  useEffect(() => {
    if (props.parentSubmitting) {
      props.attemptSubmission(props.submitForm());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentSubmitting]);
  useEffect(() => {
    if (getChildErrors) {
      props.setErrorLength(Object.values(props.errors).length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChildErrors]);

  const core = useMemo(() => ({
    codeTypeField: {
      label: 'Code Type',
      required: values['containerCode'] && containerCodes.find(codeArray => codeArray[0] === values['containerCode'])?.[1].length ?
        true : false,
      disabled: !values['containerCode'] ||
        !containerCodes.find(codeArray => codeArray[0] === values['containerCode'])?.[1].length ||
        props.parentSubmitting,
    },
    yieldGrossWeightField: {
      required: true,
      decimal: 0,
      label: 'Gross Weight',
      disabled: props.parentSubmitting,
    },
    yieldTareWeightField: {
      required: true,
      label: 'Tare Weight',
      decimal: 0,
      disabled: props.parentSubmitting,
    },
    containerCodeField: {
      required: true,
      label: 'Container Code',
      disabled: props.parentSubmitting,
      onChange: ({form, field}) => {
        form.setFieldValue(`codeType`, '');
        form.setFieldError(`codeType`, '');
      },
    },
    shortNoField: {
      required: true,
      disabled: true,
      label: 'New Short No.',
    },
    yieldField: {
      label: 'Yield',
      required: true,
      loading: !props.selectableYields,
      options: props.selectableYields,
      optionKey: 'UnitDetails.MaterialName',
      disabled: props.selectedProcessForm.YieldRef !== 'mixed' || props.parentSubmitting,
    },
    flagField: {
      label: 'Flag',
      required: values.yield && values.yield?.FlagStatus === 'Required' ? true : false,
      loading: !values.yield,
      loadingText: 'Select a yield to populate',
      options: values.yield?.Flags || [],
      optionKey: 'Name',
      disabled: props.selectedProcessForm.YieldRef !== 'mixed' || values.yield?.FlagStatus === 'N/A' || props.parentSubmitting,
    },
    typeField: {
      label: 'Type',
      required: true,
      loading: !values.yield,
      loadingText: 'Select a yield to populate',
      options: types[values.yield?.MaterialId]?.filter(type => type.Active) || [],
      optionKey: 'TypeName',
      disabled: props.selectedProcessForm.YieldRef !== 'mixed' || props.parentSubmitting,
    },
    outlinedGrid: {
      item: true,
      xs: 12,
      container: true,
      spacing: 2,
      style: {border: '1px solid lightgrey', margin: '8px'},
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [props.parentSubmitting, types, values]);

  return (
    <Grid {...core.outlinedGrid}>
      <Grid item xs={1} container alignItems='center'>
        <Typography variant='h6'>{props.index + 1}.</Typography>
      </Grid>
      <Grid item xs={11} container spacing={2} style={{margin: '0px'}}>
        <Grid item xs={12} sm={3}>
          <TextField name={`newShortNo`} id={`newShortNo${props.index}n`} {...core.shortNoField} fast />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`yield`} id={`yield${props.index}n`} {...core.yieldField} fast />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`type`} id={`type${props.index}n`} {...core.typeField} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`flag`} id={`flag${props.index}n`} {...core.flagField} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name={`containerCode`} id={`containerCode${props.index}n`}{...core.containerCodeField} fast>
            {containerCodes.map((codeArray, index) => {
              return (<MenuItem value={codeArray[0]} key={index}>{codeArray[0]}</MenuItem>);
            })}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name={`codeType`} id={`codeType${props.index}n`}{...core.codeTypeField}>
            {values.containerCode ? containerCodes.find(codeArray => codeArray[0] === values.containerCode)[1]
              .map((code, index) => {
                return (<MenuItem value={code} key={index}>{code}</MenuItem>);
              }) : []}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <WeightField name={`grossWeight`} id={`grossWeight${props.index}n`} {...core.yieldGrossWeightField} fast />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WeightField name={`tareWeight`} id={`tareWeight${props.index}n`} {...core.yieldTareWeightField} fast />
        </Grid>
      </Grid>
    </Grid>
  );
};

NewContainerForm.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  parentSubmitting: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
  selectedProcessForm: PropTypes.object.isRequired,
  selectableYields: PropTypes.array.isRequired,
  attemptSubmission: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrorLength: PropTypes.func.isRequired,
  getChildErrors: PropTypes.bool.isRequired,
};

export default withFormik({
  mapPropsToValues: props => ({
    newShortNo: props.values.newShortNo,
    containerCode: props.values.containerCode,
    codeType: props.values.codeType,
    tareWeight: props.values.tareWeight,
    grossWeight: props.values.grossWeight,
    yield: props.values.yield,
    flag: props.values.flag,
    type: props.values.type,
  }),
  validationSchema: yup.object().shape({
    newShortNo: yup.string().required(),
    containerCode: yup.string().required('Container Code is a required field.'),
    codeType: yup.mixed().test({
      name: 'typeRequired',
      test: function(value) {
        if (!value && containerCodes.find(codeArray => codeArray[0] === this.parent.containerCode)?.[1]?.length) return false;
        else return true;
      },
      message: 'The selected Container Code requires a type.',
    }),
    tareWeight: yup.number().required('Tare Weight is a required field.').min(0, 'Tare Weight must be a positive number').test({
      name: 'smallerThanGross',
      test: function(value) {
        if (value && value > this.parent.grossWeight) return false;
        else return true;
      },
      message: 'Tare Weight must be less than Gross Weight.',
    }),
    grossWeight: yup.number().required('Gross Weight is a required field.').min(1, 'Gross Weight must be greater than 0'),
    yield: yup.object().nullable().exists('Yield is a required field.'),
    flag: yup.mixed().test({
      name: 'required Flag',
      test: function(value) {
        if (!value && this.parent.yield?.FlagStatus === 'Required') return false;
        else return true;
      },
      message: 'Flag is a required field.',
    }),
    type: yup.object().nullable().exists('Type is a required field.'),
  }),
  handleSubmit: (values, {props, setSubmitting}) => {
    props.updateState('newContainerArray', values);
    setSubmitting(false);
  },
})(NewContainerForm);

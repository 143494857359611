/* eslint-disable react/display-name */
import React, {useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography, Paper} from '@material-ui/core';
import {Print} from '@material-ui/icons';
import {TableWithPaper} from 'components';
import {useSelector} from 'react-redux';

const SortSummaryPanel = ({inboundContainers, setListOfContainersToPrint, paginationOffForPrint}) => {
  const [productionContainerData, setProductionContainerData] = useState([]);
  const containers = useSelector(state => state.firestore.containers);
  useEffect(() => {
    if (containers && inboundContainers) {
      const objectOfContainers = {};
      inboundContainers.forEach(container => {
        container.SortedInventoryItems.forEach(item => {
          if (objectOfContainers[item.ProductionContainer]) {
            objectOfContainers[item.ProductionContainer] = {
              ...objectOfContainers[item.ProductionContainer],
              NetWeight: objectOfContainers[item.ProductionContainer].NetWeight + item.NetWeight,
            };
          }
          else {
            objectOfContainers[item.ProductionContainer] = {
              ...item,
              systemWeight: containers.ref[item.ProductionContainer]?.NetWeight || 0,
            };
          }
        });
      });
      setProductionContainerData(Object.values(objectOfContainers));
    }
    else setProductionContainerData([]);
  }, [containers, inboundContainers]);

  const core = {
    productionContainerTable: {
      data: productionContainerData,
      columns: [
        {accessor: 'ProductionContainer', Header: 'Short No.'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'NetWeight', Header: 'Form Weight (Net)', type: 'numeric'},
        {accessor: 'systemWeight', Header: 'System Weight (Net)', type: 'numeric'},
      ],
      title: {
        primary: 'Sort Summary',
        secondary: 'A list of all containers that have been modified by this sort form:',
      },
      paginationActive: !paginationOffForPrint,
      actionsPerRow: [
        (rowData) => ({
          icon: Print,
          hidden: rowData.systemWeight === null ? true : false,
          tooltip: 'Print Label',
          onClick: ({rowData}) => {
            setListOfContainersToPrint([containers.ref[rowData.ProductionContainer]]);
          },
        }),
      ],
      actionsBar: [
        {
          icon: Print,
          text: 'Print All Labels',
          onClick: () => {
            const arrayOfContainersStillInFacility = [];
            productionContainerData.forEach(prodContainer => containers.ref[prodContainer.ProductionContainer] ?
              arrayOfContainersStillInFacility.push(containers.ref[prodContainer.ProductionContainer]) : null);
            setListOfContainersToPrint(arrayOfContainersStillInFacility);
          },
        },
      ],
    },
  };

  return (
    <Fragment>
      {productionContainerData.length ? (
        <TableWithPaper {...core.productionContainerTable} />
      ) : (
        <Paper style={{padding: '16px'}}>
          <Typography variant='h6' paragraph>Sort Summary</Typography>
          <Typography variant='subtitle1'>
              No Summary for this sort form yet. Add an inbound container to begin populating data.
          </Typography>
        </Paper>
      )
      }
    </Fragment>
  );
};

SortSummaryPanel.propTypes = {
  inboundContainers: PropTypes.arrayOf(PropTypes.object),
  setListOfContainersToPrint: PropTypes.func.isRequired,
  paginationOffForPrint: PropTypes.bool.isRequired,
};

export default SortSummaryPanel;

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, List, ListItem, MenuList, MenuItem, Paper, Typography} from '@material-ui/core';
import {ChevronLeft, DeleteForever, Edit} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import flowRight from 'lodash.flowright';
import {Firestore} from 'config.js';
import {ShippingNamePanel, TypePanel} from 'panels/';
import ModalMaterial from './ModalMaterial.js';
import {connect} from 'react-redux';

class ViewMaterialDetails extends Component {
  state = {
    material: null,
    modalMaterial: null,
    unsubscribe: {},
    shippingNames: null,
  };
  componentDidMount() {
    const listener = Firestore.collection('Tracking-Materials').doc(this.props.material.MaterialId).collection('ProperShippingNames').onSnapshot(snapShot => {
      this.setState({
        shippingNames: snapShot.docs.map(doc => ({...doc.data(), id: doc.id})),
      });
    });
    this.setState({unsubscribe: {...this.state.unsubscribe, listener}});
  }
  componentWillUnmount() {
    const {unsubscribe} = this.state;
    // eslint-disable-next-line guard-for-in
    for (const listener in unsubscribe) {
      unsubscribe[listener]();
    }
  }
  createCodeList = codes => {
    return codes.map((c, i, a) => {
      if (i === a.length - 1) {
        return <span key={i}>{c}</span>;
      }
      else {
        return <span key={i}>{`${c}, `}</span>;
      }
    });
  };
  render() {
    const {classes, material, types} = this.props;
    const core = {
      buttonBack: {
        color: 'primary',
        variant: 'outlined',
        onClick: () => this.props.onBackClick(),
      },
      buttonEdit: {
        color: 'primary',
        size: 'small',
        style: {marginLeft: '8px'},
        variant: 'outlined',
        onClick: () => this.setState({modalMaterial: {newMaterial: false, material: material}}),
      },
      buttonExpand: {
        color: 'primary',
        size: 'small',
        style: {marginLeft: '8px'},
        variant: 'outlined',
        onClick: this.handleExpandButton,
      },
      menuSection: {
        style: {display: 'flex', flexDirection: 'column', maxWidth: '100%'},
      },
      menuTitle: {
        variant: 'subtitle1',
        style: {width: '100%', textAlign: 'left', marginLeft: '16px', fontWeight: '600'},
      },
      menuPrimaryText: {
        style: {lineHeight: 1.5, fontSize: '1.0rem'},
        variant: 'caption',
      },
      menuSecondaryText: {
        noWrap: true,
        style: {lineHeight: 1.2, fontSize: '0.85rem'},
        variant: 'subtitle1',
      },
      panelShippingName: {
        material,
        shippingNames: this.state.shippingNames || null,
      },
      panelTypes: {
        material,
        types: types[material.MaterialId] || [],
      },
      modal: key => {
        return {
          [key]: this.state[key],
          close: () => this.setState({[key]: null}),
        };
      },
    };
    if (!material) return <div />;
    return (
      <Fragment>
        <div className='sideScrollingContainer'>
          {this.state.modalMaterial && <ModalMaterial {...core.modal('modalMaterial')} />}
          <Button {...core.buttonBack}>
            <ChevronLeft className={classes.extendedIcon} />
            Back
          </Button>
          <Button {...core.buttonEdit}>
            <Edit className={classes.extendedIcon} />
            Edit
          </Button>
        </div>
        <Grid spacing={5} container style={{marginTop: '8px'}}>
          <Grid item xs={12} md={3}>
            <Paper {...core.paperHeader}>
              <DeleteForever style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                <ListItem style={{flexDirection: 'column'}}>
                  <Typography variant='h6' style={{lineHeigt: '.9', textAlign: 'center'}}>
                    {material.UnitDetails.MaterialName}
                  </Typography>
                  <Typography variant='caption' style={{lineHeigt: '.9', textAlign: 'center'}}>
                    {material.MaterialId}
                  </Typography>
                </ListItem>
                <ListItem style={{flexDirection: 'column'}}>
                  <MenuList className={classes.menuStyles}>
                    <StyledMenuItem disableGutters className={classes.disabled}>
                      <Typography {...core.menuTitle}>Material Information</Typography>
                    </StyledMenuItem>
                    {material.UnitDetails.MaterialNameShort && (
                      <StyledMenuItem className={classes.disabled}>
                        <div {...core.menuSection}>
                          <Typography {...core.menuPrimaryText}>Short Name</Typography>
                          <Typography {...core.menuSecondaryText}>{material.UnitDetails.MaterialNameShort}</Typography>
                        </div>
                      </StyledMenuItem>
                    )}
                    <StyledMenuItem className={classes.disabled}>
                      <div {...core.menuSection}>
                        <Typography {...core.menuPrimaryText}>Category</Typography>
                        <Typography {...core.menuSecondaryText}>{material.UnitDetails.Category}</Typography>
                      </div>
                    </StyledMenuItem>
                    <StyledMenuItem className={classes.disabled}>
                      <div {...core.menuSection}>
                        <Typography {...core.menuPrimaryText}>CA Codes</Typography>
                        <Typography {...core.menuSecondaryText}>
                          {this.createCodeList(material.WasteCodes.CA)}
                        </Typography>
                      </div>
                    </StyledMenuItem>
                    <StyledMenuItem className={classes.disabled}>
                      <div {...core.menuSection}>
                        <Typography {...core.menuPrimaryText}>RCRA Codes</Typography>
                        <Typography {...core.menuSecondaryText}>
                          {this.createCodeList(material.WasteCodes.RCRA)}
                        </Typography>
                      </div>
                    </StyledMenuItem>
                  </MenuList>
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <TypePanel {...core.panelTypes} />
            <ShippingNamePanel {...core.panelShippingName} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});
const StyledMenuItem = withStyles({
  root: {
    minHeight: '0px',
    whiteSpace: 'normal',
  },
})(MenuItem);
const mapStateToProps = state => ({
  types: state.firestore.types,
});
ViewMaterialDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  onBackClick: PropTypes.func.isRequired,
  material: PropTypes.object,
  types: PropTypes.object,
};
export default flowRight(
  connect(mapStateToProps),
  withStyles(styles, {withTheme: true}),
)(ViewMaterialDetails);

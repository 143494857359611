import React, {Component, Fragment} from 'react';
import {Typography} from '@material-ui/core';

class CustomerProfiles extends Component {
  render() {
    return (
      <Fragment>
        <Typography variant='h5' gutterBottom>
          Customer Profiles
        </Typography>
        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
        </ul>
      </Fragment>
    );
  }
}

export default CustomerProfiles;

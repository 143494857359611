import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, Grid, Collapse, DialogActions, DialogContentText, MenuItem} from '@material-ui/core';
import {Alert, Formik} from '@kbi/component-library';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import * as yup from 'yup';
import {Firestore} from 'config.js';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import firebase from 'firebase/app';
import 'firebase/firestore';
const {SelectField, FormikForm, FormButton, SubmitButton, DateField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const stageArray = ['basic', 'success'];

const NewProcessFormModal = props => {
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [newProcessFormId, setNewProcessFormId] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const {facilityUnits, types, materials} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const defaultFormik = props.selectedProcessForm ? {
    'Date': moment(props.selectedProcessForm.Date).format('YYYY-MM-DD'),
    'Facility': {value: props.selectedProcessForm.Facility},
    'Facility Unit': facilityUnits.ref[props.selectedProcessForm.FacilityUnitRef],
    'Shift': props.selectedProcessForm.Shift,
    'Yield': props.selectedProcessForm.YieldRef,
    'Type': props.selectedProcessForm.TypeRef,
    'Flag': props.selectedProcessForm.Flag,
  } : {
    'Date': moment().format('YYYY-MM-DD'),
    'Facility': '',
    'Facility Unit': '',
    'Shift': '',
    'Yield': '',
    'Type': '',
    'Flag': '',
  };

  const handleSubmit = (values, actions) => {
    setDeleteConfirmation(false);
    const objectForFirestore = {
      Date: firebase.firestore.Timestamp.fromDate(new Date(new Date(values.Date).setHours(24, 0, 0, 0))), // sets timestamp to midnight of the current date.
      Facility: values.Facility.value,
      Shift: values.Shift,
      FacilityUnitRef: values['Facility Unit'].FacilityId,
      YieldRef: values.Yield,
      TypeRef: values.Type,
      Flag: values.Flag,
      FormType: 'Process',
      Completed: false,
      Active: true,
    };
    let processFormRef;
    if (props.selectedProcessForm) {
      processFormRef = Firestore.collection('Tracking-Forms').doc(props.selectedProcessForm.id);
      objectForFirestore['System.UpdatedOn'] = firebase.firestore.Timestamp.now();
      objectForFirestore['System.UpdatedBy'] = {Name: currentUser.displayName, Email: currentUser.email};
      processFormRef.update(objectForFirestore).then(() => {
        actions.setSubmitting(false);
        if (!props.selectedProcessForm) setNewProcessFormId(processFormRef.id);
        setStage(stage + 1);
      })
        .catch(error => {
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    }
    else {
      processFormRef = Firestore.collection('Tracking-Forms').doc();
      objectForFirestore.System = {
        CreatedBy: {Name: currentUser.displayName, Email: currentUser.email},
        CreatedOn: firebase.firestore.Timestamp.now(),
      };
      processFormRef.set(objectForFirestore).then(() => {
        actions.setSubmitting(false);
        if (!props.selectedProcessForm) setNewProcessFormId(processFormRef.id);
        setStage(stage + 1);
      })
        .catch(error => {
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    }
  };
  const handleDelete = () => {
    if (!deleteConfirmation) {
      setDeleteConfirmation(true);
      return;
    }
    else {
      setSubmitting(true);
      const formRef = Firestore.collection('Tracking-Forms').doc(props.selectedProcessForm.id);
      formRef.update({Active: false})
        .then(() => {
          setStage(stage + 1);
          setSubmitting(false);
        })
        .catch(error => {
          setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    }
  };
  const clearFlagAndTypeField = form => {
    form.setFieldValue('Flag', '');
    form.setFieldValue('Type', '');
  };
  const createFlagOptions = formikProps => {
    if (formikProps.values.Yield && formikProps.values.Yield !== 'mixed') {
      const arrayOfFlags = [...materials.ref[formikProps.values.Yield].Flags.sort((a, b) => a.Name > b.Name ? 1 : -1)];
      if (materials.ref[formikProps.values.Yield].FlagStatus === 'Optional') {
        arrayOfFlags.unshift({Name: '(none)'});
      }
      return arrayOfFlags
        .map((flag, index) =>
          <MenuItem key={index} value={flag.Name === '(none)' ? '' : flag.Name}>{flag.Name}</MenuItem>);
    }
    else return [];
  };
  const createTypeOptions = formikProps => {
    if (formikProps.values.Yield && formikProps.values.Yield !== 'mixed') {
      return types[formikProps.values.Yield].filter(type => type.Active).map((type, index) => (
        <MenuItem key={index} value={type.id}>{type.TypeName}</MenuItem>),
      );
    }
    else return [];
  };
  const validationSchema = yup.object().shape({
    'Date': yup.date().required('Date is a required field.').max(new Date()),
    'Facility': yup.object().nullable().exists('Facility is a required field.'),
    'Shift': yup.string().required('Shift is a required field.'),
    'Facility Unit': yup.object().nullable().exists('Facility Unit is a required field.'),
    'Yield': yup.string().required('Yield is a required field.'),
    'Type': yup.string().when('Yield', {
      is: value => materials.ref[value],
      then: yup.string().required('Type is a required field.'),
      otherwise: yup.string(),
    }),
    'Flag': yup.string().when('Yield', {
      is: value => materials.ref[value] && materials.ref[value].FlagStatus === 'Required' ? true : false,
      then: yup.string().required('Flag is required for this material.'),
      otherwise: yup.string(),
    }),
  });

  const core = {
    dialog: {
      open: props.open,
      fullWidth: true,
      maxWidth: 'sm',
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    closeButton: {
      color: 'primary',
      variant: 'text',
      onClick: () => {
        if (newProcessFormId) {
          props.history.push(`/production/process/${newProcessFormId}`);
        }
        else if (deleteConfirmation) {
          props.history.push('/production/process');
        }
        else props.close();
      },
    },
    cancelButton: {
      color: 'secondary',
      onClick: props.close,
      variant: 'text',
      disabled: submitting,
    },
    submitButton: {
      variant: 'text',
      text: 'Submit',
      color: 'primary',
      disabled: submitting,
    },
    deleteButton: {
      disabled: submitting,
      variant: 'text',
      color: 'secondary',
      onClick: handleDelete,
    },
    formikOptions: {
      initialValues: {
        ...defaultFormik,
      },
      onSubmit: handleSubmit,
      validationSchema: validationSchema,
    },
    submitConfirmation: {
      text: !props.selectedProcessForm ? 'Process form sucessfully created. You will be redirected there upon closing this form.' :
        `Process Form sucessfully ${deleteConfirmation ? 'deleted' : 'updated'}.`,
      stage: stageArray[stage],
      delete: deleteConfirmation,
    },
    flagField: formikProps => ({
      required: formikProps.values.Yield && materials.ref[formikProps.values.Yield]?.FlagStatus === 'Required' ? true : false,
      disabled: !formikProps.values.Yield || formikProps.values.Yield === 'mixed' || materials.ref[formikProps.values.Yield]?.FlagStatus === 'N/A' ?
        true : false,
    }),
    typeField: formikProps => ({
      required: formikProps.values.Yield !== 'mixed',
      disabled: !formikProps.values.Yield || formikProps.values.Yield === 'mixed' || formikProps.isSubmitting,
    }),
    yieldField: formikProps => ({
      disabled: !formikProps.values['Facility Unit'],
      onChange: ({field, form}) => {
        clearFlagAndTypeField(form);
      },
    }),
    facilityUnitField: formikProps => ({
      required: true,
      name: 'Facility Unit',
      disabled: formikProps.isSubmitting || formikProps.isValidating ? true : false,
      options: facilityUnits.active.filter(unit => unit.Facility === formikProps.values.Facility?.value).sort((a, b) => a.Name > b.Name ? 1 : -1),
      optionKey: 'Name',
      loading: !formikProps.values.Facility,
      loadingText: 'Please select a facility to populate options',
      onChange: ({form}) => {
        form.setFieldValue('Yield', '');
        clearFlagAndTypeField(form);
      },
    }),
    facilityField: {
      name: 'Facility',
      required: true,
      options: facilitiesConst.map(facility => ({value: facility})),
      onChange: ({form}) => {
        form.setFieldValue('Facility Unit', '');
        form.setFieldValue('Yield', '');
        clearFlagAndTypeField(form);
      },
    },
  };
  return (
    <Dialog {...core.dialog}>
      <FormikForm {...core.formikOptions}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>{props.selectedProcessForm ? 'Edit Process Form' : 'New Process Form'}</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter the process form details:</DialogContentText>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DateField fast name='Date' required
                      disabled={formikProps.isSubmitting || formikProps.isValidating || props.selectedProcessForm ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteObject {...core.facilityField} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField fast name='Shift' required>
                      <MenuItem value='First'>First</MenuItem>
                      <MenuItem value='Second'>Second</MenuItem>
                      <MenuItem value='Third'>Third</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteObject {...core.facilityUnitField(formikProps)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='Yield' required
                      {...core.yieldField(formikProps)}
                    >
                      {formikProps.values['Facility Unit'] ? materials.yieldList.map((yieldObj, index) => {
                        for (const sectionName in formikProps.values['Facility Unit'].AllowedItems) {
                          // eslint-disable-next-line max-len
                          if (formikProps.values['Facility Unit'].AllowedItems[sectionName].Yields.find(yieldRef => yieldRef === yieldObj.MaterialId) ||
                            formikProps.values['Facility Unit'].AllowedItems[sectionName].Materials.find(yieldRef => yieldRef === yieldObj.MaterialId)) {
                            return <MenuItem key={index} value={yieldObj.MaterialId}>{yieldObj.UnitDetails.MaterialName}</MenuItem>;
                          }
                        }
                        // eslint-disable-next-line array-callback-return
                        return;
                      }) : <MenuItem />}
                      {formikProps.values['Facility Unit']?.MixedYields && <MenuItem value='mixed'>Mixed</MenuItem>}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='Type' {...core.typeField(formikProps)} >
                      {createTypeOptions(formikProps)}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='Flag' {...core.flagField(formikProps)} >
                      {createFlagOptions(formikProps)}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton {...core.closeButton}>Close</FormButton>
              </DialogActions>
            ) :
              <DialogActions style={{justifyContent: 'space-between'}}>
                {props.selectedProcessForm ? (
                  <Fragment>
                    <FormButton {...core.deleteButton}>{deleteConfirmation ? 'Confirm?' :'Delete'}</FormButton>
                    <div>
                      <FormButton {...core.cancelButton}>Cancel</FormButton>
                      <SubmitButton {...core.submitButton}>Submit</SubmitButton>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <FormButton {...core.cancelButton}>Cancel</FormButton>
                    <SubmitButton {...core.submitButton}>Submit</SubmitButton>
                  </Fragment>
                )}
              </DialogActions>}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewProcessFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectedProcessForm: PropTypes.object,
};

export default flowright(withRouter)(NewProcessFormModal);

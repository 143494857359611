import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Button, Collapse, Grid, TextField} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import flowRight from 'lodash.flowright';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
const cleanState = {
  name: {
    display: 'Shipping Name',
    error: null,
    value: '',
  },
  stage: 'basic',
  submitting: false,
  formError: '',
  deleteConfirmation: false,
  deleting: false,
};

class ViewShippingNameModal extends Component {
  state = {...cleanState};
  componentDidMount() {
    if (this.props.edit) {
      this.setState(prevState => ({name: {...prevState.name, value: this.props.name}}));
    }
  }
  deleteShippingName = () => {
    this.setState({deleting: true});
    Firestore.collection('Tracking-Materials')
      .doc(this.props.material.MaterialId)
      .collection('ProperShippingNames')
      .doc(this.props.id)
      .delete()
      .then(() => this.setState({deleting: false, stage: 'success'}))
      .catch(err => {
        console.error('Error removing shipping name');
      });
  };
  determineButtonsToShow = () => {
    const core = {
      deleteButton: {
        color: 'secondary',
        onClick: () => {
          this.setState({deleteConfirmation: true});
        },
      },
      confirmButton: {
        color: 'secondary',
        onClick: this.deleteShippingName,
        text: 'Confirm?',
        loading: this.state.deleting,
        disabled: this.state.submitting,
      },
      cancelButton: {
        color: 'primary',
        onClick: this.handleClose,
      },
      submitButton: {
        text: 'Submit',
        color: 'primary',
        loading: this.state.submitting,
        onClick: this.handleSubmit,
        disabled: this.state.deleting,
      },
    };
    if (this.state.stage === 'success') {
      return (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.cancelButton}>Close</Button>
        </DialogActions>
      );
    }
    else {
      if (this.state.deleteConfirmation) {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <SubmitButton {...core.confirmButton} />
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
      else {
        return (
          <DialogActions style={{justifyContent: this.props.edit ? 'space-between' : 'flex-end'}}>
            {this.props.edit ? <Button {...core.deleteButton}>Delete</Button> : null}
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
    }
  };
  handleClose = () => {
    this.setState({...cleanState});
    this.props.close();
  };
  handleSubmit = () => {
    if (!this.state.name.value) {
      this.setState(prevState => ({name: {...prevState.name, error: 'This is a required field'}}));
      return;
    }
    this.setState({submitting: true});
    if (this.props.edit) {
      Firestore.collection('Tracking-Materials')
        .doc(this.props.material.MaterialId)
        .collection('ProperShippingNames')
        .doc(this.props.id)
        .update({
          'ShippingName': this.state.name.value,
          'System.ModifiedBy': this.props.currentUser.displayName,
          'System.ModifiedOn': firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          this.setState({submitting: false, stage: 'success'});
        })
        .catch(error => {
          this.setState({submitting: false, formError: 'Error updating data. Try again.'});
        });
    }
    else {
      const newShippingName = {
        System: {
          CreatedBy: this.props.currentUser.displayName,
          CreatedOn: firebase.firestore.Timestamp.now(),
          ModifiedBy: this.props.currentUser.displayName,
          ModifiedOn: firebase.firestore.Timestamp.now(),
        },
        ShippingName: this.state.name.value,
      };
      Firestore.collection('Tracking-Materials')
        .doc(this.props.material.MaterialId)
        .collection('ProperShippingNames')
        .doc()
        .set(newShippingName)
        .then(() => {
          this.setState({submitting: false, stage: 'success'});
        })
        .catch(error => {
          this.setState({submitting: false, formError: 'Error submitting data. Try again.'});
        });
    }
  };
  render() {
    const {newShippingNameModalOpen} = this.props;
    const core = {
      dialog: {
        open: newShippingNameModalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      textField: key => {
        return {
          error: Boolean(this.state[key].error),
          fullWidth: true,
          helperText: this.state[key].error,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          onChange: e => {
            const value = e.target.value;
            this.setState({
              [key]: {
                display: this.state[key].display,
                error: this.state[key].error,
                value,
              },
            });
          },
        };
      },
      submitConfirmation: {
        text: this.state.deleteConfirmation ?
          'Shipping name successfully deleted.' :
          'New shipping name successfully added.',
        stage: this.state.stage,
        delete: this.state.deleteConfirmation,
      },
    };
    return (
      <Dialog {...core.dialog}>
        {this.state.stage !== 'success' && <DialogTitle>{this.props.edit ? 'Edit Proper Shipping Name' : 'New Proper Shipping Name'}</DialogTitle>}
        <DialogContent>
          <Fragment>
            <Collapse in={this.state.stage === 'basic'}>
              <DialogContentText>Enter Proper Shipping Name</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField {...core.textField('name')} />
                </Grid>
              </Grid>
              <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
            </Collapse>
          </Fragment>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {this.determineButtonsToShow()}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};
ViewShippingNameModal.propTypes = {
  close: PropTypes.func.isRequired,
  material: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  newShippingNameModalOpen: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
};
export default flowRight(
  connect(
    mapStateToProps,
    null,
  ),
)(ViewShippingNameModal);

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper} from 'components/';
import {Paper, Typography, Button, withStyles, LinearProgress} from '@material-ui/core';
import {Add, Send, Search} from '@material-ui/icons';
import {AssignmentTurnedInOutlined} from '@material-ui/icons';
import moment from 'moment';
import {connect} from 'react-redux';
import flowright from 'lodash.flowright';
import {ConsolidationModal} from './Consolidate/';
import {MovementFormModal} from 'components/';

class Consolidate extends React.Component {
  state = {
    consolidationForms: null,
    consolidationFormModalOpen: false,
    movementFormModalOpen: false,
  }
  componentDidMount() {
    if (this.props.consolidationForms && this.props.facilityUnits) {
      this.setState({
        consolidationForms: this.props.consolidationForms.list
          .map(form => ({...form,
            facilityUnitName: this.props.facilityUnits.ref[form.FacilityUnitRef].Name,
            materialName: this.props.materials.ref[form.MaterialRef]?.UnitDetails.MaterialName,
          })),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.consolidationForms !== this.props.consolidationForms ||
      (prevProps.facilityUnits !== this.props.facilityUnits && this.props.consolidationForms)) {
      this.setState({
        consolidationForms: this.props.consolidationForms.list
          .map(form => ({
            ...form,
            facilityUnitName: this.props.facilityUnits.ref[form.FacilityUnitRef].Name,
            materialName: this.props.materials.ref[form.MaterialRef]?.UnitDetails.MaterialName,
          })),
      });
    }
  }
  render() {
    if (!this.state.consolidationForms) return <LinearProgress />;
    const core = {
      paper: {
        className: this.props.classes.paper,
        style: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
      button: {
        variant: 'outlined',
      },
      consolidationFormButton: {
        onClick: () => {
          this.setState({consolidationFormModalOpen: true});
        },
      },
      movementFormButton: {
        style: {marginLeft: '8px'},
        onClick: () => {
          this.setState({movementFormModalOpen: true});
        },
      },
      movementFormModal: {
        open: this.state.movementFormModalOpen,
        close: () => this.setState({movementFormModalOpen: false}),
      },
      consolidationModal: {
        close: () => this.setState({consolidationFormModalOpen: false}),
      },
      consolidationFormTable: {
        title: 'Production - Consolidation',
        data: this.state.consolidationForms,
        columns: [
          {accessor: 'Date', Header: 'Date', type: 'datetime'},
          {accessor: 'Facility', Header: 'Facility'},
          {accessor: 'facilityUnitName', Header: 'Facility Unit'},
          {accessor: 'ConsolidationContainer', Header: 'Consolidation Container'},
          {accessor: 'materialName', Header: 'Material'},
          {accessor: 'MixedLoad', Header: 'Mixed?', type: 'boolean'},
          {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
        ],
        sortInitial: [{id: 'Date', desc: true}],
        paginationInitialSize: 10,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Form Details',
            onClick: ({rowData}) => {
              this.props.history.push(`/production/consolidate/${rowData.id}`);
            },
          },
        ],
        actionsBar: [
          {
            icon: Add,
            text: 'New Consolidation Form',
            onClick: () => {
              this.setState({consolidationFormModalOpen: true});
            },
          },
          {
            icon: Send,
            text: 'New Movement Form',
            onClick: () => {
              this.setState({movementFormModalOpen: true});
            },
          },
        ],
      },
    };
    return (
      <div className="hidePrint">
        {this.state.consolidationForms.length ? (
          <TableWithPaper {...core.consolidationFormTable} />
        ) :
          <Fragment>
            <Typography variant="h6" gutterBottom>
              Production - Consolidation
            </Typography>
            <Paper {...core.paper}>
              <Typography variant="h4">Daily Production</Typography>
              <Typography variant="h6" gutterBottom>
                {moment().format('MM/DD/YYYY')}
              </Typography>
              <AssignmentTurnedInOutlined style={{fontSize: '10em'}} />
              <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                How would you like to start the day?
              </Typography>
              <div>
                <Button {...core.button} {...core.consolidationFormButton}>
                  New Consolidation Form
                </Button>
                <Button {...core.button} {...core.movementFormButton}>
                  New Movement Form
                </Button>
              </div>
            </Paper>
          </Fragment>
        }
        {this.state.movementFormModalOpen && <MovementFormModal {...core.movementFormModal} />}
        {this.state.consolidationFormModalOpen && <ConsolidationModal {...core.consolidationModal} />}
      </div>
    );
  }
}

const styles = theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});
const mapStateToProps = state => ({
  consolidationForms: state.firestore.consolidationForms,
  facilityUnits: state.firestore.facilityUnits,
  materials: state.firestore.materials,
});

Consolidate.propTypes = {
  classes: PropTypes.object,
  consolidationForms: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  facilityUnits: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  history: PropTypes.object.isRequired,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
  }),
};

export default flowright(connect(mapStateToProps), withStyles(styles, {withTheme: true}))(Consolidate);

import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from '@material-ui/core';
import {Grid, MenuItem} from '@material-ui/core';
import {shortNumberGenerator} from '@kbi/utility-library';
import {containerCodes, SubmitConfirmation} from 'components/';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {Firestore} from 'config.js';
import {number, object, string} from 'yup';
import sortBy from 'lodash.sortby';
const {AutoComplete, FormikForm, SelectField, WeightField, FormButton, SubmitButton} = Formik;

const ContainerModal = ({close, open}) => {
  const [stage, setStage] = useState('container');
  const {containers, facilityUnits, materials} = useSelector(state => state.firestore);

  const handleClose = useCallback(() => {
    setStage('container');
    close();
  }, [close]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'md',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);

  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      material: '',
      materialRef: '',
      containerCode: '',
      containerCodeType: '',
      flag: '',
      tareWeight: '',
      facility: '',
      facilityUnit: '',
      facilityUnitRef: '',
    },
    onSubmit: (values, actions) => {
      const containerData = {
        Active: true,
        ContainerCode: values.containerCode,
        ContainerCodeType: values.containerCodeType ? values.containerCodeType : '',
        FacilityUnitRef: values.facilityUnitRef,
        Flag: values.flag,
        Shard: parseInt(Math.random() * 5 + 1),
        InboundContainer: false,
        MaterialRef: values.materialRef,
        TareWeight: parseInt(values.tareWeight),
        AccumulationStartDate: new Date(),
        InventoryItems: [],
        NetWeight: 0,
      };
      const getShortNo = () => {
        let testNumber = null;
        do {
          testNumber = shortNumberGenerator();
        } while (containers.ref[testNumber]);
        return testNumber;
      };
      const submitData = async (shortNo, containerData) => {
        try {
          await Firestore.collection('Tracking-Containers').doc(shortNo).set(containerData);
          actions.setSubmitting(false);
          setStage('success');
        }
        catch (error) {
          actions.setSubmitting(false);
          actions.setStatus({alert: error.message});
        }
      };
      const shortNo = getShortNo();
      submitData(shortNo, containerData);
    },
    validationSchema: object().shape({
      material: string().label('Material').nullable().required(),
      containerCode: string().label('Container Code').nullable().required(),
      containerCodeType: string().label('Container Code Type').when('containerCode', {
        is: value => {
          let count = 0;
          containerCodes.forEach(item => {
            if (item[0] === value) count = item[1].length;
          });
          return count !== 0 ? true : false;
        },
        then: string().required(),
        otherwise: string().notRequired(),
      }),
      flag: string().label('Flag').when('materialRef', {
        is: materialRef => {
          let count = 0;
          let flagRequired = false;
          if (materials.ref[materialRef]) {
            count = materials.ref[materialRef].Flags.length;
            flagRequired = Boolean(materials.ref[materialRef].FlagStatus === 'Required');
          }
          return Boolean(count !== 0 && flagRequired);
        },
        then: string().required(),
        otherwise: string().notRequired(),
      }),
      tareWeight: number().label('Tare Weight').required(),
      facility: string().label('Facility').required(),
      facilityUnit: string().label('Facility Unit').nullable().required(),
    }),
  }), [containers.ref, materials.ref]);

  const facilities = useMemo(() => {
    const tracker = {};
    if (facilityUnits) {
      facilityUnits.active.forEach(unit => {
        tracker[unit.Facility] = true;
      });
      return Object.keys(tracker);
    }
    return [];
  }, [facilityUnits]);

  const autoMaterialProps = useMemo(() => {
    const flatMaterials = materials.completeList.map(material => {
      return {...material, MaterialName: material.UnitDetails.MaterialName};
    });
    return {
      label: 'Material',
      name: 'material',
      options: sortBy(flatMaterials, ['MaterialName']),
      optionKey: 'MaterialName',
      required: true,
      onChange: ({form, field}) => {
        form.setFieldValue('flag', '');
        form.setFieldValue('materialRef', field.value ? field.value.MaterialId : '');
        form.setFieldTouched('flag', false);
      },
    };
  }, [materials.completeList]);

  const autoContainerCodeProps = useMemo(() => {
    const flatContainer = containerCodes.map(codeArray => {
      return {value: codeArray[0]};
    });
    return {
      label: 'Container Code',
      name: 'containerCode',
      options: flatContainer,
      optionKey: 'value',
      required: true,
      onChange: ({form, field}) => {
        form.setFieldValue('containerCodeType', '');
        form.setFieldTouched('containerCodeType', false);
      },
    };
  }, []);

  const autoContainerCodeTypeProps = useCallback(formikProps => {
    const {containerCode} = formikProps.values;
    const getCodeTypes = () => {
      let codeTypes = [];
      containerCodes.forEach(item => {
        if (item[0] === containerCode) codeTypes = item[1];
      });
      return codeTypes;
    };
    const codeTypes = containerCode ? getCodeTypes() : [];
    return {
      disabled: !containerCode || codeTypes.length === 0,
      label: 'Container Code Type',
      name: 'containerCodeType',
      noOptionsText: 'The selected container code has no types',
      options: codeTypes.map(item => {
        return {value: item};
      }),
      optionKey: 'value',
      required: (() => {
        let count = 0;
        const {containerCode} = formikProps.values;
        if (containerCode) {
          containerCodes.forEach(code => {
            if (code[0] === containerCode) count = code[1].length;
          });
        }
        return count !== 0 ? true : false;
      })(),
    };
  }, []);

  const autoFlagProps = useCallback(formikProps => {
    const {materialRef} = formikProps.values;
    return {
      disabled: !materials?.ref[materialRef] || materials.ref[materialRef].Flags.length === 0,
      label: 'Flag',
      name: 'flag',
      noOptionsText: 'The selected Material has no flags',
      options: materials?.ref[materialRef]?.Flags || [],
      optionKey: 'Name',
      required: (() => {
        if (materials?.ref[materialRef]?.Flags === undefined) return false;
        else if (materials?.ref[materialRef]?.FlagStatus === 'Optional') return false;
        else if (materials?.ref[materialRef]?.Flags.length === 0) return false;
        else return true;
      })(),
    };
  }, [materials]);

  const autoFacilityUnitProps = useCallback(formikProps => {
    const {facility} = formikProps.values;
    return {
      disabled: !facility,
      label: 'Facility Unit',
      name: 'facilityUnit',
      options: facilityUnits.active.filter(unit => {
        const formFacility = formikProps.values.facility;
        if (unit.Facility === formFacility) return true;
        return false;
      }),
      optionKey: 'Name',
      required: true,
      onChange: ({form, field}) => form.setFieldValue('facilityUnitRef', field.value ? field.value.FacilityId : ''),
    };
  }, [facilityUnits.active]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formikProps => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Add Empty Container
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'container'}>
                <DialogContentText>Complete form to add a new container to inventory.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <AutoComplete {...autoMaterialProps} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <AutoComplete {...autoContainerCodeProps} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <AutoComplete {...autoContainerCodeTypeProps(formikProps)} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <AutoComplete {...autoFlagProps(formikProps)} />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <WeightField name='tareWeight' label='Tare Weight' decimal={0} required />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <SelectField name='facility' label='Facility' required>
                      {facilities.map(facility => (
                        <MenuItem value={facility} key={facility}>{facility}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <AutoComplete {...autoFacilityUnitProps(formikProps)} />
                  </Grid>
                </Grid>
                <Alert in={Boolean(formikProps.status.alert)} text={formikProps.status.alert} severity='error' />
              </Collapse>
              <SubmitConfirmation stage={stage} text='New Container successfully added to inventory.' />
            </DialogContent>
            {stage === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton onClick={handleClose} variant='text' color='primary'>Close</FormButton>
              </DialogActions>
            ) : <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={handleClose}>Cancel</FormButton>
              <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
            </DialogActions>}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ContainerModal.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
export default ContainerModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  Grid,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import {ExpandMore, Add, Search, Edit} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {ShippingDocumentModal, EditLineItemModal} from './ShippingDocumentPanel/';
import {withRouter} from 'react-router-dom';

const ShippingDocumentPanel = props => {
  const styles = useStyles();
  const {shippingDocuments} = props;
  const [selectedShippingDocument, setSelectedShippingDocument] = useState(null);
  const [shippingDocumentModalOpen, setShippingDocumentModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (selectedShippingDocument) {
      // this is to make sure the selectedShippingDocument gets updated when a new item gets added or deleted from it.
      setSelectedShippingDocument(props.shippingDocuments.find(document => document.DocumentNumber === selectedShippingDocument.DocumentNumber));
    }
  }, [props.shippingDocuments]);
  useEffect(() => {
    if (props.match.params.ShippingDocId) {
      setSelectedShippingDocument(props.shippingDocuments.find(document => document.id === props.match.params.ShippingDocId));
      setExpanded(true);
    }
  }, [props.match.params]);

  const details = () => {
    if (shippingDocuments.length) {
      return (
        <Table {...core.shippingDocumentTable} />
      );
    }
    else {
      return (
        <Grid container item xs={12}
          justify='space-between' style={{padding: '16px'}}
        >
          <Grid item>
            <Typography>No shipping documents have been added to this inbound load.</Typography>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      );
    }
  };
  const onEditDocumentClick = shippingDoc => {
    setSelectedShippingDocument(shippingDoc);
    setShippingDocumentModalOpen(true);
  };

  const core = {
    addShippingDocumentButton: {
      variant: 'outlined',
      onClick: e => {
        e.stopPropagation();
        setShippingDocumentModalOpen(true);
        setSelectedShippingDocument(null);
      },
    },
    shippingDocumentTable: {
      columns: [
        {accessor: 'DocumentNumber', Header: 'Document Number'},
        {accessor: 'DocumentType', Header: 'Document Type'},
        {accessor: 'PieceCount', Header: 'Piece Count', type: 'numeric'},
        {accessor: 'PONumber.Number', Header: 'PO Number'},
        {accessor: 'generatorName', Header: 'Generator'},
        {accessor: 'weightTicketString', Header: 'Weight Tickets'},
      ],
      data: shippingDocuments,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Line Items',
          onClick: ({rowData}) => setSelectedShippingDocument(rowData),
        },
        {
          icon: Edit,
          tooltip: 'Edit Shipping Document',
          hide: !props.selectedInbound.InProgress,
          onClick: ({rowData}) => onEditDocumentClick(rowData),
        },
      ],
    },
    shippingDocumentModal: {
      open: shippingDocumentModalOpen,
      close: () => {
        setShippingDocumentModalOpen(false);
        setSelectedShippingDocument(null);
      },
      selectedInbound: props.selectedInbound,
      listOfWeightTickets: props.listOfWeightTickets,
      selectedShippingDocument,
    },
    lineItemsTable: selectedShippingDocument && {
      columns: props.selectedInbound.InProgress ? [
        {accessor: 'Line', Header: 'Line No.'},
        {accessor: 'PieceNumber', Header: 'Piece No.'},
        {accessor: 'AcceptedName', Header: 'Accepted'},
        {accessor: 'Flag', Header: 'Flag', filter: 'includes'},
        {accessor: 'Status', Header: 'Status'},
        {accessor: 'NetWeight', Header: 'Net Weight'},
        {accessor: 'containerString', Header: 'Container'},
        {accessor: 'Classification', Header: 'Classification'},
      ] : [
        {accessor: 'Line', Header: 'Line No.'},
        {accessor: 'PieceNumber', Header: 'Piece No.'},
        {accessor: 'ContainerRef', Header: 'Short No.'},
        {accessor: 'AcceptedName', Header: 'Accepted'},
        {accessor: 'Flag', Header: 'Flag', filter: 'includes'},
        {accessor: 'Status', Header: 'Status'},
        {accessor: 'NetWeight', Header: 'Net Weight'},
        {accessor: 'containerString', Header: 'Container'},
        {accessor: 'Classification', Header: 'Classification'},
      ],
      sortInitial: [{id: 'Line', desc: false}],
      data: selectedShippingDocument.LineItems.map(item => (
        {...item, containerString: `${item.ContainerCode}${item.ContainerCodeType ? `- ${item.ContainerCodeType}` : ''}`}
      )),
      actionsPerRow: [
        {
          tooltip: 'Edit Line Item',
          icon: props.selectedInbound.InProgress ? Edit : Search,
          onClick: ({rowData}) => {
            setEditItemModalOpen(true);
            setSelectedLineItem(rowData);
          },
        },
      ],
      actionsBar: !props.selectedInbound.InProgress ? [] : [
        {
          text: 'Add Line Item',
          icon: Add,
          // hide: !props.selectedInbound.InProgress,
          onClick: () => {
            setEditItemModalOpen(true);
          },
        },
      ],
    },
    editLineItemModal: {
      open: editItemModalOpen,
      close: () => {
        setSelectedLineItem(null);
        setEditItemModalOpen(false);
      },
      selectedLineItem,
      shippingDocument: selectedShippingDocument || {},
      selectedInbound: props.selectedInbound,
    },
  };

  return (
    <Fragment>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography>Shipping Documents</Typography>
            </div>
            {expanded && props.selectedInbound.InProgress ? (
              <div>
                {props.listOfWeightTickets.length || props.selectedInbound.Facility === 'Brea-1125' ?
                  <Button {...core.addShippingDocumentButton}>Add Shipping Document</Button> :
                  <Button color='secondary' variant='outlined'>Add a Weight Ticket To Start</Button>
                }
              </div>
            ) : null}
          </Grid>
        </AccordionSummary>
        {details()}
      </Accordion>
      <Collapse in={!!selectedShippingDocument && expanded}>
        {selectedShippingDocument && (
          <Accordion defaultExpanded={true} disabled className={styles.root}>
            <AccordionSummary className={styles.root}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Line Items</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>All line items on Shipping Document {selectedShippingDocument.DocumentNumber}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <Table {...core.lineItemsTable} />
          </Accordion>
        )}
      </Collapse>
      {shippingDocumentModalOpen && <ShippingDocumentModal {...core.shippingDocumentModal} />}
      {editItemModalOpen && <EditLineItemModal {...core.editLineItemModal} />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&.Mui-disabled': {
      backgroundColor: '#fff',
      opacity: '1',
    },
    'cursor': 'default',
  },
}));

ShippingDocumentPanel.propTypes = {
  shippingDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedInbound: PropTypes.object.isRequired,
  listOfWeightTickets: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(ShippingDocumentPanel);

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, Fragment, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {SubmitConfirmation, containerCodes} from 'components/';
import {useSelector} from 'react-redux';
import * as yup from 'yup';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {FieldArray} from 'formik';
import {Clear, Add} from '@material-ui/icons';
import get from 'lodash.get';
const {FormikForm, NumberField, TextField, SelectField, AutoCompleteObject, FormButton, SubmitButton, WeightField, validateAutoObject} = Formik;
validateAutoObject();

const stageArray = ['basic', 'lines', 'success'];
// https://github.com/jquense/yup#mixednullableisnullable-boolean--true-schema got this addMethod from here.
yup.addMethod(yup.object, 'uniqueProperty', function(propertyName, message, fieldName) {
  return this.test('unique', message, function(value) {
    if (!value || !get(value, propertyName)) {
      return true;
    }
    if (
      this.parent
        .filter(v => v !== value)
        .some(v => get(v, propertyName) === get(value, propertyName))
    ) {
      throw this.createError({
        path: `${this.path}.${fieldName || propertyName}`,
      });
    }

    return true;
  });
});

const NewLineModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [validationSchema, setValidatonSchema] = useState(null);
  const {materials, containers, facilityUnits} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const selectableContainers = useMemo(() => {
    const mapOfUnitsAtFacility = {};
    facilityUnits.list.forEach(unit => {
      if (unit.Facility === props.selectedOutbound.Facility) mapOfUnitsAtFacility[unit.FacilityId] = true;
    });
    return containers.list.filter(container => mapOfUnitsAtFacility[container.FacilityUnitRef]);
  }, []);

  const defaultFormik = props.selectedLine ?
    {
      lineNumber: props.selectedLine.LineNumber,
      material: materials.ref[props.selectedLine.Material.Ref],
      classification: props.selectedLine.Classification,
      containerCode: props.selectedLine.ContainerCode,
      quantity: props.selectedLine.Quantity,
      rcraCodes: props.selectedLine.RCRACodes.map(code => ({code})),
      caCodes: props.selectedLine.CACodes.map(code => ({code})),
      addedContainers: props.selectedLine.AddedContainers.map(container => ({
        shortNo: containers.ref[container.ShortNo],
        netWeight: container.NetWeight,
        material: props.selectedLine.Material.Name,
        tareWeight: container.TareWeight,
        grossWeight: container.GrossWeight,
        parentMaterial: props.selectedLine.Material.Name,
      })),
    } :
    {
      lineNumber: '',
      material: '',
      classification: '',
      containerCode: '',
      quantity: '',
      addedContainers: [],
      rcraCodes: props.selectedOutbound.DocumentType === 'HW Manifest' ? [{code: ''}] : [],
      caCodes: props.selectedOutbound.DocumentType === 'HW Manifest' ? [{code: ''}] : [],
    };

  useEffect(() => {
    setValidatonSchema(createValidationSchema());
  }, [stage]);


  const handleSubmit = (values, actions) => {
    if (stageArray[stage] === 'basic') {
      actions.setSubmitting(false);
      setStage(stage + 1);
    }
    else {
      const dataForSubcollection = createDataForFirestore(values, {currentUser});
      let firestoreRef;
      if (props.selectedLine) {
        firestoreRef = Firestore.collection('Tracking-Shipments').doc(props.selectedOutbound.id).collection('Lines').doc(props.selectedLine.id);
      }
      else {
        firestoreRef = Firestore.collection('Tracking-Shipments').doc(props.selectedOutbound.id).collection('Lines').doc();
      }
      firestoreRef.set(dataForSubcollection)
        .then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
        })
        .catch(() => {
          actions.isSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    }
  };
  const createValidationSchema = () => {
    if (stageArray[stage] === 'basic') {
      return yup.object({
        lineNumber: yup.string().required('Line is a required field.'),
        material: yup.object().exists('Materials is a required field.').nullable(),
        classification: yup.string().required('Classification is a required field.'),
        containerCode: yup.string().required('Container Code is a required field.'),
        quantity: yup.number().required('Quantity is a required field.').min(1, 'Quantity must be greater than 0.'),
        caCodes: yup.array().of(yup.object().shape({
          code: yup.string().required('CA Code is required.'),
        }).uniqueProperty('code', 'This code is already assigned.'),
        ),
        rcraCodes: yup.array().of(yup.object().shape({
          code: yup.string().required('RCRA Code is required.'),
        }).uniqueProperty('code', 'This code is already assigned.'),
        ),

      });
    }
    else if (stageArray[stage] === 'lines') {
      return yup.object({
        addedContainers: yup.array().of(
          yup.object().shape({
            shortNo: yup.object().exists('Short Number is a required field.').nullable(),
            material: yup.string().required('Material is a required field.'),
            grossWeight: yup.mixed().required('Gross Weight is a required field.'),
            tareWeight: yup.mixed().required('Tare Weight is a required field.'),
            netWeight: yup.mixed().required('Net Weight is a required field.'),
          }).uniqueProperty('shortNo.ShortNo', 'This container is already on this outbound.', 'shortNo'),
        ),
      });
    }
  };
  const determineMaterialWarning = (values) => {
    let warning = '';
    const listOfContainersThatDontMatch = [];
    values.addedContainers.forEach(container => {
      if (container.material && container.material !== values.material.UnitDetails.MaterialName) {
        listOfContainersThatDontMatch.push(container?.shortNo?.ShortNo);
      }
    });
    if (listOfContainersThatDontMatch.length) {
      // eslint-disable-next-line max-len
      warning = `There are containers on this line that do not match the material you selected. Please review before continuing. (${listOfContainersThatDontMatch.join(', ')})`;
    }
    return warning;
  };

  const core = {
    dialog: {
      open: props.open,
      maxWidth: 'md',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    cancelButton: {
      onClick: props.close,
      color: 'secondary',
      variant: 'text',
    },
    closeButton: {
      variant: 'text',
      onClick: props.close,
      color: 'primary',
    },
    backButton: {
      variant: 'text',
      color: 'primary',
      onClick: () => setStage(stage - 1),
    },
    submitButton: {
      variant: 'text',
      color: 'primary',
    },
    submitConfirmation: {
      text: props.selectedLine ? 'Line successfully updated.' : 'Line successfully created',
      stage: stageArray[stage],
    },
    materialAutoField: {
      label: 'Material',
      name: 'material',
      required: true,
      autoSelect: true,
      options: materials.completeList,
      optionKey: 'UnitDetails.MaterialName',
      onChange: ({field, form}) => {
        if (field.value && form.values.addedContainers.length) {
          form.values.rcraCodes.forEach((codeField, index) => {
            form.setFieldValue(`rcraCodes[${index}].code`, '');
          });
          form.values.addedContainers.forEach((containerFields, index) => {
            form.setFieldValue(`addedContainers[${index}].parentMaterial`, materials.ref[field.value.MaterialId].UnitDetails.MaterialName);
            form.setFieldTouched(`addedContainers[${index}].material`, true);
          });
          form.values.caCodes.forEach((codeField, index) => {
            form.setFieldValue(`caCodes[${index}].code`, '');
          });
        }
        else if (form.values.addedContainers.length) {
          form.values.addedContainers.forEach((containerFields, index) => {
            form.setFieldValue(`addedContainers[${index}].parentMaterial`, '');
          });
          form.values.rcraCodes.forEach((codeField, index) => {
            form.setFieldValue(`rcraCodes[${index}].code`, '');
          });
          form.values.caCodes.forEach((codeField, index) => {
            form.setFieldValue(`caCodes[${index}].code`, '');
          });
        }
        else {
          form.values.rcraCodes.forEach((codeField, index) => {
            form.setFieldValue(`rcraCodes[${index}].code`, '');
          });
          form.values.caCodes.forEach((codeField, index) => {
            form.setFieldValue(`caCodes[${index}].code`, '');
          });
        }
        if (form.values.quantity) {
          form.setFieldValue('addedContainers', createArrayOfAddedContainers(form.values));
        }
      },
    },
    addedContainerShortNo: (index) => ({
      name: `addedContainers.${index}.shortNo`,
      label: 'Container Short No.',
      required: true,
      autoSelect: true,
      options: selectableContainers,
      optionKey: 'ShortNo',
      onChange: ({field, form}) => {
        if (field.value) {
          form.setFieldValue(`addedContainers[${index}].material`, materials.ref[field.value.MaterialRef].UnitDetails.MaterialName);
          form.setFieldValue(`addedContainers[${index}].netWeight`, field.value.NetWeight);
          form.setFieldValue(`addedContainers[${index}].tareWeight`, field.value.TareWeight);
          form.setFieldValue(`addedContainers[${index}].grossWeight`, field.value.TareWeight + field.value.NetWeight);
        }
        else {
          form.setFieldValue(`addedContainers[${index}].shortNo`, '');
          form.setFieldValue(`addedContainers[${index}].material`, '');
          form.setFieldValue(`addedContainers[${index}].netWeight`, '');
          form.setFieldValue(`addedContainers[${index}].tareWeight`, '');
          form.setFieldValue(`addedContainers[${index}].grossWeight`, '');
        }
      },
    }),
    addCodebutton: (arrayHelpers) => ({
      color: 'primary',
      onClick: () => {
        arrayHelpers.push({code: ''});
      },
    }),
    clearCodeButton: (arrayHelpers) => ({
      color: 'secondary',
      onClick: () => {
        arrayHelpers.pop();
      },
    }),
    classificationField: {
      name: 'classification',
      label: 'Classification',
      required: true,
      onChange: ({form, field}) => {
        if (field.value === 'Hazardous Waste') {
          form.setFieldValue('rcraCodes', [{code: ''}]);
          form.setFieldValue('caCodes', [{code: ''}]);
        }
        else {
          form.setFieldValue('rcraCodes', []);
          form.setFieldValue('caCodes', []);
        }
      },
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm
        onSubmit={handleSubmit}
        initialValues={{...defaultFormik}}
        validationSchema={validationSchema}
        // due to a bug fix in formik 2.2.2, this must be set to false. there is a conflict between formik validation and autocomplete's autoSelect that is used on this form
        // until there is a valid solution to this issue, it is better to have this validation be off to allow the user to tab/scan through the fields
        validateOnBlur={false}
      >
        {({values}) => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>{props.selectedLine ? 'Edit Outbound Line' : 'New Outbound Line'}</DialogTitle>}
            <DialogContent style={{padding: '16px'}}>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Create line information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <TextField name='lineNumber' label='Line Number' required
                      fast
                    />
                  </Grid>
                  <Grid item xs={6} md>
                    <AutoCompleteObject {...core.materialAutoField} />
                  </Grid>
                  <Grid item xs={6} md>
                    <SelectField {...core.classificationField}>
                      {(props.selectedOutbound.DocumentType === 'HW Manifest' ?
                        ['Hazardous Waste', 'Universal Waste', 'Exempt per 49 CFR 173.159'] : ['Universal Waste', 'N/A']).map(classification => (
                        <MenuItem key={classification} value={classification}>{classification}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} md>
                    <SelectField name='containerCode' label='Container Code' required>
                      {containerCodes.map(codeArray => (
                        <MenuItem key={codeArray[0]} value={codeArray[0]}>{codeArray[0]}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <NumberField name='quantity' label='Quantity' required
                      onBlur={({field, form}) => {
                        if (values.quantity && values.material) {
                          form.setFieldValue('addedContainers', createArrayOfAddedContainers(values));
                        }
                      }}
                    />
                  </Grid>
                  {props.selectedOutbound.DocumentType === 'HW Manifest' && values.classification === 'Hazardous Waste' ? (
                    <Grid item xs={12} container
                      spacing={2} alignItems='flex-end'
                    >
                      <FieldArray name='rcraCodes'>
                        {arrayHelpers => (
                          <Fragment>
                            {values.rcraCodes.map((rcraCode, index) => (
                              <Grid item xs={12} sm={3}
                                key={index}
                              >
                                <SelectField name={`rcraCodes.${index}.code`} label='RCRA Code' required>
                                  {values.material ?
                                    values.material.WasteCodes.RCRA
                                      .map(code => (
                                        <MenuItem value={code} key={code}>{code}</MenuItem>
                                      )) : <MenuItem></MenuItem>}
                                </SelectField>
                              </Grid>
                            ))}
                            {values.rcraCodes.length > 1 && <Button {...core.clearCodeButton(arrayHelpers)}><Clear /></Button>}
                            <Button {...core.addCodebutton(arrayHelpers)}><Add /></Button>
                            <Grid item xs={12} sm={2}>
                            </Grid>
                          </Fragment>
                        )
                        }
                      </FieldArray>
                    </Grid>) : null}
                  {props.selectedOutbound.DocumentType === 'HW Manifest' && values.classification === 'Hazardous Waste' ? (
                    <Grid item xs={12} container
                      spacing={2} alignItems='flex-end'
                    >
                      <FieldArray name='caCodes'>
                        {arrayHelpers => (
                          <Fragment>
                            {values.caCodes.map((caCode, index) => (
                              <Grid item xs={12} sm={3}
                                key={index}
                              >
                                <SelectField name={`caCodes.${index}.code`} label='CA Code' required>
                                  {values.material ?
                                    values.material.WasteCodes.CA
                                      .map(code => (
                                        <MenuItem value={code} key={code}>{code}</MenuItem>
                                      )) : <MenuItem></MenuItem>}
                                </SelectField>
                              </Grid>
                            ))}
                            {values.caCodes.length > 1 && <Button {...core.clearCodeButton(arrayHelpers)}><Clear /></Button>}
                            <Button {...core.addCodebutton(arrayHelpers)}><Add /></Button>
                            <Grid item xs={12} sm={2}>
                            </Grid>
                          </Fragment>
                        )
                        }
                      </FieldArray>
                    </Grid>) : null}
                </Grid>
              </Collapse>
              <Collapse in={stageArray[stage] === 'lines'}>
                <DialogContentText>Fill lines with containers from inventory:</DialogContentText>
                <Grid container spacing={2}>
                  <FieldArray name='addedContainers'>
                    {arrayhelpers => {
                      return values.addedContainers.map((containerFields, index) => (
                        <Fragment key={index}>
                          <Grid item xs={6} sm={3}>
                            <AutoCompleteObject {...core.addedContainerShortNo(index)} />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField name={`addedContainers.${index}.material`} label='Material' disabled />
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <WeightField name={`addedContainers.${index}.grossWeight`} label='Gross Weight' disabled />
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <WeightField name={`addedContainers.${index}.tareWeight`} label='Tare Weight' disabled />
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <WeightField name={`addedContainers.${index}.netWeight`} label='Net Weight' disabled />
                          </Grid>
                        </Fragment>
                      ));
                    }}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
              {stageArray[stage] !== 'success' && <Alert in={Boolean(determineMaterialWarning(values))} text={determineMaterialWarning(values)} severity='warning' />}
            </DialogContent>
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton {...core.closeButton}>Close</FormButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton {...core.cancelButton}>Cancel</FormButton>
                <div>
                  <FormButton {...core.backButton}>Back</FormButton>
                  <SubmitButton {...core.submitButton}>{stageArray[stage] === 'lines' ? 'Submit' : 'Next'}</SubmitButton>
                </div>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

function createDataForFirestore(formikValues, {currentUser}) {
  return {
    LineNumber: formikValues.lineNumber,
    Material: {
      Name: formikValues.material.UnitDetails.MaterialName,
      Ref: formikValues.material.MaterialId,
    },
    RCRACodes: formikValues.rcraCodes[0] ? formikValues.rcraCodes.map(codeObj => codeObj.code) : [],
    CACodes: formikValues.caCodes[0] ? formikValues.caCodes.map(codeObj => codeObj.code) : [],
    Quantity: parseInt(formikValues.quantity),
    ContainerCode: formikValues.containerCode,
    Classification: formikValues.classification,
    AddedContainers: formikValues.addedContainers.map(container => ({
      GrossWeight: container.grossWeight,
      NetWeight: container.netWeight,
      ShortNo: container.shortNo.ShortNo,
      TareWeight: container.tareWeight,
    })),
    System: {
      CreatedBy: currentUser.displayName,
      CreatedOn: firebase.firestore.Timestamp.now(),
    },
  };
}
function createArrayOfAddedContainers({addedContainers, quantity, material}) {
  if (!material) return;
  const returnedArray = new Array(parseInt(quantity) || 0)
    .fill({shortNo: '', netWeight: '', material: '', tareWeight: '', grossWeight: '', parentMaterial: material.UnitDetails.MaterialName});
  for (let index = 0; index < parseInt(quantity); index++) {
    if (addedContainers[index]) returnedArray[index] = {...addedContainers[index]};
  }
  return returnedArray;
}

NewLineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedOutbound: PropTypes.object.isRequired,
  selectedLine: PropTypes.object,
};


export default NewLineModal;

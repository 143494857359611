import React from 'react';
import {
  AdminSettings,
  CustomerProfiles,
  Dashboard,
  Shipments,
  InboundDetails,
  Reports,
  Production,
  SortFormDetails,
  ProcessFormDetails,
  ContainerDetails,
  OutboundDetails,
  Accounting,
  Invoice,
  ConsolidationFormDetails,
  InventoryDetails,
} from './views/';
import {Assessment, CallSplit, LocalShipping, Settings, Dashboard as DashboardIcon, AccountBalance} from '@material-ui/icons';

export const routes = [
  {component: () => <Dashboard />, exact: true, strict: true, path: '/dashboard', pageTitle: 'Tracking Dashboard'},
  {component: () => <CustomerProfiles />, exact: true, strict: true, path: '/customer-profiles/', pageTitle: 'Customer Profiles'},
  {component: () => <Shipments />, exact: true, strict: true, path: '/shipments', pageTitle: 'Shipments'},
  {component: () => <InboundDetails />, exact: true, strict: true, path: '/shipments/inbound/:InboundId/:ShippingDocId?', pageTitle: 'Shipments'},
  {component: () => <OutboundDetails />, exact: true, strict: true, path: '/shipments/outbound/:OutboundId', pageTitle: 'Shipments'},
  {component: () => <Production />, exact: true, strict: true, path: '/production/sort', pageTitle: 'Production - Sort'},
  {component: () => <Production />, exact: true, strict: true, path: '/production/process', pageTitle: 'Production - Process'},
  {component: () => <Production />, exact: true, strict: true, path: '/production/consolidate', pageTitle: 'Production - Consolidate'},
  {component: () => <Production />, exact: true, strict: true, path: '/production/inventory', pageTitle: 'Production - Onsite Inventory'},
  {component: () => <SortFormDetails />, exact: true, strict: true, path: '/production/sort/:SortFormId', pageTitle: 'Production - Sort'},
  {component: () => <ProcessFormDetails />, exact: true, strict: true, path: '/production/process/:ProcessFormId', pageTitle: 'Production - Process'},
  {component: () => <ContainerDetails />, exact: true, strict: true, path: '/production/inventory/:ShortNo/:inventoryId?', pageTitle: 'Production - Onsite Inventory'},
  {component: () => <ConsolidationFormDetails />, exact: true, strict: true, path: '/production/consolidate/:ConsolidationFormId', pageTitle: 'Production - Consolidate'},
  {component: () => <Accounting />, exact: true, strict: true, path: '/accounting/tracking', pageTitle: 'Accounting - Tracking'},
  {component: () => <Accounting />, exact: true, strict: true, path: '/accounting/outbound', pageTitle: 'Accounting - Outbound'},
  {component: () => <Accounting />, exact: true, strict: true, path: '/accounting/proforma', pageTitle: 'Accounting - Proforma'},
  {component: () => <Invoice />, exact: true, strict: true, path: '/accounting/proforma/:InvoiceId', pageTitle: 'Accounting - Proforma'},
  {component: () => <Reports />, exact: true, strict: true, path: '/reports', pageTitle: 'Reports'},
  {component: () => <Reports />, exact: true, strict: true, path: '/reports/inventoryItems/', pageTitle: 'Reports'},
  {component: () => <InventoryDetails />, exact: true, strict: true, path: '/reports/inventory/:InventoryId', pageTitle: 'Reports'},
  {component: () => <AdminSettings />, exact: true, strict: true, path: '/settings', pageTitle: 'Admin Settings'},
];

export const menuItems = [
  {icon: () => <DashboardIcon />, text: 'Dashboard', link: '/dashboard'},
  {icon: () => <LocalShipping />, text: 'Shipments', link: '/shipments'},
  {icon: () => <CallSplit />, text: 'Production', link: '/production/sort', selectedLinkComparison: '/production'},
  {icon: () => <Assessment />, text: 'Reports', link: '/reports'},
  {icon: () => <AccountBalance />, text: 'Accounting', link: '/accounting/proforma', selectedLinkComparison: '/accounting'},
  {icon: () => <Settings />, text: 'Admin Settings', link: '/settings'},
];

export const moduleMenuOptions = [
  {title: 'Entry', path: 'https://auth.kbi.works'},
  {title: 'EHS', path: 'https://ehs.kbi.works'},
  {title: 'CRM', path: 'https://crm.kbi.works'},
];

import React, {Fragment, useMemo, useState, useCallback} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  Button,
} from '@material-ui/core';
import {ImportExport} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {Table} from '@kbi/component-library';
import {exportToCSV} from '@kbi/utility-library';
import {Alert, Formik, Collapse} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import * as yup from 'yup';
import moment from 'moment';
const {TextField, FormButton, FormikForm, SubmitButton} = Formik;

const stageArray = ['basic', 'success'];

const KBIGenerationFormModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const checkIfUniqueAccountingId = useCallback(accountingId => {
    return Firestore.collection('Tracking-Forms').where('FormType', '==', 'KBI Generation').where('AccountingId', '==', accountingId).get().then(snap => {
      if (snap.empty) return true;
      else return false;
    });
  }, []);

  const formik = useMemo(() => ({
    initialValues: {
      accountingId: '',
    },
    validationSchema: yup.object().shape({
      accountingId: yup.string().required('Accounting Id is a required field.').test({
        name: 'uniqueId',
        test: async function(value) {
          if (value) {
            const unique = await checkIfUniqueAccountingId(value);
            if (unique) return true;
            else return false;
          }
          else return false;
        },
        message: 'The Accounting Id entered is already in use.',
      }),
    }),
    onSubmit: (values, actions) => {
      const batch = Firestore.batch();
      props.selectedGenerationData.lines.forEach(lineData => {
        batch.update(Firestore.collection('Tracking-Forms').doc(lineData.id), {
          IsInvoiced: true,
          AccountingId: values.accountingId,
        });
      });
      batch.commit().then(() => {
        actions.setSubmitting(false);
        setStage(stage + 1);
      }).catch(error => {
        actions.setSubmitting(false);
        setFormError('There was an error during submission. Please try again.');
      });
    },
  }), [checkIfUniqueAccountingId, props.selectedGenerationData.lines, stage]);

  const core = useMemo(() => ({
    dialog: {
      open: true,
      maxWidth: 'md',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    submitConfirmation: {
      stage: stageArray[stage],
      text: 'KBI Generations successfully marked as invoiced.',
    },
    generationTable: {
      data: props.selectedGenerationData.lines,
      columns: [
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'typeName', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'Weight', Header: 'Weight'},
      ],
      sortInitial: [{id: 'typeName', desc: false}],
      paginationActive: false,
    },
    exportButton: {
      variant: 'outlined',
      onClick: () => {
        exportToCSV(props.selectedGenerationData.lines, [
          {accessor: 'materialName', Header: 'Material'},
          {accessor: 'typeName', Header: 'Type'},
          {accessor: 'Flag', Header: 'Flag'},
          {accessor: 'Weight', Header: 'Weight'},
        ], 'KBI_Generation_Forms.csv');
      },
    },
  }), [props.selectedGenerationData, stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && (
              <DialogTitle>
              KBI Generation Forms - {moment(props.selectedGenerationData.date).format('MM-DD-YYYY')}
              </DialogTitle>
            )}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Review data, and submit to mark inventory as invoiced:</DialogContentText>
                <Grid container spacing={2} justify='flex-end'>
                  <Grid item xs={3}>
                    <Button {...core.exportButton}>
                      <ImportExport style={{marginRight: '8px'}} />
                      Export Table
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Table {...core.generationTable} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='accountingId' label='Accounting Id' required />
                  </Grid>
                </Grid>
              </Collapse>
            </DialogContent>
            <SubmitConfirmation {...core.submitConfirmation} />
            <Alert in={Boolean(formError)} text={formError} severity='error' />
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton onClick={props.close} variant='text' color='primary'>Close</FormButton>
              </DialogActions>
            ) : <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
              <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
            </DialogActions>}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

KBIGenerationFormModal.propTypes = {
  selectedGenerationData: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default KBIGenerationFormModal;

// first index in each array is the container code itself
// second index in each array is the container code types
const containerCodes = [
  ['BA', []],
  ['CF', ['Box', 'Crate', 'Gaylord', 'Tote']],
  ['CM', []],
  ['CW', ['Crate', 'Pallet']],
  ['CY', []],
  ['DF', ['2', '5', '10', '14', '15', '20', '30', '55', '85']],
  ['DM', ['2', '5', '10', '14', '15', '20', '30', '55']],
  ['DT', []],
  ['DW', []],
  ['LO', ['Loose']],
];

export default containerCodes;

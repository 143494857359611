import React, {Fragment, useMemo, useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {Paper, LinearProgress} from '@material-ui/core';
import {Search, Add, Security, Print, Send, ImportExport} from '@material-ui/icons';
import {GenericPrintLabels, MovementFormModal, TableWithPaper} from 'components/';
import {useSelector} from 'react-redux';
import {AdminContainerModal} from '../ContainerDetails/';
import {ContainerModal} from '../Inventory/';
import {exportToCSV} from '@kbi/utility-library';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const Inventory = () => {
  const [addContainerModalOpen, setAddContainerModalOpen] = useState(false);
  const [kbiWasteModalOpen, setKbiWasteModalOpen] = useState(false);
  const [listOfContainersToPrint, setListOfContainersToPrint] = useState(null);
  const [movementFormModalOpen, setMovementFormModalOpen] = useState(null);

  const {currentUser} = useSelector(state => state.auth);
  const {containers, facilityUnits, materials, inventoryItems} = useSelector(state => state.firestore);
  const history = useHistory();

  useEffect(() => {
    if (listOfContainersToPrint) {
      window.print();
      setListOfContainersToPrint(null);
    }
  }, [listOfContainersToPrint]);
  const createTypesString = useCallback(arrayOfItemRefs => {
    const arrayOfItemTypes = arrayOfItemRefs.map(ref => {
      if (inventoryItems.ref[ref]) {
        return inventoryItems.ref[ref]?.Type.Name;
      }
      else return '';
    }).filter(onlyUnique);
    return arrayOfItemTypes.join(', ');
  }, [inventoryItems]);
  const limitStringTo24Char = useCallback(string => {
    let reducedString = string;
    if (reducedString.length > 25) reducedString = reducedString.substring(0, 24) + '...';
    return reducedString;
  }, []);
  const arrayForTable = useMemo(() => {
    if (containers && materials && facilityUnits && inventoryItems) {
      return containers.list.map(container => ({
        ...container,
        GrossWeight: container.NetWeight + container.TareWeight,
        Container: `${container.ContainerCode}${container.ContainerCodeType ? `, ${container.ContainerCodeType}` : ''}`,
        materialName: materials.ref[container.MaterialRef].UnitDetails.MaterialName,
        typeString: limitStringTo24Char(createTypesString(container.InventoryItems)),
        facilityUnitName: facilityUnits.ref[container.FacilityUnitRef].Name,
        startDate: container.AccumulationStartDate.toDate(),
      }));
    }
  }, [containers, createTypesString, facilityUnits, inventoryItems, limitStringTo24Char, materials]);
  const inventoryTableProps = useMemo(() => {
    return {
      columns: [
        {accessor: 'ShortNo', Header: 'Short Number'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'typeString', Header: 'Types'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'GrossWeight', Header: 'Gross', filterDisable: true, type: 'numeric'},
        {accessor: 'TareWeight', Header: 'Tare', filterDisable: true, type: 'numeric'},
        {accessor: 'NetWeight', Header: 'Net', filterDisable: true, type: 'numeric'},
        {accessor: 'Container', Header: 'Container', filterDisable: true},
        {accessor: 'InboundContainer', Header: 'Inbound?', type: 'boolean'},
        {accessor: 'startDate', Header: 'Start Date', type: 'datetime', filterDisable: true},
      ],
      sortInitial: [{id: 'startDate', desc: true}],
      data: arrayForTable || [],
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Container',
          onClick: ({rowData}) => history.push(`/production/inventory/${rowData.ShortNo}`),
        },
        {
          icon: Print,
          tooltip: 'Print Label',
          onClick: ({rowData}) => {
            setListOfContainersToPrint([rowData]);
          },
        },
      ],
      actionsBar: [
        {
          icon: Add,
          text: 'Add KBI Container',
          onClick: () => setKbiWasteModalOpen(true),
        },
        {
          icon: Send,
          text: 'New Movement Form',
          onClick: () => setMovementFormModalOpen(true),
        },
        {
          icon: Security,
          text: 'Admin Add Container',
          hide: currentUser.roleTracking !== 'Admin',
          onClick: () => setAddContainerModalOpen(true),
        },
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [{accessor: 'ShortNo', Header: 'Short Number'},
              {accessor: 'facilityUnitName', Header: 'Facility Unit'},
              {accessor: 'materialName', Header: 'Material'},
              {accessor: 'typeString', Header: 'Types'},
              {accessor: 'Flag', Header: 'Flag'},
              {accessor: 'GrossWeight', Header: 'Gross', filterDisable: true},
              {accessor: 'TareWeight', Header: 'Tare', filterDisable: true},
              {accessor: 'NetWeight', Header: 'Net', filterDisable: true},
              {accessor: 'Container', Header: 'Container', filterDisable: true},
              {accessor: 'InboundContainer', Header: 'Inbound?', type: 'boolean'},
              {accessor: 'startDate', Header: 'Start Date', type: 'datetime', filterDisable: true},
            ];
            exportToCSV(arrayForTable, columns, 'Inventory.csv');
          },
        },
      ],
      paginationSizes: [10, 25, 50, 100],
      paginationInitialSize: 10,
      title: 'Onsite Inventory',
    };
  }, [arrayForTable, currentUser.roleTracking, history]);

  if (!arrayForTable || !materials || !facilityUnits || !containers || !inventoryItems) return <LinearProgress />;
  return (
    <Fragment>
      <div className='hidePrint'>
        <Paper style={{margin: '16px 0px'}}>
          <TableWithPaper {...inventoryTableProps} />
        </Paper>
        {addContainerModalOpen && <AdminContainerModal close={() => setAddContainerModalOpen(false)} />}
        <ContainerModal close={() => setKbiWasteModalOpen(false)} open={kbiWasteModalOpen} />
        {movementFormModalOpen && <MovementFormModal close={() => setMovementFormModalOpen(false)} open={movementFormModalOpen} />}
      </div>
      <GenericPrintLabels listOfContainersToPrint={listOfContainersToPrint} />
    </Fragment>
  );
};

export default Inventory;

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetLocations} from 'state/firestore/actions.js';

const useLocations = () => {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.firestore.locations);

  useEffect(() => {
    if (!locations) dispatch(acGetLocations);
  }, [dispatch, locations]);

  return locations;
};

export default useLocations;

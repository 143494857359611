import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem, Typography} from '@material-ui/core';
import {withFormik} from 'formik';
import {Formik} from '@kbi/component-library';
import {containerCodes} from 'components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
const {SelectField, WeightField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const ExistingContainerForm = props => {
  const {values, getChildErrors} = props;
  const {types, materials} = useSelector(state => state.firestore);

  useEffect(() => {
    if (props.parentSubmitting) {
      props.attemptSubmission(props.submitForm());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parentSubmitting]);
  useEffect(() => {
    if (getChildErrors) {
      props.setErrorLength(Object.values(props.errors).length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChildErrors]);
  useEffect(() => {
    props.updateState('existingContainerArray', values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.existingShortNo]);

  const core = useMemo(() => ({
    codeTypeField: {
      label: 'Code Type',
      required: values['containerCode'] && containerCodes.find(codeArray => codeArray[0] === values['containerCode'])?.[1].length ?
        true : false,
      disabled: true,
    },
    yieldGrossWeightField: {
      required: true,
      decimal: 0,
      label: 'New Gross Weight',
      disabled: props.parentSubmitting,
    },
    yieldTareWeightField: {
      required: true,
      label: 'Tare Weight',
      decimal: 0,
      disabled: true,
    },
    containerCodeField: {
      required: true,
      label: 'Container Code',
      disabled: true,
      onChange: ({form, field}) => {
        form.setFieldValue(`codeType`, '');
        form.setFieldError(`codeType`, '');
      },
    },
    existingContainerAutoField: {
      name: `existingShortNo`,
      options: props.yieldContainersInUnit,
      optionKey: 'ShortNo',
      label: 'Existing Short No.',
      required: true,
      disabled: props.parentSubmitting,
      onChange: ({form, field}) => {
        if (field.value) {
          form.setFieldValue(`containerCode`, field.value.ContainerCode);
          form.setFieldValue(`codeType`, field.value.ContainerCodeType);
          form.setFieldValue(`tareWeight`, field.value.TareWeight);
          form.setFieldValue(`yield`, materials.ref[field.value.MaterialRef]);
          form.setFieldValue(`type`, props.selectedProcessForm.YieldRef === 'mixed' ? '' :
            types[field.value.MaterialRef]?.find(type => type.id === props.selectedProcessForm.TypeRef));
          form.setFieldValue(`flag`, materials.ref[field.value.MaterialRef].Flags.find(flag => flag.Name === field.value.Flag) || '');
        }
        else {
          form.setFieldValue(`containerCode`, '');
          form.setFieldValue(`codeType`, '');
          form.setFieldValue(`tareWeight`, '');
          form.setFieldValue(`flag`, '');
          form.setFieldValue(`type`, '');
          form.setFieldValue(`yield`, '');
        }
      },
    },
    yieldField: {
      label: 'Yield',
      required: true,
      loading: !props.selectableYields,
      options: props.selectableYields,
      optionKey: 'UnitDetails.MaterialName',
      disabled: true,
    },
    flagField: {
      label: 'Flag',
      required: values.yield && values.yield?.FlagStatus === 'Required' ? true : false,
      loading: !values.yield,
      loadingText: 'Select a yield to populate',
      options: values.yield?.Flags || [],
      optionKey: 'Name',
      disabled: true,
    },
    typeField: {
      label: 'Type',
      required: true,
      loading: !values.yield,
      loadingText: 'Select a yield to populate',
      options: types[values.yield?.MaterialId]?.filter(type => type.Active) || [],
      optionKey: 'TypeName',
      disabled: props.selectedProcessForm.YieldRef !== 'mixed' || props.parentSubmitting,
    },
    outlinedGrid: {
      item: true,
      xs: 12,
      container: true,
      spacing: 2,
      style: {border: '1px solid lightgrey', margin: '8px'},
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [props.parentSubmitting, props.updateState, types, values]);

  return (
    <Grid {...core.outlinedGrid}>
      <Grid item xs={1} container alignItems='center'>
        <Typography variant='h6'>{props.index + 1}.</Typography>
      </Grid>
      <Grid item xs={11} container spacing={2} style={{margin: '0px'}}>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject {...core.existingContainerAutoField} id={`existingShortNo${props.index}e`} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`yield`} id={`yield${props.index}e`} {...core.yieldField} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`type`} id={`type${props.index}e`} {...core.typeField} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <AutoCompleteObject name={`flag`} id={`flag${props.index}e`} {...core.flagField} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name={`containerCode`} id={`containerCode${props.index}e`}{...core.containerCodeField}>
            {containerCodes.map((codeArray, index) => {
              return (<MenuItem value={codeArray[0]} key={index}>{codeArray[0]}</MenuItem>);
            })}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name={`codeType`} id={`codeType${props.index}e`}{...core.codeTypeField}>
            {values.containerCode ? containerCodes.find(codeArray => codeArray[0] === values.containerCode)[1]
              .map((code, index) => {
                return (<MenuItem value={code} key={index}>{code}</MenuItem>);
              }) : []}
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <WeightField name={`grossWeight`} id={`grossWeight${props.index}e`} {...core.yieldGrossWeightField} fast />
        </Grid>
        <Grid item xs={12} sm={3}>
          <WeightField name={`tareWeight`} id={`tareWeight${props.index}e`} {...core.yieldTareWeightField} />
        </Grid>
      </Grid>
    </Grid>
  );
};

ExistingContainerForm.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  parentSubmitting: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
  selectedProcessForm: PropTypes.object.isRequired,
  selectableYields: PropTypes.array.isRequired,
  attemptSubmission: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrorLength: PropTypes.func.isRequired,
  getChildErrors: PropTypes.bool.isRequired,
  yieldContainersInUnit: PropTypes.array.isRequired,
};

export default withFormik({
  mapPropsToValues: props => ({
    existingShortNo: props.values.existingShortNo,
    containerCode: props.values.containerCode,
    codeType: props.values.codeType,
    tareWeight: props.values.tareWeight,
    grossWeight: props.values.grossWeight,
    yield: props.values.yield,
    flag: props.values.flag,
    type: props.values.type,
  }),
  validationSchema: yup.object().shape({
    existingShortNo: yup.object().nullable().exists('Existing Short No is a required field.'),
    grossWeight: yup.number().required('Gross Weight is a required field.').test({
      name: 'greaterThanOriginal',
      test: function(value) {
        if (value && this.parent.existingShortNo && parseInt(value) < this.parent.existingShortNo.NetWeight + this.parent.existingShortNo.TareWeight) {
          return false;
        }
        else return true;
      },
      message: 'New Gross Weight must be greater than the original container weight.',
    }),
    type: yup.object().nullable().exists('Type is a required field.'),
  }),
  handleSubmit: (values, {props, setSubmitting}) => {
    props.updateState('existingContainerArray', values);
    setSubmitting(false);
  },
})(ExistingContainerForm);

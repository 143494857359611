import React, {useState, useMemo, Fragment} from 'react';
import {Typography, makeStyles, Button, Paper, LinearProgress} from '@material-ui/core';
import {NewSortFormModal} from '../Production/';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {TableWithPaper, MovementFormModal} from 'components/';
import moment from 'moment';
import {AssignmentTurnedInOutlined, Search, Add, Send} from '@material-ui/icons';

const SortView = () => {
  const [sortFormModalOpen, setSortFormModalOpen] = useState(false);
  const [movementFormModalOpen, setMovementFormModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const {sortForms, facilityUnits, containers, inventoryItems} = useSelector(state => state.firestore);

  const sortFormsForTable = useMemo(() => {
    if (sortForms && facilityUnits) {
      return sortForms.list.map((form, index) => ({
        ...form,
        facilityUnitName: facilityUnits?.ref[form.FacilityUnitRef].Name,
      }));
    }
    else return [];
  }, [facilityUnits, sortForms]);

  const core = {
    sortFormModal: {
      open: sortFormModalOpen,
      close: () => setSortFormModalOpen(false),
    },
    button: {
      variant: 'outlined',
    },
    sortFormButton: {
      onClick: () => setSortFormModalOpen(true),
    },
    movementFormModal: {
      open: movementFormModalOpen,
      close: () => setMovementFormModalOpen(false),
    },
    movementFormButton: {
      style: {marginLeft: '8px'},
      onClick: () => setMovementFormModalOpen(true),
    },
    sortFormTable: {
      columns: [
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'Shift', Header: 'Shift'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
      ],
      sortInitial: [{id: 'Date', desc: true}],
      data: sortFormsForTable,
      title: 'Production - Sort',
      paginationInitialSize: 10,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Form Details',
          onClick: ({rowData}) => {
            history.push(`/production/sort/${rowData.id}`);
          },
        },
      ],
      actionsBar: [
        {
          icon: Add,
          text: 'Add Inbound Sort Form',
          onClick: () => setSortFormModalOpen(true),
        },
        {
          icon: Send,
          text: 'New Movement Form',
          onClick: () => setMovementFormModalOpen(true),

        },
      ],
    },
  };

  if (!sortForms || !facilityUnits || !inventoryItems || !containers) return <LinearProgress />;

  return <div className='hidePrint'>
    {sortForms.list.length ? (
      <TableWithPaper {...core.sortFormTable} />
    ) : (
      <Fragment>
        <Paper className={classes.paper}>
          <Typography variant="h4">Daily Production</Typography>
          <Typography variant="h6" gutterBottom>
            {moment().format('MM/DD/YYYY')}
          </Typography>
          <AssignmentTurnedInOutlined style={{fontSize: '10em'}} />
          <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    How would you like to start the day?
          </Typography>
          <div>
            <Button {...core.button} {...core.sortFormButton}>
                          New Inbound Sort Form
            </Button>
            <Button {...core.button} {...core.movementFormButton}>
                      New Movement Form
            </Button>
          </div>
        </Paper>
      </Fragment>
    )}
    {sortFormModalOpen && <NewSortFormModal {...core.sortFormModal} />}
    {movementFormModalOpen && <MovementFormModal {...core.movementFormModal} />}
  </div>;
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

SortView.propTypes = {
// there are no props
};

export default SortView;

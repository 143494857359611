import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {BackButton, SubmitButton, MarkAsInvoicedModal} from 'components/';
import {InvoiceSummary, InvoiceDetails, BillingInformation} from './Invoice/';
import {Button, withStyles, Typography, Chip, LinearProgress} from '@material-ui/core';
import {Firestore} from 'config.js';
import {Edit, Print, Search} from '@material-ui/icons';
import {EditInvoiceModal} from './Invoice/';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import {connect} from 'react-redux';

class Invoice extends React.Component {
  state = {
    selectedInvoice: null,
    selectedInbound: null,
    editInvoiceModalOpen: false,
    markAsInvoicedModalOpen: false,
    submitting: false,
    referencedPO: null,
    pieces: null,
  }
  componentDidMount() {
    this.setInvoiceToState();
    Firestore.collection('Tracking-Invoices')
      .doc(this.props.match.params.InvoiceId)
      .collection('Pieces')
      .get()
      .then(pieceSnap => {
        const pieces = pieceSnap.docs.map(pieceDoc => ({...pieceDoc.data(), id: pieceDoc.id}));
        this.setState({pieces: pieces.sort((a, b) => a.id - b.id)});
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedInvoice !== this.state.selectedInvoice && this.state.selectedInvoice) {
      this.setInboundToState();
    }
  }
  setInvoiceToState() {
    Firestore.collection('Tracking-Invoices').doc(this.props.match.params.InvoiceId).get().then(invoiceDoc => {
      this.setState({selectedInvoice: {...invoiceDoc.data(), id: invoiceDoc.id}});
    });
  }
  setInboundToState() {
    Firestore.collection('Tracking-Shipments').doc(this.state.selectedInvoice.InboundRef).get().then(inboundDoc => {
      this.setState({selectedInbound: {...inboundDoc.data(), id: inboundDoc.id}});
    });
  }
  // markAsInvoiced() {
  //   this.setState({submitting: true});
  //   const firestoreBatch = Firestore.batch();
  //   firestoreBatch.update(Firestore.collection('Tracking-Invoices').doc(this.state.selectedInvoice.id), {
  //     'IsInvoiced': true,
  //     'System.InvoicedBy': this.props.currentUser.displayName,
  //     'System.InvoicedOn': new Date(),
  //   });
  //   firestoreBatch.update(Firestore.collection('Tracking-Shipments')
  //     .doc(this.state.selectedInvoice.InboundRef)
  //     .collection('Shipping-Documents')
  //     .doc(this.state.selectedInvoice.ShippingDocRef),
  //   {Invoiced: true});

  //   firestoreBatch.commit().then(() => {
  //     this.setState({submitting: false});
  //     this.setInvoiceToState();
  //   });
  // }
  render() {
    const {classes} = this.props;
    const {selectedInvoice, selectedInbound} = this.state;

    if (!selectedInvoice || !selectedInbound) return <LinearProgress />;
    const core = {
      editButton: {
        variant: 'outlined',
        size: 'small',
        className: `hidePrint ${classes.marginLeft}`,
        onClick: () => this.setState({editInvoiceModalOpen: true}),
      },
      markAsInvoicedButton: {
        variant: 'outlined',
        size: 'small',
        icon: 'Check',
        loading: this.state.submitting,
        className: `hidePrint ${classes.marginLeft}`,
        text: 'Mark as Invoiced',
        onClick: () => this.setState({markAsInvoicedModalOpen: true}),
      },
      invoiceProps: {
        classes,
        StyledTypography,
        selectedInvoice,
        selectedInbound,
        pieces: this.state.pieces,
      },
      editModal: {
        selectedInvoice,
        updateInvoiceFunc: () => this.setInvoiceToState(),
        pieces: this.state.pieces,
        close: () => this.setState({editInvoiceModalOpen: false}),
      },
      markAsInvoicedModal: {
        close: () => {
          this.setInvoiceToState();
          this.setState({markAsInvoicedModalOpen: false});
        },
        documentType: 'Invoice',
        selectedInvoice,
      },
      printButton: {
        variant: 'outlined',
        size: 'small',
        className: `hidePrint ${classes.marginLeft}`,
        onClick: () => window.print(),
      },
    };
    return (
      <Fragment>
        <div className='sideScrollingContainer' style={{marginBottom: '15px'}}>
          <BackButton />
          <Button {...core.printButton}><Print className={classes.extendedIcon} />Print</Button>
          <Button {...core.editButton}>
            {selectedInvoice.IsInvoiced ? <Fragment><Search className={classes.extendedIcon} />View Invoice</Fragment> :
              <Fragment><Edit className={classes.extendedIcon} />Edit Invoice</Fragment>}
          </Button>
          {selectedInvoice.System.UpdatedBy ? selectedInvoice.IsInvoiced ? (
            <Chip label={`Invoiced - Id: ${this.state.selectedInvoice.AccountingId}`} color='primary' variant='outlined'
              className={`hidePrint ${classes.marginLeft}`}
            />
          ) : (
            <Fragment>
              <SubmitButton {...core.markAsInvoicedButton} />
              <Chip label={`Approved by ${selectedInvoice.System.UpdatedBy}`} color='primary' variant='outlined'
                className={`hidePrint ${classes.marginLeft}`}
              />
            </Fragment>) :
            <Chip label='Awaiting Sales Approval' color='secondary' variant='outlined'
              className={`hidePrint ${classes.marginLeft}`}
            />}
        </div>
        <BillingInformation {...core.invoiceProps} referencedPO={this.state.referencedPO} />
        <InvoiceSummary {...core.invoiceProps} />
        <div style={{pageBreakAfter: 'always'}} />
        <InvoiceDetails {...core.invoiceProps} />
        {this.state.editInvoiceModalOpen && <EditInvoiceModal {...core.editModal} />}
        {this.state.markAsInvoicedModalOpen && <MarkAsInvoicedModal {...core.markAsInvoicedModal} />}
      </Fragment>
    );
  }
}

const styles = theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  avoidPrint: {
    pageBreakInside: 'avoid',
  },
  newSection: {
    marginTop: '25px',
  },
  bold: {
    fontWeight: '600',
  },
  italic: {
    fontStyle: 'italic',
  },
  paddingLeft: {
    '& p': {
      paddingLeft: '5px',
    },
  },
  marginLeft: {
    marginLeft: '8px',
  },
  titleSection: {
    backgroundColor: 'red',
    color: 'white',
    borderBottom: '1px solid black',
  },
  textChildrenPadding: {
    '& p': {
      padding: '3px 5px',
    },
  },
  borders: {
    borderTop: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
  },
  borderTop: {
    borderTop: '1px solid black',
  },
  borderLeft: {
    borderLeft: '1px solid black',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  borderRight: {
    borderRight: '1px solid black',
  },
  tableHeader: {
    backgroundColor: 'rgba(255,0,0,0.2)',
  },
  centerText: {textAlign: 'center'},
});
const StyledTypography = withStyles({
  body2: {
    fontSize: '0.8rem',
    lineHeight: '1.20',
  },
  subtitle2: {
    fontSize: '0.8rem',
    paddingLeft: '5px',
  },
  caption: {
    fontSize: '0.7rem',
    lineHeight: '1.10',
  },
})(Typography);

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});
Invoice.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default flowright(connect(mapStateToProps), withStyles(styles), withRouter)(Invoice);

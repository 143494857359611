/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
  DialogContentText,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {shortNumberGenerator} from '@kbi/utility-library';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {withRouter} from 'react-router-dom';
import flowRight from 'lodash.flowright';
import {useSelector} from 'react-redux';

const stageArray = ['basic', 'success'];
// these are the id's of the different facilities starting facility unit
const anaheimStartingUnit = 'CyzNJPMETU8rkOC3zxsP';
const breaStartingUnit = 'npkkBIk2iWGJaASdFBDb';

function determineStartingUnit(facilityString) {
  switch (facilityString) {
  case 'Anaheim-1314':
    return anaheimStartingUnit;
  case 'Brea-1125':
    return breaStartingUnit;
  default:
    break;
  }
}

const ConfirmationModal = props => {
  const {open, close} = props;
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const [itemNeedingCompletion, setItemNeedingCompletion] = useState('');
  const [listOfLineItems, setListOfLineItems] = useState([]);
  const containers = useSelector(state => state.firestore.containers);

  useEffect(() => {
    // sets the list of line items
    const arrayforState = [];
    props.listOfShippingDocuments.forEach(shippingDoc => {
      shippingDoc.LineItems.forEach(item => {
        arrayforState.push({...item, shippingDoc});
      });
    });
    setListOfLineItems(arrayforState);
  }, []);
  useEffect(() => {
    // this function sets the itemNeedingCompletion state, which disables the submit button, and displays the error that the tracker needs to complete
    // it is ran every opening of the modal.
    let stringToPassToState = '';
    // at least one shipping document
    if (!props.listOfShippingDocuments.length) {
      stringToPassToState = 'please make sure there is at least one shipping document.';
      // at least one weight ticket
    }
    else if (!props.listOfWeightTickets.length && props.selectedInbound.Facility !== 'Brea-1125') {
      stringToPassToState = 'please make sure there is at least one weight ticket.';
    }
    else {
      if (props.selectedInbound.Facility !== 'Brea-1125') {
        props.listOfWeightTickets.forEach(weightTicket => {
          if (!weightTicket.GrossWeight) {
            // all weight tickets need to be competed
            stringToPassToState = 'all weight tickets must have a gross weight on file.';
          }
        });
      }
      listOfLineItems.forEach(item => {
        if (!item.Status) {
          // all items must have been tracked, and marked with some form of status
          stringToPassToState = 'all line items must have completed the WAP process.';
        }
        if (
          item.Status === 'Discrepancy' &&
          item.Classification === 'Hazardous Waste' &&
          (!item.CACodes.length || !item.RCRACodes.length)
        ) {
          // if an item has been marked as discrepancy, but has not been updated with waste codes
          stringToPassToState = 'please update all discrepancies with waste codes.';
        }
      });
    }
    setItemNeedingCompletion(stringToPassToState);
  }, [listOfLineItems]);

  const createArrayOfErrorsForEmail = () => {
    const arrayOfShippingDocs = [];
    props.listOfShippingDocuments.forEach(shippingDoc => {
      shippingDoc.LineItems.forEach(item => {
        if (item.Status !== 'Accepted') {
          if (
            !arrayOfShippingDocs.length ||
            arrayOfShippingDocs[arrayOfShippingDocs.length - 1].shippingDocId !== shippingDoc.DocumentNumber
          ) {
            arrayOfShippingDocs.push({
              shippingDocId: shippingDoc.DocumentNumber,
              lineItems: [{pieceNumber: item.PieceNumber, issue: item.Status}],
            });
          }
          else {
            arrayOfShippingDocs[arrayOfShippingDocs.length - 1].lineItems.push({
              pieceNumber: item.PieceNumber,
              issue: item.Status,
            });
          }
        }
      });
    });
    return arrayOfShippingDocs.length ? arrayOfShippingDocs : null;
  };
  const handleBatchOverflow = (writeCount, batchArray) => {
    if (writeCount % 499 === 0) {
      batchArray.push(Firestore.batch());
    }
  };
  const handleSubmit = () => {
    setSubmitting(true);
    const batchArray = [];
    let writeCount = 0;
    const inboundId = Firestore.collection('Tracking-Shipments').doc(props.selectedInbound.id);
    const arrayOfUniqueIds = createArrayOfRandomIds(listOfLineItems);
    const accumulationStartDate = firebase.firestore.Timestamp.now();
    // this loop in the batch adds the null to BillingRef, which allows the function that creates invoices to now start including this shipping doc
    props.listOfShippingDocuments.forEach(shippingDoc => {
      handleBatchOverflow(writeCount, batchArray);
      batchArray[batchArray.length - 1].update(
        Firestore.collection('Tracking-Shipments').doc(props.selectedInbound.id).collection('Shipping-Documents').doc(shippingDoc.id),
        {
          BillingRef: null,
        },
      );
      writeCount++;
    });

    handleBatchOverflow(writeCount, batchArray);
    batchArray[batchArray.length - 1].update(inboundId, {
      'ContainersShipped': arrayOfUniqueIds,
      'System.CompletedOn': firebase.firestore.Timestamp.now(),
      'System.CompletedBy': props.currentUser.displayName,
      'InProgress': false,
    });
    writeCount++;

    const objectToUpdateLineItems = {};
    listOfLineItems.forEach((item, itemIndex) => {
      // this block loops over all line items, and creates inventory items and containers in firestore
      if (item.Status !== 'Rejected') {
        // if an item was rejected, it does not get put into inventory
        const shortNo = arrayOfUniqueIds[itemIndex];
        const inventoryRef = Firestore.collection('Tracking-Inventory').doc();

        /* eslint-disable */
        var containerRef = Firestore.collection('Tracking-Containers').doc(shortNo);
        /* eslint-enable */

        handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].set(containerRef, {
          Active: true,
          ContainerCode: item.ContainerCode,
          ContainerCodeType: item.ContainerCodeType,
          TareWeight: item.TareWeight,
          NetWeight: item.NetWeight,
          Shard: parseInt(Math.random() * 5 + 1),
          FacilityUnitRef: determineStartingUnit(props.selectedInbound.Facility),
          MaterialRef: item.AcceptedMaterial.Ref,
          InventoryItems: [inventoryRef.id],
          Flag: item.Flag,
          AccumulationStartDate: accumulationStartDate,
          InboundContainer: true,
        });
        writeCount++;
        handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].set(inventoryRef, {
          AccountRef: props.selectedInbound.AccountId,
          ShipmentInRef: props.selectedInbound.id,
          ShipmentOutRef: null,
          ShippingDocRef: item.shippingDoc.id,
          PurchaseOrderRef: item.shippingDoc.PONumber.Ref,
          ContainerRef: containerRef.id,
          Material: item.AcceptedMaterial,
          Type: item.AcceptedType,
          IncomingPieceNo: item.PieceNumber,
          Flag: item.Flag,
          Billable: false,
          Source: 'Inbound',
          Weight: item.NetWeight,
          CACodes: item.CACodes,
          RCRACodes: item.RCRACodes,
          Classification: item.Classification,
          FormHistory: [],
          Manifested: item.shippingDoc.DocumentType === 'HW Manifest' ? true : false,
          AccumulationStartDate: accumulationStartDate,
        });
        writeCount++;

        handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].set(inventoryRef.collection('Form-History').doc(), {
          Date: new Date(),
          FormId: props.selectedInbound.id,
          SubcollectionId: item.shippingDoc.id,
          FormType: 'Inbound',
          StartContainer: 'Start of life',
          StartMaterial: 'Start of life',
          StartType: 'Start of life',
          StartWeight: 'Start of life',
          StartFlag: 'Start of life',
          EndContainer: containerRef.id,
          EndMaterial: item.AcceptedMaterial.Name,
          EndType: item.AcceptedType.Name,
          EndWeight: item.NetWeight,
          EndFlag: item.Flag,
        });
        writeCount++;
      }
      /* this block is to create an object that can be used in order to update all
        shipping documents with the new line items*/
      // eslint-disable-next-line no-unused-vars
      const {shippingDoc, ...newItem} = item;
      if (objectToUpdateLineItems[item.shippingDoc.id]) {
        objectToUpdateLineItems[item.shippingDoc.id].push({
          ...newItem,
          ContainerRef: containerRef ? containerRef.id : null,
        });
      }
      else {
        objectToUpdateLineItems[item.shippingDoc.id] = [{...newItem, ContainerRef: containerRef ? containerRef.id : null}];
      }
    });

    /** this block loops over the object created above, and updates all the shipping documents with their updated line items */
    // eslint-disable-next-line guard-for-in
    for (const shippingDocId in objectToUpdateLineItems) {
      const shippingDocRef = Firestore.collection('Tracking-Shipments')
        .doc(props.selectedInbound.id)
        .collection('Shipping-Documents')
        .doc(shippingDocId);

      handleBatchOverflow(writeCount, batchArray);
      batchArray[batchArray.length - 1].update(shippingDocRef, {LineItems: objectToUpdateLineItems[shippingDocId]});
      writeCount++;
    }

    Promise.all(batchArray.map(batch => batch.commit()))
      .then(() => {
        setSubmitting(false);
        setStage(stage + 1);
        props.sendEmailAfterConfirmation(createArrayOfErrorsForEmail());
      })
      .catch(error => {
        setFormError('There has been an error. Please try again.');
        setSubmitting(false);
      });
  };
  const createArrayOfRandomIds = arrayOfItems => {
    const idArray = [];
    for (let itemIndex = 0; itemIndex < arrayOfItems.length; itemIndex++) {
      const newRandomId = shortNumberGenerator();
      // if the id doesnt already exist, add it to map of ids
      if (!containers[newRandomId]) idArray.push(newRandomId);
      else itemIndex--; // else force the loop back once, so it creates another random id
    }
    return idArray; // return an itterable array
  };

  const core = {
    dialog: {
      open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    cancelButton: {
      onClick: close,
      color: 'secondary',
      disabled: submitting,
    },
    submitButton: {
      loading: submitting,
      color: 'primary',
      text: 'Confirm',
      disabled: !!itemNeedingCompletion || submitting,
      onClick: handleSubmit,
    },
    closeButton: {
      onClick: close,
      color: 'primary',
    },
    submitConfirmation: {
      text: 'Inbound load successfully marked as completed.',
      stage: stageArray[stage],
    },
  };
  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>Confirm Completion</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>
            {itemNeedingCompletion ?
              `In order to mark this inbound as complete, ${itemNeedingCompletion}` :
              `Only confirm if you have finished entering all data for the inbound load. Doing this will remove it from
            being tracked as in progress, locking all items and documents from further editting. Are you sure?`}
          </DialogContentText>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean*(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] === 'success' ? (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedInbound: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  listOfWeightTickets: PropTypes.arrayOf(PropTypes.object),
  sendEmailAfterConfirmation: PropTypes.func.isRequired,
  listOfShippingDocuments: PropTypes.arrayOf(PropTypes.object),
};

export default flowRight(
  connect(mapStateToProps),
  withRouter,
)(ConfirmationModal);

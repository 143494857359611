import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash.flowright';
import {Button, Collapse, Typography, LinearProgress} from '@material-ui/core';
import {ChevronLeft} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {ModalFacilityUnit, ModalMaterial} from './AdminSettings/';
import {
  ViewFacilityUnits,
  ViewNoSelection,
  ViewMaterialDetails,
  ViewMaterials,
} from './AdminSettings/';
import {useFacilityUnits, useMaterials, useTypes} from 'hooks/';

const AdminSettings = ({classes}) => {
  const [modalFacilityUnit, setModalFacilityUnit] = useState(null);
  const [modalMaterial, setModalMaterial] = useState(null);
  const [view, setView] = useState(null);
  const [materialId, setMaterialId] = useState(null);
  const [renderMaterialDetails, setRenderMaterialDetails] = useState(null);

  const materials = useMaterials();
  const facilityUnits = useFacilityUnits();
  useTypes();

  const core = {
    buttonBack: {
      size: 'small',
      variant: 'outlined',
      onClick: () => setView(null),
    },
    buttonViewFacilityUnits: {
      color: 'primary',
      style: {marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => setView('facilityUnits'),
    },
    modal: {
      modalFacilityUnit: modalFacilityUnit,
      modalMaterial: modalMaterial,
      close: () => {
        setModalMaterial(null);
        setModalFacilityUnit(null);
      },
    },
    viewFacilityUnits: {
      facilityUnits: facilityUnits ? facilityUnits : null,
      footerClick: () => {
        setModalFacilityUnit({newFacilityUnit: true, selectedFacilityUnit: null});
      },
      onFacilitySelect: facilityObj => {
        setModalFacilityUnit({newFacilityUnit: false, selectedFacilityUnit: facilityObj});
      },
    },
    viewMaterialDetails: {
      material: materialId && materials ? materials.ref[materialId] : null,
      onBackClick: () => {
        setView('materials');
        setMaterialId(null);
        setRenderMaterialDetails(false);
      },
    },
    viewMaterials: {
      materials,
      footerClick: () => setModalMaterial({newMaterial: true, material: null}),
      onMaterialSelect: materialId => {
        setView('materialDetails');
        setMaterialId(materialId);
        setRenderMaterialDetails(true);
      },
    },
  };

  const headerButtons = () => {
    if (!view || view === 'materialDetails') {
      return null;
    }
    else {
      return (
        <Button {...core.buttonBack}>
          <ChevronLeft className={classes.extendedIcon} />
            Back
        </Button>
      );
    }
  };

  if (!materials || !facilityUnits) return <LinearProgress />;

  return (
    <Fragment>
      <Typography variant='h5' gutterBottom>
        Admin Settings
      </Typography>
      {headerButtons()}
      <Collapse in={!view}>
        <ViewNoSelection classes={classes} onViewChange={view => setView(view)} />
      </Collapse>
      <Collapse in={view === 'materials'}>
        <ViewMaterials {...core.viewMaterials} />
      </Collapse>
      <Collapse in={view === 'materialDetails'}>
        {renderMaterialDetails ? <ViewMaterialDetails {...core.viewMaterialDetails} /> : null}
      </Collapse>
      <Collapse in={view === 'facilityUnits'}>
        <ViewFacilityUnits {...core.viewFacilityUnits} />
      </Collapse>
      {modalFacilityUnit && <ModalFacilityUnit {...core.modal} />}
      {modalMaterial && <ModalMaterial {...core.modal} />}
    </Fragment>
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

AdminSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default flowRight(
  withStyles(styles, {withTheme: true}),
  withRouter,
)(AdminSettings);

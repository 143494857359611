import React, {useState, useMemo, useCallback, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
  DialogActions,
} from '@material-ui/core';
import {Alert, Formik, Collapse} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {FieldArray} from 'formik';
const {FormikForm, SubmitButton, FormButton, SwitchField, WeightField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const NewProcessedContainerModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const {containers, materials} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const flagWarning = useCallback((arrayOfContainers) => {
    let unmatchedFlag = false;
    arrayOfContainers.forEach(container => {
      if (container.Flag !== props.selectedProcessForm.Flag) {
        unmatchedFlag = true;
      }
    });
    return unmatchedFlag ?
      'At least one container being put on this form does not have a matching flag as the process form. Please check the selected containers.' :'';
  }, [props.selectedProcessForm.Flag]);
  const containersInUnit = useMemo(() => {
    return containers.list
      // removes the container if the container is not in the facility unit, and if the material is not allowed on process forms.
      .filter(container => container.FacilityUnitRef === props.selectedProcessForm.FacilityUnitRef &&
      materials.ref[container.MaterialRef].UnitDetails.ProcessFormAllowed,
      ).filter(container => {
        // removes the container if it is already a processed container
        for (let i = 0; i < props.processedContainers.length; i++) {
          if (container.ShortNo === props.processedContainers[i].ShortNo) {
            return false;
          }
        }
        return true;
      })
      .filter(container => {
        // removes the container if it is already added as a yield container
        let alreadyAYieldContainer = false;
        props.createdYields.forEach(yieldCont => {
          if (container.ShortNo === yieldCont.CreatedShortNo) alreadyAYieldContainer = true;
        });
        return !alreadyAYieldContainer;
      });
  }, [containers.list, materials.ref, props.createdYields, props.processedContainers, props.selectedProcessForm]);
  const createDataForFirestore = useCallback((containerDetails, scannedNumber) => {
    return {
      ShortNo: containerDetails.ShortNo,
      Material: materials.ref[containerDetails.MaterialRef]?.UnitDetails.MaterialName,
      NetWeight: containerDetails.NetWeight,
      Flag: containerDetails.Flag,
      ProcessStatus: containerDetails.partial ? 'Partial' : 'Complete',
      RemainingWeight: parseInt(containerDetails.remainingWeight),
      ScannedNumber: props.processedContainers.length ?
        props.processedContainers
          .sort((a, b) => a.ScannedNumber > b.ScannedNumber ? 1 : -1)[props.processedContainers.length - 1].ScannedNumber + scannedNumber :
        scannedNumber,
      System: {
        CreatedBy: currentUser.displayName,
        CreatedOn: firebase.firestore.Timestamp.now(),
      },
    };
  }, [currentUser.displayName, materials.ref, props.processedContainers]);
  const formik = useMemo(() => {
    return {
      initialValues: {
        containers: [],
        details: [],
      },
      validationSchema: yup.object().shape({
        containers: yup.array().min(1, 'At least one container must be processed.'),
        details: yup.array().of(yup.object().shape({
          remainingWeight: yup.mixed().test({
            name: 'partial weight',
            test: function(value) {
              if (this.parent.partial) {
                if (parseInt(value) < 1) {
                  return false;
                }
                else return true;
              }
              else return true;
            },
            message: 'Remaining Weight must be greater than 0 for partially processed containers.',
          }).test({
            name: 'overWeight',
            test: function(value) {
              if (this.parent.partial) {
                if (parseInt(value) > this.parent.NetWeight) {
                  return false;
                }
                else return true;
              }
              else return true;
            },
            message: 'Remaining Weight must be less than the original net weight.',
          }),
        }),
        )}),
      onSubmit: (values, actions) => {
        const firestoreBatch = Firestore.batch();
        values.details.forEach((containerDetails, index) => {
          const newContainerRef = Firestore.collection('Tracking-Forms').doc(props.selectedProcessForm.id).collection('Processed-Containers').doc();
          firestoreBatch.set(newContainerRef, createDataForFirestore(containerDetails, index + 1));
        });
        firestoreBatch.commit()
          .then(() => {
            actions.setSubmitting(false);
            setStage(stage + 1);
            setFormError('');
          })
          .catch(error => {
            actions.setSubmitting(false);
            setFormError('There was an error during submission. Please try again');
          });
      },
    };
  }, [createDataForFirestore, props.selectedProcessForm.id, stage]);
  const core = useMemo(() => ({
    dialog: {
      open: props.open,
      maxWidth: 'lg',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    submitButton: formikProps => ({
      text: 'Submit',
      color: 'primary',
      onClick: formikProps.handleSubmit,
      loading: formikProps.isValidating || formikProps.isSubmitting,
    }),
    cancelButton: formikProps => ({
      disabled: formikProps.isValidating || formikProps.isSubmitting,
      onClick: () => props.close(),
      color: 'secondary',
    }),
    closeButton: {
      onClick: () => props.close(),
      color: 'primary',
    },
    submitConfirmation: {
      text: 'Processed container successfully added',
      stage: stage === 1 ? 'success' : 'basic',
    },
    textFieldProps: {
      disabled: true,
      margin: 'dense',
      fullWidth: true,
    },
    remainingWeightField: (formikProps, index) => ({
      label: 'Remaining Net Weight',
      name: `details[${index}].remainingWeight`,
      disabled: formikProps.isSubmitting || !formikProps.values.details[index]?.partial,
      decimal: 0,
      required: formikProps.values.details[index]?.partial,
    }),
    containerAutocomplete: {
      options: containersInUnit,
      optionKey: 'ShortNo',
      name: 'containers',
      label: 'Select All Containers Processed...',
      multiple: true,
      autoSelect: false,
      required: true,
      onChange: ({form, field}) => {
        if (field.value) {
          form.setFieldValue('details', field.value?.map(container => ({...container, remainingWeight: 0, partial: false})));
        }
      },
    },
  }), [containersInUnit, props, stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stage === 0 && <DialogTitle>Add Processed Containers</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Add existing container to be processed:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AutoCompleteObject {...core.containerAutocomplete} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField {...core.textFieldProps} label='No. of Containers Processed'
                      value={formikProps.values.containers.length}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField {...core.textFieldProps} label='Total Gross Weight'
                      value={
                        formikProps.values.details.map(container => container.NetWeight+container.TareWeight - container.remainingWeight)
                          .reduce((a, b) => a + b, 0)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField {...core.textFieldProps} label='Total Net Weight'
                      value={formikProps.values.details.map(container => container.NetWeight - container.remainingWeight).reduce((a, b) => a + b, 0)}
                    />
                  </Grid>
                  <FieldArray name='details'>
                    {arrayHelpers => (
                      formikProps.values.details.map((container, index) => (
                        <Fragment key={index}>
                          <Grid item xs={2} container justify='center' alignContent='center'>
                            <Typography>{container.ShortNo}:</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <SwitchField name={`details[${index}].partial`} label='Partial?' onChange={() => {
                              formikProps.setFieldValue(`details[${index}].remainingWeight`, 0);
                            }} />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField {...core.textFieldProps} label='Material'
                              value={materials.ref[formikProps.values.details[index]?.MaterialRef]?.UnitDetails.MaterialName}
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField {...core.textFieldProps} value={formikProps.values.details[index]?.Flag || 'None'} label='Flag' />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <TextField {...core.textFieldProps} label='Gross Weight'
                              value={formikProps.values.details[index].NetWeight + formikProps.values.details[index].TareWeight}
                            />
                          </Grid>
                          <Grid item xs={4} md={2}>
                            <WeightField {...core.remainingWeightField(formikProps, index)} />
                          </Grid>
                        </Fragment>
                      ))
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(stage === 0 ? flagWarning(formikProps.values.containers) : '')} text={stage === 0 ? flagWarning(formikProps.values.containers) : ''} severity='warning' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewProcessedContainerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedProcessForm: PropTypes.object.isRequired,
  processedContainers: PropTypes.arrayOf(PropTypes.object),
  createdYields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewProcessedContainerModal;

import React, {Fragment} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Alert, Formik, Collapse} from '@kbi/component-library';
import {Firestore} from 'config.js';
import {connect} from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
const {FormikForm, AutoCompleteObject, validateAutoObject, WeightField, SubmitButton, FormButton} = Formik;
validateAutoObject();

class AdminInventoryModal extends React.Component {
  state = {
    formError: '',
    stage: 0,
  };
  defaultFormik = {
    type: '',
    weight: '',
  };
  validationSchema = yup.object().shape({
    type: yup.object().nullable().exists('Type is a required field.'),
    weight: yup.number().required('Weight is a required field.'),
  })
  handleSubmit(values, actions) {
    const firestoreBatch = Firestore.batch();
    const inventoryRef = Firestore.collection('Tracking-Inventory').doc();
    const containerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedContainer.ShortNo);
    const generationFormRef = Firestore.collection('Tracking-Forms').doc();
    const inventoryData = this.createInventoryData(values, generationFormRef.id);
    firestoreBatch.set(inventoryRef, inventoryData);
    firestoreBatch.set(inventoryRef.collection('Form-History').doc(), this.createFormHistoryData(inventoryData, generationFormRef.id));
    firestoreBatch.update(containerRef, {
      NetWeight: this.props.selectedContainer.NetWeight + parseInt(values.weight),
      InventoryItems: [...this.props.selectedContainer.InventoryItems, inventoryRef.id],
      AccumulationStartDate: moment(this.props.selectedContainer.AccumulationStartDate)
        .isAfter(moment(inventoryData.AccumulationStartDate)) ? new Date(values.startDate) : this.props.selectedContainer.AccumulationStartDate,
    });
    firestoreBatch.set(generationFormRef, this.createInventoryGenerationFormData(values, inventoryRef.id), {merge: true});
    firestoreBatch.commit().then(() => {
      actions.setSubmitting(false);
      this.setState({stage: this.state.stage + 1});
    }).catch(error => {
      this.setState({formError: 'There was an error during submission. Please try again.'});
      actions.setSubmitting(false);
    });
  }
  createInventoryData(values, formId) {
    return {
      AccountRef: 'E2GsmirifazTKFDDFp6y', // this is the id of kinsbursky bros. supply, inc (kbi). the account for kbi
      AccumulationStartDate: new Date(),
      Billable: true,
      Classification: 'N/A',
      IncomingPieceNo: null,
      Manifested: false,
      Material: {
        Name: this.props.materials.ref[this.props.selectedContainer.MaterialRef].UnitDetails.MaterialName,
        Ref: this.props.selectedContainer.MaterialRef,
      },
      Type: {
        Name: values.type.TypeName,
        Ref: values.type.id,
      },
      Flag: this.props.selectedContainer.Flag,
      PurchaseOrderRef: null,
      ShipmentInRef: null,
      ShippingDocRef: null,
      Source: 'KBI Generated',
      Weight: parseInt(values.weight),
      ContainerRef: this.props.selectedContainer.ShortNo,
      FormHistory: [formId],
      ShipmentOutRef: null,
      CACodes: [],
      RCRACodes: [],
    };
  }
  createFormHistoryData(inventory, formId) {
    return {
      Date: new Date(),
      FormId: formId,
      SubcollectionId: null,
      FormType: 'KBI Generation',
      StartContainer: 'Start of life',
      StartMaterial: 'Start of life',
      StartType: 'Start of life',
      StartWeight: 'Start of life',
      StartFlag: 'Start of life',
      EndContainer: inventory.ContainerRef,
      EndMaterial: inventory.Material.Name,
      EndType: inventory.Type.Name,
      EndWeight: inventory.Weight,
      EndFlag: inventory.Flag,
    };
  }
  createInventoryGenerationFormData(values, itemId) {
    return {
      MaterialRef: this.props.selectedContainer.MaterialRef,
      TypeRef: values.type.id,
      Flag: this.props.selectedContainer.Flag,
      Weight: parseInt(values.weight),
      ContainerRef: this.props.selectedContainer.ShortNo,
      DateCreated: new Date(),
      System: {
        CreatedBy: this.props.currentUser.displayName,
      },
      ItemRef: itemId,
      AccountingId: null,
      IsInvoiced: false,
      FormType: 'KBI Generation',
      Completed: true,
    };
  }
  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: 'Item successfully created',
        stage: this.state.stage === 1 ? 'success' : 'not success',
      },
      typeAutocomplete: {
        name: 'type',
        label: 'Type',
        required: true,
        options: this.props.types[this.props.selectedContainer.MaterialRef]?.filter(type => type.Active),
        optionKey: 'TypeName',
      },
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm initialValues={this.defaultFormik} onSubmit={this.handleSubmit.bind(this)} validationSchema={this.validationSchema}>
          {() => (
            <Fragment>
              {this.state.stage === 0 && <DialogTitle>Add KBI Waste Item</DialogTitle>}
              <DialogContent>
                <Collapse in={this.state.stage === 0}>
                  <DialogContentText>Enter data for the KBI waste:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <AutoCompleteObject {...core.typeAutocomplete} />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <WeightField name='weight' label='Weight' decimal={0} required />
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {this.state.stage !== 1 ? (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton variant='text' color='secondary' onClick={this.props.close}>Cancel</FormButton>
                  <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <FormButton variant='text' color='secondary' onClick={this.props.close}>close</FormButton>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  materials: state.firestore.materials,
  types: state.firestore.types,
  currentUser: state.auth.currentUser,
});

AdminInventoryModal.propTypes = {
  selectedContainer: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  currentUser: PropTypes.object.isRequired,
  types: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of other items where id is an account id
  }),
};

export default connect(mapStateToProps)(AdminInventoryModal);

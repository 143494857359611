import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
import {useSelector} from 'react-redux';
const {DateField, AutoCompleteObject, TextField, SelectField, NumberField} = Formik;

const InboundLoadsForm = ({values, toUpper}) => {
  const {accounts, locations} = useSelector(reduxState => reduxState.firestore);

  const core = useMemo(() => ({
    accountAutocomplete: {
      name: 'account',
      label: 'Account',
      options: accounts ? Object.values(accounts) : [],
      optionKey: 'AccountName',
      onChange: ({form, field}) => {
        form.setFieldValue('generator', '');
      },
    },
    generatorAutocomplete: {
      name: 'generator',
      label: 'Generator',
      loading: !values.account,
      loadingText: 'Select an account to populate',
      options: values.account ? locations[values.account.AccountId].sort((a, b) => (a.LocationName < b.LocationName ? 1 : -1)) : [],
      optionKey: 'LocationName',
    },
  }), [accounts, locations, values.account]);

  if (!accounts || !locations) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <SelectField name='completed' label='Completed?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='inboundId' label='Inbound Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='shippingDocumentNumber' label='Shipping Document Number' onBlur={() => toUpper('shippingDocumentNumber')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.accountAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.generatorAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='startDate' label='Start Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='endDate' label='End Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberField name='weightTicketNumber' label='Weight Ticket Number' />
      </Grid>
    </Grid>
  );
};

InboundLoadsForm.propTypes = {
  values: PropTypes.shape({
    startDate: null,
    endDate: null,
    account: null,
    shippingDocumentNumber: null,
    weightTicketNumber: null,
  }).isRequired,
  toUpper: PropTypes.func.isRequired,
};

export default InboundLoadsForm;

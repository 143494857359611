/* eslint-disable react/display-name */
import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography, Paper, Grid, Button} from '@material-ui/core';
import {Print, Add, DeleteForever} from '@material-ui/icons';
import {TableWithPaper} from 'components/';
import {useSelector} from 'react-redux';
import {NewConsolidatedContainerModal, DeleteModal} from './ConsolidatedContainersPanel/';

const ConsolidatedContainersPanel = props => {
  const [newContainerModalOpen, setNewContainerModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedConsolidatedContainer, setSelectedConsolidatedContainer] = useState(null);
  const containers = useSelector(state => state.firestore.containers);

  const core = {
    addContainerButton: {
      variant: 'outlined',
      onClick: () => setNewContainerModalOpen(true),
    },
    addContainerModal: {
      selectedConsolidationForm: props.selectedConsolidationForm,
      close: () => setNewContainerModalOpen(false),
    },
    consolidatedContainersTable: {
      data: props.listOfConsolidatedContainers,
      columns: [
        {accessor: 'OriginalShortNo', Header: 'Short No.'},
        {accessor: 'WeightConsolidated', Header: 'Weight Consolidated', type: 'numeric'},
        {accessor: 'NetWeight', Header: 'Original Weight', type: 'numeric'},
        {accessor: 'remainingWeight', Header: 'Remaining Weight', type: 'numeric'},
      ],
      title: {
        primary: 'Consolidated Containers',
        secondary: 'A list of all containers that have been consolidated by this form:',
      },
      paginationActive: !props.printView,
      actionsPerRow: [
        {
          icon: DeleteForever,
          tooltip: 'Remove From Form',
          hide: props.selectedConsolidationForm.Completed ? true : false,
          onClick: ({rowData}) => {
            setDeleteModalOpen(true);
            setSelectedConsolidatedContainer(rowData);
          },
        },
        rowData => ({
          icon: Print,
          hidden: !containers.ref[rowData.OriginalShortNo],
          tooltip: 'Print Label',
          onClick: ({rowData}) => {
            props.setListOfContainersToPrint([containers.ref[rowData.OriginalShortNo]]);
          },
        }),
      ],
      actionsBar: [
        {
          icon: Add,
          buttonProps: {style: {display: props.selectedConsolidationForm.Completed || !props.validContainer ? 'none' : 'inline-flex'}},
          text: 'Add Consolidated Container',
          onClick: () => {
            setNewContainerModalOpen(true);
          },
        },
        {
          icon: Print,
          text: 'Print All Labels',
          onClick: () => {
            const arrayToPrint = [];
            props.listOfConsolidatedContainers.forEach(containerDoc => {
              if (containers.ref[containerDoc.OriginalShortNo]) {
                arrayToPrint.push(containers.ref[containerDoc.OriginalShortNo]);
              }
            });
            props.setListOfContainersToPrint(arrayToPrint);
          },
        },
      ],
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
    deleteModal: {
      selectedConsolidationForm: props.selectedConsolidationForm,
      selectedConsolidatedContainer,
      close: () => {
        setDeleteModalOpen(false);
        setSelectedConsolidatedContainer(null);
      },
    },
  };

  return (
    <Fragment>
      {props.listOfConsolidatedContainers.length ? (
        <TableWithPaper {...core.consolidatedContainersTable} />
      ) : (
        <Paper style={{padding: '16px'}}>
          <Typography variant='h6' paragraph>Consolidated Containers</Typography>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>
                There have been no containers consolidated yet. Please add one to continue.
            </Typography>
            {props.validContainer && <Button {...core.addContainerButton}>Add Consolidated Container</Button>}
          </Grid>
        </Paper>
      )
      }
      {newContainerModalOpen && <NewConsolidatedContainerModal {...core.addContainerModal} />}
      {deleteModalOpen && <DeleteModal {...core.deleteModal} />}
    </Fragment>
  );
};

ConsolidatedContainersPanel.propTypes = {
  printView: PropTypes.bool.isRequired,
  selectedConsolidationForm: PropTypes.object.isRequired,
  listOfConsolidatedContainers: PropTypes.arrayOf(PropTypes.object),
  setListOfContainersToPrint: PropTypes.func.isRequired,
  validContainer: PropTypes.bool.isRequired,
};

export default ConsolidatedContainersPanel;

import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore, Storage} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {useSelector} from 'react-redux';

const stageArray = ['basic', 'success'];

const DeleteInboundContainerModal = props => {
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const [shippingDoc, setShippingDoc] = useState(null);
  const [formHistoryToDelete, setFormHistoryToDelete] = useState(null);
  const loadingData = useMemo(() => {
    if (!shippingDoc) {
      return true;
    }
    else {
      return false;
    }
  }, [shippingDoc]);
  const {inventoryItems, containers} = useSelector(state => state.firestore);
  const originalInventoryDoc = inventoryItems.ref[props.rowToBeDeleted.SortedInventoryItems[0].InventoryRef];

  useEffect(() => {
    if (originalInventoryDoc) {
      Firestore.collection('Tracking-Shipments')
        .doc(originalInventoryDoc.ShipmentInRef)
        .collection('Shipping-Documents')
        .doc(originalInventoryDoc.ShippingDocRef)
        .get()
        .then(shippingDocSnap => {
          setShippingDoc({...shippingDocSnap.data(), id: shippingDocSnap.id});
        });
    }
  }, [originalInventoryDoc]);
  useEffect(() => {
    Firestore.collectionGroup('Form-History').where('SubcollectionId', '==', props.rowToBeDeleted.id).get().then(snap => {
      const history = snap.docs.map(doc => doc);
      setFormHistoryToDelete(history);
    });
  }, [props.rowToBeDeleted]);

  const getAllImageDocuments = () => {
    return Firestore.collection('Tracking-Forms')
      .doc(props.selectedSortForm.id)
      .collection('Inbound-Containers')
      .doc(props.rowToBeDeleted.id)
      .collection('Files')
      .get()
      .then(snap => {
        return snap.docs.map(doc => doc);
      });
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const shippingDocLineItem = shippingDoc.LineItems.find(lineItem => lineItem.PieceNumber === originalInventoryDoc.IncomingPieceNo);
    shippingDocLineItem.SortNotes = [];
    const firestoreBatch = Firestore.batch();
    const imageDocArray = await getAllImageDocuments();
    imageDocArray.forEach(doc => {
      firestoreBatch.delete(doc.ref);
      Storage.ref(`Sort_Forms/${props.selectedSortForm.id}/${props.rowToBeDeleted.id}/${doc.id}`).delete();
    });
    // update original inventory item
    firestoreBatch.update(Firestore.collection('Tracking-Inventory').doc(originalInventoryDoc.id), {
      Material: shippingDocLineItem.AcceptedMaterial,
      Type: shippingDocLineItem.AcceptedType,
      CACodes: shippingDocLineItem.CACodes,
      RCRACodes: shippingDocLineItem.RCRACodes,
      Classification: shippingDocLineItem.Classification,
      ContainerRef: shippingDocLineItem.ContainerRef,
      Weight: shippingDocLineItem.NetWeight,
      Flag: shippingDocLineItem.Flag,
      Billable: false,
      FormHistory: originalInventoryDoc.FormHistory.slice(0, originalInventoryDoc.FormHistory.length - 1),
    });
    // update original container
    firestoreBatch.update(Firestore.collection('Tracking-Containers').doc(shippingDocLineItem.ContainerRef), {
      NetWeight: shippingDocLineItem.NetWeight,
      InventoryItems: [originalInventoryDoc.id],
      Active: true,
      AccumulationStartDate: originalInventoryDoc.AccumulationStartDate,
      Flag: shippingDocLineItem.Flag,
      MaterialRef: shippingDocLineItem.AcceptedMaterial.Ref,
      FacilityUnitRef: props.selectedSortForm.FacilityUnitRef,
    });
    // update Shipping doc line item
    firestoreBatch.update(Firestore.collection('Tracking-Shipments')
      .doc(originalInventoryDoc.ShipmentInRef)
      .collection('Shipping-Documents')
      .doc(shippingDoc.id), {
      LineItems: shippingDoc.LineItems,
    });
    // delete items that aren't the original inventory item
    props.rowToBeDeleted.SortedInventoryItems.forEach((item, index) => {
      if (index !== 0) {
        firestoreBatch.delete(Firestore.collection('Tracking-Inventory').doc(item.InventoryRef));
      }
    });
    // delete all form history for this interaction
    formHistoryToDelete.forEach(historyDoc => {
      firestoreBatch.delete(Firestore.collection('Tracking-Inventory').doc(historyDoc.ref.parent.parent.id).collection('Form-History').doc(historyDoc.id));
    });

    const mapOfContainers = createMapOfContainersToUpdate();

    // eslint-disable-next-line guard-for-in
    for (const shortNo in mapOfContainers) {
      // loop over and update the containers that were updated by the item being deleted
      firestoreBatch.update(Firestore.collection('Tracking-Containers').doc(shortNo), {
        InventoryItems: firebase.firestore.FieldValue.arrayRemove(...mapOfContainers[shortNo].inventoryItemsToRemove),
        NetWeight: mapOfContainers[shortNo].NetWeight,
      });
    }
    // delete inbound container from sort form
    firestoreBatch.delete(Firestore.collection('Tracking-Forms').doc(props.selectedSortForm.id).collection('Inbound-Containers').doc(props.rowToBeDeleted.id));

    firestoreBatch.commit().then(() => {
      setSubmitting(false);
      setStage(stage + 1);
    }).catch(error => {
      setSubmitting(false);
      setFormError('There was an error during submission. Please try again.');
    });
  };
  const createMapOfContainersToUpdate = () => {
    const mapOfContainers = {};
    props.rowToBeDeleted.SortedInventoryItems.forEach((item, index) => {
      if (mapOfContainers[item.ProductionContainer]) {
        mapOfContainers[item.ProductionContainer].inventoryItemsToRemove.push(item.InventoryRef);
        mapOfContainers[item.ProductionContainer].NetWeight -= item.NetWeight;
      }
      else {
        mapOfContainers[item.ProductionContainer] = {
          inventoryItemsToRemove: [item.InventoryRef],
          NetWeight: containers.ref[item.ProductionContainer].NetWeight - item.NetWeight,
        };
      }
    });
    return mapOfContainers;
  };

  const core = {
    dialog: {
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    cancelButton: {
      onClick: props.close,
      color: 'secondary',
      disabled: submitting,
    },
    closeButton: {
      onClick: props.close,
      color: 'primary',
    },
    submitButton: {
      text: 'Submit',
      color: 'primary',
      disabled: loadingData,
      loading: submitting,
      onClick: handleSubmit,
    },
    submitConfirmation: {
      text: 'Inbound container successfully removed from sort form.',
      delete: true,
      stage: stageArray[stage],
    },
  };

  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>Remove Container from Sort Form</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>
            Removing this container from the sort form will affect all containers that were affected by this sort.
            Only continue if you are certain you want to undo this sort.
          </DialogContentText>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] !== 'success' ? (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      )}
    </Dialog >
  );
};

DeleteInboundContainerModal.propTypes = {
  selectedSortForm: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  rowToBeDeleted: PropTypes.object.isRequired,
};

export default DeleteInboundContainerModal;

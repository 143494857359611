import React, {useState, useMemo, Fragment} from 'react';
import {Typography, makeStyles, Button, Paper} from '@material-ui/core';
import {NewProcessFormModal} from '../Production/';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {TableWithPaper, AsyncLoader, MovementFormModal} from 'components';
import moment from 'moment';
import {AssignmentTurnedInOutlined, Search, Add, Send} from '@material-ui/icons';

const ProcessView = () => {
  const [processFormModalOpen, setProcessFormModalOpen] = useState(false);
  const [movementFormModalOpen, setMovementFormModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const {processForms, facilityUnits, containers, inventoryItems, materials, types} = useSelector(state => state.firestore);

  const processFormsForTable = useMemo(() => {
    if (processForms && facilityUnits) {
      return processForms.list
        .map((form, index) => ({
          ...form,
          yieldName: materials.ref[form.YieldRef]?.UnitDetails.MaterialName || 'Mixed',
          facilityUnitName: facilityUnits.ref[form.FacilityUnitRef].Name,
          typeName: types[form.YieldRef]?.find(type => type.id === form.TypeRef)?.TypeName,
        }));
    }
    else return [];
  }, [facilityUnits, materials, processForms, types]);

  const core = {
    processFormModal: {
      open: processFormModalOpen,
      close: () => setProcessFormModalOpen(false),
    },
    button: {
      variant: 'outlined',
    },
    processFormButton: {
      onClick: () => setProcessFormModalOpen(true),
    },
    movementFormModal: {
      open: movementFormModalOpen,
      close: () => setMovementFormModalOpen(false),
    },
    movementFormButton: {
      style: {marginLeft: '8px'},
      onClick: () => setMovementFormModalOpen(true),
    },
    processFormsTable: {
      columns: [
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'Shift', Header: 'Shift'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'yieldName', Header: 'Yield Name'},
        {accessor: 'typeName', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
      ],
      sortInitial: [{id: 'Date', desc: true}],
      data: processFormsForTable,
      title: 'Production - Process',
      paginationInitialSize: 10,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Form Details',
          onClick: ({rowData}) => history.push(`/production/process/${rowData.id}`),
        },
      ],
      actionsBar: [
        {
          icon: Add,
          text: 'Add Process Form',
          onClick: () => setProcessFormModalOpen(true),
        },
        {
          icon: Send,
          text: 'New Movement Form',
          onClick: () => setMovementFormModalOpen(true),
        },
      ],
    },
  };

  if (!processForms || !facilityUnits || !inventoryItems || !containers || !materials) return <AsyncLoader />;

  return <div className='hidePrint'>
    {processForms.list.length ? (
      <TableWithPaper {...core.processFormsTable} />
    ) : (
      <Fragment>
        <Paper className={classes.paper}>
          <Typography variant="h4">Daily Production</Typography>
          <Typography variant="h6" gutterBottom>
            {moment().format('MM/DD/YYYY')}
          </Typography>
          <AssignmentTurnedInOutlined style={{fontSize: '10em'}} />
          <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    How would you like to start the day?
          </Typography>
          <div>
            <Button {...core.button} {...core.processFormButton}>
                          New Process Form
            </Button>
            <Button {...core.button} {...core.movementFormButton}>
                      New Movement Form
            </Button>
          </div>
        </Paper>
      </Fragment>
    )}
    {processFormModalOpen && <NewProcessFormModal {...core.processFormModal} />}
    {movementFormModalOpen && <MovementFormModal {...core.movementFormModal} />}
  </div>;
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

ProcessView.propTypes = {
// there are no props
};

export default ProcessView;

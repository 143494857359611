/* eslint-disable guard-for-in */
import React, {useState, Fragment, useContext, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {EventNote, ChevronLeft, ExpandMore, ExpandLess, Search, ImportExport} from '@material-ui/icons';
import {NewReportModal} from './BaseReportView/';
import {ReportContext} from 'App.js';
import {Collapse} from '@kbi/component-library';
import {TableWithPaper, InboundTable} from 'components/';
import {useHistory} from 'react-router-dom';
import {exportToCSV} from '@kbi/utility-library';

const BaseReportView = props => {
  const reportContext = useContext(ReportContext);
  const [newReportModalOpen, setNewReportModalOpen] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const [collapsedReportData, setCollapsedReportData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.split('/')[history.location.pathname.split('/').length - 2] === 'inventoryItems') {
      const params = new URLSearchParams(history.location.search);
      setQueryParams({
        formId: params.get('formId'),
      });
      if (!reportContext.reportData?.length) {
        setNewReportModalOpen(true);
      }
    }
  }, [history.location, reportContext.reportData]);

  const core = useMemo(() =>({
    paper: {
      className: props.classes.paper,
      style: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    backButton: {
      variant: 'outlined',
      onClick: () => {
        reportContext.setReportData([]);
        reportContext.setView(null);
      },
    },
    createReportButton: {
      color: 'primary',
      variant: 'outlined',
      onClick: () => setNewReportModalOpen(true),
    },
    newReportModal: {
      open: newReportModalOpen,
      close: () => setNewReportModalOpen(false),
      queryParams,
      changeView: ({view, data}) => {
        reportContext.setReportData(data);
        reportContext.setView(view);
      },
    },
    inboundTable: {
      title: 'Inbound Report',
      trackingShipments: reportContext.reportData,
    },
    annualInboundTable: {
      columns: [
        {Header: 'Date Received', accessor: 'ShipmentTime', type: 'datetime'},
        {Header: 'Generator EPA ID #', accessor: 'LocationEpaId'},
        {Header: 'Generator Name', accessor: 'LocationName'},
        {Header: 'Facility', accessor: 'Facility'},
        {Header: 'Material', accessor: 'Material.Name'},
        {Header: 'Type', accessor: 'Type.Name'},
        {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
        {Header: 'State Codes', accessor: 'CACodeString'},
        {Header: 'Federal Codes', accessor: 'RCRACodeString'},
        {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
        {Header: 'Shipping Paper #', accessor: 'ShippingDocNumber'},
        {Header: 'Inbound Id', accessor: 'ShipmentInRef'},
        {Header: 'Handling Code', accessor: 'HandlingCode'},
        {Header: 'Classification', accessor: 'Classification'},
        {Header: 'Transporter Name', accessor: 'TransporterName'},
        {Header: 'Transporter EPA ID #', accessor: 'TransporterEpaId'},
      ],
      title: {primary: 'Annual Inbound Report'},
      sortInitial: [{id: 'LocationEpaId', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      data: reportContext.reportData,
      actionsBar: [
        collapseActionButton(reportContext.reportData, [collapsedReportData, setCollapsedReportData]),
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [
              {Header: 'Date Received', accessor: 'ShipmentTime', type: 'datetime'},
              {Header: 'Generator EPA ID #', accessor: 'LocationEpaId'},
              {Header: 'Generator Name', accessor: 'LocationName'},
              {Header: 'Facility', accessor: 'Facility'},
              {Header: 'Material', accessor: 'Material.Name'},
              {Header: 'Type', accessor: 'Type.Name'},
              {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
              {Header: 'State Codes', accessor: 'CACodeString'},
              {Header: 'Federal Codes', accessor: 'RCRACodeString'},
              {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
              {Header: 'Shipping Paper #', accessor: 'ShippingDocNumber'},
              {Header: 'Inbound Id', accessor: 'ShipmentInRef'},
              {Header: 'Handling Code', accessor: 'HandlingCode'},
              {Header: 'Classification', accessor: 'Classification'},
              {Header: 'Transporter Name', accessor: 'TransporterName'},
              {Header: 'Transporter EPA ID #', accessor: 'TransporterEpaId'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Annual_Inbound.csv');
          },
        },
      ],
    },
    annualInboundCollapsedTable: {
      columns: [
        {Header: 'Facility', accessor: 'Facility'},
        {Header: 'Generator EPA ID #', accessor: 'LocationEpaId'},
        {Header: 'Generator Name', accessor: 'LocationName'},
        {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
        {Header: 'Material', accessor: 'Material.Name'},
        {Header: 'Type', accessor: 'Type.Name'},
        {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
        {Header: 'State Codes', accessor: 'CACodeString'},
        {Header: 'Federal Codes', accessor: 'RCRACodeString'},
        {Header: 'Handling Code', accessor: 'HandlingCode'},
      ],
      title: {primary: 'Annual Inbound Collapsed Report'},
      sortInitial: [{id: 'LocationEpaId', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      data: collapsedReportData,
      actionsBar: [
        collapseActionButton(reportContext.reportData, [collapsedReportData, setCollapsedReportData]),
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns =[
              {Header: 'Facility', accessor: 'Facility'},
              {Header: 'Generator EPA ID #', accessor: 'LocationEpaId'},
              {Header: 'Generator Name', accessor: 'LocationName'},
              {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
              {Header: 'Material', accessor: 'Material.Name'},
              {Header: 'Type', accessor: 'Type.Name'},
              {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
              {Header: 'State Codes', accessor: 'CACodeString'},
              {Header: 'Federal Codes', accessor: 'RCRACodeString'},
              {Header: 'Handling Code', accessor: 'HandlingCode'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Annual_Inbound_Collapsed.csv');
          },
        },
      ],
    },
    annualOutboundTable: {
      columns: [
        {Header: 'Date Sent', accessor: 'ShipmentTime', type: 'datetime'},
        {Header: 'Facility', accessor: 'Facility'},
        {Header: 'Receiving Facility EPA ID #', accessor: 'LocationEpaId'},
        {Header: 'Receiving Facility Name', accessor: 'LocationName'},
        {Header: 'Material', accessor: 'Material.Name'},
        {Header: 'Type', accessor: 'Type.Name'},
        {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
        {Header: 'State Codes', accessor: 'CACodeString'},
        {Header: 'Federal Codes', accessor: 'RCRACodeString'},
        {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
        {Header: 'Shipping Paper #', accessor: 'ShippingDocNumber'},
        {Header: 'Outbound Id', accessor: 'ShipmentOutRef'},
        {Header: 'Handling Code', accessor: 'HandlingCode'},
        {Header: 'Classification', accessor: 'Classification'},
        {Header: 'Transporter Name', accessor: 'TransporterName'},
        {Header: 'Transporter EPA ID #', accessor: 'TransporterEpaId'},
      ],
      title: {primary: 'Annual Outbound Report'},
      sortInitial: [{id: 'LocationEpaId', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      data: reportContext.reportData || [],
      actionsBar: [
        collapseActionButton(reportContext.reportData, [collapsedReportData, setCollapsedReportData]),
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [
              {Header: 'Date Sent', accessor: 'ShipmentTime', type: 'datetime'},
              {Header: 'Facility', accessor: 'Facility'},
              {Header: 'Receiving Facility EPA ID #', accessor: 'LocationEpaId'},
              {Header: 'Receiving Facility Name', accessor: 'LocationName'},
              {Header: 'Material', accessor: 'Material.Name'},
              {Header: 'Type', accessor: 'Type.Name'},
              {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
              {Header: 'State Codes', accessor: 'CACodeString'},
              {Header: 'Federal Codes', accessor: 'RCRACodeString'},
              {Header: 'Shipping Paper Type', accessor: 'ShippingDocType'},
              {Header: 'Shipping Paper #', accessor: 'ShippingDocNumber'},
              {Header: 'Outbound Id', accessor: 'ShipmentOutRef'},
              {Header: 'Handling Code', accessor: 'HandlingCode'},
              {Header: 'Classification', accessor: 'Classification'},
              {Header: 'Transporter Name', accessor: 'TransporterName'},
              {Header: 'Transporter EPA ID #', accessor: 'TransporterEpaId'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Annual_Outbound.csv');
          },
        },
      ],
    },
    annualOutboundCollapsedTable: {
      columns: [
        {Header: 'Facility', accessor: 'Facility'},
        {Header: 'Receiving Facility EPA ID #', accessor: 'LocationEpaId'},
        {Header: 'Facility Name', accessor: 'LocationName'},
        {Header: 'Material', accessor: 'Material.Name'},
        {Header: 'Type', accessor: 'Type.Name'},
        {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
        {Header: 'State Codes', accessor: 'CACodeString'},
        {Header: 'Federal Codes', accessor: 'RCRACodeString'},
        {Header: 'Handling Code', accessor: 'HandlingCode'},
      ],
      title: {primary: 'Annual Outbound Collapsed Report'},
      sortInitial: [{id: 'LocationEpaId', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      data: collapsedReportData || [],
      actionsBar: [
        collapseActionButton(reportContext.reportData, [collapsedReportData, setCollapsedReportData]),
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [
              {Header: 'Facility', accessor: 'Facility'},
              {Header: 'Receiving Facility EPA ID #', accessor: 'LocationEpaId'},
              {Header: 'Facility Name', accessor: 'LocationName'},
              {Header: 'Material', accessor: 'Material.Name'},
              {Header: 'Type', accessor: 'Type.Name'},
              {Header: 'Weight', accessor: 'Weight', type: 'numeric'},
              {Header: 'State Codes', accessor: 'CACodeString'},
              {Header: 'Federal Codes', accessor: 'RCRACodeString'},
              {Header: 'Handling Code', accessor: 'HandlingCode'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Annual_Outbound_Collapsed.csv');
          },
        },
      ],
    },
    returnedShippingDocTable: {
      columns: [
        {Header: 'Account Name', accessor: 'accountName'},
        {Header: 'Location Name', accessor: 'locationName'},
        {Header: 'Shipment Time', accessor: 'ShipmentTime', type: 'datetime'},
        {Header: 'Days Since Shipped', accessor: 'daysSinceShipped', type: 'numeric'},
        {Header: 'Required Reason', accessor: 'requiredReason'},
        {Header: 'Document Type', accessor: 'DocumentType'},
      ],
      title: 'Returned Shipping Document Report',
      sortInitial: [{id: 'daysSinceShipped', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      data: reportContext.reportData,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Outbound',
          onClick: ({rowData}) => {
            history.push(`/shipments/outbound/${rowData.id}`);
          },
        },
      ],
    },
    inventoryTable: {
      columns: [
        {accessor: 'ContainerRef', Header: 'Short No.'},
        {accessor: 'id', Header: 'Id'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'Type.Name', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'Weight', Header: 'Weight', type: 'numeric'},
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'generator.LocationName', Header: 'Generator'},
        {accessor: 'AccumulationStartDate', Header: 'Start Date', type: 'datetime'},
        {accessor: 'IncomingPieceNo', Header: 'Incoming Piece No.', type: 'numeric'},
        {accessor: 'purchaseOrder.OrderNumber', Header: 'PO No.'},
        {accessor: 'ShipmentInRef', Header: 'Inbound Id'},
        {accessor: 'shippingDoc.DocumentNumber', Header: 'Shipping Doc No.'},
        {accessor: 'ShipmentOutRef', Header: 'Outbound Id'},
        {accessor: 'Billable', Header: 'Billable?', type: 'boolean'},
        {accessor: 'Manifested', Header: 'Manifested?', type: 'boolean'},
      ],
      data: reportContext.reportData,
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      title: {primary: 'Inventory Report'},
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Inventory Item',
          onClick: ({rowData}) => {
            history.push(`/reports/inventory/${rowData.id}`);
          },
        }],
      actionsBar: [
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [
              {accessor: 'ContainerRef', Header: 'Short No.'},
              {accessor: 'id', Header: 'Id'},
              {accessor: 'Material.Name', Header: 'Material'},
              {accessor: 'Type.Name', Header: 'Type'},
              {accessor: 'Flag', Header: 'Flag'},
              {accessor: 'Weight', Header: 'Weight', type: 'numeric'},
              {accessor: 'accountName', Header: 'Account'},
              {accessor: 'generator.LocationName', Header: 'Generator'},
              {accessor: 'AccumulationStartDate', Header: 'Start Date', type: 'datetime'},
              {accessor: 'IncomingPieceNo', Header: 'Incoming Piece No.', type: 'numeric'},
              {accessor: 'purchaseOrder.OrderNumber', Header: 'PO No.'},
              {accessor: 'ShipmentInRef', Header: 'Inbound Id'},
              {accessor: 'shippingDoc.DocumentNumber', Header: 'Shipping Doc No.'},
              {accessor: 'ShipmentOutRef', Header: 'Outbound Id'},
              {accessor: 'Billable', Header: 'Billable?', type: 'boolean'},
              {accessor: 'Manifested', Header: 'Manifested?', type: 'boolean'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Inventory_Report.csv');
          },
        },
      ],
    },
    outboundTable: {
      columns: [
        {accessor: 'id', Header: 'Id'},
        {Header: 'Account Name', accessor: 'accountName'},
        {Header: 'Location Name', accessor: 'locationName'},
        {Header: 'Transporter Name', accessor: 'transporterName'},
        {Header: 'Shipment Time', accessor: 'ShipmentTime', type: 'datetime'},
        {Header: 'Booking No.', accessor: 'BookingNumber'},
        {Header: 'Container No.', accessor: 'ContainerNumber'},
        {Header: 'Shipping Doc No.', accessor: 'DocumentNumber'},
        {Header: 'Facility', accessor: 'Facility'},
        {Header: 'PO No.', accessor: 'PurchaseOrderNumber'},
        {Header: 'Seal No.', accessor: 'SealNumber'},
        {Header: 'Accounting Id', accessor: 'AccountingId'},
      ],
      data: reportContext.reportData,
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      title: {primary: 'Outbound Report'},
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Outbound',
          onClick: ({rowData}) => {
            history.push(`/shipments/outbound/${rowData.id}`);
          },
        }],
    },
    buildingOutboundTable: {
      columns: [
        {accessor: 'ShortNo', Header: 'Short Number'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'typeString', Header: 'Types'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'grossWeight', Header: 'Gross (lbs)', type: 'numeric'},
        {accessor: 'TareWeight', Header: 'Tare (lbs)', type: 'numeric'},
        {accessor: 'NetWeight', Header: 'Net (lbs)', type: 'numeric'},
        {accessor: 'Container', Header: 'Container'},
        {accessor: 'InboundContainer', Header: 'Inbound?', type: 'boolean'},
        {accessor: 'billableContainer', Header: 'Billable?', type: 'boolean'},
        {accessor: 'startDate', Header: 'Start Date', type: 'datetime'},
      ],
      data: reportContext.reportData,
      title: {primary: 'Building Outbound Load'},
      sortInitial: [{id: 'startDate', desc: true}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      actionsBar: [
        {
          icon: ImportExport,
          text: 'Export Table',
          onClick: () => {
            const columns = [
              {accessor: 'ShortNo', Header: 'Short Number'},
              {accessor: 'facilityUnitName', Header: 'Facility Unit'},
              {accessor: 'materialName', Header: 'Material'},
              {accessor: 'typeString', Header: 'Types'},
              {accessor: 'Flag', Header: 'Flag'},
              {accessor: 'grossWeight', Header: 'Gross (lbs)', type: 'numeric'},
              {accessor: 'TareWeight', Header: 'Tare (lbs)', type: 'numeric'},
              {accessor: 'NetWeight', Header: 'Net (lbs)', type: 'numeric'},
              {accessor: 'Container', Header: 'Container'},
              {accessor: 'InboundContainer', Header: 'Inbound?', type: 'boolean'},
              {accessor: 'billableContainer', Header: 'Billable?', type: 'boolean'},
              {accessor: 'startDate', Header: 'Start Date', type: 'datetime'},
            ];
            exportToCSV(reportContext.reportData, columns, 'Building_Outbound.csv');
          },
        },
      ],
    },
    proformaTable: {
      columns: [
        {Header: 'Id', accessor: 'id'},
        {Header: 'Invoiced Date', accessor: 'System.InvoicedOn', type: 'timestamp'},
        {Header: 'Accounting Id', accessor: 'AccountingId'},
        {Header: 'Account Name', accessor: 'accountName'},
        {Header: 'Generator Name', accessor: 'GeneratorName'},
        {Header: 'Inbound Id', accessor: 'InboundRef'},
        {Header: 'PO Number', accessor: 'InternalPO'},
        {Header: 'Shipping Doc No.', accessor: 'ShippingDocNo'},
        {Header: 'Weight Ticket', accessor: 'WeightTickets'},
        {Header: 'Invoiced?', accessor: 'IsInvoiced', type: 'boolean'},
      ],
      data: reportContext.reportData,
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      title: {primary: 'Proforma Invoice Report'},
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Proforma Invoice',
          onClick: ({rowData}) => {
            history.push(`/accounting/proforma/${rowData.id}`);
          },
        }],
    },
    processFormTable: {
      data: reportContext.reportData,
      columns: [
        {accessor: 'id', Header: 'Form Id'},
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'yieldName', Header: 'Yield'},
        {accessor: 'typeName', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'Shift', Header: 'Shift'},
        {accessor: 'AccountingId', Header: 'Accounting Id'},
        {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
        {accessor: 'IsInvoiced', Header: 'Invoiced?', type: 'boolean'},
      ],
      title: {primary: 'Process Form Report'},
      sortInitial: [{id: 'Date', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Process Form',
          onClick: ({rowData}) => {
            history.push(`/production/process/${rowData.id}`);
          },
        },
      ],
    },
    sortFormTable: {
      data: reportContext.reportData,
      columns: [
        {accessor: 'id', Header: 'Form Id'},
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'Shift', Header: 'Shift'},
        {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
      ],
      title: {primary: 'Sort Form Report'},
      sortInitial: [{id: 'Date', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Sort Form',
          onClick: ({rowData}) => {
            history.push(`/production/sort/${rowData.id}`);
          },
        },
      ],
    },
    consolidationFormTable: {
      data: reportContext.reportData,
      columns: [
        {accessor: 'id', Header: 'Form Id'},
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
        {accessor: 'ConsolidationContainer', Header: 'Consolidation Container'},
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'MixedLoad', Header: 'Mixed?', type: 'boolean'},
        {accessor: 'Completed', Header: 'Completed?', type: 'boolean'},
      ],
      title: {primary: 'Consolidation Form Report'},
      sortInitial: [{id: 'Date', desc: false}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Consolidation Form',
          onClick: ({rowData}) => {
            history.push(`/production/consolidate/${rowData.id}`);
          },
        },
      ],
    },
    containerQueryTable: {
      data: reportContext.reportData,
      columns: [
        {accessor: 'id', Header: 'Form Id'},
        {accessor: 'Date', Header: 'Date', type: 'timestamp'},
        {accessor: 'FormType', Header: 'Form Type'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'facilityUnitName', Header: 'Facility Unit'},
      ],
      title: {primary: 'Container Report'},
      sortInitial: [{id: 'Date', desc: true}],
      paginationSizes: [10, 20, 50],
      paginationInitialSize: 10,
      actionsPerRow: [
        (rowData) => ({
          icon: Search,
          hide: rowData.FormType === 'KBI Generation',
          tooltip: `View ${rowData.FormType} Form`,
          onClick: ({rowData}) => {
            let historyString = '';
            if (rowData.FormType === 'Consolidation') {
              historyString = `/production/consolidate/${rowData.id}`;
            }
            else if (rowData.FormType === 'Process') {
              historyString = `/production/process/${rowData.id}`;
            }
            else if (rowData.FormType === 'Sort') {
              historyString = `/production/sort/${rowData.id}`;
            }
            else if (rowData.FormType === 'Outbound') {
              historyString = `/shipments/outbound/${rowData.id}`;
            }
            else if (rowData.FormType === 'Inbound') {
              historyString = `/shipments/inbound/${rowData.id}`;
            }
            history.push(historyString);
          },
        }),
      ],
    },
  }), [props.classes.paper, newReportModalOpen, queryParams, reportContext, collapsedReportData, history]);

  return (
    <Fragment>
      {reportContext.view && (
        <Button {...core.backButton}>
          <ChevronLeft className={props.classes.extendedIcon} />
          New Report
        </Button>
      )}
      <Collapse in={!reportContext.view}>
        <Paper {...core.paper}>
          <Typography variant='h4' gutterBottom>
            Tracking System Reporting
          </Typography>
          <EventNote style={{fontSize: '10em'}} />
          <Button {...core.createReportButton}>Create Report</Button>
        </Paper>
      </Collapse>
      <Collapse in={reportContext.view === 'container'}>
        {reportContext.view === 'container' && (
          <TableWithPaper {...core.containerQueryTable} />
        )}
      </Collapse>
      <Collapse in={reportContext.view === 'inboundLoad'}>
        <InboundTable {...core.inboundTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'outboundLoad'}>
        <TableWithPaper {...core.outboundTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'inventoryItems'}>
        <TableWithPaper {...core.inventoryTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'buildOutbound'}>
        <TableWithPaper {...core.buildingOutboundTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'returnedShippingDocuments'}>
        <TableWithPaper {...core.returnedShippingDocTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'annualInbound'}>
        {collapsedReportData ? <TableWithPaper {...core.annualInboundCollapsedTable} /> : null}
        {!collapsedReportData ? <TableWithPaper {...core.annualInboundTable} /> : null}
      </Collapse>
      <Collapse in={reportContext.view === 'annualOutbound'}>
        {collapsedReportData ? <TableWithPaper {...core.annualOutboundCollapsedTable} /> : null}
        {!collapsedReportData ? <TableWithPaper {...core.annualOutboundTable} /> : null}
      </Collapse>
      <Collapse in={reportContext.view === 'proformaInvoice'}>
        <TableWithPaper {...core.proformaTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'process'}>
        <TableWithPaper {...core.processFormTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'sort'}>
        <TableWithPaper {...core.sortFormTable} />
      </Collapse>
      <Collapse in={reportContext.view === 'consolidation'}>
        <TableWithPaper {...core.consolidationFormTable} />
      </Collapse>
      {newReportModalOpen && <NewReportModal {...core.newReportModal} />}
    </Fragment>
  );
};

function collapseAnnualData(reportData) {
  const inventoryMap = {};
  // loops over each item within the table creating a map of generator objects, with material maps inside
  reportData.forEach(item => {
    let currentPath = inventoryMap; // per facility

    if (currentPath[item.Facility]) {
      currentPath = inventoryMap[item.Facility]; // per facility per location

      if (currentPath[item.LocationName]) {
        currentPath = inventoryMap[item.Facility][item.LocationName]; // per facility per location per doc type

        if (currentPath[item.ShippingDocType]) {
          currentPath = inventoryMap[item.Facility][item.LocationName][item.ShippingDocType]; // per facility per location per doc type per material

          if (currentPath[item.Material.Name]) {
            currentPath = inventoryMap[item.Facility][item.LocationName][item.ShippingDocType][item.Material.Name];

            if (currentPath[item.Type.Name]) {
              currentPath = currentPath[item.Type.Name];

              // adds the weight to the generator/material objects weight
              currentPath.Weight += item.Weight;
              // loops over the items rcra codes, and appends them to the generator/material object
              item.RCRACodes.forEach(itemCode => {
                if (!currentPath.RCRACodeString.includes(itemCode)) {
                  currentPath.RCRACodeString += currentPath.RCRACodeString.length ?
                    `, ${itemCode}` : itemCode;
                }
              });
              // loops over the items ca codes, and appends them to the generator/material object
              item.CACodes.forEach(itemCode => {
                if (!currentPath.CACodeString.includes(itemCode)) {
                  currentPath.CACodeString += currentPath.CACodeString.length ?
                    `, ${itemCode}` : itemCode;
                }
              });
              // appends handling code to the generator/material object
              if (!currentPath.HandlingCode) {
                currentPath.HandlingCode = item.HandlingCode;
              }
            }
            else {
              currentPath[item.Type.Name] = {...item};
            }
          }
          else {
            currentPath[item.Material.Name] = {[item.Type.Name]: {...item}};
          }
        }
        else {
          currentPath[item.ShippingDocType] = {[item.Material.Name]: {[item.Type.Name]: {...item}}};
        }
      }
      else {
        currentPath[item.LocationName] = {[item.ShippingDocType]: {[item.Material.Name]: {[item.Type.Name]: {...item}}}};
      }
    }
    else {
      currentPath[item.Facility] = {[item.LocationName]: {[item.ShippingDocType]: {[item.Material.Name]: {[item.Type.Name]: {...item}}}}};
    }
  });
  // this block converts the generator/material map into a flat array to be passed into the table
  const inventoryArray = [];
  for (const facility in inventoryMap) {
    for (const epaId in inventoryMap[facility]) {
      for (const shippingDocType in inventoryMap[facility][epaId]) {
        for (const materialName in inventoryMap[facility][epaId][shippingDocType]) {
          for (const typeName in inventoryMap[facility][epaId][shippingDocType][materialName]) {
            inventoryArray.push(inventoryMap[facility][epaId][shippingDocType][materialName][typeName]);
          }
        }
      }
    }
  }
  return inventoryArray;
}
function collapseActionButton(reportData, [collapsedReportData, setCollapsedReportData]) {
  return {
    // eslint-disable-next-line react/display-name
    icon: collapsedReportData ? ExpandMore : ExpandLess,
    text: collapsedReportData ? 'Expand Data' : 'Collapse Data',
    onClick: () => {
      if (collapsedReportData) {
        setCollapsedReportData(null);
      }
      else setCollapsedReportData(collapseAnnualData(reportData));
    },
  };
}

BaseReportView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BaseReportView;

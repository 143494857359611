/* eslint-disable guard-for-in */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Firestore, Storage} from 'config.js';
import {Alert, Formik} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  Collapse,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import {object, number, array, string} from 'yup';
import {FieldArray} from 'formik';
import {connect} from 'react-redux';
import {Clear} from '@material-ui/icons';
const {FormikForm, TextField, SelectField, CurrencyField, FormButton, SubmitButton} = Formik;

class EditInvoiceModal extends React.Component {
  state = {
    stage: 0,
    formError: '',
    files: {},
    viewOnly: this.props.selectedInvoice.IsInvoiced,
  };
  componentDidMount() {
    Firestore.collectionGroup('Files').where('ShippingDocRef', '==', this.props.selectedInvoice.ShippingDocRef).get().then(snap => {
      const mapOfFiles = {};
      snap.forEach(doc => {
        if (mapOfFiles[doc.data().IncomingPieceNo]) {
          mapOfFiles[doc.data().IncomingPieceNo].push(doc.data().FilePath);
        }
        else {
          mapOfFiles[doc.data().IncomingPieceNo] = [doc.data().FilePath];
        }
      });
      this.setState({files: mapOfFiles});
    });
  }
  stageArray = ['basic', 'additionalCharges', 'success'];
  validationSchema = () => {
    if (this.stageArray[this.state.stage] === 'basic') {
      return object().shape({
        arrayOfTypes: array().of(
          object().shape({
            billType: string().required('Bill Type is a required field.')
              .notOneOf(['Market'], 'Market is not a valid choice for invoice. You must select a choice that affects the bill.'),
            price: number().transform(value => isNaN(parseFloat(value)) ? 0 : value).required('Price is a required field.').when('billType', {
              is: value => value === 'No Charge',
              then: number().min(0, 'No Charge items must be $0.00').max(0, 'No Charge items must be $0.00'),
              otherwise: number().min(0.0001, 'Item must have a price.'),
            }),
          }),
        ),
      });
    }
    else if (this.stageArray[this.state.stage] === 'additionalCharges') {
      return object().shape({
        arrayOfAdditionalCharges: array().of(
          object().shape({
            description: string().required('Description is a required field.'),
            billType: string().required('Bill Type is a required field.'),
            price: number().transform(value => isNaN(parseFloat(value)) ? 0 : value).required('Price is a required field.').when('billType', {
              is: value => value === 'No Charge',
              then: number().min(0, 'No Charge items must be $0.00').max(0, 'No Charge items must be $0.00'),
              otherwise: number().min(0.01, 'Item must have a price.'),
            }),
          }),
        ),
      });
    }
  }
  defaultFormik = {
    arrayOfTypes: Object.values(this.props.selectedInvoice.Summary).map((materialType, index) => {
      return Object.values(materialType).map(flagObject => {
        return {
          material: flagObject.Material.Name,
          type: flagObject.Type.Name,
          flag: flagObject.Flag,
          billType: flagObject.BillType,
          price: flagObject.Price,
        };
      });
    }).flat(),
    arrayOfAdditionalCharges: this.props.selectedInvoice.AdditionalCharges ?
      Object.values(this.props.selectedInvoice.AdditionalCharges).map((additionalCharge, index) => {
        return {
          description: additionalCharge.Description,
          billType: additionalCharge.BillType,
          price: additionalCharge.Price,
        };
      }) : [],
  }
  handleSubmit(values, actions) {
    if (this.stageArray[this.state.stage] === 'basic') {
      actions.setSubmitting(false);
      return this.setState({stage: this.state.stage + 1});
    }
    this.setState({formError: ''});
    const invoiceRef = Firestore.collection('Tracking-Invoices').doc(this.props.selectedInvoice.id);
    const summaryDataObject = this.createSummaryDataForFirestore(values);
    const additionalChargeData = this.createAdditionalChargesDataForFirestore(values);
    Firestore.collection('Email').add({
      to: ['ar@kbirecycling.com'],
      template: {
        name: 'PricingApprovedEmail',
        data: {
          poNumber: this.props.selectedInvoice.InternalPO,
          link: `https://tracking.kbi.works/accounting/proforma/${this.props.selectedInvoice.id}`,
        },
      },
    });
    invoiceRef.update({
      'Summary': summaryDataObject,
      'AdditionalCharges': additionalChargeData,
      'System.UpdatedBy': this.props.currentUser.displayName,
      'System.UpdatedOn': new Date()})
      .then(() => {
        actions.setSubmitting(false);
        this.setState({stage: this.state.stage + 1});
        this.props.updateInvoiceFunc();
      })
      .catch(error => {
        actions.setSubmitting(false);
        this.setState({formError: 'There was an error during submission. Please try again.'});
      });
  }
  createAdditionalChargesDataForFirestore(values) {
    return values.arrayOfAdditionalCharges.map(charge => ({
      Description: charge.description.trim(),
      BillType: charge.billType,
      Price: charge.price,
    }));
  }
  createSummaryDataForFirestore(values) {
    const summary = JSON.parse(JSON.stringify(this.props.selectedInvoice.Summary));
    for (const typeId in summary) {
      for (const flag in summary[typeId]) {
        const matchingFormField = values.arrayOfTypes.find(fieldRow => fieldRow.type === summary[typeId][flag || 'none'].Type.Name &&
          fieldRow.flag === summary[typeId][flag || 'none'].Flag );
        summary[typeId][flag || 'none'].BillType = matchingFormField.billType;
        summary[typeId][flag || 'none'].Price = matchingFormField.price;
      }
    }
    return summary;
  }
  openImageLink(imgPath) {
    Storage.ref(imgPath).getDownloadURL().then(url => window.open(url, '__blank'));
  }
  createPieceNotesText() {
    return this.props.pieces.map((pieceDoc, index) => {
      if (pieceDoc.Notes.length) {
        return (
          <Fragment key={index}>
            {<ListSubheader disableSticky>Piece #{pieceDoc.id}</ListSubheader>}
            {pieceDoc.Notes.map((note, index) => (
              note.length ? (
                <ListItem key={`note${index}`}>
                  <ListItemText primary={`- ${note}`} />
                </ListItem>
              ) : null
            ))}
            {this.state.files[pieceDoc.id] ? (
              this.state.files[pieceDoc.id].map((imgPath, index) => (
                <ListItem key={`img${index}`}>
                  <ListItem button onClick={() => this.openImageLink(imgPath)}>
                    <Typography color='primary'>{`- Image #${index + 1} Link`}</Typography>
                  </ListItem>
                </ListItem>
              ))
            ) : null}
          </Fragment>);
      }
      else {
        return null;
      }
    });
  }
  render() {
    const core = {
      dialog: {
        scroll: 'body',
        transitionDuration: {exit: 0},
        maxWidth: 'lg',
        fullWidth: true,
        open: true,
      },
      cancelButton: {
        onClick: () => this.props.close(),
        color: 'secondary',
        variant: 'text',
      },
      submitButton: {
        color: 'primary',
        variant: 'text',
      },
      backButton: {
        disabled: this.stageArray[this.state.stage] === 'basic',
        color: 'secondary',
        variant: 'text',
        onClick: () => {
          this.setState({stage: this.state.stage - 1});
        },
      },
      closeButton: {
        variant: 'text',
        onClick: () => {
          this.props.close(this.state.newCreatedInboundId);
        },
        color: 'primary',
      },
      submitConfirmation: {
        text: 'Invoice successfully updated.',
        stage: this.stageArray[this.state.stage],
      },
      formProps: {
        initialValues: this.defaultFormik,
        validationSchema: this.validationSchema,
        onSubmit: (values, actions) => this.handleSubmit(values, actions),
      },
      billTypeField: (formikProps, fieldString) => ({
        label: 'Bill Type',
        required: true,
        disabled: this.state.viewOnly || formikProps.isSubmitting,
        onChange: ({field, form}) => {
          if (field.value === 'No Charge') formikProps.setFieldValue(fieldString, '0.00');
        },
      }),
      currencyField: (formikProps, billType) => ({
        disabled: billType === 'No Charge' ||
          formikProps.isSubmitting || formikProps.isValidating || this.state.viewOnly,
        required: true,
        decimal: 4,
      }),
      removeItemButton: (arrayHelpers, formikProps, index) => ({
        onClick: () => arrayHelpers.remove(index),
        color: 'secondary',
        disabled: formikProps.isSubmitting || formikProps.isValidating || this.state.viewOnly,
      }),
      addItemButton: (arrayHelpers, formikProps) => ({
        onClick: () => arrayHelpers.push({description: '', billType: 'Charge', price: ''}),
        color: 'primary',
        disabled: formikProps.isSubmitting || formikProps.isValidating || this.state.viewOnly,
      }),
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm {...core.formProps}>
          {formikProps => (
            <Fragment>
              {this.stageArray[this.state.stage] !== 'success' && <DialogTitle>{this.state.viewOnly ? 'View' : 'Edit'} Invoice</DialogTitle>}
              <DialogContent>
                <Collapse in={this.stageArray[this.state.stage] === 'basic'}>
                  <DialogContentText>Edit the bill types and prices for this invoice:</DialogContentText>
                  <Grid container spacing={2}>
                    <FieldArray name='arrayOfTypes'>
                      {arrayHelpers => {
                        return formikProps.values.arrayOfTypes.map((typeFields, index) => (
                          <Fragment key={index}>
                            <Grid item xs={6} sm={3}>
                              <TextField name={`arrayOfTypes[${index}].material`} disabled label='Material' />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <TextField name={`arrayOfTypes[${index}].type`} disabled label='Type' />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                              <TextField name={`arrayOfTypes[${index}].flag`} disabled label='Flag' />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                              <SelectField name={`arrayOfTypes[${index}].billType`} {...core.billTypeField(formikProps, `arrayOfTypes[${index}].price`)}>
                                <MenuItem value='Charge'>Charge</MenuItem>
                                <MenuItem value='No Charge'>No Charge</MenuItem>
                                <MenuItem value='Payment'>Payment</MenuItem>
                                <MenuItem value='Market' disabled>Market</MenuItem>
                              </SelectField>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                              <CurrencyField label='Price per lb' name={`arrayOfTypes[${index}].price`}
                                {...core.currencyField(formikProps, formikProps.values.arrayOfTypes[index].type)}
                              />
                            </Grid>
                          </Fragment>
                        ));
                      }}
                    </FieldArray>
                  </Grid>
                </Collapse>
                <Collapse in={this.stageArray[this.state.stage] === 'additionalCharges'}>
                  <DialogContentText>Add any additional charges for this invoice:</DialogContentText>
                  <Grid container spacing={2} alignItems='flex-end'>
                    <FieldArray name='arrayOfAdditionalCharges'>
                      {arrayHelpers => {
                        return (
                          <Fragment>
                            {formikProps.values.arrayOfAdditionalCharges.map((chargeFields, index) => (
                              <Fragment key={index}>
                                <Grid item xs={6} sm={4}>
                                  <TextField name={`arrayOfAdditionalCharges[${index}].description`} label='Description'
                                    required disabled={this.state.viewOnly || formikProps.isSubmitting}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                  <SelectField name={`arrayOfAdditionalCharges[${index}].billType`}
                                    {...core.billTypeField(formikProps, `arrayOfAdditionalCharges[${index}].price`)}
                                  >
                                    <MenuItem value='Charge'>Charge</MenuItem>
                                    <MenuItem value='No Charge'>No Charge</MenuItem>
                                    <MenuItem value='Payment'>Payment</MenuItem>
                                  </SelectField>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                  <CurrencyField label='Total' name={`arrayOfAdditionalCharges[${index}].price`}
                                    {...core.currencyField(formikProps, formikProps.values.arrayOfAdditionalCharges[index].billType)}
                                    decimal={2}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                  <Button {...core.removeItemButton(arrayHelpers, formikProps, index)}><Clear /></Button>
                                </Grid>
                              </Fragment>
                            ))
                            }
                            <Grid item container xs={12} direction='row-reverse'>
                              <Button {...core.addItemButton(arrayHelpers, formikProps)}>Add Charge</Button>
                            </Grid>
                          </Fragment>
                        );
                      }}
                    </FieldArray>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1'>
                        Notes Attached to Inbound Piece Numbers
                      </Typography>
                      <List dense>
                        {this.createPieceNotesText()}
                      </List>
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {this.stageArray[this.state.stage] === 'success' ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <FormButton {...core.closeButton}>Close</FormButton>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton {...core.cancelButton}>Cancel</FormButton>
                  <div>
                    <FormButton {...core.backButton}>Back</FormButton>
                    <SubmitButton {...core.submitButton}>
                      {this.state.stage !== 1 ? 'Next' : 'Submit'}
                    </SubmitButton>
                  </div>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({currentUser: state.auth.currentUser});

EditInvoiceModal.propTypes = {
  selectedInvoice: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  updateInvoiceFunc: PropTypes.func.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(EditInvoiceModal);

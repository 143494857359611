import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {LinearProgress} from '@material-ui/core';
import {useAuthCheck} from '@kbi/utility-library';
import {Auth, Functions} from 'config.js';
import {acGetCurrentUser} from 'state/auth/actions.js';
import {useModuleAccessCheck} from 'hooks/';
import {AppFrame, ErrorBoundary} from '@kbi/component-library';
import {menuItems, routes, moduleMenuOptions} from './appFrameProps.js';
import axios from 'axios';

export const ReportContext = React.createContext(null);

const Bowser = require('bowser/bundled');
const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

if (browser === 'Internet Explorer') window.location.replace('http://kbi.works/PreventIE.html');

const handleLogout = async () => {
  document.cookie = `signOut=true.${Date.now()};domain=kbi.works;max-age=600`;
  try {
    await axios({
      method: 'post',
      url: `https://server.kbi.works/auth/signout`,
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
  catch (error) {
    console.log('logout error', error);
  }
};

function sendDataToEmailCloudFunction(error, info, currentUser) {
  if (process.env.NODE_ENV === 'production') {
    Functions.httpsCallable('emailCatchError')({
      errorMessage: error.message,
      errorName: error.name,
      errorLog: error.message.split(' at')[0],
      errorStack: info.componentStack,
      errorLocation: window.location.href,
      errorTime: new Date().toLocaleString(),
      user: currentUser?.email || 'No User Data found.',
    });
  }
}

const App = () => {
  const [view, setView] = useState(null);
  const [reportData, setReportData] = useState([]);

  const {currentUser} = useAuthCheck({Auth, acGetCurrentUser});
  useModuleAccessCheck();

  if (!currentUser) return <LinearProgress />;

  return (
    <ErrorBoundary errorHandlerFunction={(error, info) => sendDataToEmailCloudFunction(error, info, currentUser)}>
      <ReportContext.Provider
        value={{
          view: view,
          reportData: reportData,
          setReportData: reportData => setReportData(reportData),
          setView: view => setView(view),
        }}
      >
        <AppFrame routes={routes}
          menuItems={menuItems}
          moduleTitle='Waste Tracking'
          redirectTo='/dashboard'
          currentUserEmail={currentUser.email}
          moduleMenuOptions={moduleMenuOptions}
          logoutFunction={() => handleLogout()}
        />
      </ReportContext.Provider>
    </ErrorBoundary>
  );
};


export default withRouter(App);

/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AdminInventoryModal, KbiWasteModal} from './ContainerInventoryPanel/';
import {InventoryDetailsPanel} from '../panels/';
import {TableWithPaper} from 'components/';
import {useSelector} from 'react-redux';
import {Add, Security, Search} from '@material-ui/icons';

const ContainerInventoryPanel = props => {
  const [inventoryInContainer, setInventoryInContainer] = useState([]);
  const [inventoryModalOpen, setInventoryModalOpen] = useState(false);
  const [kbiWasteModalOpen, setKbiWasteModalOpen] = useState(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
  const {accounts, materials, inventoryItems} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    const arrayOfItems = [];
    props.selectedContainer.InventoryItems.forEach(itemRef => {
      if (inventoryItems.ref[itemRef]) {
        arrayOfItems.push({
          ...inventoryItems.ref[itemRef],
          startDate: inventoryItems.ref[itemRef].AccumulationStartDate.toDate(),
          accountName: accounts[inventoryItems.ref[itemRef].AccountRef].AccountName,
        });
      }
    });
    setInventoryInContainer(arrayOfItems);
  }, [props.selectedContainer, inventoryItems]);

  const createAllowedRowButtons = () => {
    const rowButtons = [
      {
        icon: Search,
        tooltip: 'View Item Details',
        onClick: ({rowData}) => setSelectedInventoryItem(rowData),
      },
    ];
    if (currentUser.roleTracking === 'Admin') {
      rowButtons.push({
        icon: Security,
        tooltip: 'Admin Edit Item',
        onClick: ({rowData}) => {
          setSelectedInventoryItem(rowData);
          setInventoryModalOpen(true);
        },
      });
    }
    return rowButtons;
  };
  const createAllowedBarButtons = () => {
    if (currentUser.roleTracking === 'Admin' && materials.ref[props.selectedContainer.MaterialRef].UnitDetails.KbiGenerated) {
      return [
        {
          icon: Add,
          text: 'Add KBI Waste',
          onClick: (event) => {
            setSelectedInventoryItem(null);
            setKbiWasteModalOpen(true);
          },
        },
        {
          icon: Security,
          text: 'Admin Add Item',
          onClick: (event) => {
            setSelectedInventoryItem(null);
            setInventoryModalOpen(true);
          },
        },
      ];
    }
    else if (currentUser.roleTracking === 'Admin') {
      return [
        {
          icon: Security,
          text: 'Admin Add Item',
          onClick: (event) => {
            setSelectedInventoryItem(null);
            setInventoryModalOpen(true);
          },
        },
      ];
    }
    else if (materials.ref[props.selectedContainer.MaterialRef].UnitDetails.KbiGenerated) {
      return [
        {
          icon: Add,
          text: 'Add KBI Waste',
          onClick: (event) => {
            setSelectedInventoryItem(null);
            setKbiWasteModalOpen(true);
          },
        },
      ];
    }
    else return null;
  };

  const core = {
    adminInventoryModal: {
      selectedInventoryItem,
      selectedContainer: props.selectedContainer,
      close: () => {
        setSelectedInventoryItem(null);
        setInventoryModalOpen(false);
      },
    },
    kbiWasteModal: {
      selectedContainer: props.selectedContainer,
      close: () => {
        setSelectedInventoryItem(null);
        setKbiWasteModalOpen(false);
      },
    },
    inventoryItemTable: {
      data: inventoryInContainer,
      columns: [
        {accessor: 'id', Header: 'ID'},
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'Type.Name', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'Weight', Header: 'Weight', type: 'numeric'},
        {accessor: 'startDate', Header: 'Start Date', type: 'datetime'},
      ],
      actionsPerRow: createAllowedRowButtons(),
      actionsBar: createAllowedBarButtons(),
      title: {
        primary: 'Contents',
        secondary: `A list of all the items within the container ${props.selectedContainer.ShortNo}:`,
      },
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
  };

  return (
    <Fragment>
      <TableWithPaper {...core.inventoryItemTable} />
      {selectedInventoryItem && <InventoryDetailsPanel selectedInventoryItem={selectedInventoryItem} />}
      {inventoryModalOpen && <AdminInventoryModal {...core.adminInventoryModal} />}
      {kbiWasteModalOpen && <KbiWasteModal {...core.kbiWasteModal} />}
    </Fragment>
  );
};

ContainerInventoryPanel.propTypes = {
  selectedContainer: PropTypes.shape({
    AccumulationStartDate: PropTypes.object,
    InventoryItems: PropTypes.arrayOf(PropTypes.string),
    ShortNo: PropTypes.string,
    MaterialRef: PropTypes.string,
  }).isRequired,
};

export default ContainerInventoryPanel;

import React, {useState, useEffect, Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {LocalShipping, Edit, Print, Check} from '@material-ui/icons';
import {Button, Grid, withStyles, Paper, List, ListItem, Checkbox, FormControlLabel, Chip, LinearProgress} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import flowRight from 'lodash.flowright';
import {MarkAsInvoicedModal, AddFileModal, FileViewModal, BackButton, DetailsFieldList, DetailsTitle} from 'components/';
import moment from 'moment';
import {Firestore, Storage} from 'config.js';
import {OutboundSummaryPanel} from '../panels/';
import {MarkAsCompleteModal, PrintableOutbound} from './OutboundDetails/';
import {OutboundModal} from './Shipments/';
import {useTrackingShipments, useLocations, useAccounts, useInventory, useContainers, usePurchaseOrders, useFacilityUnits, useMaterials, useTypes} from 'hooks';

const OutboundDetails = props => {
  const {classes, match} = props;
  // setting up redux listeners
  const trackingShipments = useTrackingShipments();
  const accounts = useAccounts();
  const locations = useLocations();
  const currentUser = useSelector(state => state.auth.currentUser);
  useInventory();
  useContainers();
  usePurchaseOrders();
  useFacilityUnits();
  useMaterials();
  useTypes();

  // the reference to the Outbound that is being viewed
  const [selectedOutbound, setSelectedOutbound] = useState(null);
  // from firestore, all lines in the current outbounds line subcollection
  const [listOfLines, setListOfLines] = useState(null);
  const [inventoryItemData, setInventoryItemData] = useState(null);
  // the portions of state that are used to handle the primary file upload and inbound load progress based on the file
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFileRef, setUploadedFileRef] = useState(null);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);
  // this portion handles all modals
  const [fileViewModalOpen, setFileViewModalOpen] = useState(false);
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);
  const [markAsInvoicedModalOpen, setMarkAsInvoicedModalOpen] = useState(false);
  const [outboundModalOpen, setOutboundModalOpen] = useState(false);
  const [listOfSupplementalFiles, setListOfSupplementalFiles] = useState([]);

  const [printView, setPrintView] = useState(false);

  useEffect(() => {
    if (isFileUploaded) setUploadedFileRef(Storage.ref(`Outbound_Forms/${selectedOutbound.id}_primary_file.pdf`));
  }, [isFileUploaded, selectedOutbound]);
  useEffect(() => {
    if (trackingShipments) {
      if (!trackingShipments.ref[match.params.OutboundId]) {
        props.history.push('/shipments');
        return;
      }
      setSelectedOutbound(trackingShipments.ref[match.params.OutboundId]);
      setIsFileUploaded(!!trackingShipments.ref[match.params.OutboundId].UploadedBy);
    }
  }, [trackingShipments, match, uploadFileModalOpen, props.history]);
  useEffect(() => {
    // grabs all lines from the Line subcollection through a listener
    const listener = Firestore.collection('Tracking-Shipments')
      .doc(match.params.OutboundId)
      .collection('Lines')
      .onSnapshot(
        {includeMetadataChanges: true},
        snap => {
          const arrayForState = [];
          snap.forEach(doc => {
            arrayForState.push({
              ...doc.data(),
              totalGrossWeight: doc.data().AddedContainers.map(container => container.GrossWeight).reduce((a, b) => a + b),
              totalNetWeight: doc.data().AddedContainers.map(container => container.NetWeight).reduce((a, b) => a + b),
              id: doc.id,
            });
          });
          setListOfLines([...arrayForState]);
        },
        error => {
          throw new Error(error);
        },
      );
    return () => {
      // removes the listener on dismount
      listener();
    };
  }, [match.params.OutboundId]);
  useEffect(() => {
    // grabs all file references from files subcollection through a listener
    const arrayForState = [];
    const listener = Firestore.collection('Tracking-Shipments')
      .doc(match.params.OutboundId)
      .collection('Supplemental-Files')
      .onSnapshot(
        {includeMetadataChanges: true},
        snap => {
          snap.docChanges().forEach(change => {
            arrayForState.push({...change.doc.data(), Date: change.doc.data().Date.toDate()});
          });
          setListOfSupplementalFiles([...arrayForState]);
        },
        error => {
          throw new Error(error);
        },
      );
    return () => {
      // removes the listener on dismount
      listener();
    };
  }, [match.params.OutboundId]);
  useEffect(() => {
    if (printView) {
      window.print();
      setPrintView(false);
    }
  }, [printView]);

  // sets all the inventory items that are on this process form, only after marked as complete
  useEffect(() => {
    if (selectedOutbound && !selectedOutbound.InProgress) {
      Firestore.collection('Tracking-Inventory').where('ShipmentOutRef', '==', selectedOutbound.id).get().then(snap => {
        const totals = [];
        const arrayOfItems = [];
        snap.forEach(item => {
          const itemData = {...item.data(), id: item.id};
          arrayOfItems.push(itemData);
          const indexOfMatch = totals.findIndex(total => total.material === itemData.Material.Name &&
             total.type === itemData.Type.Name &&
             total.flag === itemData.Flag);
          if (indexOfMatch !== -1) {
            totals[indexOfMatch].weight += itemData.Weight;
          }
          else {
            totals.push({
              material: itemData.Material.Name,
              type: itemData.Type.Name,
              flag: itemData.Flag,
              weight: itemData.Weight,
            });
          }
        });
        setInventoryItemData({totals, arrayOfItems});
      });
    }
  }, [selectedOutbound]);

  const core = useMemo(() => ({
    button: {
      size: 'small',
      style: {marginLeft: '8px'},
      variant: 'outlined',
      className: 'hidePrint',
    },
    printFormButton: {
      onClick: () => setPrintView(true),
    },
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    outboundModal: {
      selectedOutbound,
      listOfLines,
      close: () => {
        setOutboundModalOpen(false);
      },
    },
    uploadFileModal: {
      addFileModalOpen: uploadFileModalOpen,
      close: () => setUploadFileModalOpen(false),
      selectedOutbound,
      mimeType: fileViewModalOpen ? 'application/pdf, image/*' : 'application/pdf',
      // if fileViewModal is open, that means the file uploaded is a supplemental file, and that is requires a name
      supplementalFile: fileViewModalOpen,
      fileNameNotNeeded: !fileViewModalOpen,
    },
    fileButton: {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      onClick: () => {
        setUploadFileModalOpen(true);
      },
    },
    viewFileButton: {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      onClick: () => {
        uploadedFileRef.getDownloadURL().then(url => {
          window.open(url, '__blank');
        });
      },
    },
    markAsCompleteModal: {
      open: markAsCompleteModalOpen,
      selectedOutbound,
      listOfLines,
      close: () => setMarkAsCompleteModalOpen(false),
    },
    markAsInvoicedModal: {
      selectedOutbound,
      documentType: 'Outbound',
      close: () => setMarkAsInvoicedModalOpen(false),
    },
    completeButton: {
      variant: 'outlined',
      color: 'secondary',
      fullWidth: true,
      onClick: () => setMarkAsCompleteModalOpen(true),
    },
    editOutboundButton: {
      onClick: () => setOutboundModalOpen(true),
    },
    link: accountId => ({
      onClick: () => window.open(`https://crm.kbi.works/accounts/${accountId}`, '__blank'),
      inputProps: {
        style: {color: '#3f51b5', cursor: 'pointer'},
      },
    }),
    fileViewModal: {
      open: fileViewModalOpen,
      classes,
      selectedOutbound,
      uploadButtonOnClick: () => setUploadFileModalOpen(true),
      files: listOfSupplementalFiles,
      close: () => setFileViewModalOpen(false),
    },
    openFileViewButton: {
      variant: 'outlined',
      fullWidth: true,
      color: 'primary',
      onClick: () => setFileViewModalOpen(true),
    },
    textFields: {
      InputProps: {
        readOnly: true,
      },
      variant: 'outlined',
      margin: 'dense',
      fullWidth: true,
      multiline: true,
    },
    outboundSummaryPanel: {
      listOfLines,
      selectedOutbound,
    },
    printableOutbound: {
      listOfLines,
      selectedOutbound,
      inventoryItemData,
    },
    returnedCheckbox: {
      checked: selectedOutbound?.ReturnedShippingDocumentReceived,
      color: 'primary',
      onChange: (e) => {
        Firestore.collection('Tracking-Shipments').doc(selectedOutbound.id).update({
          'ReturnedShippingDocumentReceived': e.target.checked,
          'System.ReceivedUpdatedBy': currentUser.displayName,
          'System.ReceivedUpdatedOn': new Date(),
        });
      },
    },
  // eslint-disable-next-line max-len
  }), [classes, fileViewModalOpen, inventoryItemData, listOfLines, listOfSupplementalFiles, markAsCompleteModalOpen, currentUser, selectedOutbound, uploadFileModalOpen, uploadedFileRef]);

  const displayInvoicedHeader = useMemo(() => {
    if (selectedOutbound?.AccountingId) {
      return <Chip color='primary' label={`Invoiced - Id: ${selectedOutbound?.AccountingId}`} variant='outlined' style={{marginLeft: '8px'}} />;
    }
    else {
      return (
        <Button {...core.button} onClick={() => setMarkAsInvoicedModalOpen(true)}>
          <Check className={classes.extendedIcon} /> Mark as Invoiced
        </Button>
      );
    }
  }, [classes.extendedIcon, core.button, selectedOutbound]);

  if (!selectedOutbound || !accounts || !locations || !listOfLines || !currentUser) return <LinearProgress />;

  return (
    <Fragment>
      <div className='sideScrollingContainer hidePrint'>
        <BackButton />
        {selectedOutbound.InProgress && (
          <Fragment>
            <Button {...core.button} {...core.editOutboundButton}>
              <Edit className={classes.extendedIcon} />
              Edit Outbound
            </Button>
          </Fragment>)}
        <Button {...core.button} {...core.printFormButton}><Print className={classes.extendedIcon} />Print Form</Button>
        {!selectedOutbound.InProgress && displayInvoicedHeader}
      </div>
      <Grid className='hidePrint' container spacing={5} style={{marginTop: '8px'}}>
        <Grid item xs={12} md={3}>
          <Paper {...core.paperHeader}>
            <LocalShipping style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
            <DetailsTitle title='Outbound Load' />
            <DetailsFieldList fields={[
              {label: 'Outbound Id', value: selectedOutbound.id},
              {label: 'Date', value: moment(selectedOutbound.ShipmentTime).format('MM/DD/YYYY')},
              {label: 'Facility', value: selectedOutbound.Facility},
              {label: 'Account', value: accounts[selectedOutbound.AccountId].AccountName, link: `https://crm.kbi.works/accounts/${selectedOutbound.AccountId}`},
              {label: 'Location', value: locations[selectedOutbound.AccountId].find(location => location.id === selectedOutbound.LocationRef).LocationName},
              {label: 'Transporter', value: accounts[selectedOutbound.Transporter].AccountName, link: `https://crm.kbi.works/accounts/${selectedOutbound.Transporter}`},
              {label: 'Shipping Paper', value: `${selectedOutbound.DocumentType}${selectedOutbound.DocumentNumber ?
                `: ${selectedOutbound.DocumentNumber}` : ''}`},
              {label: 'Weight Ticket', value: selectedOutbound.WeightTicket || 'N/A'},
              {label: 'Booking No.', value: selectedOutbound.BookingNumber, visible: Boolean(selectedOutbound.BookingNumber)},
              {label: 'Container No.', value: selectedOutbound.ContainerNumber, visible: Boolean(selectedOutbound.ContainerNumber)},
              {label: 'Seal No.', value: selectedOutbound.SealNumber, visible: Boolean(selectedOutbound.SealNumber)},
              {label: 'Customer PO No.', value: selectedOutbound.PurchaseOrderNumber, visible: Boolean(selectedOutbound.PurchaseOrderNumber)},
              {label: 'KBI PO No.', value: selectedOutbound.KbiPONumber, visible: Boolean(selectedOutbound.KbiPONumber)},
              {label: 'Comments', value: selectedOutbound.Comments, visible: Boolean(selectedOutbound.Comments)},
              {label: 'Accounting Id', value: selectedOutbound.AccountingId, visible: Boolean(selectedOutbound.AccountingId)},
            ]}
            />
            <List style={{minWidth: '100%', maxWidth: '100%'}}>
              {selectedOutbound.InProgress && (
                <ListItem>
                  <Button {...core.fileButton}>
                    {isFileUploaded ? 'Reupload Primary File' : 'Upload Primary File'}
                  </Button>
                </ListItem>)}
              {(isFileUploaded && uploadedFileRef) && (
                <ListItem>
                  <Button {...core.viewFileButton}>View Primary File</Button>
                </ListItem>)}
              {(isFileUploaded && selectedOutbound.InProgress) && (
                <ListItem>
                  <Button {...core.completeButton}>Mark as Complete</Button>
                </ListItem>)}
              <ListItem>
                <Button {...core.openFileViewButton}>Supplemental Files</Button>
              </ListItem>
              {(!selectedOutbound.InProgress && selectedOutbound.ReturnedShippingDocumentRequired && currentUser.roleTracking !== 'User') && (
                <ListItem>
                  <FormControlLabel control={<Checkbox {...core.returnedCheckbox} />} label='Signed Shipping Document Returned?' />
                </ListItem>)}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <OutboundSummaryPanel {...core.outboundSummaryPanel} />
        </Grid>
      </Grid>
      {outboundModalOpen && <OutboundModal {...core.outboundModal} />}
      {uploadFileModalOpen && <AddFileModal {...core.uploadFileModal} />}
      {markAsCompleteModalOpen && <MarkAsCompleteModal {...core.markAsCompleteModal} />}
      {markAsInvoicedModalOpen && <MarkAsInvoicedModal {...core.markAsInvoicedModal} />}
      {fileViewModalOpen && <FileViewModal {...core.fileViewModal} />}
      {printView && <PrintableOutbound {...core.printableOutbound} />}
    </Fragment>
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
  sideScrollingContainer: {
    'display': 'flex',
    'flexWrap': 'nowrap',
    'overflowX': 'auto',
    '& div': {
      flex: '0 0 auto',
    },
  },
});

OutboundDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default flowRight(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(OutboundDetails);

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetAccounts} from 'state/firestore/actions.js';

const useAccounts = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(state => state.firestore.accounts);

  useEffect(() => {
    if (!accounts) dispatch(acGetAccounts);
  }, [dispatch, accounts]);

  return accounts;
};

export default useAccounts;

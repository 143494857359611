import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles, Paper, Button, LinearProgress} from '@material-ui/core';
import {ContainerInventoryPanel} from '../panels/';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import moment from 'moment';
import {BackButton, DetailsFieldList, DetailsTitle, InventoryIcon, GenericPrintLabels} from 'components/';
import {Print, Security, Edit, DeleteForever, FitnessCenter} from '@material-ui/icons';
import {AdminContainerModal, EditTareWeightModal, DeleteEmptyContainerModal, WeightAdjustmentForm} from './ContainerDetails/';
import {useHistory} from 'react-router-dom';
import {useInventory, useContainers, useTypes, useFacilityUnits, useMaterials, useAccounts, useTrackingShipments, usePurchaseOrders} from 'hooks/';

const ContainerDetails = props => {
  const {classes} = props;
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
  const [adminContainerModalOpen, setAdminContainerModalOpen] = useState(false);
  const [listOfContainersToPrint, setListOfContainersToPrint] = useState(null);
  const [editTareWeightModalOpen, setEditTareWeightModalOpen] = useState(false);
  const [deleteEmptyContainerModalOpen, setDeleteEmptyContainerModalOpen] = useState(false);
  const [weightAdjustmentModalOpen, setWeightAdjustmentModalOpen] = useState(false);

  const {currentUser} = useSelector(state => state.auth);
  const history = useHistory();

  // setting up redux listeners
  const inventoryItems = useInventory();
  const facilityUnits = useFacilityUnits();
  const containers = useContainers();
  const materials = useMaterials();
  const accounts = useAccounts();
  useTypes();
  useTrackingShipments();
  usePurchaseOrders();

  useEffect(() => {
    if (containers && containers.ref[props.match.params.ShortNo]) {
      setSelectedContainer(containers.ref[props.match.params.ShortNo]);
    }
  }, [containers, history, props.match.params.ShortNo]);
  useEffect(() => {
    if (selectedContainer && inventoryItems) {
      setSelectedInventoryItem(inventoryItems.ref[props.match.params.inventoryId]);
    }
  }, [inventoryItems, props.match.params.inventoryId, selectedContainer]);
  useEffect(() => {
    if (listOfContainersToPrint) {
      window.print();
      setListOfContainersToPrint(null);
    }
  }, [listOfContainersToPrint]);

  if (!selectedContainer || !inventoryItems || !facilityUnits || !materials || !accounts) return <LinearProgress />;

  const selectInventoryItem = inventoryId => {
    setSelectedInventoryItem(inventoryItems.ref[inventoryId]);
  };

  const core = {
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    inventoryPanel: {
      selectedContainer,
      selectInventoryItem,
    },
    inventoryDetailsPanel: {
      selectedInventoryItem,
    },
    printButton: {
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => {
        setListOfContainersToPrint([selectedContainer]);
      },
    },
    deleteContainerButton: {
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => {
        setDeleteEmptyContainerModalOpen(true);
      },
    },
    adminEditButton: {
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => {
        setAdminContainerModalOpen(true);
      },
    },
    containerEditModal: {
      selectedContainer,
      close: () => setAdminContainerModalOpen(false),
    },
    tareWeightModal: {
      selectedContainer,
      close: () => setEditTareWeightModalOpen(false),
    },
    weightAdjustmentForm: {
      selectedContainer,
      close: () => setWeightAdjustmentModalOpen(false),
    },
    deleteContainerModal: {
      selectedContainer,
      close: () => setDeleteEmptyContainerModalOpen(false),
    },
    editTareWeightButton: {
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => setEditTareWeightModalOpen(true),
    },
    weightAdjustmentButton: {
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => setWeightAdjustmentModalOpen(true),
    },
  };

  return (
    <Fragment>
      <div className='sideScrollingContainer hidePrint'>
        <BackButton />
        <Button {...core.printButton}><Print style={{marginRight: '8px'}} />Print Label</Button>
        <Button {...core.editTareWeightButton}><Edit style={{marginRight: '8px'}} />Edit Tare Weight</Button>
        {!selectedContainer.InboundContainer && (
          <Button {...core.weightAdjustmentButton}>
            <FitnessCenter style={{marginRight: '8px'}} />
            Net Weight Adjustment Form
          </Button>)}
        {(currentUser.roleTracking === 'Admin' || currentUser.roleTracking === 'Manager') && !selectedContainer.InventoryItems.length ?
          <Button {...core.deleteContainerButton}><DeleteForever style={{marginRight: '8px'}} />Delete Empty Container</Button> : null}
        {currentUser.roleTracking === 'Admin' && <Button {...core.adminEditButton}><Security style={{marginRight: '8px'}} />Admin Edit Container</Button>}
      </div>
      <Grid className='hidePrint' container spacing={5} style={{marginTop: '8px'}}>
        <Grid item xs={12} md={3}>
          <Paper {...core.paperHeader}>
            <InventoryIcon style={{fontSize: '10em', fill: 'slategray', width: '100%', marginTop: '25px'}} />
            <DetailsTitle title={selectedContainer.ShortNo} />
            <DetailsFieldList fields={[
              {label: 'Material', value: materials.ref[selectedContainer.MaterialRef].UnitDetails.MaterialName},
              {label: 'Flag', value: selectedContainer.Flag || 'None'},
              {label: 'Gross Weight (lbs)', value: selectedContainer.TareWeight + selectedContainer.NetWeight},
              {label: 'Tare Weight (lbs)', value: selectedContainer.TareWeight},
              {label: 'Container', value: `${selectedContainer.ContainerCode}${selectedContainer.ContainerCodeType ?
                ` - ${selectedContainer.ContainerCodeType}` : ''}`},
              {label: 'Facility Unit', value: facilityUnits.ref[selectedContainer.FacilityUnitRef].Name},
              {label: 'Start Date', value: moment(selectedContainer.AccumulationStartDate.toDate()).format('MM/DD/YYYY')},
            ]} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <ContainerInventoryPanel {...core.inventoryPanel} />
        </Grid>
      </Grid>
      <GenericPrintLabels listOfContainersToPrint={listOfContainersToPrint} />
      {adminContainerModalOpen && <AdminContainerModal {...core.containerEditModal} />}
      {editTareWeightModalOpen && <EditTareWeightModal {...core.tareWeightModal} />}
      {deleteEmptyContainerModalOpen && <DeleteEmptyContainerModal {...core.deleteContainerModal} /> }
      {weightAdjustmentModalOpen && <WeightAdjustmentForm {...core.weightAdjustmentForm} /> }
    </Fragment>
  );
};
const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
});

ContainerDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default flowright(withStyles(styles, {withTheme: true}), withRouter)(ContainerDetails);

/* eslint-disable max-len */
import React, {useEffect, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  MenuItem,
  Collapse,
  DialogActions,
} from '@material-ui/core';
import {Alert, Formik} from '@kbi/component-library';
import {SubmitConfirmation, ReportGenerator} from 'components/';
import {withFormik} from 'formik';
import {object, string} from 'yup';
import {InboundLoadsForm,
  InventoryItemsForm,
  OutboundLoadForm,
  BuildOutboundForm,
  InvoiceForm,
  SortForm,
  ProcessForm,
  ConsolidationForm,
  ContainerForm,
  MaterialsAndTypesForm,
} from './NewReportModal/';
import moment from 'moment';
import {exportToCSV} from '@kbi/utility-library';
import {useContainers, useFacilityUnits, useInventory, useTypes, useAccounts, useLocations, useMaterials} from 'hooks';
const {SelectField, SubmitButton, FormButton} = Formik;

const stageArray = ['basic', 'success'];
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const NewReportModal = ({values, open, close, changeView, ...formikBag}) => {
  const [listOfQueryResults, setListOfQueryResults] = useState([]);
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const accounts = useAccounts();
  const locations = useLocations();
  const materials = useMaterials();
  const types = useTypes();
  const containers = useContainers();
  const inventoryItems = useInventory();
  const facilityUnits = useFacilityUnits();

  const changeModalStage = useCallback((resultsFromQuery) => {
    formikBag.setSubmitting(false);
    if (!resultsFromQuery.length) {
      setFormError('The report you tried to generate did not contain any documents.');
      formikBag.setStatus({});
    }
    else {
      setStage(stage + 1);
      setFormError('');
    }
  }, [formikBag, stage]);

  const createTypesString = useCallback(arrayOfItemRefs => {
    const arrayOfItemTypes = arrayOfItemRefs.map(ref => inventoryItems.ref[ref].Type.Name).filter(onlyUnique);
    return arrayOfItemTypes.join(', ');
  }, [inventoryItems]);

  const limitStringTo24Char = useCallback(string => {
    let reducedString = string;
    if (reducedString.length > 25) reducedString = reducedString.substring(0, 24) + '...';
    return reducedString;
  }, []);

  const toUpper = useCallback(fieldName => {
    formikBag.setFieldValue(fieldName, values[fieldName].toUpperCase());
  }, [formikBag, values]);

  // this report does not build a query. all information is within redux already. therefore, it is not held within ReportGenerator
  const buildingOutboundReport = useCallback((values) => {
    const arrayOfMatchingContainers = [];
    // if the report filterType is material, it loops over containers in redux, and filters out containers based on the inputs for the report.
    if (values.typeOfFilter === 'Material') {
      containers.list.forEach(container => {
        let putInMatchingArray = true;
        let billableContainer = true;
        container.InventoryItems.forEach(itemId => {
          if (!inventoryItems.ref[itemId].Billable) {
            billableContainer = false;
          }
        });
        if (values.materialList.length) {
          if (!values.materialList.find(material => material.MaterialId === container.MaterialRef)) {
            putInMatchingArray = false;
          }
        }
        if (values.onlyBillable && !billableContainer) {
          putInMatchingArray = false;
        }
        if (values.flag) {
          if (container.Flag !== values.flag.Name) {
            putInMatchingArray = false;
          }
        }
        if (putInMatchingArray) {
          arrayOfMatchingContainers.push({
            ...container,
            Container: `${container.ContainerCode}${container.ContainerCodeType ? `, ${container.ContainerCodeType}` : ''}`,
            billableContainer,
            typeString: limitStringTo24Char(createTypesString(container.InventoryItems)),
            materialName: materials.ref[container.MaterialRef].UnitDetails.MaterialName,
            facilityUnitName: facilityUnits.ref[container.FacilityUnitRef].Name,
            startDate: container.AccumulationStartDate.toDate(),
            grossWeight: container.NetWeight + container.TareWeight,
          });
        }
      });
    }
    else {
      values.containerList.forEach(container => {
        let billableContainer = true;
        container.InventoryItems.forEach(itemId => {
          if (!inventoryItems.ref[itemId].Billable) {
            billableContainer = false;
          }
        });
        arrayOfMatchingContainers.push({
          ...container,
          Container: `${container.ContainerCode}${container.ContainerCodeType ? `, ${container.ContainerCodeType}` : ''}`,
          billableContainer,
          typeString: limitStringTo24Char(createTypesString(container.InventoryItems)),
          materialName: materials.ref[container.MaterialRef].UnitDetails.MaterialName,
          facilityUnitName: facilityUnits.ref[container.FacilityUnitRef].Name,
          startDate: container.AccumulationStartDate.toDate(),
          grossWeight: container.NetWeight + container.TareWeight,
        });
      });
    }
    // then it sets the listOfQueryResults with the array of matched containers, and the modal continues back in its normal flow.
    setListOfQueryResults(arrayOfMatchingContainers);
    changeModalStage(arrayOfMatchingContainers);
  }, [containers, createTypesString, facilityUnits, inventoryItems, limitStringTo24Char, materials, changeModalStage]);

  // this report does not build a query. all information is within redux already. therefore, it is not held within ReportGenerator
  const materialsAndTypesReport = useCallback((values) => {
    let arrayOfMaterialsThatMatch = [];
    let arrayOfTypesThatMatch = [];

    // handles the activeOnly filter
    arrayOfMaterialsThatMatch.push(...Object.values(materials.ref).filter(material => values.activeOnly ? material.Active : true));
    arrayOfTypesThatMatch.push(...Object.values(types).flat(1).filter(type => (values.activeOnly ? type.Active : true) && typeof type !== 'function'));

    if (values.addedSinceDate) {
      const addedSinceMoment = moment(values.addedSinceDate);

      arrayOfMaterialsThatMatch = arrayOfMaterialsThatMatch.filter(
        material => moment(material.System.CreatedOn?.toDate()).isAfter(addedSinceMoment) ||
            (material.System.UpdatedOn ? moment(material.System.UpdatedOn?.toDate()).isAfter(addedSinceMoment) : false),
      );
      arrayOfTypesThatMatch = arrayOfTypesThatMatch.filter(
        type => moment(type.System.CreatedOn?.toDate()).isAfter(addedSinceMoment) ||
        (type.System.ModifiedOn ? moment(type.System.ModifiedOn?.toDate()).isAfter(addedSinceMoment) : false),
      );
    }

    const results = [arrayOfMaterialsThatMatch, arrayOfTypesThatMatch];

    exportData(results, materials);

    // then it sets the listOfQueryResults with the array of matched containers, and the modal continues back in its normal flow.
    setListOfQueryResults(results);
    changeModalStage(results);
  }, [materials, types, changeModalStage]);

  const nonFormikSubmit = useCallback(() => {
    if (values.reportType === 'buildOutbound') {
      return buildingOutboundReport(values);
    }
    if (values.reportType === 'materialsAndTypes') {
      return materialsAndTypesReport(values);
    }
    const ReportGen = new ReportGenerator({
      values,
      reportType: values.reportType,
      reduxValues: {locations, accounts, materials, types, containers, inventoryItems, facilityUnits},
    });
    ReportGen.runQuery().then(results => {
      setListOfQueryResults(results);
      changeModalStage(results);
    });
  }, [accounts, buildingOutboundReport, materialsAndTypesReport, changeModalStage, containers, facilityUnits, inventoryItems, locations, materials, types, values]);

  useEffect(() => {
    if (stage === 0 && formikBag.status && formikBag.status.success) {
      nonFormikSubmit(values);
    }
  }, [formikBag.status, nonFormikSubmit, stage, values]);

  const closeHandler = () => {
    if (listOfQueryResults?.length && values.reportType !== 'materialsAndTypes') changeView({view: values.reportType, data: listOfQueryResults});
    close();
  };

  const core = useMemo(() => ({
    dialog: {
      open: open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    submitButtonProps: {
      disabled: !locations || !accounts || !materials || !containers || !inventoryItems || !facilityUnits,
      onClick: formikBag.handleSubmit,
    },
    submitConfirmation: {
      text: 'Report successfully created.',
      stage: stageArray[stage],
    },
    /* eslint-disable-next-line */
  }), [open, stage, formikBag.isSubmitting, formikBag.handleSubmit, locations, accounts, materials, containers, inventoryItems, facilityUnits, changeView, values.reportType, close]);

  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>New Report</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>Select fields to create a custom report.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectField required name='reportType' label='Report Type'>
                <MenuItem value='container'>Container</MenuItem>
                <MenuItem value='inboundLoad'>Inbound Load</MenuItem>
                <MenuItem value='outboundLoad'>Outbound Load</MenuItem>
                <MenuItem value='inventoryItems'>Inventory Items</MenuItem>
                <MenuItem value='proformaInvoice'>Proforma Invoice</MenuItem>
                <MenuItem value='process'>Process Form</MenuItem>
                <MenuItem value='sort'>Sort Form</MenuItem>
                <MenuItem value='consolidation'>Consolidation Form</MenuItem>
                <MenuItem value='buildOutbound'>Build Outbound Table</MenuItem>
                <MenuItem value='returnedShippingDocuments'>Returned Shipping Documents Report</MenuItem>
                <MenuItem value='materialsAndTypes'>Materials/Types Report</MenuItem>
                <MenuItem value='annualInbound'>Annual Report [Inbound]</MenuItem>
                <MenuItem value='annualOutbound'>Annual Report [Outbound]</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
          <Collapse in={values.reportType === 'container'}>
            {values.reportType === 'container' && <ContainerForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'inboundLoad'}>
            {values.reportType === 'inboundLoad' && <InboundLoadsForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'process'}>
            {values.reportType === 'process' && <ProcessForm values={values} />}
          </Collapse>
          <Collapse in={values.reportType === 'sort'}>
            {values.reportType === 'sort' && <SortForm values={values} />}
          </Collapse>
          <Collapse in={values.reportType === 'consolidation'}>
            {values.reportType === 'consolidation' && <ConsolidationForm values={values} />}
          </Collapse>
          <Collapse in={values.reportType === 'inventoryItems'}>
            {values.reportType === 'inventoryItems' && <InventoryItemsForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'outboundLoad'}>
            {values.reportType === 'outboundLoad' && <OutboundLoadForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'buildOutbound'}>
            {values.reportType === 'buildOutbound' && <BuildOutboundForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'proformaInvoice'}>
            {values.reportType === 'proformaInvoice' && <InvoiceForm values={values} toUpper={toUpper} />}
          </Collapse>
          <Collapse in={values.reportType === 'materialsAndTypes'}>
            {values.reportType === 'materialsAndTypes' && <MaterialsAndTypesForm values={values} />}
          </Collapse>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] !== 'success' ? (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormButton variant='text' color='secondary' onClick={closeHandler}>Cancel</FormButton>
          <SubmitButton variant='text' color='primary' {...core.submitButtonProps}>Submit</SubmitButton>
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <FormButton variant='text' color='secondary' onClick={closeHandler}>Close</FormButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

function exportData(results, reduxMaterials) {
  const [materials, types] = results;
  exportMaterials(materials);
  exportTypes(types, reduxMaterials);
}
function exportTypes(types, reduxMaterials) {
  const flatTypes = Object.values(types).flat(1).map(type => {
    return {
      ...type,
      MaterialName: reduxMaterials.ref[type.MaterialId].UnitDetails.MaterialName,
      WasteCodes: {
        RCRA: type.WasteCodes.RCRA.map(code => code.name ? code.name : code).join(', '),
        CA: type.WasteCodes.CA.map(code => code.name ? code.name : code).join(', '),
      },
    };
  });
  const typesColumns = [
    {accessor: 'TypeName', Header: 'Type Name'},
    {accessor: 'MaterialName', Header: 'material'},
    {accessor: 'Active', Header: 'active?', type: 'boolean'},
    {accessor: 'BillType', Header: 'bill type'},
    {accessor: 'Price', Header: 'price'},
    {accessor: 'HandlingCode', Header: 'handling code'},
    {accessor: 'ShowOnProfile', Header: 'show on profile?', type: 'boolean'},
    {accessor: 'WasteCodes.RCRA', Header: 'RCRA Codes'},
    {accessor: 'WasteCodes.CA', Header: 'CA Codes'},
  ];
  exportToCSV(flatTypes, typesColumns, 'Types_06_29_2020.csv');
}
function exportMaterials(materials) {
  const flatMaterials = materials.map(material => {
    return {...material,
      Flags: material.Flags.map(flag => flag.Name).join(', '),
      ChemicalConstituents: material.ChemicalConstituents.map(con => con.constituent).join(', '),
      Regulations: {
        Other: material.Regulations.Other.join(', '),
        Shipping: material.Regulations.Shipping.join(', '),
      },
      WasteCodes: {
        RCRA: material.WasteCodes.RCRA.join(', '),
        CA: material.WasteCodes.CA.join(', '),
      },
    };
  });
  const materialColumns = [
    {accessor: 'UnitDetails.MaterialName', Header: 'Material Name'},
    {accessor: 'UnitDetails.KbiGenerated', Header: 'KBI generated', type: 'boolean'},
    {accessor: 'UnitDetails.InboundFormAllowed', Header: 'inbound Allowed', type: 'boolean'},
    {accessor: 'UnitDetails.ProcessFormAllowed', Header: 'process allowed', type: 'boolean'},
    {accessor: 'UnitDetails.Yield', Header: 'yield', type: 'boolean'},
    {accessor: 'ChemicalConstituents', Header: 'ChemicalConstituents'},
    {accessor: 'Flags', Header: 'Flags'},
    {accessor: 'WasteCodes.RCRA', Header: 'RCRA Codes'},
    {accessor: 'WasteCodes.CA', Header: 'CA Codes'},
  ];
  exportToCSV(flatMaterials, materialColumns, 'Materials_06_29_2020.csv');
}

NewReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changeView: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
};

export default withFormik({
  mapPropsToValues: props => ({
    account: '',
    accountingId: '',
    activeOnly: true,
    addedSinceDate: '',
    billable: '',
    bookingNo: '',
    containerNo: '',
    containerList: [],
    consolidationContainer: '',
    completed: true,
    endDate: '',
    facility: '',
    facilityUnit: '',
    formId: props.queryParams?.formId || '',
    flag: '',
    generator: '',
    incomingPieceNo: '',
    inboundId: '',
    isInvoiced: '',
    location: '',
    materialList: [],
    mixedLoad: false,
    material: '',
    materialType: '',
    manifested: '',
    outboundId: '',
    onlyBillable: false,
    purchaseOrderNumber: '',
    poNumber: '',
    reportType: props.queryParams?.formId ? 'inventoryItems' : '',
    sealNo: '',
    startDate: '',
    shift: '',
    shippingDocumentNumber: '',
    shortNumber: '',
    transporter: '',
    typeOfFilter: 'Material',
    weightTicketNumber: '',
  }),
  validationSchema: object().shape({
    reportType: string().required('Report Type is a required field.'),
  }),
  handleSubmit: (values, {...actions}) => {
    actions.setStatus({success: true});
  },
  validateOnChange: false,
})(NewReportModal);

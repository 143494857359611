import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper, TopNavBar} from 'components/';
import {Collapse} from '@kbi/component-library';
import {Firestore, Functions} from 'config.js';
import {Grid, LinearProgress} from '@material-ui/core';
import {Search, Refresh} from '@material-ui/icons';
import {KBIGenerationFormModal} from './Accounting/';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {useAccounts, useMaterials, useTypes, useLocations} from 'hooks';

const arrayOfTabs = [
  {label: 'Proformas', path: '/accounting/proforma'},
  {label: 'Outbounds', path: '/accounting/outbound'},
  {label: 'Tracking', path: '/accounting/tracking'},
];

const Accounting = props => {
  const [invoices, setInvoices] = useState([]);
  const [processForms, setProcessForms] = useState([]);
  const [nonBillableShipments, setNonBillableShipments] = useState([]);
  const [outbounds, setOutbounds] = useState([]);
  const [generationForms, setGenerationForms] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [generationFormModalOpen, setGenerationFormModalOpen] = useState(false);
  const [selectedGenerationData, setSelectedGenerationData] = useState(null);

  const accounts = useAccounts();
  const materials = useMaterials();
  const types = useTypes();
  const locations = useLocations();

  // all one time get requests
  useEffect(() => {
    if (accounts && materials && types && locations) {
      Promise.all([getNonInvoicedShippingDocs(), getOutbounds(), getProcessForms(), getKbiGenerationForms()])
        .then(() => setLoadingData(false));
      const invoiceListener = getInvoices();
      return () => {
        invoiceListener();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, materials, types, locations]);

  const sendToInvoiceAction = {
    icon: Search,
    tooltip: 'View Invoice',
    onClick: ({rowData}) => {
      props.history.push(`/accounting/proforma/${rowData.id}`);
    },
  };
  const getNonInvoicedShippingDocs = () => {
    return Firestore.collectionGroup('Shipping-Documents').where('BillingRef', '==', null).get().then(shipmentSnap => {
      const nonBillableShipmentsList = [];
      const docs = shipmentSnap.docs.map(async shipmentDoc => {
        const docData = {
          ...shipmentDoc.data(), id: shipmentDoc.id, status: !shipmentDoc.data().IsInvoiced && shipmentDoc.data().System.UpdatedBy ?
            'Awaiting Accounting' : 'Awaiting Sales Approval',
        };
        const inboundData = await shipmentDoc.ref.parent.parent.get().then(doc => ({id: doc.id, ...doc.data()}));
        docData.accountName = accounts[inboundData.AccountId].AccountName;
        docData.ShipmentTime = moment(inboundData.ShipmentTime.toDate()).format('MM/DD/YY');
        docData.Facility = inboundData.Facility;
        docData.inboundId = inboundData.id;
        return docData;
      });
      Promise.all(docs).then(values => {
        values.forEach(doc => {
          if (doc.RemainingContainersUntilBillable) nonBillableShipmentsList.push(doc);
        });
        setNonBillableShipments([...nonBillableShipmentsList]);
      });
    });
  };
  const getInvoices = () => {
    return Firestore.collection('Tracking-Invoices').where('IsInvoiced', '==', false).onSnapshot(snapshot => {
      const invoiceList = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        status: !doc.data().IsInvoiced && doc.data().System.UpdatedBy ? 'Awaiting Accounting' : 'Awaiting Sales Approval',
        accountName: accounts[doc.data().AccountRef].AccountName,
        daysSinceBillable: moment().diff(moment(doc.data().System.CreatedOn.toDate()), 'days'),
        DateShipped: doc.data().DateShipped?.toDate(),
      }),
      );
      setInvoices([...invoiceList]);
    });
  };
  const getProcessForms = () => {
    return Firestore.collection('Tracking-Forms')
      .where('FormType', '==', 'Process')
      .where('Active', '==', true)
      .where('IsInvoiced', '==', false)
      .get()
      .then(snapshot => {
        const processFormList = snapshot.docs.map(doc => (
          {
            ...doc.data(),
            id: doc.id,
            daysSinceBillable: moment().diff(moment(doc.data().System.CreatedOn.toDate()), 'days'),
            materialName: doc.data().YieldRef === 'mixed' ? 'Mixed' : materials.ref[doc.data().YieldRef]?.UnitDetails.MaterialName,
            typeName: types[doc.data().YieldRef]?.find(type => type.id === doc.data().TypeRef)?.TypeName,
            Date: doc.data().Date.toDate(),
          }));
        setProcessForms([...processFormList]);
      });
  };
  const getKbiGenerationForms = () => {
    return Firestore.collection('Tracking-Forms').where('FormType', '==', 'KBI Generation').where('IsInvoiced', '==', false).onSnapshot(snapshot => {
      const generationFormList = {};
      snapshot.forEach(doc => {
        const docData = {
          ...doc.data(),
          id: doc.id,
          materialName: materials.ref[doc.data().MaterialRef]?.UnitDetails.MaterialName,
          typeName: types[doc.data().MaterialRef]?.find(type => type.id === doc.data().TypeRef)?.TypeName,
        };
        const midnight = moment(docData.DateCreated.toDate()).startOf('day');
        // this conditional block creates a map of generated items
        // it groups them by date
        if (generationFormList[midnight]) {
          generationFormList[midnight].lines.push(docData);
          generationFormList[midnight].totalWeight += docData.Weight;
        }
        else {
          generationFormList[midnight] = {
            lines: [docData],
            totalWeight: docData.Weight,
            date: midnight.toDate(),
          };
        }
      });
      setGenerationForms([...Object.values(generationFormList)]);
    });
  };
  const getOutbounds = () => {
    return Firestore.collection('Tracking-Shipments').where('IsInvoiced', '==', false).get().then(snapshot => {
      const outboundList = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        Date: doc.data().System.CreatedOn.toDate(),
        daysSinceBillable: moment().diff(moment(doc.data().System.CreatedOn.toDate()), 'days'),
        accountName: accounts[doc.data().AccountId]?.AccountName,
        locationName: locations[doc.data().AccountId]?.find(location => location.id === doc.data().LocationRef)?.LocationName,
        transporterName: accounts[doc.data().Transporter]?.AccountName,
      }));
      setOutbounds([...outboundList]);
    });
  };

  if (!accounts) return <LinearProgress />;

  const core = {
    billableTable: {
      data: invoices,
      title: {primary: 'Shipping Documents Ready to be Billed'},
      columns: [
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'daysSinceBillable', Header: 'Days Since Billable', type: 'numeric'},
        {accessor: 'DateShipped', Header: 'Date Received', type: 'datetime'},
        {accessor: 'ShippingDocNo', Header: 'Shipping Doc No.'},
        {accessor: 'ShippingDocType', Header: 'Shipping Doc Type'},
        {accessor: 'status', Header: 'Status'},
      ],
      paginationInitialSize: 15,
      sortInitial: [{id: 'accountName', desc: false}, {id: 'daysSinceBillable', desc: true}],
      actionsBar: [
        {
          icon: Refresh,
          text: 'Refresh Billable Documents',
          onClick: () => {
            Functions.httpsCallable('updateBillableShippingDocs').call();
          },
        },
      ],
      actionsPerRow: [sendToInvoiceAction],
      paperProps: {
        style: {
          marginTop: '24px',
        },
      },
    },
    nonBillableTable: {
      data: nonBillableShipments,
      title: {primary: 'Shipping Documents Awaiting Further Processing'},
      columns: [
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'ShipmentTime', Header: 'Shipment Time'},
        {accessor: 'DocumentNumber', Header: 'Shipping Doc No.'},
        {accessor: 'DocumentType', Header: 'Shipping Doc Type'},
        {accessor: 'Facility', Header: 'Facility'},
        {accessor: 'RemainingContainersUntilBillable', Header: 'Remaining Containers', type: 'numeric'},
      ],
      sortInitial: [{id: 'RemainingContainersUntilBillable', desc: false}],
      paginationInitialSize: 15,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Shipping Document',
          onClick: ({rowData}) => {
            props.history.push(`/shipments/inbound/${rowData.inboundId}/${rowData.id}`);
          },
        },
      ],
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
    outboundTable: {
      data: outbounds,
      title: {primary: 'Outbounds Ready for Invoicing'},
      columns: [
        {accessor: 'id', Header: 'Id'},
        {accessor: 'daysSinceBillable', Header: 'Days Since Billable', type: 'numeric'},
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'locationName', Header: 'Location'},
        {accessor: 'transporterName', Header: 'Transporter'},
        {accessor: 'DocumentType', Header: 'Shipping Doc Type'},
        {accessor: 'DocumentNumber', Header: 'Shipping Doc No.'},
      ],
      sortInitial: [{id: 'daysSinceBillable', desc: true}],
      paginationSizes: [5, 10, 20],
      paginationInitialSize: 15,
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Outbound',
          onClick: ({rowData}) => {
            props.history.push(`/shipments/outbound/${rowData.id}`);
          },
        },
      ],
    },
    processFormTable: {
      data: processForms,
      title: {primary: 'Process Forms Ready for Invoicing'},
      columns: [
        {accessor: 'id', Header: 'Id'},
        {accessor: 'daysSinceBillable', Header: 'Days Since Billable', type: 'numeric'},
        {accessor: 'Date', Header: 'Date', type: 'datetime'},
        {accessor: 'materialName', Header: 'Yield'},
        {accessor: 'typeName', Header: 'Type'},
        {accessor: 'Flag', Header: 'Flag'},
      ],
      paginationInitialSize: 15,
      sortInitial: [{id: 'daysSinceBillable', desc: true}],
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Process Form',
          onClick: ({rowData}) => {
            props.history.push(`/production/process/${rowData.id}`);
          },
        },
      ],
      paperProps: {
        style: {
          marginTop: '24px',
        },
      },
    },
    kbiGenerationTable: {
      data: generationForms,
      title: {primary: 'KBI Generation Forms'},
      columns: [
        {accessor: 'date', Header: 'Date', type: 'datetime'},
        {accessor: 'totalWeight', Header: 'Weight Generated', type: 'numeric'},
      ],
      paginationInitialSize: 15,
      sortInitial: [{id: 'date', desc: true}],
      actionsPerRow: [
        {
          icon: Search,
          tooltip: 'View Generation Form',
          onClick: ({rowData}) => {
            setGenerationFormModalOpen(true);
            setSelectedGenerationData(rowData);
          },
        },
      ],
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
    kbiGenerationModal: {
      selectedGenerationData: selectedGenerationData,
      close: () => {
        setSelectedGenerationData(null);
        setGenerationFormModalOpen(false);
      },
    },
  };

  return (
    <Fragment>
      <TopNavBar tabArray={arrayOfTabs} />
      {loadingData ? <LinearProgress /> : <Fragment>
        <Collapse in={props.history.location.pathname.includes('accounting/proforma')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableWithPaper {...core.billableTable} />
            </Grid>
            <Grid item xs={12}>
              <TableWithPaper {...core.nonBillableTable} />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={props.history.location.pathname.includes('accounting/outbound')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableWithPaper {...core.outboundTable} />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={props.history.location.pathname.includes('accounting/tracking')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableWithPaper {...core.processFormTable} />
            </Grid>
            <Grid item xs={12}>
              <TableWithPaper {...core.kbiGenerationTable} />
            </Grid>
          </Grid>
        </Collapse>
      </Fragment>
      }
      {generationFormModalOpen && <KBIGenerationFormModal {...core.kbiGenerationModal} />}
    </Fragment>
  );
};

Accounting.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Accounting);

import React from 'react';
import PropTypes from 'prop-types';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Collapse,
  Button,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {connect} from 'react-redux';
import moment from 'moment';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

const stageArray = ['basic', 'success'];

class NewConsolidatedContainerModal extends React.Component {
  state = {
    stage: 0,
    formError: '',
    submitting: false,
    formHistoryToDelete: null,
  }
  componentDidMount() {
    Firestore.collectionGroup('Form-History').where('SubcollectionId', '==', this.props.selectedConsolidatedContainer.id).get().then(snap => {
      const history = snap.docs.map(doc => doc);
      this.setState({
        formHistoryToDelete: history,
      });
    });
  }
  handleSubmit() {
    this.setState({submitting: true}, () => {
      const firestoreBatch = Firestore.batch();
      const consolidatedContainerRef = Firestore.collection('Tracking-Forms')
        .doc(this.props.selectedConsolidationForm.id)
        .collection('Consolidated-Containers')
        .doc(this.props.selectedConsolidatedContainer.id);

      // deletes the document for the consolidation
      firestoreBatch.delete(consolidatedContainerRef);
      // deletes the form histories from this form
      this.state.formHistoryToDelete.forEach(doc => {
        firestoreBatch.delete(doc.ref);
      });

      // updates the container that is selected by the form
      const consolidationContainerData = this.createConsolidationData();
      const consolidationContainerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedConsolidationForm.ConsolidationContainer);
      firestoreBatch.update(consolidationContainerRef, consolidationContainerData);

      // updates the original container that was consolidated
      const originalContainerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedConsolidatedContainer.OriginalShortNo);
      firestoreBatch.update(originalContainerRef, this.createOriginalContainerData());

      // loop over inventory items within the subcollection and update them to be where they were before the form
      this.props.selectedConsolidatedContainer.InventoryItems.forEach(item => {
        const itemRef = Firestore.collection('Tracking-Inventory').doc(item);
        firestoreBatch.update(itemRef, {
          ContainerRef: this.props.selectedConsolidatedContainer.OriginalShortNo,
          FormHistory: firebase.firestore.FieldValue.arrayRemove(this.props.selectedConsolidationForm.id),
        });
      });

      firestoreBatch.commit().then(() => {
        this.setState({submitting: false, stage: this.state.stage + 1});
      }).catch(error => {
        this.setState({submitting: false, formError: 'There was an error during submission. Please try again.'});
      });
    });
  }
  createConsolidationData() {
    // figures out the new weight of the container, and the new date after the inventory items trimmed
    const newWeight = this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].NetWeight - this.props.selectedConsolidatedContainer.WeightConsolidated;
    let oldestDate = moment(this.props.inventoryItems.ref[this.props.selectedConsolidatedContainer.InventoryItems[0]].AccumulationStartDate.toDate());
    this.props.selectedConsolidatedContainer.InventoryItems.forEach(itemRef => {
      const inventoryRef = this.props.inventoryItems.ref[itemRef];
      if (moment(inventoryRef.AccumulationStartDate.toDate()).isBefore(oldestDate)) {
        oldestDate = moment(inventoryRef.AccumulationStartDate.toDate());
      }
    });
    // returns the new weight, trimmed inventory, and new date
    return {
      AccumulationStartDate: oldestDate.toDate(),
      NetWeight: newWeight,
      InventoryItems: firebase.firestore.FieldValue.arrayRemove(...this.props.selectedConsolidatedContainer.InventoryItems),
    };
  }
  createOriginalContainerData() {
    // figures out the oldest date with the new inventory
    let oldestDate = moment(this.props.inventoryItems.ref[this.props.selectedConsolidatedContainer.InventoryItems[0]].AccumulationStartDate.toDate());
    this.props.selectedConsolidatedContainer.InventoryItems.forEach(itemRef => {
      const inventoryRef = this.props.inventoryItems.ref[itemRef];
      if (moment(inventoryRef.AccumulationStartDate.toDate()).isBefore(oldestDate)) {
        oldestDate = moment(inventoryRef.AccumulationStartDate.toDate());
      }
    });
    // returns all the information that was stored in the form subcollection that brings back this container to its state before being consolidated
    return {
      AccumulationStartDate: oldestDate.toDate(),
      InventoryItems: firebase.firestore.FieldValue.arrayUnion(...this.props.selectedConsolidatedContainer.InventoryItems),
      NetWeight: this.props.selectedConsolidatedContainer.NetWeight,
      Active: true,
      FacilityUnitRef: this.props.selectedConsolidatedContainer.FacilityUnitRef,
      Flag: this.props.selectedConsolidatedContainer.Flag,
      MaterialRef: this.props.selectedConsolidatedContainer.MaterialRef,
    };
  }
  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: 'Container successfully removed from form.',
        delete: true,
        stage: stageArray[this.state.stage],
      },
      cancelButton: {
        onClick: this.props.close,
        color: 'secondary',
        disabled: this.state.submitting,
      },
      closeButton: {
        onClick: this.props.close,
        color: 'primary',
      },
      submitButton: {
        text: 'Submit',
        loading: this.state.submitting,
        disabled: !this.state.formHistoryToDelete,
        color: 'primary',
        onClick: () => this.handleSubmit(),
      },
    };

    return (
      <Dialog {...core.dialog}>
        {stageArray[this.state.stage] !== 'success' && <DialogTitle>Remove Consolidated Container from Form ({this.props.selectedConsolidatedContainer.OriginalShortNo})</DialogTitle>}
        <DialogContent>
          <Collapse in={stageArray[this.state.stage] === 'basic'}>
            <DialogContentText>Only proceed with this action if you are certain that the container being removed is correct.
              It will reset the container selected back to what it was before this form. If you are unsure, please contact a supervisor.</DialogContentText>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
          <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
        </DialogContent>
        {stageArray[this.state.stage] === 'success' ? (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button {...core.closeButton}>Close</Button>
          </DialogActions>
        ) : (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button {...core.cancelButton}>Cancel</Button>
            <SubmitButton {...core.submitButton} />
          </DialogActions>
        )
        }
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  containers: state.firestore.containers,
  currentUser: state.firestore.currentUser,
  inventoryItems: state.firestore.inventoryItems,
});
NewConsolidatedContainerModal.propTypes = {
  close: PropTypes.func.isRequired,
  containers: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  inventoryItems: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  currentUser: PropTypes.object.isRequired,
  selectedConsolidationForm: PropTypes.object.isRequired,
  selectedConsolidatedContainer: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NewConsolidatedContainerModal);

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetFacilities} from 'state/firestore/actions.js';

const useFacilityUnits = () => {
  const dispatch = useDispatch();
  const facilityUnits = useSelector(state => state.firestore.facilityUnits);

  useEffect(() => {
    if (!facilityUnits) dispatch(acGetFacilities);
  }, [dispatch, facilityUnits]);

  return facilityUnits;
};

export default useFacilityUnits;

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetActiveProcessForms} from 'state/firestore/actions.js';

const useProcessForms = () => {
  const dispatch = useDispatch();
  const processForms = useSelector(state => state.firestore.processForms);

  useEffect(() => {
    if (!processForms) dispatch(acGetActiveProcessForms);
  }, [dispatch, processForms]);

  return processForms;
};

export default useProcessForms;

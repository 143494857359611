import React from 'react';
import {SvgIcon} from '@material-ui/core';

const DefaultIcon = props => {
  return (
    <SvgIcon viewBox='0 0 407.601 407.601' {...props}>
      <g>
        <path d='M325.882,57.796H81.717c-11.748,0-21.306,9.552-21.306,21.299v249.426c0,11.738,9.552,21.297,21.306,21.297h244.165
    c11.744,0,21.302-9.553,21.302-21.297V79.095C347.178,67.348,337.626,57.796,325.882,57.796z M222.095,249.239h-45.608
    l-44.044-67.182v67.182H91.49V84.329h40.953v67.185l44.044-67.185h45.608l-54.781,82.511L222.095,249.239z M365.832,0H41.763
    C20.626,0,3.485,17.142,3.485,38.281v331.035c0,21.144,17.141,38.284,38.278,38.284h324.075c21.138,0,38.278-17.141,38.278-38.284
    V38.281C404.116,17.142,386.976,0,365.832,0z M362.274,328.509c0,20.061-16.326,36.393-36.393,36.393H81.717
    c-20.064,0-36.396-16.326-36.396-36.393V79.095c0-20.058,16.325-36.389,36.396-36.389h244.165
    c20.061,0,36.393,16.326,36.393,36.389V328.509z' />
      </g>
    </SvgIcon>);
};

export default DefaultIcon;

// https://material-ui.com/components/tabs/#scrollable-tabs

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Hidden, AppBar, Tabs, Tab, makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

function a11yProps(index) {
  return {
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TopNavBar({tabArray}) {
  const classes = useStyles();
  const history = useHistory();
  const [indexOfTab, setIndexOfTab] = useState(tabArray.findIndex(tab => tab.path.includes(history.location.pathname)));

  useEffect(() => {
    const indexOfPath = tabArray.findIndex(tab => tab.path.includes(history.location.pathname));
    if (indexOfPath !== -1) {
      setIndexOfTab(indexOfPath);
    }
  }, [history.location.pathname, tabArray]);

  const handleChange = (event, newValue) => {
    history.push(tabArray[newValue].path);
  };

  return (
    <div className={`hidePrint ${classes.root}`}>
      <AppBar position="static" color="default">
        <Hidden smDown>
          <Tabs
            value={indexOfTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabArray.map((tab, index) => (
              <Tab label={tab.label} key={`scrollable-auto-tab-${index}`} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Hidden>
        <Hidden mdUp>
          <Tabs
            value={indexOfTab}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabArray.map((tab, index) => (
              <Tab label={tab.label} key={`scrollable-auto-tab-${index}`} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Hidden>
      </AppBar>
    </div>
  );
}

TopNavBar.propTypes = {
  tabArray: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
};

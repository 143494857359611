/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogContentText,
  Collapse,
  DialogActions,
} from '@material-ui/core';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import {Alert, Formik} from '@kbi/component-library';
import {useSelector} from 'react-redux';
import {object, array} from 'yup';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
const {FormikForm, SubmitButton, FormButton, AutoCompleteObject} = Formik;

function reducer(state, action) {
  switch (action.type) {
  case 'value':
    return {...state, [action.field]: {...state[action.field], value: action.payload}};
  case 'error':
    return {...state, [action.field]: {...state[action.field], error: action.payload}};
  case 'stage':
    return {...state, stage: action.payload};
  case 'submitting':
    return {...state, submitting: action.payload};
  case 'addContainer':
    return {...state, arrayOfContainerShortNumbers: [...state.arrayOfContainerShortNumbers, action.payload]};
  case 'deleteContainer':
    return {
      ...state,
      arrayOfContainerShortNumbers: [
        ...state.arrayOfContainerShortNumbers.slice(0, action.payload),
        ...state.arrayOfContainerShortNumbers.slice(action.payload + 1),
      ],
    };
  case 'formError':
    return {...state, formError: action.payload};
  default:
    throw new Error();
  }
}

const cleanState = {
  arrayOfContainerShortNumbers: [],
  stage: 0,
  submitting: false,
  formError: '',
};
const validationSchema = object({
  facilityUnit: object().nullable().required('Facility Unit is a required field.'),
  movedContainers: array().required('At least one container must be selected.').min(1, 'At leaste one container must be selected.'),
});
const stageArray = ['basic', 'success'];

const MovementFormModal = props => {
  const [state, dispatch] = useReducer(reducer, cleanState);
  const [listOfInvalidContainers, setListOfInvalidContainers] = useState(null);

  const {facilityUnits, containers} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    // fires on mount. this populates the modal with data from a previously created form.
    if (props.selectedMovementForm) {
      props.selectedMovementForm.ContainersMoved.forEach(containerShortNumber => {
        dispatch({type: 'addContainer', payload: containerShortNumber});
      });
      dispatch({type: 'value', field: 'facilityUnit', payload: props.selectedMovementForm.FacilityUnitRef});
    }
  }, [props.selectedMovementForm]);
  useEffect(() => {
    if (listOfInvalidContainers && listOfInvalidContainers.length) {
      let errorMessage = '';
      if (listOfInvalidContainers.length === 1) {
        // eslint-disable-next-line max-len
        errorMessage = `${listOfInvalidContainers[0]} is not of a matching material or yield type allowed in the selected facility unit. Either remove that container from the form, or select an appropriate facility unit.`;
      }
      else {
        // eslint-disable-next-line max-len
        errorMessage = `${listOfInvalidContainers.join(', ')} are not of a matching material or yield type allowed in the selected facility unit. Either remove those containers from the form, or select an appropriate facility unit.`;
      }
      dispatch({type: 'formError', payload: errorMessage});
    }
    else {
      dispatch({type: 'formError', payload: ''});
    }
  }, [listOfInvalidContainers]);

  const defaultFormik = {
    movedContainers: [],
    facilityUnit: facilityUnits.ref[props.defaultUnit] || '',
    facility: props.defaultFacility ? {value: props.defaultFacility} : '',
  };

  const handleSubmit = (values, actions) => {
    if ((!listOfInvalidContainers || !listOfInvalidContainers.length)) {
      const batchArray = [];
      let writeCount = 0;
      const handleBatchOverflow = (writeCount, batchArray) => {
        // i believe the FieldValue.arrayUnion has an effect on the amount of batch requests, lowing this value well below the 500 limit.
        if (writeCount % 200 === 0) {
          batchArray.push(Firestore.batch());
        }
      };
      const movementFormRef = Firestore.collection('Tracking-Forms').doc();

      values.movedContainers.forEach(container => {
        // loop over containers and create a batch update
        // updates the facilityUnitRef for each container
        const containerRef = Firestore.collection('Tracking-Containers').doc(container.ShortNo);
        handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].update(containerRef, {FacilityUnitRef: values.facilityUnit.FacilityId});
        writeCount++;

        container.InventoryItems.forEach(itemRef => {
          handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].update(Firestore.collection('Tracking-Inventory').doc(itemRef), {
            FormHistory: firebase.firestore.FieldValue.arrayUnion(movementFormRef.id),
          });
          writeCount++;
        });
      });

      handleBatchOverflow(writeCount, batchArray);
      // create a document that is a record of this movement form
      batchArray[batchArray.length - 1].set(movementFormRef, {
        ContainersMoved: values.movedContainers.map(container => container.ShortNo),
        FacilityUnitRef: values.facilityUnit.FacilityId,
        FormType: 'Movement',
        System: {
          CreatedBy: currentUser.displayName,
          CreatedOn: firebase.firestore.Timestamp.now(),
        },
      });
      writeCount++;

      Promise.all(batchArray.map(batch => batch.commit()))
        .then(() => {
          dispatch({type: 'stage', payload: state.stage + 1});
          actions.setSubmitting(false);
        })
        .catch(error => {
          actions.setSubmitting(false);
          dispatch({type: 'formError', payload: 'There was an error submitting movement form. Please try again.'});
        });
    }
    else {
      actions.setSubmitting(false);
    }
  };
  const validateFacilityUnit = form => {
    if (!form.values.facilityUnit || !form.values.movedContainers) {
      return;
    }
    const arrayOfInvalidContainers = [];
    const matchedFacilityUnit = form.values.facilityUnit;
    form.values.movedContainers.forEach(container => {
      let materialIsAllowed = false;
      for (const description in matchedFacilityUnit.AllowedItems) {
        if (matchedFacilityUnit.AllowedItems[description].Materials.find(materialRef => materialRef === container.MaterialRef) ||
          matchedFacilityUnit.AllowedItems[description].Yields.find(yieldRef => yieldRef === container.MaterialRef)) {
          materialIsAllowed = true;
          break;
        }
      }
      if (!materialIsAllowed) {
        arrayOfInvalidContainers.push(container.ShortNo);
      }
    });
    setListOfInvalidContainers(arrayOfInvalidContainers);
  };

  const core = {
    dialog: {
      open: props.open,
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    submitConfirmation: {
      text: 'Movement Form successfully submitted.',
      stage: stageArray[state.stage],
    },
    containerField: formikProps => ({
      label: 'Container Short No.',
      name: 'movedContainers',
      options: containers.list.filter(container => facilityUnits.ref[container.FacilityUnitRef].Facility === formikProps.values.facility?.value),
      optionKey: 'ShortNo',
      disabled: !!props.selectedMovementForm || formikProps.isSubmitting,
      multiple: true,
      autoSelect: false,
      placeholder: 'Select containers to move...',
      onChange: ({field, form}) => {
        validateFacilityUnit(form);
      },
    }),
    unitField: formikProps => ({
      required: true,
      name: 'facilityUnit',
      label: 'Facility Unit',
      loading: !formikProps.values.facility,
      loadingText: 'Please select a facility to populate options',
      options: facilityUnits.active.filter(unit => unit.Facility === formikProps.values.facility?.value),
      optionKey: 'Name',
      onChange: ({field, form}) => {
        if (!field.value) {
          return;
        }
        else {
          validateFacilityUnit(form);
        }
      },
    }),
    facilityField: {
      required: true,
      name: 'facility',
      label: 'Facility',
      options: facilitiesConst.map(facility => ({value: facility})),
      onChange: ({field, form}) => {
        form.setFieldValue('facilityUnit', '');
        form.setFieldValue('movedContainers', '');
      },
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm
        onSubmit={handleSubmit}
        initialValues={{...defaultFormik}}
        validationSchema={validationSchema}
        validateOnBlur={true}
      >
        {formikProps => (
          <Fragment>
            {stageArray[state.stage] === 'basic' && <DialogTitle>Movement Form</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[state.stage] === 'basic'}>
                <DialogContentText>Select containers to move and destination:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <AutoCompleteObject {...core.facilityField} />
                  </Grid>
                  <Grid item xs={6}>
                    <AutoCompleteObject {...core.unitField(formikProps)} />
                  </Grid>
                  <Grid item xs={12}>
                    <AutoCompleteObject {...core.containerField(formikProps)} />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(state.formError)} text={state.formError} severity='error' />
            </DialogContent>
            {stageArray[state.stage] !== 'success' ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>close</FormButton>
              </DialogActions>
            )}          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

MovementFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedMovementForm: PropTypes.object,
  defaultFacility: PropTypes.string,
  defaultUnit: PropTypes.string,

};

export default MovementFormModal;

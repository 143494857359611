import React, {Fragment, useState} from 'react';
import {Typography, Grid, Button, makeStyles, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {CallMadeRounded, CallReceivedRounded} from '@material-ui/icons';
import {ShipmentCalendar, InboundTable} from 'components/';
import {InboundModal, OutboundModal} from './Shipments/';
import {useAccounts, useShipments, useTrackingShipments, usePurchaseOrders, useLocations} from 'hooks';

const Shipments = props => {
  const [inboundModalOpen, setInboundModalOpen] = useState(false);
  const [outboundModalOpen, setOutboundModalOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const trackingShipments = useTrackingShipments();
  const shipments = useShipments();
  const accounts = useAccounts();
  const purchaseOrders = usePurchaseOrders();
  const locations = useLocations();

  const styles = useStyles();

  if (!shipments || !accounts || !trackingShipments || !purchaseOrders || !locations) return <LinearProgress />;

  const calendarEventClick = async event => {
    if (event.ShipmentId) {
      if (event.ScheduleType === 'inbound') sendUserToInboundLoad(event.ShipmentId);
      else sendUserToOutboundLoad(event.ShipmentId);
    }
    else {
      if (event.ScheduleType === 'inbound') {
        setInboundModalOpen(true);
        setSelectedShipment(event);
      }
      else {
        setOutboundModalOpen(true);
        setSelectedShipment(event);
      }
    }
  };
  const sendUserToInboundLoad = docId => {
    props.history.push(`/shipments/inbound/${docId}`);
  };
  const sendUserToOutboundLoad = docId => {
    props.history.push(`/shipments/outbound/${docId}`);
  };

  const core = {
    inboundModalProps: {
      selectedShipment,
      close: inboundId => {
        setInboundModalOpen(false);
        setSelectedShipment(null);
        if (inboundId) sendUserToInboundLoad(inboundId);
      },
    },
    outboundModalProps: {
      open: outboundModalOpen,
      selectedShipment,
      close: outboundId => {
        setOutboundModalOpen(false);
        setSelectedShipment(null);
        if (outboundId) sendUserToOutboundLoad(outboundId);
      },
    },
    inboundButton: {
      size: 'small',
      variant: 'outlined',
      onClick: () => setInboundModalOpen(true),
    },
    outboundButton: {
      size: 'small',
      variant: 'outlined',
      style: {marginLeft: '8px'},
      onClick: () => setOutboundModalOpen(true),
    },
    inboundTable: {
      trackingShipments: trackingShipments.list.filter(shipment => shipment.InProgress) || [],
      title: 'In Progress',
    },
  };

  return (
    <Fragment>
      <Typography variant='h5' gutterBottom>
        Shipments
      </Typography>
      <div className='sideScrollingContainer'>
        <Button {...core.inboundButton}>
          <CallReceivedRounded className={styles.extendedIcon} />
          Create Inbound Load
        </Button>
        <Button {...core.outboundButton}>
          <CallMadeRounded className={styles.extendedIcon} />
          Create Outbound Load
        </Button>
      </div>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <ShipmentCalendar eventClickHandler={calendarEventClick} />
        </Grid>
        <Grid item xs={12}>
          <InboundTable {...core.inboundTable} />
        </Grid>
      </Grid>
      {inboundModalOpen && <InboundModal {...core.inboundModalProps} />}
      {outboundModalOpen && <OutboundModal {...core.outboundModalProps} />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  }}));

Shipments.propTypes = {
  history: PropTypes.object.isRequired,
};


export default withRouter(Shipments);

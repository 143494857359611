import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetMaterials} from 'state/firestore/actions.js';

const useMaterials = () => {
  const dispatch = useDispatch();
  const materials = useSelector(state => state.firestore.materials);

  useEffect(() => {
    if (!materials) dispatch(acGetMaterials);
  }, [dispatch, materials]);

  return materials;
};

export default useMaterials;

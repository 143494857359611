/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import {Add, Clear} from '@material-ui/icons';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {SubmitConfirmation, AsyncLoader} from 'components/';
import {NewFileItem, FirestoreImg} from './CarouselModal/';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {FieldArray} from 'formik';
import * as yup from 'yup';
const {TextField, FormikForm, FormButton, SubmitButton} = Formik;

const stageArray = ['basic', 'success'];

const CarouselModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [files, setFiles] = useState(null);

  useEffect(() => {
    const listener = Firestore.collection('Tracking-Forms')
      .doc(props.selectedSortForm.id)
      .collection('Inbound-Containers')
      .doc(props.selectedInboundContainer.id)
      .collection('Files')
      .onSnapshot({includeMetadataChanges: true}, snap => {
        Promise.all(snap.docs.map(fileDoc => ({...fileDoc.data(), id: fileDoc.id})).map(fileDoc => {
          return firebase.storage()
            .ref(`Sort_Forms/${props.selectedSortForm.id}/${props.selectedInboundContainer.id}/${fileDoc.FileName}`)
            .getDownloadURL()
            .then(url => {
              return [fileDoc, url];
            });
        })).then(values => {
          setFiles(values);
        });
      });
    return () => {
      if (listener) {
        listener();
      }
    };
  }, []);


  const core = {
    dialog: {
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    submitConfirmation: {
      text: 'Inbound Container notes successfully saved.',
      stage: stageArray[stage],
    },
    carouselProps: {
      showThumbs: false,
      // centerMode: true,
      onClickItem: (index, carouselItem) => {
        // if the item is an image, it will have the file prop. otherwise it is the dropzone item. dont fire the window.open
        if (carouselItem?.props?.file) {
          window.open(carouselItem.props.file[1]);
        }
      },
      width: '250px',
    },
    addNoteButton: (arrayHelpers, isSubmitting) => ({
      color: 'primary',
      disabled: isSubmitting,
      onClick: () => arrayHelpers.push(''),
    }),
    removeNoteButton: (arrayHelpers, index, array, isSubmitting) => ({
      color: 'secondary',
      disabled: array.length === 1 || isSubmitting,
      onClick: () => arrayHelpers.remove(index),
    }),
    newFileItem: {
      selectedInboundContainer: props.selectedInboundContainer,
      selectedSortForm: props.selectedSortForm,
      setFormError: (string) => setFormError(string),
    },
  };
  const formikProps = {
    initialValues: {
      notes: props.selectedInboundContainer.Notes || [''],
    },
    validationSchema: yup.object().shape({
      notes: yup.array(),
    }),
    onSubmit: (values, actions) => {
      return Firestore.runTransaction(async transaction => {
        const inventoryItem = await Firestore.collection('Tracking-Inventory')
          .doc(props.selectedInboundContainer.SortedInventoryItems[0].InventoryRef)
          .get()
          .then(doc => ({...doc.data(), id: doc.id}));
        const shippingDoc = await transaction.get(Firestore.collection('Tracking-Shipments')
          .doc(inventoryItem.ShipmentInRef).collection('Shipping-Documents').doc(inventoryItem.ShippingDocRef),
        );
        transaction.update(
          Firestore.collection('Tracking-Forms').doc(props.selectedSortForm.id).collection('Inbound-Containers').doc(props.selectedInboundContainer.id), {
            Notes: values.notes,
          },
        );
        const lineItemIndex = shippingDoc.data().LineItems.findIndex(item => item.PieceNumber === inventoryItem.IncomingPieceNo);
        return transaction.update(shippingDoc.ref, {
          LineItems: [
            ...shippingDoc.data().LineItems.slice(0, lineItemIndex),
            {...shippingDoc.data().LineItems[lineItemIndex], SortNotes: values.notes},
            ...shippingDoc.data().LineItems.slice(lineItemIndex + 1),
          ],
        });
      }).then(() => {
        setStage(stage + 1);
        actions.setSubmitting(false);
      }).catch(error => {
        actions.setStatus({success: false});
        actions.setSubmitting(false);
      });
    },
  };

  // if(!files) return <Dialog {...core.dialog}><AsyncLoader /></Dialog>;

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formikProps}>
        {({values, isSubmitting}) => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Notes and Image Gallery</DialogTitle>}
            <DialogContent >
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>
                  Add any notes or images to this inbound container sort:
                </DialogContentText>
                <Grid container spacing={2} alignItems='flex-end'>
                  <Grid container spacing={2} item xs={12} justify='center'>
                    <Grid item>
                      <div id="main-slide" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                          <Carousel {...core.carouselProps}>
                            {files ? files.map(fileArray => (
                              <FirestoreImg key={fileArray[1]} file={fileArray} selectedInboundContainer={props.selectedInboundContainer}
                                selectedSortForm={props.selectedSortForm}
                              />),
                            ) : <AsyncLoader style={{marginTop: '50px'}} />}
                            <NewFileItem {...core.newFileItem} />
                          </Carousel>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <FieldArray name='notes'>
                    {arrayHelpers => (
                      <Fragment>
                        {values.notes.map((noteField, index, array) => (
                          <Fragment key={index}>
                            <Grid item xs={8}>
                              <TextField name={`notes[${index}]`} label='Note' />
                            </Grid>
                            <Grid item xs={2}>
                              <Button {...core.removeNoteButton(arrayHelpers, index, array, isSubmitting)}><Clear /></Button>
                            </Grid>
                            {index === array.length - 1 && (
                              <Grid item xs={2}>
                                <Button {...core.addNoteButton(arrayHelpers, isSubmitting)}><Add /></Button>
                              </Grid>)}
                          </Fragment>
                        ))}
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] !== 'success' ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog >
  );
};

CarouselModal.propTypes = {
  selectedSortForm: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  selectedInboundContainer: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
};

export default CarouselModal;

import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, Collapse, DialogContentText} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitConfirmation, DefaultModalButtons} from 'components/';
import {Firestore} from 'config.js';

const stageArray = ['basic', 'success'];

const DeleteEmptyContainerModal = props => {
  const [submitting, setSubmitting] = useState(false);
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const core = useMemo(() => ({
    dialog: {
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    buttonProps: {
      isSubmitting: submitting,
      stage: stageArray[stage],
      handleSubmit: () => {
        setSubmitting(true);
        if (props.selectedContainer.InventoryItems.length) {
          setFormError('The container has inventory items. It cannot be delete. Please contact a supervisor if this is an issue.');
          setSubmitting(false);
        }
        Firestore.collection('Tracking-Containers').doc(props.selectedContainer.ShortNo).update({
          Active: false,
          AccumulationStartDate: null,
          FacilityUnitRef: null,
          Flag: null,
          MaterialRef: null,
          NetWeight: null,
        }).then(() => {
          setSubmitting(false);
          setStage(stage + 1);
        })
          .catch(error => {
            setSubmitting(false);
            setFormError('There was an error during submission. Please try again.');
          });
      },
      close: props.close,
    },
    submitConfirmation: {
      text: 'Container successfully deleted.',
      stage: stageArray[stage],
      delete: true,
    },
  }), [props.close, props.selectedContainer.InventoryItems.length, props.selectedContainer.ShortNo, stage, submitting]);
  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>Delete Empty Container</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>
          Only submit if you know this container is not being used. It will remove the short number from the system completely.
          </DialogContentText>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      <DefaultModalButtons {...core.buttonProps} />
    </Dialog>
  );
};

DeleteEmptyContainerModal.propTypes = {
  selectedContainer: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default DeleteEmptyContainerModal;

import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {ExpandMore, Edit} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {WeightTicketModal} from './WeightTickets/';

const WeightTickets = props => {
  const {listOfWeightTickets} = props;
  const [expanded, setExpanded] = useState(false);
  const [weightTicketModalOpen, setWeightTicketModalOpen] = useState(false);
  const [selectedWeightTicket, setSelectedWeightTicket] = useState(null);


  if (!listOfWeightTickets) return <LinearProgress />;

  const details = () => {
    if (listOfWeightTickets.length) {
      return <Table {...core.weightTicketTable} />;
    }
    else {
      return (
        <Grid container justify='space-between' style={{padding: '16px'}}>
          <Grid item>
            <Typography>No weight tickets have been added to this inbound load.</Typography>
          </Grid>
        </Grid>
      );
    }
  };
  const editWeightTicketButtonClick = weightTicket => {
    setWeightTicketModalOpen(true);
    setSelectedWeightTicket(weightTicket);
  };

  const core = {
    weightTicketTable: {
      columns: [
        {accessor: 'TicketNumber', Header: 'Ticket Number'},
        {accessor: 'HeavyWeight', Header: 'Heavy Weight'},
        {accessor: 'LightWeight', Header: 'Light Weight'},
        {accessor: 'GrossWeight', Header: 'Gross Weight'},
      ],
      data: listOfWeightTickets,
      actionsPerRow: [
        {
          icon: Edit,
          tooltip: 'Edit Weight Ticket',
          hide: !props.selectedInbound.InProgress,
          onClick: ({rowData}) => editWeightTicketButtonClick(rowData),
        },
      ],
    },
    addWeightTicketButton: {
      variant: 'outlined',
      onClick: e => {
        e.stopPropagation();
        setWeightTicketModalOpen(true);
        setSelectedWeightTicket(null);
      },
    },
    weightTicketModal: {
      open: weightTicketModalOpen,
      close: () => {
        setWeightTicketModalOpen(false);
        setSelectedWeightTicket(null);
      },
      selectedInbound: props.selectedInbound,
      selectedWeightTicket,
    },
  };

  return (
    <Fragment>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container justify='space-between'>
            <div>
              <Typography>Weight Tickets</Typography>
            </div>
            {expanded && props.selectedInbound.InProgress ? (
              <div>
                <Button {...core.addWeightTicketButton}>Add Weight Ticket</Button>
              </div>
            ) : null}
          </Grid>
        </AccordionSummary>
        {details()}
      </Accordion>
      {weightTicketModalOpen && <WeightTicketModal {...core.weightTicketModal} />}
    </Fragment>
  );
};

WeightTickets.propTypes = {
  selectedInbound: PropTypes.object.isRequired,
  listOfWeightTickets: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WeightTickets;

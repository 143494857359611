import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetAllMaterialTypes} from 'state/firestore/actions.js';

const useTypes = () => {
  const dispatch = useDispatch();
  const types = useSelector(state => state.firestore.types);

  useEffect(() => {
    if (!types) dispatch(acGetAllMaterialTypes);
  }, [dispatch, types]);

  return types;
};

export default useTypes;

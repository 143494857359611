import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetActiveSortForms} from 'state/firestore/actions.js';

const useSortForms = () => {
  const dispatch = useDispatch();
  const sortForms = useSelector(state => state.firestore.sortForms);

  useEffect(() => {
    if (!sortForms) dispatch(acGetActiveSortForms);
  }, [dispatch, sortForms]);

  return sortForms;
};

export default useSortForms;

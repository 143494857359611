export const GET_SHIPMENTS = 'module_tracking/state/firestore/GET_SHIPMENTS';
export const SET_SHIPMENT_LISTENER = 'module_tracking/state/firestore/SET_SHIPMENT_LISTENER';
export const SET_ACCOUNTS = 'module_tracking/state/firestore/SET_ACCOUNTS';
export const SET_ACCOUNT_LISTENER = 'module_tracking/state/firestore/SET_ACCOUNT_LISTENER';
export const GET_LOCATIONS = 'module_tracking/state/firestore/GET_LOCATIONS';
export const SET_PURCHASE_ORDERS = 'module_tracking/state/firestore/SET_PURCHASE_ORDERS';
export const SET_PURCHASE_ORDER_LISTENER = 'module_tracking/state/firestore/SET_PURCHASE_ORDER_LISTENER';
export const SET_MATERIALS = 'module_tracking/state/firestore/SET_MATERIALS';
export const SET_ALL_MATERIAL_TYPES = 'module_tracking/state/firestore/SET_ALL_MATERIAL_TYPES';
export const SET_ACCOUNT_PROFILES = 'module_tracking/state/firestore/SET_ACCOUNT_PROFILES';

export const SET_SHIPPING_NAME_LISTENER = 'module_tracking/state/SET_SHIPPING_NAME_LISTENER';
export const SET_FACILITIES = 'module_tracking/state/SET_FACILITIES';
export const SET_SHIPMENTS = 'module_tracking/state/SET_SHIPMENTS';
export const SET_INVENTORY_LISTENER = 'module_tracking/state/SET_INVENTORY_LISTENER';
export const SET_INVENTORY_ITEMS = 'module_tracking/state/SET_INVENTORY_ITEMS';
export const SET_CONTAINER_LISTENER = 'module_tracking/state/SET_CONTAINER_LISTENER';
export const SET_CONTAINERS = 'module_tracking/state/SET_CONTAINERS';
export const SET_SORT_FORM_LISTENER = 'module_tracking/state/SET_SORT_FORM_LISTENER';
export const SET_SORT_FORMS = 'module_tracking/state/SET_SORT_FORMS';
export const SET_PROCESS_FORM_LISTENER = 'module_tracking/state/SET_PROCESS_FORM_LISTENER';
export const SET_PROCESS_FORMS = 'module_tracking/state/SET_PROCESS_FORMS';
export const SET_CONSOLIDATION_FORM_LISTENER = 'module_tracking/state/SET_CONSOLIDATION_FORM_LISTENER';
export const SET_CONSOLIDATION_FORMS = 'module_tracking/state/SET_CONSOLIDATION_FORMS';

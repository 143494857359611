import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {List, ListItem, Typography} from '@material-ui/core';


const DetailsTitle = props => {
  const {title, style, subtitle, subtitleLink} = props;
  const listStyle = useMemo(() => ({
    width: '100%',
    ...style,
  }), [style]);
  const subtitleMemo = useMemo(() => {
    if (subtitle) return <Typography variant='body2' style={{lineHeigt: '.9', textAlign: 'center'}}>{subtitle}</Typography>;
    return null;
  }, [subtitle]);

  return (
    <List style={{...listStyle}}>
      <ListItem style={{flexDirection: 'column'}}>
        <Typography variant='h6' style={{lineHeigt: '.9', textAlign: 'center'}}>
          {title}
        </Typography>
        {!subtitleLink && subtitleMemo}
        {subtitleLink && <Link to={subtitleLink}>{subtitleMemo}</Link>}
      </ListItem>
    </List>
  );
};

DetailsTitle.defaultProps = {
  title: `Add 'title' Prop`,
};
DetailsTitle.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  subtitleLink: PropTypes.string,
};
export default DetailsTitle;

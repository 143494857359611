import React, {useState, useEffect, Fragment} from 'react';
import {Button, Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Firestore} from 'config';
import firebase from 'firebase/app';
import {DeleteForeverOutlined} from '@material-ui/icons';
import {AsyncLoader} from 'components/';

const FirestoreImg = ({selectedInboundContainer, selectedSortForm, file}) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (deleteConfirmation) {
      setTimeout(() => setDeleteConfirmation(false), 2000);
    }
  }, [deleteConfirmation]);
  const core = {
    deleteButton: {
      size: 'small',
      color: 'secondary',
      style: {
        position: 'absolute',
        right: 35,
        top: 0,
      },
      onClick: e => {
        e.stopPropagation();
        if (deleteConfirmation) {
          setDeleting(true);
          firebase.storage().ref(`Sort_Forms/${selectedSortForm.id}/${selectedInboundContainer.id}/${file[0].FileName}`).delete().then(() => {
            return Firestore.collection('Tracking-Forms')
              .doc(selectedSortForm.id)
              .collection('Inbound-Containers')
              .doc(selectedInboundContainer.id)
              .collection('Files')
              .doc(file[0].id)
              .delete();
          }).then(() => {
            setDeleteConfirmation(false);
            setDeleting(false);
          });
        }
        else {
          setDeleteConfirmation(true);
        }
      },
    },
    divStyles: {
      container: true,
      justify: 'center',
      style: {
        height: '250px',
        cursor: 'pointer',
        width: '250px',
        backgroundColor: 'lightgray',
      },
    },
    image: {
      src: file[1],
      style: {
        maxWidth: '250px',
        maxHeight: '250px',
        width: 'auto',
        height: 'auto',
      },
    },
  };

  return (
    <Grid {...core.divStyles}>
      {deleting ? <AsyncLoader style={{marginTop: '20px'}} /> : (
        <Fragment>
          {!selectedSortForm.Completed && <Button {...core.deleteButton}>{deleteConfirmation ? 'Confirm?' : <DeleteForeverOutlined fontSize='small' />}</Button>}
          <img alt='downloaded from firestore' {...core.image} />
        </Fragment>
      )}
    </Grid>
  );
};

FirestoreImg.propTypes = {
  file: PropTypes.array.isRequired,
  selectedInboundContainer: PropTypes.object.isRequired,
  selectedSortForm: PropTypes.object.isRequired,
};

export default FirestoreImg;

import React, {Fragment} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {shortNumberGenerator} from '@kbi/utility-library';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {SubmitConfirmation, containerCodes} from 'components/';
import {Firestore} from 'config.js';
import {connect} from 'react-redux';
import * as yup from 'yup';
const {AutoCompleteObject, FormikForm, WeightField, SubmitButton, FormButton, validateAutoObject} = Formik;
validateAutoObject();

class AdminContainerModal extends React.Component {
  state = {
    formError: '',
    stage: 0,
    defaultFormik: this.props.selectedContainer ? {
      material: this.props.materials.ref[this.props.selectedContainer.MaterialRef] || '',
      flag: this.props.materials.ref[this.props.selectedContainer.MaterialRef].Flags.find(flag => flag.Name === this.props.selectedContainer.Flag) || '',
      containerCode: {
        value: containerCodes.find(codeArray => codeArray[0] === this.props.selectedContainer.ContainerCode)[0],
        types: containerCodes.find(codeArray => codeArray[0] === this.props.selectedContainer.ContainerCode)[1]},
      containerCodeType: {value: this.props.selectedContainer.ContainerCodeType},
      tareWeight: this.props.selectedContainer.TareWeight,
      facilityUnit: this.props.facilityUnits.list.find(unit => unit.FacilityId === this.props.selectedContainer.FacilityUnitRef),
      inboundContainer: {value: this.props.selectedContainer.InboundContainer, display: this.props.selectedContainer.InboundContainer ? 'True' : 'False'},
    } : {
      material: '',
      flag: '',
      containerCode: '',
      containerCodeType: '',
      tareWeight: '',
      facilityUnit: '',
      inboundContainer: '',
    },
  }
  validationSchema = yup.object().shape({
    material: yup.object().nullable().exists('Material is a required field.'),
    flag: yup.object().nullable(),
    containerCode: yup.object().nullable().exists('Container Code is a required field.'),
    containerCodeType: yup.mixed().when('containerCode', {
      is: value => value && value.types.length ? true : false,
      then: yup.object().nullable().exists('The selected Container Code requires a type.'),
      otherwise: yup.mixed(),
    }),
    tareWeight: yup.number().required('Tare Weight is a required field.'),
    facilityUnit: yup.object().nullable().exists('Facility Unit is a required field.'),
    inboundContainer: yup.object().nullable().exists('Inbound Container is a required field.'),
  });
  handleSubmit(values, actions) {
    let containerRef = null;
    const containerData = this.createDataForContainer(values);
    const firestoreBatch = Firestore.batch();
    if (this.props.selectedContainer) {
      containerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedContainer.ShortNo);
      firestoreBatch.update(containerRef, containerData);
    }
    else {
      const newShortNo = (() => {
        do {
          // eslint-disable-next-line no-var
          var testNumber = shortNumberGenerator();
        } while (this.props.containers.ref[testNumber]); return testNumber;
      })();
      containerRef = Firestore.collection('Tracking-Containers').doc(newShortNo);
      firestoreBatch.set(containerRef, {
        ...containerData,
        AccumulationStartDate: new Date(),
        Shard: parseInt(Math.random() * 5 + 1),
        InventoryItems: [],
        NetWeight: 0,
      });
    }
    firestoreBatch.commit().then(() => {
      actions.setSubmitting(false);
      this.setState({stage: this.state.stage + 1});
    }).catch(error => {
      actions.setSubmitting(false);
      this.setState({formError: 'There was an error during submission. Please try again.'});
    });
  }
  createDataForContainer(values) {
    return {
      Active: true,
      ContainerCode: values.containerCode ? values.containerCode.value : '',
      ContainerCodeType: values.containerCodeType ? values.containerCodeType.value : '',
      FacilityUnitRef: values.facilityUnit ? values.facilityUnit.FacilityId : '',
      Flag: values.flag ? values.flag.Name : '',
      InboundContainer: values.inboundContainer ? values.inboundContainer.value : '',
      MaterialRef: values.material ? values.material.MaterialId : '',
      TareWeight: parseInt(values.tareWeight) || 0,
    };
  }

  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'md',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: this.props.selectedContainer ? 'Container successfully updated.' : 'Container successfully created',
        stage: this.state.stage === 1 ? 'success' : 'not success',
      },
      materialAutoField: {
        name: 'material',
        label: 'Material',
        options: Object.values(this.props.materials.ref),
        optionKey: 'UnitDetails.MaterialName',
        onChange: ({form, field}) => {
          form.setFieldValue('flag', '');
        },
      },
      flagAutoComplete: (formikProps) => ({
        name: 'flag',
        label: 'Flag',
        loading: !formikProps.values.material,
        loadingText: 'Please select a material to populate options',
        noOptionsText: 'This material has no flags',
        options: formikProps.values.material ? formikProps.values.material.Flags : [],
        optionKey: 'Name',
      }),
      containerCodeField: {
        name: 'containerCode',
        label: 'Container Code',
        options: containerCodes.map(codeArray => ({value: codeArray[0], types: codeArray[1]})),
        optionKey: 'value',
        onChange: ({form, field}) => {
          form.setFieldValue('containerCodeType', '');
        },
      },
      containerCodeTypeField: formikProps => ({
        name: 'containerCodeType',
        label: 'Container Code Type',
        loading: !formikProps.values.containerCode,
        loadingText: 'Please select a container code to populate options',
        noOptionsText: 'The selected container code has no types',
        options: formikProps.values.containerCode ? formikProps.values.containerCode.types.map(code => ({value: code})) : [],
      }),
      facilityUnitField: {
        name: 'facilityUnit',
        label: 'Facility Unit',
        options: this.props.facilityUnits.list,
        optionKey: 'Name',
      },
      inboundContainerField: {
        name: 'inboundContainer',
        label: 'Inbound Container?',
        options: [{value: true, display: 'True'}, {value: false, display: 'False'}],
        optionKey: 'display',
      },
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm initialValues={this.state.defaultFormik} onSubmit={this.handleSubmit.bind(this)} validationSchema={this.validationSchema}>
          {formikProps => (
            <Fragment>
              {this.state.stage === 0 && <DialogTitle>Admin Container {this.props.selectedContainer ? 'Edit' : 'Add'}</DialogTitle>}
              <DialogContent>
                <Collapse in={this.state.stage === 0}>
                  <DialogContentText>Enter all data for the container:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.materialAutoField} required />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.flagAutoComplete(formikProps)} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.containerCodeField} required />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.containerCodeTypeField(formikProps)} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <WeightField name='tareWeight' label='Tare Weight' decimal={0}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.facilityUnitField} required />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.inboundContainerField} required />
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {this.state.stage !== 1 ? (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton variant='text' color='secondary' onClick={this.props.close}>Cancel</FormButton>
                  <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <FormButton variant='text' color='secondary' onClick={this.props.close}>close</FormButton>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  materials: state.firestore.materials,
  facilityUnits: state.firestore.facilityUnits,
  containers: state.firestore.containers,
});

AdminContainerModal.propTypes = {
  selectedContainer: PropTypes.object,
  close: PropTypes.func.isRequired,
  facilityUnits: PropTypes.shape({
    active: PropTypes.arrayOf(PropTypes.object),
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  containers: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
  }),
};

export default connect(mapStateToProps)(AdminContainerModal);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Search, Add} from '@material-ui/icons';
import {TableWithPaper} from 'components/';

class ViewMaterials extends Component {
  render() {
    const {materials} = this.props;
    const core = {
      tableMaterials: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'UnitDetails.MaterialName', Header: 'Material Name'},
          {accessor: 'UnitDetails.Category', Header: 'Category'},
          {accessor: 'UnitDetails.InboundFormAllowed', Header: 'Inbound Form', type: 'boolean'},
          {accessor: 'UnitDetails.ProcessFormAllowed', Header: 'Process Form', type: 'boolean'},
          {accessor: 'UnitDetails.Yield', Header: 'Yield', type: 'boolean'},
          {accessor: 'UnitDetails.KbiGenerated', Header: 'KBI Generated', type: 'boolean'},
        ],
        sortInitial: [{id: 'UnitDetails.MaterialName', desc: false}],
        data: materials ? Object.values(materials.ref) : [],
        paginationInitialSize: 20,
        paginationSizes: [5, 10, 20, 50],
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Material',
            onClick: ({rowData}) => this.props.onMaterialSelect(rowData.MaterialId),
          },
        ],
        actionsBar: [
          {
            icon: Add,
            text: 'Add Material',
            onClick: this.props.footerClick,
          },
        ],
        title: {primary: 'Materials'},
      },
    };
    return (
      <TableWithPaper {...core.tableMaterials} />
    );
  }
}

ViewMaterials.propTypes = {
  onMaterialSelect: PropTypes.func.isRequired,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    completeList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  footerClick: PropTypes.func.isRequired,
};
export default ViewMaterials;

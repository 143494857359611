import React from 'react';
import {SvgIcon} from '@material-ui/core';

const MergeIcon = props => {
  return (
    <SvgIcon viewBox='0 0 512 512' {...props}>
      <g>
        <g>
          <path d="M472,317v135c0,11.028-8.972,20-20,20H313v40h139c33.084,0,60-26.916,60-60V317H472z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M452,0H313v40h139c11.028,0,20,8.972,20,20v137h40V60C512,26.916,485.084,0,452,0z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="512,237 364.284,237 418.142,183.142 389.858,154.858 287.795,256.92 389.778,360.062 418.222,331.938
      363.901,277 512,277"
          />
        </g>
      </g>
      <g>
        <g>
          <path d="M60,472c-11.028,0-20-8.972-20-20V317H0v135c0,33.084,26.916,60,60,60h139v-40H60z" />
        </g>
      </g>
      <g>
        <g>
          <path d="M60,0C26.916,0,0,26.916,0,60v137h40V60c0-11.028,8.972-20,20-20h139V0H60z" />
        </g>
      </g>
      <g>
        <g>
          <polygon points="122.142,154.858 93.858,183.142 147.716,237 0,237 0,277 148.099,277 93.778,331.938 122.222,360.062
      224.205,256.92"
          />
        </g>
      </g>
    </SvgIcon>);
};

export default MergeIcon;

/* eslint-disable guard-for-in */
import React, {useState, useCallback, useMemo} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Paper, Grid, Button} from '@material-ui/core';

const localizer = momentLocalizer(moment);

const ShipmentCalendar = props => {
  const [filter, setFilter] = useState('');
  const {shipments, accounts} = props;

  const inboundButton = useMemo(() => ({
    color: filter === 'inbound' ? 'primary' : 'default',
    variant: 'outlined',
    onClick: () => {
      if (filter !== 'inbound') setFilter('inbound');
      else setFilter('');
    },
  }), [filter]);
  const outboundButton = useMemo(() => ({
    color: filter === 'outbound' ? 'primary' : 'default',
    variant: 'outlined',
    onClick: () => {
      if (filter !== 'outbound') setFilter('outbound');
      else setFilter('');
    },
  }), [filter]);
  const displayEventDetails = useCallback(eventObj => {
    // this formats, and displays, the tooltip on hover for an event
    return `
  Pieces: ${eventObj.Pieces}
  Load Type: ${eventObj.LoadType}
  Contact: ${eventObj.Contact.Name.Full}${eventObj.Container &&
      `
  Container: ${eventObj.Container}`}${eventObj.Notes &&
      `
  Notes: ${eventObj.Notes}`}
    `;
  }, []);
  const highlightDay = useCallback(date => {
    return moment(date).isSame(moment(), 'day') ? {style: {border: '1px solid rgb(107, 120, 143)'}} : null; // puts a border around the current day
  }, []);
  const listOfEvents = useMemo(() => {
    const arrayOfShipments = [];
    for (const accountId in shipments) {
      shipments[accountId].forEach(shipment => {
        const formattedEvent = {
          title: accounts[accountId].AccountName, // required property
          start: shipment.StartDateTime, // required property
          end: shipment.EndDateTime, // required property
          ...shipment,
        };
        arrayOfShipments.push(formattedEvent);
      });
    }
    if (filter) {
      return arrayOfShipments.filter(shipment => shipment.ScheduleType === filter);
    }
    else return arrayOfShipments;
  }, [accounts, filter, shipments]);

  return (
    <Paper style={{padding: '16px', marginTop: '16px', height: '60vh'}}>
      {/** There is a css selector div.rbc-month-view in the index.css file that addresses this component. at the time of creation (7/16/19)
       *  there was a bug causing the react-big-calendar in monthly view to overflow its container.
       * if this has been fixed, or if there are further issues, start with that selector */}
      <Calendar
        views={['day', 'week', 'work_week', 'month']}
        defaultView={'day'}
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        eventPropGetter={event => {
          if (event.ShipmentId) return {style: {backgroundColor: 'grey', border: '1px solid black'}};
          else if (event.ScheduleType === 'outbound') {
            return {style: {backgroundColor: 'blue', border: '1px solid black'}};
          }
          else return {style: {backgroundColor: 'green', border: '1px solid black'}};
        }}
        timeslots={4} // not quite sure how this number works, but i found that 4 relates to 2 hour blocks on the calendar
        dayPropGetter={date => highlightDay(date)}
        events={listOfEvents}
        min={new Date(new Date().setHours(6, 0))}
        max={new Date(new Date().setHours(18, 0))}
        tooltipAccessor={event => displayEventDetails(event)}
        onSelectEvent={event => props.eventClickHandler(event)}
      />
      <Grid container spacing={1} justify='center' style={{marginTop: '4px'}}>
        <Grid item xs={6} sm={2} md={1} >
          <Button {...inboundButton}>Inbounds</Button>
        </Grid>
        <Grid item xs={6} sm={2} md={1}>
          <Button {...outboundButton}>Outbounds</Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {shipments: state.firestore.shipments, accounts: state.firestore.accounts};
};

ShipmentCalendar.propTypes = {
  shipments: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  accounts: PropTypes.objectOf(PropTypes.object),
  eventClickHandler: PropTypes.func.isRequired, // if you dont want a click event to happen, pass an empty function
};

export default connect(mapStateToProps)(ShipmentCalendar);

// http://intljusticemission.github.io/react-big-calendar/examples/index.html#basic
// https://www.npmjs.com/package/react-big-calendar
// docs for the calendar

/* eslint-disable guard-for-in */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField as MuiTextField,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import {Collapse, Alert, Formik} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import {FieldArray} from 'formik';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {connect} from 'react-redux';
import * as yup from 'yup';
import {Clear, Add} from '@material-ui/icons';
const {FormikForm, TextField, WeightField, AutoCompleteObject, SubmitButton, FormButton, validateAutoObject} = Formik;
validateAutoObject();

function determineIfNotesAreRequired(fieldArray, initialContainer) {
  let notesAreRequired = false;
  let weightSorted = 0;
  const weightOfInitialContainer = initialContainer && initialContainer.NetWeight;
  for (let index = 0; index < fieldArray.length; index++) {
    if (!isNaN(parseInt(fieldArray[index].netWeight))) {
      weightSorted += parseInt(fieldArray[index].netWeight);
    }
    else {
      return false;
    }
  }
  if (weightSorted < (weightOfInitialContainer * 0.9) || weightSorted > weightOfInitialContainer * 1.1) notesAreRequired = true;
  return notesAreRequired;
}

class SortInboundModal extends React.Component {
  state = {
    formError: '',
    stage: 0,
    selectedInventoryItem: null,
    purchaseOrderDoc: null,
    activeMaterialProfileDoc: null,
    arrayOfManifestedItemsThatChanged: [],
    arrayOfMaterialsNotOnProfile: [],
    shippingDoc: null,
  }
  stageArray = ['inboundContainer', 'sorting', 'success'];
  createValidationSchema(materials) {
    if (this.stageArray[this.state.stage] === 'inboundContainer') {
      return yup.object().shape({
        inboundContainer: yup.object().nullable().exists('Inbound Container Short No. is a required field.'),
      });
    }
    else {
      return yup.object().shape({
        notes: yup.string().test({
          name: 'need a note',
          test: function(value) {
            return !value && determineIfNotesAreRequired(this.parent.sortedItems, this.parent.inboundContainer) ? false : true;
          },
          message: 'Note is required due to weight discrepancy.',
        }),
        sortedItems: yup.array().of(yup.object().shape({
          existingContainer: yup.object().nullable().exists('Existing Container Short No. is a required field.'),
          type: yup.object().nullable().exists('Type is a required field.'),
          flag: yup.object().nullable().test({
            name: 'required Type',
            test: function(value) {
              return !value && this.parent.existingContainer &&
               materials.ref[this.parent.existingContainer.MaterialRef].FlagStatus === 'Required' ? false : true;
            },
            message: 'Flag is required for this material.',
          }),
          netWeight: yup.number()
            .typeError('Net Weight is a required field.')
            .min(1, 'Net Weight must be positive.')
            .required('Net Weight is a required field'),
        })),
      });
    }
  }

  handleSubmit(values, actions) {
    // handles next
    if (this.stageArray[this.state.stage] === 'inboundContainer') {
      actions.setSubmitting(false);
      actions.setFieldTouched('notes', false);
      values.sortedItems.forEach((objectOfFields, index) => {
        for (const field in objectOfFields) {
          actions.setFieldTouched(`sortedItems[${index}].${field}`, false);
        }
      });
      return this.setState({stage: this.state.stage + 1});
    }
    else {
      const firestoreBatch = Firestore.batch();
      const inboundContainerRef = Firestore.collection('Tracking-Forms')
        .doc(this.props.selectedSortForm.id)
        .collection('Inbound-Containers')
        .doc();
      const inboundData = this.createInboundContainerData(values);
      const arrayOfInventoryItems = this.createInventoryItems(inboundData);
      firestoreBatch.update(Firestore.collection('Tracking-Containers').doc(values.inboundContainer.ShortNo), {
        Active: false,
        AccumulationStartDate: null,
        FacilityUnitRef: null,
        InventoryItems: [],
        Flag: null,
        MaterialRef: null,
        NetWeight: null,
      });
      const containersToUpdate = {};
      arrayOfInventoryItems.forEach(item => {
        const {id, ...data} = item;
        // update inventory item with new data
        firestoreBatch.set(Firestore.collection('Tracking-Inventory').doc(id), {...data});
        // create form history document
        firestoreBatch.set(Firestore.collection('Tracking-Inventory').doc(id).collection('Form-History').doc(), {
          Date: inboundData.System.CreatedOn,
          FormId: this.props.selectedSortForm.id,
          SubcollectionId: inboundContainerRef.id,
          FormType: 'Sort',
          StartContainer: inboundData.ShortNo,
          StartMaterial: inboundData.OriginalMaterial,
          StartType: inboundData.OriginalType,
          StartWeight: inboundData.StartWeight,
          StartFlag: inboundData.Flag,
          EndContainer: data.ContainerRef,
          EndMaterial: data.Material.Name,
          EndType: data.Type.Name,
          EndWeight: data.Weight,
          EndFlag: data.Flag,
        });

        if (containersToUpdate[data.ContainerRef]) {
          containersToUpdate[data.ContainerRef].InventoryItems.push(id);
          containersToUpdate[data.ContainerRef].NetWeight += data.Weight;
        }
        else {
          containersToUpdate[data.ContainerRef] = {
            InventoryItems: [id],
            NetWeight: this.props.containers.ref[data.ContainerRef].NetWeight + data.Weight,
          };
        }
      });
      for (const shortNo in containersToUpdate) {
        firestoreBatch.update(Firestore.collection('Tracking-Containers').doc(shortNo), {
          InventoryItems: firebase.firestore.FieldValue.arrayUnion(...containersToUpdate[shortNo].InventoryItems),
          NetWeight: containersToUpdate[shortNo].NetWeight,
        });
      }
      firestoreBatch.set(inboundContainerRef, inboundData);
      const lineItemIndex = this.state.shippingDoc.LineItems.findIndex(item => item.PieceNumber === this.state.selectedInventoryItem.IncomingPieceNo);
      firestoreBatch.update(Firestore.collection('Tracking-Shipments')
        .doc(this.state.selectedInventoryItem.ShipmentInRef)
        .collection('Shipping-Documents')
        .doc(this.state.selectedInventoryItem.ShippingDocRef), {
        LineItems: [
          ...this.state.shippingDoc.LineItems.slice(0, lineItemIndex),
          {...this.state.shippingDoc.LineItems[lineItemIndex], SortNotes: [values.notes.trim()]},
          ...this.state.shippingDoc.LineItems.slice(lineItemIndex + 1),
        ],
      });

      firestoreBatch.commit().then(() => {
        actions.setSubmitting(false);
        this.setState({stage: this.state.stage + 1});
      }).catch(error => {
        actions.setSubmitting(false);
        this.setState({formError: 'There was an error during submission. Please try again.'});
      });
    }
  }
  createInventoryItems(inboundContainerDoc) {
    const arrayOfInventoryItems = [];
    inboundContainerDoc.SortedInventoryItems.forEach((item, index) => {
      const newInventoryItem = {
        ...this.state.selectedInventoryItem,
        Material: item.Material,
        Type: item.Type,
        ContainerRef: item.ProductionContainer,
        Flag: item.Flag || '',
        Weight: item.NetWeight,
        Source: 'Sort',
        FormHistory: [...this.state.selectedInventoryItem.FormHistory, this.props.selectedSortForm.id],
        Billable: true,
        id: item.InventoryRef,
      };
      if (newInventoryItem.Material.Ref !== this.state.selectedInventoryItem.Material.Ref) {
        newInventoryItem.RCRACodes = [];
        newInventoryItem.CACodes = [];
        newInventoryItem.Classification = '';
        if (newInventoryItem.Manifested) {
          this.state.arrayOfManifestedItemsThatChanged.push({
            inventoryRef: newInventoryItem.id,
            shortNo: newInventoryItem.ContainerRef,
          });
        }
        if (!this.state.activeMaterialProfileDoc?.Materials?.find(profileMat => newInventoryItem.Material.Ref === profileMat.Ref)) {
          this.state.arrayOfMaterialsNotOnProfile.push({
            inventoryRef: newInventoryItem.id,
            shortNo: newInventoryItem.ContainerRef,
          });
        }
      }
      arrayOfInventoryItems.push(newInventoryItem);
    });
    return arrayOfInventoryItems;
  }
  createInboundContainerData(values) {
    return {
      Flag: values.inboundContainer.Flag,
      OriginalMaterial: this.props.materials.ref[values.inboundContainer.MaterialRef].UnitDetails.MaterialName,
      Notes: [values.notes.trim()],
      ShortNo: values.inboundContainer.ShortNo,
      SortFormRef: this.props.selectedSortForm.id,
      OriginalType: this.state.selectedInventoryItem.Type.Name,
      SortedInventoryItems: values.sortedItems.map((formikItem, index) => ({
        Flag: formikItem.flag?.Name || '',
        Material: {
          Ref: formikItem.existingContainer.MaterialRef,
          Name: this.props.materials.ref[formikItem.existingContainer.MaterialRef].UnitDetails.MaterialName,
        },
        NetWeight: parseInt(formikItem.netWeight),
        ProductionContainer: formikItem.existingContainer.ShortNo,
        Type: {
          Ref: formikItem.type.id,
          Name: formikItem.type.TypeName,
        },
        InventoryRef: index === 0 ? this.state.selectedInventoryItem.id : Firestore.collection('Tracking-Inventory').doc().id,
      })),
      StartWeight: values.inboundContainer.NetWeight,
      RemainingWeight: values.inboundContainer.NetWeight - values.sortedItems.reduce((prev, current) => prev + parseInt(current.netWeight), 0),
      System: {
        CreatedBy: this.props.currentUser.displayName,
        CreatedOn: new Date(),
      },
    };
  }
  checkForMismatchedFlags(values) {
    let stringForAlert = '';
    console.log('values.sortedItems', values.sortedItems);
    values.sortedItems.forEach(item => {
      if (item.flag.Name && item.flag.Name !== item.existingContainer.Flag) {
        stringForAlert = 'At least one item sorted has a flag that does not match it\'s new container flag. Before proceeding, ensure that the information entered is correct.';
      }
    });
    return stringForAlert;
  }
  getActiveMaterialProfile(inventoryItemDoc) {
    Firestore.collection('CRM-Accounts')
      .doc(inventoryItemDoc.AccountRef)
      .collection('Material-Profiles')
      .where('Active', '==', true)
      .get()
      .then(snap => {
        this.setState({
          activeMaterialProfileDoc: {...snap.docs.map(doc => ({...doc.data(), id: doc.id}))[0]},
        });
      });
  }
  getShippingDocument(inventoryItemDoc) {
    Firestore.collection('Tracking-Shipments')
      .doc(inventoryItemDoc.ShipmentInRef)
      .collection('Shipping-Documents')
      .doc(inventoryItemDoc.ShippingDocRef)
      .get()
      .then(doc => {
        this.setState({
          shippingDoc: {...doc.data(), id: doc.id},
        });
      });
  }
  deactivateInboundContainer(firestoreBatch, inboundContainer) {
    const originalContainerRef = Firestore.collection('Tracking-Containers').doc();
    const objectOfContainer = {
      NetWeight: null,
      Flag: null,
      AccumulationStartDate: null,
      Active: false,
      FacilityUnitRef: null,
      MaterialRef: null,
      InventoryItems: [],
    };
    firestoreBatch.update(originalContainerRef, objectOfContainer);
  }
  convertStringToNumber(string) {
    if (isNaN(parseInt(string))) {
      return 0;
    }
    else return parseInt(string);
  }
  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'lg',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      inboundAutocomplete: {
        name: 'inboundContainer',
        label: 'Container Short No.',
        required: true,
        options: this.props.containersInCurrentFacilityUnit.filter(container => container.InboundContainer),
        optionKey: 'ShortNo',
        onChange: ({field, form}) => {
          if (field.value) {
            const inventoryItemOfContainer = this.props.inventoryItems.ref[field.value.InventoryItems[0]];
            const purchaseOrderDoc = this.props.purchaseOrders[inventoryItemOfContainer.AccountRef]
              .find(po => po.id === inventoryItemOfContainer.PurchaseOrderRef);
            this.setState({selectedInventoryItem: inventoryItemOfContainer, purchaseOrderDoc});
            this.getActiveMaterialProfile(inventoryItemOfContainer);
            this.getShippingDocument(inventoryItemOfContainer);
          }
        },
      },
      formik: {
        initialValues: {
          inboundContainer: '',
          notes: '',
          sortedItems: [
            {
              existingContainer: '',
              type: '',
              flag: '',
              netWeight: '',
            },
          ],
        },
        onSubmit: this.handleSubmit.bind(this),
        validationSchema: this.createValidationSchema(this.props.materials),
      },
      submitConfirmation: {
        text: 'Inbound Container successfully added to sort form.',
        stage: this.stageArray[this.state.stage],
      },
      cancelButton: {
        onClick: this.props.close,
        color: 'secondary',
        variant: 'text',
      },
      closeButton: {
        onClick: this.props.close,
        color: 'primary',
      },
      submitButton: {
        color: 'primary',
        variant: 'text',
      },
      backButton: {
        disabled: this.stageArray[this.state.stage] === 'inboundContainer',
        color: 'primary',
        variant: 'text',
        onClick: () => this.setState({stage: this.state.stage - 1}),
      },
      disabledTextField: {
        disabled: true,
        margin: 'dense',
        fullWidth: true,
      },
      flagField: (index, formikProps) => ({
        name: `sortedItems[${index}].flag`,
        label: 'Item Flag',
        required: formikProps.values.sortedItems[index].existingContainer &&
         this.props.materials.ref[formikProps.values.sortedItems[index].existingContainer.MaterialRef].FlagStatus === 'Required' ? true : false,
        loading: formikProps.values.sortedItems[index].existingContainer ? false : true,
        loadingText: 'Please select a container to populate flag options',
        options: formikProps.values.sortedItems[index].existingContainer ?
          this.props.materials.ref[formikProps.values.sortedItems[index].existingContainer.MaterialRef].Flags : [],
        optionKey: 'Name',
      }),
      typeAutocomplete: (index, formikProps) => ({
        name: `sortedItems[${index}].type`,
        label: 'Type',
        required: true,
        loading: formikProps.values.sortedItems[index].existingContainer ? false : true,
        loadingText: 'Please select a container to populate type options',
        options: formikProps.values.sortedItems[index].existingContainer ?
          this.props.types[formikProps.values.sortedItems[index].existingContainer.MaterialRef]?.filter(type => type.Active) : [],
        optionKey: 'TypeName',
      }),
      existingContainerAutocomplete: index => ({
        name: `sortedItems[${index}].existingContainer`,
        label: 'Existing Container Short No.',
        required: true,
        options: this.props.containersInCurrentFacilityUnit.filter(container => !container.InboundContainer),
        optionKey: 'ShortNo',
        onChange: ({form, field}) => {
          form.setFieldValue(`sortedItems[${index}].flag`, '');
          form.setFieldValue(`sortedItems[${index}].type`, '');
        },
      }),
    };

    return (
      <Dialog {...core.dialog}>
        <FormikForm {...core.formik}>
          {formikProps => (
            <Fragment>
              {this.stageArray[this.state.stage] !== 'success' && <DialogTitle>Sort Inbound Container</DialogTitle>}
              <DialogContent>
                <Collapse in={this.stageArray[this.state.stage] === 'inboundContainer'}>
                  <DialogContentText>Select the container you are sorting:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <AutoCompleteObject {...core.inboundAutocomplete} />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Material'
                        value={formikProps.values.inboundContainer ?
                          this.props.materials.ref[formikProps.values.inboundContainer.MaterialRef].UnitDetails.MaterialName : ''}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Flag'
                        value={formikProps.values.inboundContainer?.Flag || ''}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Net Weight'
                        value={formikProps.values.inboundContainer?.NetWeight || ''} />
                    </Grid>
                  </Grid>
                </Collapse>
                <Collapse in={this.stageArray[this.state.stage] === 'sorting'}>
                  <DialogContentText>Sort out ALL the weight of the selected inbound container:</DialogContentText>
                  <Grid container spacing={2} style={{marginBottom: '8px'}}>
                    <Grid item xs={6} sm={3}>
                      <AutoCompleteObject {...core.inboundAutocomplete} disabled label='Selected Container' />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Material'
                        value={this.props.materials.ref[formikProps.values.inboundContainer?.MaterialRef]?.UnitDetails.MaterialName || ''}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Flag'
                        value={formikProps.values.inboundContainer?.Flag || ''}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <MuiTextField {...core.disabledTextField} label='Net Weight Remaining'
                        value={(formikProps.values.inboundContainer?.NetWeight || 0) -
                          formikProps.values.sortedItems.reduce((a, b) => a + this.convertStringToNumber(b.netWeight), 0)
                        }
                      />
                    </Grid>
                  </Grid>
                  <FieldArray name='sortedItems'>
                    {arrayHelpers => (
                      <Fragment>
                        {formikProps.values.sortedItems.map((itemFields, index, array) => (
                          <Fragment key={index}>
                            <Typography>Sorted Item #{index + 1}</Typography>
                            <Grid container>
                              <Grid container spacing={2} item
                                xs={11} style={{paddingLeft: '16px', marginBottom: '8px'}}
                              >
                                <Grid item xs={4}>
                                  <AutoCompleteObject {...core.existingContainerAutocomplete(index)} />
                                </Grid>
                                <Grid item xs={4}>
                                  <MuiTextField {...core.disabledTextField} label='Material'
                                    value={formikProps.values.sortedItems[index].existingContainer ?
                                      this.props.materials.ref[formikProps.values.sortedItems[index].existingContainer.MaterialRef].UnitDetails.MaterialName :
                                      ''}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <MuiTextField {...core.disabledTextField} label='Container Flag'
                                    value={formikProps.values.sortedItems[index].existingContainer?.Flag || ''}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <MuiTextField {...core.disabledTextField} label='Original Net Weight'
                                    value={formikProps.values.sortedItems[index].existingContainer?.NetWeight || ''}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <AutoCompleteObject {...core.typeAutocomplete(index, formikProps)} />
                                </Grid>
                                <Grid item xs={4}>
                                  <AutoCompleteObject {...core.flagField(index, formikProps)} />
                                </Grid>
                                <Grid item xs={2}>
                                  <WeightField name={`sortedItems[${index}].netWeight`} label='Sorted Net Weight' decimal={0}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <MuiTextField label='Updated Net Weight' {...core.disabledTextField}
                                    value={(this.convertStringToNumber(formikProps.values.sortedItems[index].netWeight) +
                                       this.convertStringToNumber(formikProps.values.sortedItems[index].existingContainer?.NetWeight)) || ''}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={1} container
                                spacing={2} alignItems='center'
                              >
                                <Grid item xs={6}>
                                  <Button color='secondary' disabled={array.length === 1 || formikProps.isSubmitting ? true : false}
                                    onClick={() => arrayHelpers.remove(index)}>
                                    <Clear />
                                  </Button>
                                </Grid>
                                {index === array.length - 1 && (
                                  <Grid item xs={6}>
                                    <Button color='primary' disabled={formikProps.isSubmitting}
                                      onClick={() => arrayHelpers.push({existingContainer: '', type: '', flag: '', netWeight: ''})}>
                                      <Add />
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Fragment>
                        ))
                        }
                      </Fragment>
                    )}
                  </FieldArray>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField name='notes' label='Notes'
                        required={determineIfNotesAreRequired(formikProps.values.sortedItems, formikProps.values.inboundContainer)}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
                <Alert in={Boolean(this.stageArray[this.state.stage] !== 'success' && this.checkForMismatchedFlags(formikProps.values))}
                  text={this.checkForMismatchedFlags(formikProps.values)} severity='warning'
                />
                <SubmitConfirmation {...core.submitConfirmation} />
              </DialogContent>
              {this.stageArray[this.state.stage] === 'success' ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <Button {...core.closeButton}>Close</Button>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton {...core.cancelButton}>Cancel</FormButton>
                  <div>
                    <FormButton {...core.backButton}>Back</FormButton>
                    <SubmitButton {...core.submitButton}>
                      {this.stageArray[this.state.stage] !== 'sorting' ? 'Next' : 'Submit'}
                    </SubmitButton>
                  </div>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  materials: state.firestore.materials,
  types: state.firestore.types,
  inventoryItems: state.firestore.inventoryItems,
  purchaseOrders: state.firestore.purchaseOrders,
  containers: state.firestore.containers,
});

SortInboundModal.propTypes = {
  close: PropTypes.func.isRequired,
  containersInCurrentFacilityUnit: PropTypes.arrayOf(PropTypes.object).isRequired,
  // arrayOfContainersAlreadySelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSortForm: PropTypes.object.isRequired,
  containers: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }).isRequired,
  types: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of other items with key = material id
  }).isRequired,
  currentUser: PropTypes.object.isRequired,
  inventoryItems: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  purchaseOrders: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(SortInboundModal);

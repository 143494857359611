import React, {Fragment, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Collapse,
  DialogContentText,
  Grid,
  MenuItem,
} from '@material-ui/core';
import {Alert, Formik} from '@kbi/component-library';
import {useSelector} from 'react-redux';
import {SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import * as yup from 'yup';
import {useTypes} from 'hooks/';
const {SubmitButton, FormButton, FormikForm, AutoCompleteValue, SelectField} = Formik;

const UpdateItemMissingDataModal = ({selectedItemMissingData, close}) => {
  const [stage, setStage] = useState(0);
  const currentUser = useSelector(state => state.auth.currentUser);
  const types = useTypes();

  const {caCodeOptions, rcraCodeOptions} = useMemo(() => {
    return {
      caCodeOptions: types[selectedItemMissingData.Material.Ref].find(type => type.id === selectedItemMissingData.Type.Ref).WasteCodes.CA.map(code => ({value: code})),
      rcraCodeOptions: types[selectedItemMissingData.Material.Ref].find(type => type.id === selectedItemMissingData.Type.Ref).WasteCodes.RCRA.map(code => ({value: code})),
    };
  }, [types, selectedItemMissingData]);

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    cancelButton: {
      color: 'secondary',
      onClick: close,
      variant: 'text',
    },
    submitButton: {
      variant: 'text',
      color: 'primary',
    },
    submitConfirmation: {
      stage: stage === 1 ? 'success' : 'false',
      text: 'Item successfully updated',
    },
    closeButton: {
      variant: 'text',
      onClick: close,
      color: 'primary',
    },
  };
  const formikProps = {
    initialValues: {
      classification: '',
      caCodes: [],
      rcraCodes: [],
    },
    initialStatus: {error: ''},
    validationSchema: yup.object().shape({
      classification: yup.string().label('Classification').required(),
      caCodes: yup.array().label('CA Codes'),
      rcraCodes: yup.array().label('RCRA Codes'),
    }),
    onSubmit: async (values, actions) => {
      try {
        await Firestore.collection('Tracking-Inventory').doc(selectedItemMissingData.id).update({
          Classification: values.classification,
          RCRACodes: values.rcraCodes,
          CACodes: values.caCodes,
          System: {
            CodesUpdatedBy: currentUser.displayName,
            CodesUpdatedOn: new Date(),
          },
        });

        actions.setStatus({error: ''});
        setStage(stage + 1);
      }
      catch (error) {
        actions.setStatus({error: 'There was an issue submitting the form. Please try again.'});
      }
    },
  };

  const rcraField = {
    options: rcraCodeOptions,
    optionKey: 'value',
    required: true,
    name: 'rcraCodes',
    label: 'RCRA Codes',
    multiple: true,
    disableCloseOnSelect: true,
  };

  const caField = {
    options: caCodeOptions,
    optionKey: 'value',
    required: true,
    name: 'caCodes',
    label: 'CA Codes',
    multiple: true,
    disableCloseOnSelect: true,
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formikProps}>
        {({values, setValues, status}) => (
          <Fragment>
            {stage === 0 && (
              <DialogTitle>Update Item</DialogTitle>
            )}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Enter item shipping information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectField required label='Classification' name='classification'
                      onChange={({event}) => {
                        // this is to solve an issue of using setFieldValue(caCodes, []). the filterOptions prop of AutoCompleteValue doesnt handle it properly
                        setValues({[event.target.name]: event.target.value, caCodes: [], rcraCodes: []}, false);
                      }}
                    >
                      <MenuItem value='Universal Waste'>Universal Waste</MenuItem>
                      <MenuItem value='Hazardous Waste'>Hazardous Waste</MenuItem>
                    </SelectField>
                  </Grid>
                  {values.classification === 'Hazardous Waste' ? (
                    <Fragment>
                      <Grid item xs={12}>
                        <AutoCompleteValue {...rcraField} />
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteValue {...caField} />
                      </Grid>
                    </Fragment>) : null}
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(status.error)} text={status.error} severity='error' />
            </DialogContent>
            {stage === 1 ? <DialogActions>
              <FormButton {...core.closeButton}>Close</FormButton>
            </DialogActions> : <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton {...core.cancelButton}>Cancel</FormButton>
              <SubmitButton {...core.submitButton}>Submit</SubmitButton>
            </DialogActions>}
          </Fragment>)}
      </FormikForm>
    </Dialog>
  );
};

UpdateItemMissingDataModal.propTypes = {
  // selectedInbound: PropTypes.object.isRequired,
  selectedItemMissingData: PropTypes.object,
  close: PropTypes.func.isRequired,
};

export default UpdateItemMissingDataModal;

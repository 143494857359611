import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acContainerListener} from 'state/firestore/actions.js';

const useContainers = () => {
  const dispatch = useDispatch();
  const containers = useSelector(state => state.firestore.containers);

  useEffect(() => {
    if (!containers) dispatch(acContainerListener);
  }, [dispatch, containers]);

  return containers;
};

export default useContainers;

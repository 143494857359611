import React, {useMemo, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
import {useSelector} from 'react-redux';
const {SelectField, AutoCompleteObject} = Formik;

const BuildOutboundForm = ({values}) => {
  const {containers, materials} = useSelector(state => state.firestore);

  const materialAutocompleteProps = useMemo(() => {
    return {
      options: materials.completeList,
      optionKey: 'UnitDetails.MaterialName',
      name: 'materialList',
      label: 'Materials',
      autoSelect: false,
      placeholder: 'Select all materials to include...',
      multiple: true,
      onChange: ({form, field}) => {
        form.setFieldValue('flag', '');
      },
    };
  }, [materials.completeList]);
  const containerAutocompleteProps = useMemo(() => {
    return {
      options: containers.list,
      optionKey: 'ShortNo',
      name: 'containerList',
      label: 'Short Numbers',
      placeholder: 'Select/Scan all containers to include...',
      multiple: true,
      autoSelect: false,
    };
  }, [containers.list]);
  const matchingFlags = useMemo(() => {
    // this variable is a list of flags that match across all different selected materials.
    const matchingFlags = [];
    if (values.materialList.length) {
      // if the list of selected materials exists, the first material has its flags pushed into the list of available choices.
      matchingFlags.push(...values.materialList[0].Flags);
    }
    for (let materialIndex = 1; materialIndex < values.materialList.length; materialIndex++) {
      // then loop over the list of materials and compare all flags
      if (!values.materialList[materialIndex].Flags.length) {
        // if the material selected has no flags, then none will match. remove all from the list of available.
        matchingFlags.splice(0, matchingFlags.length);
      }
      else {
        // otherwise loop over the list of available flags, and look for a match within the material selected.
        for (let matchingIndex = 0; matchingIndex < matchingFlags.length; matchingIndex++) {
          const indexOfMatch = values.materialList[materialIndex].Flags.findIndex(newFlag => newFlag.Name === matchingFlags[matchingIndex].Name);
          // if there is no match within the material, splice out the option from the available list
          if (indexOfMatch === -1) {
            matchingFlags.splice(matchingIndex, 1);
            matchingIndex--;
          }
        }
      }
    }
    return matchingFlags;
  }, [values.materialList]);
  const flagAutocompleteProps = useMemo(() => {
    return {
      options: matchingFlags,
      optionKey: 'Name',
      name: 'flag',
      label: 'Flag',
    };
  }, [matchingFlags]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SelectField name='typeOfFilter' label='Type of Report'>
          <MenuItem value='Container'>Container</MenuItem>
          <MenuItem value='Material'>Material</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={8}>
        {values.typeOfFilter === 'Material' ? <AutoCompleteObject {...materialAutocompleteProps} /> : <AutoCompleteObject {...containerAutocompleteProps} />}
      </Grid>
      {values.typeOfFilter === 'Material' && (
        <Fragment>
          <Grid item xs={6}>
            <SelectField name='onlyBillable' label='Only Include Billable?'>
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </SelectField>
          </Grid>
          <Grid item xs={6}>
            <AutoCompleteObject {...flagAutocompleteProps} />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

BuildOutboundForm.propTypes = {
  values: PropTypes.shape({
    materialList: PropTypes.array,
    containerList: PropTypes.array,
    typeOfFilter: PropTypes.string,
    flag: null,
  }),
};

export default BuildOutboundForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, Fragment} from 'react';
import {BatteryChargingFull} from '@material-ui/icons';
import {Paper, Grid, LinearProgress} from '@material-ui/core';
import {BackButton, DetailsFieldList, DetailsTitle} from 'components/';
import {InventoryDetailsPanel} from '../panels/';
import moment from 'moment';
import {Firestore} from 'config.js';
import {useParams} from 'react-router-dom';

const InventoryDetails = props => {
  const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);

  const params = useParams();

  const getSelectedInventoryItem = () => {
    Firestore.collection('Tracking-Inventory').doc(params.InventoryId).get().then(snap => {
      setSelectedInventoryItem({...snap.data(), id: snap.id});
    });
  };
  useEffect(() => {
    getSelectedInventoryItem();
  }, []);

  if (!selectedInventoryItem) return <LinearProgress />;
  const core = {
    paperHeader: {
      style: {
        display: 'flex',
        flexWrap: 'wrap',
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
  };

  return (
    <Fragment>
      <div className='sideScrollingContainer'>
        <BackButton />
      </div>
      <Grid container spacing={5} style={{marginTop: '8px'}}>
        <Grid item xs={12} md={3}>
          <Paper {...core.paperHeader}>
            <BatteryChargingFull style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
            <DetailsTitle title='Inventory Item' subtitle={selectedInventoryItem.id} />
            <DetailsFieldList fields={[
              {label: 'Start Date', value: moment(selectedInventoryItem.AccumulationStartDate.toDate()).format('MM/DD/YYYY')},
              {label: 'Material', value: selectedInventoryItem.Material.Name},
              {label: 'Type', value: selectedInventoryItem.Type.Name},
              {label: 'Flag', value: selectedInventoryItem.Flag},
              {label: 'Weight', value: selectedInventoryItem.Weight},
              {label: 'Container Short No.', value: selectedInventoryItem.ContainerRef || 'Item is no longer at facility'},
              {label: 'Incoming Piece No.', value: selectedInventoryItem.IncomingPieceNo},
              {label: 'Classification', value: selectedInventoryItem.Classification},
              {label: 'Billable', value: selectedInventoryItem.Billable},
            ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <InventoryDetailsPanel selectedInventoryItem={selectedInventoryItem} />
        </Grid>
      </Grid>
    </Fragment>);
};

InventoryDetails.propTypes = {

};

export default InventoryDetails;

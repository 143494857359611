import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Collapse,
  Grid,
  Button,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore} from 'config';

class NewProcessedContainerModal extends React.Component {
  state = {
    stage: 0,
    submitting: false,
    formError: '', // used to display error during submission
  }

  handleDelete() {
    this.setState({submitting: true});
    const containerRef = Firestore.collection('Tracking-Forms')
      .doc(this.props.selectedProcessForm.id)
      .collection('Created-Yields')
      .doc(this.props.selectedYieldContainer.id);
    containerRef.delete()
      .then(() => {
        this.setState({submitting: false, stage: this.state.stage + 1});
      })
      .catch(error => {
        this.setState({submitting: false, formError: 'There was an error during submission. Please try again.'});
      });
  }

  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitButton: {
        text: 'Confirm Delete',
        color: 'primary',
        onClick: () => this.handleDelete(),
        loading: this.state.submitting,
      },
      cancelButton: {
        disabled: this.state.submitting,
        onClick: () => this.props.close(),
        color: 'secondary',
      },
      closeButton: {
        onClick: () => this.props.close(),
        color: 'primary',
      },
      submitConfirmation: {
        text: 'Yielded container successfully removed.',
        stage: this.state.stage === 1 ? 'success' : 'basic',
      },
    };

    return (
      <Dialog {...core.dialog}>
        {this.state.stage === 0 && <DialogTitle>Remove Yielded Container</DialogTitle>}
        <DialogContent>
          <Collapse in={this.state.stage === 0}>
            <DialogContentText>Are you sure you want to remove the selected container ({this.props.selectedYieldContainer.CreatedShortNo}) from the process form?</DialogContentText>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
          <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
        </DialogContent>
        <DialogActions>
          {this.state.stage === 1 ? (
            <Button {...core.closeButton}>Close</Button>
          ) :
            <Grid container justify='space-between'>
              <Grid item>
                <Button {...core.cancelButton}>Cancel</Button>
              </Grid>
              <Grid item>
                <SubmitButton {...core.submitButton} />
              </Grid>
            </Grid>}
        </DialogActions>
      </Dialog>
    );
  }
}

NewProcessedContainerModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedProcessForm: PropTypes.object.isRequired,
  selectedYieldContainer: PropTypes.object.isRequired,
};

export default NewProcessedContainerModal;

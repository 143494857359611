import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Typography, withStyles} from '@material-ui/core';
import {BaseReportView} from './Reports/';
import flowRight from 'lodash.flowright';
import {useInventory, useAccounts, useContainers, useLocations, useMaterials, useTypes, useFacilityUnits} from 'hooks/';

const Reports = props => {
  useLocations();
  useAccounts();
  useMaterials();
  useContainers();
  useTypes();
  useInventory();
  useFacilityUnits();

  const core = {
    baseReportProps: {
      classes: props.classes,
    },
  };
  return (
    <Fragment>
      <Typography variant='h5' gutterBottom>
          Reports
      </Typography>
      <BaseReportView {...core.baseReportProps} />
    </Fragment>
  );
};

const styles = theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

Reports.propTypes = {
  classes: PropTypes.object,
};

export default flowRight(withStyles(styles, {withTheme: true}))(Reports);

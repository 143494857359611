/* eslint-disable no-console */
import {Firestore} from 'config.js';
import {
  SET_ACCOUNT_PROFILES,
  GET_SHIPMENTS,
  SET_SHIPMENT_LISTENER,
  SET_ACCOUNTS,
  SET_ACCOUNT_LISTENER,
  GET_LOCATIONS,
  SET_PURCHASE_ORDERS,
  SET_PURCHASE_ORDER_LISTENER,
  SET_MATERIALS,
  SET_ALL_MATERIAL_TYPES,
} from './types';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
  SET_FACILITIES,
  SET_SHIPMENTS,
  SET_CONTAINER_LISTENER,
  SET_CONTAINERS,
  SET_INVENTORY_ITEMS,
  SET_INVENTORY_LISTENER,
  SET_SORT_FORMS,
  SET_SORT_FORM_LISTENER,
  SET_PROCESS_FORMS,
  SET_PROCESS_FORM_LISTENER,
  SET_CONSOLIDATION_FORMS,
  SET_CONSOLIDATION_FORM_LISTENER,
} from './types';

export const acGetAccountProfiles = accountId => {
  return async dispatch => {
    const materialProfilesRef = Firestore.collection('CRM-Accounts')
      .doc(accountId)
      .collection('Material-Profiles');
    const listener = materialProfilesRef.onSnapshot(
      {includeMetadataChanges: true},
      snap => {
        const promiseArray = [];
        const profiles = [];

        snap.forEach(doc => {
          // this db call gets all locations from the current profiles subcollection and pushes them into an array within the profile
          promiseArray.push(
            materialProfilesRef
              .doc(doc.id)
              .collection('Profile-Locations')
              .get()
              .then(locationSnap => {
                const profileLocations = [];
                locationSnap.forEach(doc => {
                  profileLocations.push({...doc.data(), id: doc.id});
                });
                profiles.push({
                  ...doc.data(),
                  EffectiveDate: doc.data().EffectiveDate.toDate(),
                  Expiration: doc.data().Expiration.toDate(),
                  locations: profileLocations,
                  id: doc.id,
                });
              }),
          );
        });
        Promise.all(promiseArray).then(() => {
          dispatch({type: SET_ACCOUNT_PROFILES, payload: {profiles, listener}});
        });
      },
      error => { },
    );
  };
};
export const acClearAccountProfiles = profilesObjFromRedux => {
  return dispatch => {
    if (profilesObjFromRedux) {
      profilesObjFromRedux.listener();
    }
    dispatch({type: SET_ACCOUNT_PROFILES, payload: null});
  };
};

export const acGetShipments = dispatch => {
  const limitDate = new Date();
  limitDate.setFullYear(limitDate.getFullYear() - 2);
  const shipmentGroupRef = Firestore.collectionGroup('Shipments').where('System.CreatedOn', '>', limitDate);
  const listener = shipmentGroupRef.onSnapshot(snapshot => {
    const allShipments = {};
    snapshot.forEach(doc => {
      const docData = doc.data();
      // the if/else statement loops over the shipment docs and created an object of all account id's as keys,
      // with their respective shipments in an array as its value
      if (allShipments[docData.AccountId]) {
        allShipments[docData.AccountId] = [
          ...allShipments[docData.AccountId],
          {
            ...docData,
            id: doc.id,
            StartDateTime: docData.StartDateTime.toDate(),
            EndDateTime: docData.EndDateTime.toDate(),
          },
        ];
      }
      else {
        allShipments[docData.AccountId] = [
          {
            ...docData,
            id: doc.id,
            StartDateTime: docData.StartDateTime.toDate(),
            EndDateTime: docData.EndDateTime.toDate(),
          },
        ];
      }
    });
    dispatch({type: GET_SHIPMENTS, payload: allShipments});
    dispatch({type: SET_SHIPMENT_LISTENER, payload: listener});
  });
    /** the layout of the object created and stored in redux
     * allShipments: {
     *  account1: [{...shipment1}, {...shipment2}],
     *  account2: [{...shipment1}, {...shipment2}]
     * }
     */
};
export const acGetAccounts = dispatch => {
  const listener = Firestore.collection('CRM-Accounts').onSnapshot(
    snap => {
      let accounts = {};
      snap.forEach(doc => {
        accounts = {...accounts, [doc.id]: {...doc.data(), AccountId: doc.id}};
      });
      dispatch({type: SET_ACCOUNTS, payload: accounts});
      dispatch({type: SET_ACCOUNT_LISTENER, payload: listener});
    },
    error => { },
  );
};
export const acGetLocations = dispatch => {
  const listener = Firestore.collectionGroup('Locations').onSnapshot(
    snap => {
      const locationsObj = {};
      snap.forEach(doc => {
        const location = doc.data();
        if (locationsObj[location.AccountRef]) {
          locationsObj[location.AccountRef].push({...location, id: doc.id});
        }
        else {
          locationsObj[location.AccountRef] = [{...location, id: doc.id}];
        }
      });
      dispatch({type: GET_LOCATIONS, payload: {listener, ...locationsObj}});
    },
    error => { },
  );
};
export const acGetAllPurchaseOrders = async dispatch => {
  const limitDate = new Date();
  limitDate.setFullYear(limitDate.getFullYear() - 2);
  const purchaseOrderRef = Firestore.collectionGroup('Purchase-Orders').where('System.CreatedOn', '>', limitDate);
  const listener = purchaseOrderRef.onSnapshot(
    {includeMetadataChanges: true},
    snap => {
      const purchaseOrderObject = {};
      snap.forEach(purchaseOrderDoc => {
        const purchaseOrderData = purchaseOrderDoc.data();
        if (!purchaseOrderObject[purchaseOrderData.AccountId]) {
          purchaseOrderObject[purchaseOrderData.AccountId] = [
            {
              ...purchaseOrderData,
              EffectiveDate: purchaseOrderDoc
                .data()
                .EffectiveDate.toDate(),
              ExpirationDate: purchaseOrderDoc
                .data()
                .ExpirationDate.toDate(),
              id: purchaseOrderDoc.id,
            },
          ];
        }
        else {
          purchaseOrderObject[purchaseOrderData.AccountId] = [
            ...purchaseOrderObject[purchaseOrderData.AccountId],
            {
              ...purchaseOrderData,
              EffectiveDate: purchaseOrderDoc
                .data()
                .EffectiveDate.toDate(),
              ExpirationDate: purchaseOrderDoc
                .data()
                .ExpirationDate.toDate(),
              id: purchaseOrderDoc.id,
            },
          ];
        }
      });
      dispatch({type: SET_PURCHASE_ORDERS, payload: purchaseOrderObject});
      dispatch({type: SET_PURCHASE_ORDER_LISTENER, payload: listener});
    },
    error => { },
  );
};
export const acGetMaterials = dispatch => {
  const materialListener = Firestore.collection('Tracking-Materials').onSnapshot(snapshot => {
    const materialList = [];
    const materialRef = {};
    const yieldList = [];
    const processList = [];
    const inboundList = [];
    const completeList = [];
    const kbiGeneratedList = [];
    snapshot.forEach(doc => {
      const docData = {MaterialId: doc.id, ...doc.data()};
      materialRef[doc.id] = docData;

      if (docData.Active) {
        completeList.push(docData);
        if (docData.UnitDetails.Yield) yieldList.push(docData);
        else materialList.push(docData);

        if (docData.UnitDetails.ProcessFormAllowed) processList.push(docData);
        if (docData.UnitDetails.InboundFormAllowed) inboundList.push(docData);
        if (docData.UnitDetails.KbiGenerated) kbiGeneratedList.push(docData);
      }
    });
    dispatch({
      type: SET_MATERIALS,
      payload: {ref: materialRef,
        listener: materialListener,
        materialList: materialList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        yieldList: yieldList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        processList: processList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        inboundList: inboundList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        completeList: completeList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1),
        kbiGeneratedList: kbiGeneratedList.sort((a, b) => a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1)},
    });
  });
};
export const acGetAllMaterialTypes = async dispatch => {
  const typesRef = Firestore.collectionGroup('Types');
  const typesListener = typesRef.onSnapshot({includeMetadataChanges: true}, snapshot => {
    const typesObj = {};
    snapshot.forEach(typedoc => {
      const typeData = typedoc.data();
      if (!typesObj[typeData.MaterialId]) {
        typesObj[typeData.MaterialId] = [{...typeData, id: typedoc.id}];
      }
      else {
        typesObj[typeData.MaterialId].push({...typeData, id: typedoc.id});
      }
    });
    dispatch({type: SET_ALL_MATERIAL_TYPES, payload: {...typesObj, listener: typesListener}});
  });
};

export const acGetFacilities = dispatch => {
  const facilityListener = Firestore.collection('Tracking-Facility-Units').onSnapshot(snapshot => {
    const facilityList = [];
    const facilityRef = {};
    const activeList = [];
    snapshot.forEach(doc => {
      const docData = {...doc.data(), FacilityId: doc.id};
      facilityList.push(docData);
      facilityRef[doc.id] = docData;
      if (docData.Active) activeList.push(docData);
    });
    dispatch({type: SET_FACILITIES, payload: {
      list: facilityList.sort((a, b) => a.Name > b.Name ? 1 : -1),
      ref: facilityRef,
      listener: facilityListener,
      active: activeList.sort((a, b) => a.Name > b.Name ? 1 : -1)}});
  });
};

export const acGetTrackingShipments = dispatch => {
  const limitDate = new Date();
  limitDate.setFullYear(limitDate.getFullYear() - 2);
  const listener = Firestore.collection('Tracking-Shipments').where('System.CreatedOn', '>', limitDate).onSnapshot(snapshot => {
    const arrayOfInbounds = [];
    const inboundRef = {};
    snapshot.forEach(doc => {
      const docData = doc.data();
      const formattedInboundData = {
        ...docData,
        id: doc.id,
        ShipmentTime: docData.ShipmentTime.toDate(),
        ScheduledTime: docData.ScheduledTime ? docData.ScheduledTime.toDate() : null,
      };
      inboundRef[doc.id] = formattedInboundData;
      arrayOfInbounds.push(formattedInboundData);
    });
    dispatch({type: SET_SHIPMENTS, payload: {ref: inboundRef, list: arrayOfInbounds, listener}});
  });
};

export const acInventoryListener = dispatch => {
  const inventoryArray = [];
  const inventoryObject = {};
  const listener = Firestore.collection('Tracking-Inventory').where('ContainerRef', '>', '').onSnapshot({includeMetadataChanges: true}, snapshot => {
    snapshot.docChanges().forEach(change => {
      const doc = {...change.doc.data(), id: change.doc.id};
      if (change.type === 'added') {
        inventoryArray.push(doc);
        inventoryObject[doc.id] = doc;
      }
      else if (change.type === 'modified') {
        const inventoryIndex = inventoryArray.findIndex(item => item.id === doc.id);
        inventoryArray[inventoryIndex] = doc;
        inventoryObject[doc.id] = doc;
      }
      else if (change.type === 'removed') {
        const inventoryIndex = inventoryArray.findIndex(item => item.id === doc.id);
        inventoryArray.splice(inventoryIndex, 1);
        delete inventoryObject[doc.id];
      }
    });
    dispatch({type: SET_INVENTORY_ITEMS, payload: {list: inventoryArray, ref: inventoryObject}});
  },
  error => {
    console.log(error);
  });
  dispatch({type: SET_INVENTORY_LISTENER, payload: listener});
};

export const acContainerListener = dispatch => {
  const containerArray = [];
  const containerObject = {};

  const listener = Firestore.collection('Tracking-Containers')
    .where('Active', '==', true)
    .onSnapshot({includeMetadataChanges: true},
      snapshot => {
        snapshot.docChanges().forEach(change => {
          const doc = {...change.doc.data(), ShortNo: change.doc.id};
          if (change.type === 'added') {
            containerArray.push(doc);
            containerObject[doc.ShortNo] = doc;
          }
          else if (change.type === 'modified') {
            const inventoryIndex = containerArray.findIndex(item => item.ShortNo === doc.ShortNo);
            containerArray[inventoryIndex] = doc;
            containerObject[doc.ShortNo] = doc;
          }
          else if (change.type === 'removed') {
            const inventoryIndex = containerArray.findIndex(item => item.ShortNo === doc.ShortNo);
            containerArray.splice(inventoryIndex, 1);
            delete containerObject[doc.ShortNo];
          }
        });
        dispatch({type: SET_CONTAINERS, payload: {list: containerArray, ref: containerObject}});
      },
      error => {
        console.log(error);
      });

  dispatch({type: SET_CONTAINER_LISTENER, payload: listener});
};
export const acGetActiveProcessForms = async dispatch => {
  const processArray = [];
  const processObject = {};
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const sevenDaysAgo = new Date(new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0));
  const listener = Firestore.collection('Tracking-Forms')
    .where('FormType', '==', 'Process')
    .where('Date', '<=', firebase.firestore.Timestamp.fromDate(today))
    .where('Date', '>', firebase.firestore.Timestamp.fromDate(sevenDaysAgo))
    .where('Active', '==', true)
    .onSnapshot({includeMetadataChanges: true}, snap => {
      snap.docChanges().forEach(change => {
        const doc = {...change.doc.data(), id: change.doc.id, Date: change.doc.data().Date.toDate()};
        if (change.type === 'added') {
          processArray.push(doc);
          processObject[doc.id] = doc;
        }
        else if (change.type === 'modified') {
          const processIndex = processArray.findIndex(item => item.id === doc.id);
          processArray[processIndex] = doc;
          processObject[doc.id] = doc;
        }
        else {
          const processIndex = processArray.findIndex(item => item.id === doc.id);
          processArray.splice(processIndex, 1);
          delete processObject[doc.id];
        }
      });
      dispatch({type: SET_PROCESS_FORMS, payload: {list: processArray, ref: processObject}});
    });
  dispatch({type: SET_PROCESS_FORM_LISTENER, payload: listener});
};
export const acGetActiveSortForms = async dispatch => {
  const sortArray = [];
  const sortObject = {};
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const sevenDaysAgo = new Date(new Date(new Date().setDate(today.getDate() - 7)).setHours(0, 0, 0, 0));
  const listener = Firestore.collection('Tracking-Forms')
    .where('FormType', '==', 'Sort')
    .where('Date', '<=', firebase.firestore.Timestamp.fromDate(today))
    .where('Date', '>', firebase.firestore.Timestamp.fromDate(sevenDaysAgo))
    .where('Active', '==', true)
    .onSnapshot({includeMetadataChanges: true}, snap => {
      snap.docChanges().forEach(change => {
        const doc = {...change.doc.data(), id: change.doc.id, Date: change.doc.data().Date.toDate()};
        if (change.type === 'added') {
          sortArray.push(doc);
          sortObject[doc.id] = doc;
        }
        else if (change.type === 'modified') {
          const docIndex = sortArray.findIndex(item => item.id === doc.id);
          sortArray[docIndex] = doc;
          sortObject[doc.id] = doc;
        }
        else {
          const docIndex = sortArray.findIndex(item => item.id === doc.id);
          sortArray.splice(docIndex, 1);
          delete sortObject[doc.id];
        }
      });
      dispatch({type: SET_SORT_FORMS, payload: {list: sortArray, ref: sortObject}});
    });
  dispatch({type: SET_SORT_FORM_LISTENER, payload: listener});
};
export const acGetActiveConsolidationForms = async dispatch => {
  const consolidationArray = [];
  const consolidationObject = {};
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const fourteenDaysAgo = new Date(new Date(new Date().setDate(today.getDate() - 14)).setHours(0, 0, 0, 0));
  const listener = Firestore.collection('Tracking-Forms')
    .where('FormType', '==', 'Consolidation')
    .where('Date', '<=', firebase.firestore.Timestamp.fromDate(today))
    .where('Date', '>', firebase.firestore.Timestamp.fromDate(fourteenDaysAgo))
    .where('Active', '==', true)
    .onSnapshot({includeMetadataChanges: true}, snap => {
      snap.docChanges().forEach(change => {
        const doc = {...change.doc.data(), id: change.doc.id, Date: change.doc.data().Date.toDate()};
        if (change.type === 'added') {
          consolidationArray.push(doc);
          consolidationObject[doc.id] = doc;
        }
        else if (change.type === 'modified') {
          const consolidationIndex = consolidationArray.findIndex(item => item.id === doc.id);
          consolidationArray[consolidationIndex] = doc;
          consolidationObject[doc.id] = doc;
        }
        else {
          const consolidationIndex = consolidationArray.findIndex(item => item.id === doc.id);
          consolidationArray.splice(consolidationIndex, 1);
          delete consolidationObject[doc.id];
        }
      });
      dispatch({type: SET_CONSOLIDATION_FORMS, payload: {list: consolidationArray, ref: consolidationObject}});
    });
  dispatch({type: SET_CONSOLIDATION_FORM_LISTENER, payload: listener});
};

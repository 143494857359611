/* eslint-disable react/display-name */
import React, {useState, Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';
import {TableWithPaper} from 'components/';
import {DeleteForever, Add, ViewCarousel} from '@material-ui/icons';
import {SortInboundModal, DeleteInboundContainerModal, CarouselModal} from './InboundContainerPanel/';

const InboundContainerPanel = props => {
  const [newInboundContainerModalOpen, setNewInboundContainerModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState(null);
  const [carouselModalOpen, setCarouselModalOpen] = useState(false);
  const [selectedInboundContainer, setSelectedInboundContainer] = useState(null);
  const sortDetailsArray = useMemo(() => {
    const arrayOfDetails = [];
    props.inboundContainers.forEach(container => {
      container.SortedInventoryItems.forEach(item => {
        arrayOfDetails.push({...item, LineNo: container.LineNo});
      });
    });
    return arrayOfDetails;
  }, [props.inboundContainers]);

  const handleDelete = (rowData) => {
    setDeleteModalOpen(true);
    setRowToBeDeleted(rowData);
  };

  const core = {
    boldText: {
      style: {fontWeight: 600},
    },
    addInboundButton: {
      variant: 'outlined',
      onClick: () => setNewInboundContainerModalOpen(true),
    },
    paper: {
      style: {paddingTop: '16px', margin: '16px 0px'},
    },
    inboundContainerTable: {
      data: props.inboundContainers,
      columns: [
        {accessor: 'LineNo', Header: 'Line No.', defaultSort: 'asc'},
        {accessor: 'ShortNo', Header: 'Short No.'},
        {accessor: 'OriginalMaterial', Header: 'Material'},
        {accessor: 'OriginalType', Header: 'Type'},
        {accessor: 'StartWeight', Header: 'Start Weight (Net)', type: 'numeric'},
        {accessor: 'RemainingWeight', Header: 'End Weight (Net)', type: 'numeric'},
      ],
      paginationActive: !props.paginationOffForPrint,
      actionsPerRow: [
        {
          icon: DeleteForever,
          tooltip: 'Remove Container from the Form',
          hide: props.selectedSortForm.Completed ? true : false,
          onClick: ({rowData}) => handleDelete(rowData),
        },
        {
          icon: ViewCarousel,
          tooltip: 'Edit Images',
          onClick: ({rowData}) => {
            setCarouselModalOpen(true);
            setSelectedInboundContainer(rowData);
          },
        },
      ],
      actionsBar: [
        {
          icon: Add,
          text: 'Add Inbound Contianer',
          buttonProps: {style: {display: props.selectedSortForm.Completed ? 'none' : 'inline-flex'}},
          onClick: () => setNewInboundContainerModalOpen(true),
        },
      ],
      title: {
        primary: 'Inbound Containers',
        secondary: 'A list of all containers that have been sorted:',
      },
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
    sortDetailsTable: {
      data: sortDetailsArray,
      columns: [
        {accessor: 'LineNo', Header: 'Line No.', defaultSort: 'asc', type: 'numeric'},
        {accessor: 'ProductionContainer', Header: 'Short No.'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'Type.Name', Header: 'Type'},
        {accessor: 'NetWeight', Header: 'Weight (Net)', type: 'numeric'},
      ],
      title: {
        primary: 'Sort Details',
        secondary: 'A list of all items sorted out of inbound containers:',
      },
      paginationActive: !props.paginationOffForPrint,
    },
    sortInboundModal: {
      selectedSortForm: props.selectedSortForm,
      containersInCurrentFacilityUnit: props.containersInCurrentFacilityUnit,
      close: () => setNewInboundContainerModalOpen(false),
    },
    carouselModal: {
      selectedInboundContainer,
      selectedSortForm: props.selectedSortForm,
      close: () => {
        setSelectedInboundContainer(null);
        setCarouselModalOpen(false);
      },
    },
    deleteModal: {
      rowToBeDeleted,
      selectedSortForm: props.selectedSortForm,
      close: () => {
        setRowToBeDeleted(null);
        setDeleteModalOpen(false);
      },
    },
  };

  return (
    <Fragment>
      {props.inboundContainers.length ? (
        <Fragment>
          <TableWithPaper {...core.inboundContainerTable} />
          <TableWithPaper {...core.sortDetailsTable} />
        </Fragment>) :
        <Paper style={{padding: '16px', margin: '16px 0px'}}>
          <Grid container justify='flex-end'>
            <Grid item xs={12}>
              <Typography variant='h6' paragraph>Inbound Containers</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Add an inbound container to begin sorting.</Typography>
            </Grid>
            <Grid item>
              <Button {...core.addInboundButton}>Add Inbound Container</Button>
            </Grid>
          </Grid>
        </Paper>}
      {newInboundContainerModalOpen && <SortInboundModal {...core.sortInboundModal} />}
      {deleteModalOpen && <DeleteInboundContainerModal {...core.deleteModal} />}
      {carouselModalOpen && <CarouselModal {...core.carouselModal} />}
    </Fragment>
  );
};

InboundContainerPanel.propTypes = {
  inboundContainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  containersInCurrentFacilityUnit: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSortForm: PropTypes.object.isRequired,
  paginationOffForPrint: PropTypes.bool.isRequired,
};

export default InboundContainerPanel;

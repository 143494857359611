/* eslint-disable guard-for-in */
import React, {Fragment} from 'react';
import {Grid, Box} from '@material-ui/core';
import PropTypes from 'prop-types';


class InvoiceSummary extends React.Component {
  determineRowTotal(row) {
    let totalOfRow = row.Weight * parseFloat(row.Price);
    if (isNaN(totalOfRow)) totalOfRow = 'N/A';
    else totalOfRow = '$' + (totalOfRow).toFixed(2);
    if (row.BillType === 'Payment') totalOfRow = `(${totalOfRow})`;
    return totalOfRow;
  }
  determineInvoiceTotal() {
    const {selectedInvoice} = this.props;
    let total = 0;
    for (const typeId in selectedInvoice.Summary) {
      for (const flag in selectedInvoice.Summary[typeId]) {
        if (selectedInvoice.Summary[typeId][flag].Price !== 'N/A') {
          if (selectedInvoice.Summary[typeId][flag].BillType !== 'Payment') {
            total += selectedInvoice.Summary[typeId][flag].Weight * parseFloat(selectedInvoice.Summary[typeId][flag].Price);
          }
          else total -= selectedInvoice.Summary[typeId][flag].Weight * parseFloat(selectedInvoice.Summary[typeId][flag].Price);
        }
      }
    }
    if (selectedInvoice.AdditionalCharges) {
      selectedInvoice.AdditionalCharges.forEach(addCharge => {
        if (addCharge.BillType !== 'Payment') {
          total += parseFloat(addCharge.Price);
        }
        else total -= parseFloat(addCharge.Price);
      });
    }
    return total.toFixed(2) > 0 ? `$${total.toFixed(2)}` : `($${Math.abs(total).toFixed(2)})`;
  }
  determineTotalWeight() {
    const {selectedInvoice} = this.props;
    let total = 0;

    for (const typeId in selectedInvoice.Summary) {
      for (const flag in selectedInvoice.Summary[typeId]) {
        total += selectedInvoice.Summary[typeId][flag].Weight;
      }
    }
    return total;
  }
  createTableUsingData() {
    const {classes, StyledTypography} = this.props;
    return Object.values(this.props.selectedInvoice.Summary)
      .map((typeForTable, typeIndex) => {
        return Object.values(typeForTable)
          .sort((a, b) => a.Material.Name > b.Material.Name ? 1 : -1)
          .map((flagForTable, flagIndex) => (
            <Grid container className={classes.avoidPageBreak} key={(typeIndex + 1) * (flagIndex + 1)}>
              <Box width={'30%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                <StyledTypography variant='body2'>{flagForTable.Material.Name}</StyledTypography>
              </Box>
              <Box width={'26%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                <StyledTypography variant='body2'>{flagForTable.Type.Name}{flagForTable.Flag && ` - ${flagForTable.Flag}`}</StyledTypography>
              </Box>
              <Box width={'11.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                <StyledTypography variant='body2'>{flagForTable.BillType}</StyledTypography>
              </Box>
              <Box width={'12%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                <StyledTypography variant='body2'>{flagForTable.Weight}</StyledTypography>
              </Box>
              <Box width={'8.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                <StyledTypography variant='body2'>{flagForTable.Price === 'N/A' ? flagForTable.Price : `$${flagForTable.Price}`}</StyledTypography>
              </Box>
              <Box width={'12%'} className={classes.borderBottom}>
                <StyledTypography variant='body2'>{this.determineRowTotal(flagForTable)}
                </StyledTypography>
              </Box>
            </Grid>
          ));
      });
  }
  createAdditionalChargeTable() {
    const {classes, StyledTypography} = this.props;
    return this.props.selectedInvoice.AdditionalCharges
      .map((additionalCharge, index) => {
        return (
          <Grid container className={classes.avoidPageBreak} key={index}>
            <Box width={'76%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2'>{additionalCharge.Description}</StyledTypography>
            </Box>
            <Box width={'12%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2'>{additionalCharge.BillType}</StyledTypography>
            </Box>
            <Box width={'12%'} className={classes.borderBottom}>
              <StyledTypography variant='body2'>{additionalCharge.BillType === 'Payment' ? `($${additionalCharge.Price})` : `$${additionalCharge.Price}`}
              </StyledTypography>
            </Box>
          </Grid>
        );
      });
  }
  render() {
    const {classes, StyledTypography, selectedInvoice} = this.props;

    return (
      <Fragment>
        <Grid container className={`${classes.borderTop} ${classes.borderLeft} ${classes.newSection} ${classes.borderRight} ${
          classes.textChildrenPadding
        }`}
        >
          <Grid item xs={12}>
            <StyledTypography variant='subtitle2' className={`${classes.bold} ${classes.titleSection}`}>
              Invoice Summary
            </StyledTypography>
          </Grid>
          <Grid container className={classes.tableHeader}>
            <Box width={'30%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Material
              </StyledTypography>
            </Box>
            <Box width={'26%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Type
              </StyledTypography>
            </Box>
            <Box width={'11.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Bill Type
              </StyledTypography>
            </Box>
            <Box width={'12%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Weight (lbs)
              </StyledTypography>
            </Box>
            <Box width={'8.5%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
              <StyledTypography variant='body2' className={classes.bold}>
                Amount
              </StyledTypography>
            </Box>
            <Box width={'12%'} className={classes.borderBottom}>
              <StyledTypography variant='body2' className={classes.bold}>
                Total
              </StyledTypography>
            </Box>
          </Grid>
          {this.createTableUsingData()}
          {selectedInvoice.AdditionalCharges && selectedInvoice.AdditionalCharges.length ? (
            <Fragment>
              <Grid container className={classes.tableHeader}>
                <Box width={'76%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                  <StyledTypography variant='body2' className={classes.bold}>
                    Additional Fees
                  </StyledTypography>
                </Box>
                <Box width={'12%'} className={`${classes.borderBottom} ${classes.borderRight}`}>
                  <StyledTypography variant='body2' className={classes.bold}>
                    Bill Type
                  </StyledTypography>
                </Box>
                <Box width={'12%'} className={classes.borderBottom}>
                  <StyledTypography variant='body2' className={classes.bold}>
                    Total
                  </StyledTypography>
                </Box>
              </Grid>
              {this.createAdditionalChargeTable()}
            </Fragment>
          ) : null}
        </Grid>
        <Grid container>
          <Grid className={`${classes.borders} ${classes.borderLeft} ${classes.newSection}`} item xs={4}>
            <StyledTypography variant='subtitle2' className={`${classes.centerText} ${classes.bold}`}>
              Thank You for Your Business!
            </StyledTypography>
          </Grid>
          <Grid className={`${classes.borders} ${classes.newSection}`} item xs={4}>
            <StyledTypography variant='subtitle2' className={`${classes.centerText} ${classes.bold}`}>
              Total Weight: {this.determineTotalWeight()} lbs
            </StyledTypography>
          </Grid>
          <Grid className={`${classes.borders} ${classes.newSection}`} item xs={4}>
            <StyledTypography variant='subtitle2' className={`${classes.centerText} ${classes.bold}`}>
              Total Price: {this.determineInvoiceTotal()}
            </StyledTypography>
          </Grid>
          <Grid item xs={12} className={classes.newSection}>
            <StyledTypography variant='subtitle2' className={`${classes.centerText} ${classes.bold}`}>
              Details of materials shipped and sorted can be found on subsequent pages.
            </StyledTypography>
            <StyledTypography variant='subtitle2' className={`${classes.centerText} ${classes.bold}`}>
              If you have any questions about this invoice, please contact your sales representative.
            </StyledTypography>
          </Grid>
        </Grid>
      </Fragment>);
  }
}

InvoiceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
  selectedInvoice: PropTypes.object.isRequired,
};

export default InvoiceSummary
;

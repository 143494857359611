import React, {Component, Fragment} from 'react';
import {ViewTypeModal} from './Types';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Accordion, AccordionSummary, AccordionDetails, LinearProgress} from '@material-ui/core';
import {Typography, Button, Grid} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Edit} from '@material-ui/icons';

class TypePanel extends Component {
  state = {
    types: null,
    newTypeModalOpen: false,
    editType: false,
    typeId: null,
    expanded: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.types !== prevProps.types || (this.props.types && !this.state.types)) {
      const types = this.props.types.map(name => {
        return {
          ...name,
          System: {...name.System, CreatedOn: moment(name.System.CreatedOn.seconds * 1000).format('MM/DD/YYYY')},
        };
      });
      this.setState({types});
    }
  }
  types = core => {
    if (!this.state.types) {
      return <LinearProgress />;
    }
    else if (this.state.types.length === 0) {
      return (
        <AccordionDetails>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>No types found for this material.</Typography>
            <Button {...core.emptyTypesButton}>Add Type</Button>
          </Grid>
        </AccordionDetails>
      );
    }
    else {
      return (
        <AccordionDetails style={{padding: '0px'}}>
          <Grid container>
            <Grid item xs={12}>
              <Table {...core.typeTable} />
            </Grid>
          </Grid>

        </AccordionDetails>
      );
    }
  };
  openViewTypeModal = core => {
    if (this.state.newTypeModalOpen) {
      return <ViewTypeModal {...core.newTypeModal} />;
    }
    else return null;
  };
  render() {
    const {types, newTypeModalOpen, expanded} = this.state;
    const {material} = this.props;
    const core = {
      typeTable: {
        columns: [
          {accessor: 'Active', Header: 'Active', type: 'boolean'},
          {accessor: 'TypeName', Header: 'Type Name'},
          {accessor: 'TypeNameShort', Header: 'Short Name'},
          {accessor: 'Price', Header: 'Price'},
          {accessor: 'id', Header: 'Doc Id'},
        ],
        sortInitial: [{id: 'TypeName', desc: false}],
        data: types || [],
        options: {showTitle: false},
        actionsPerRow: [
          {
            icon: Edit,
            tooltip: 'Edit Type',
            onClick: ({rowData}) =>
              this.setState({
                typeId: rowData.id,
                editTypeName: rowData.TypeName,
                editTypeNameShort: rowData.TypeNameShort,
                editTypePrice: rowData.Price,
                editWasteCodes: rowData.WasteCodes,
                editActive: rowData.Active,
                editHandlingCode: rowData.HandlingCode,
                editBillType: rowData.BillType,
                editType: true,
                newTypeModalOpen: true,
                editShowOnProfile: rowData.ShowOnProfile,
              }),
          },
        ],
      },
      newTypeModal: {
        newTypeModalOpen,
        close: () => this.setState({
          newTypeModalOpen: false,
          editType: false,
          editTypeName: '',
          editTypeNameShort: '',
          editTypePrice: '',
          editWasteCodes: '',
          editActive: '',
          editHandlingCode: '',
          editBillType: '',
          editShowOnProfile: '',
        }),
        material,
        edit: this.state.editType,
        name: this.state.editTypeName,
        nameShort: this.state.editTypeNameShort,
        price: this.state.editTypePrice,
        active: this.state.editActive,
        id: this.state.typeId,
        codes: this.state.editWasteCodes,
        handlingCode: this.state.editHandlingCode,
        billType: this.state.editBillType,
        showOnProfile: this.state.editShowOnProfile,
      },
      emptyTypesButton: {
        onClick: (e) => {
          e.stopPropagation();
          this.setState({newTypeModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    return (
      <Fragment>
        <Accordion expanded={expanded} onChange={() => this.setState({expanded: !this.state.expanded})}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container justify='space-between'>
              <div>
                <Typography>Types</Typography>
              </div>
              {expanded ? (
                <div>
                  <Button {...core.emptyTypesButton}>
                    Add Type
                  </Button>
                </div>
              ) : null}
            </Grid>
          </AccordionSummary>
          {this.types(core)}
        </Accordion>
        {this.openViewTypeModal(core)}
      </Fragment>
    );
  }
}

TypePanel.propTypes = {
  types: PropTypes.arrayOf(PropTypes.object),
  material: PropTypes.object,
};
export default TypePanel;

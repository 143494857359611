import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';

// Import Tracking Reducers
import AuthReducers from './auth/reducers';
import FirestoreReducers from './firestore/reducers';

export const store = createStore(
  combineReducers({
    auth: AuthReducers,
    firestore: FirestoreReducers,
  }),
  applyMiddleware(ReduxThunk),
);

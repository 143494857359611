import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetAllPurchaseOrders} from 'state/firestore/actions.js';

const usePurchaseOrders = () => {
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();
  const purchaseOrders = useSelector(state => state.firestore.purchaseOrders);

  useEffect(() => {
    if (!purchaseOrders && !touched) {
      setTouched(true);
      setTimeout(() => dispatch(acGetAllPurchaseOrders), 5000 * Math.random());
    }
  }, [dispatch, purchaseOrders, touched]);

  return purchaseOrders;
};

export default usePurchaseOrders;

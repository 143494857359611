import React, {useReducer, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
  DialogContentText,
  TextField,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

const cleanState = {
  heavyWeight: {
    value: '',
    error: null,
    display: 'Heavy Weight',
  },
  lightWeight: {
    value: '',
    error: null,
    display: 'Light Weight',
  },
  ticketNumber: {
    value: '',
    error: null,
    display: 'Weight Ticket #',
  },
  submitting: false,
  stage: 0,
  formError: '',
};
const stageArray = ['basic', 'success'];

function reducer(state, action) {
  // action will contain a type, and a payload.
  // if the action is for a form field, the action will also contain a field property, to direct which form field the value/error goes to.
  switch (action.type) {
  case 'value':
    return {...state, [action.field]: {...state[action.field], value: action.payload}};
  case 'error':
    return {...state, [action.field]: {...state[action.field], error: action.payload}};
  case 'stage':
    return {...state, stage: action.payload};
  case 'submitting':
    return {...state, submitting: action.payload};
  case 'formError':
    return {...state, formError: action.payload};
  default:
    throw new Error();
  }
}

const WeightTicketModal = props => {
  const {open, selectedWeightTicket} = props;
  const [state, dispatch] = useReducer(reducer, cleanState);
  useEffect(() => {
    if (selectedWeightTicket) {
      dispatch({type: 'value', field: 'lightWeight', payload: selectedWeightTicket.LightWeight});
      dispatch({type: 'value', field: 'heavyWeight', payload: selectedWeightTicket.HeavyWeight});
      dispatch({type: 'value', field: 'ticketNumber', payload: selectedWeightTicket.TicketNumber});
    }
  }, [selectedWeightTicket]);

  const handleSubmit = () => {
    if (validateForm()) {
      dispatch({type: 'submitting', payload: true});
      sendDataToFirestore()
        .then(() => {
          dispatch({type: 'formError', payload: ''});
          dispatch({type: 'submitting', payload: false});
          dispatch({type: 'stage', payload: state.stage + 1});
        })
        .catch(error => {
          dispatch({type: 'formError', payload: 'Error during save. Please try again'});
          dispatch({type: 'submitting', payload: false});
        });
    }
  };
  const sendDataToFirestore = () => {
    // returns a promise, either a transaction, or a simple update, both .then-able
    if (selectedWeightTicket) {
      // if the user is editing a weight ticket, as opposed to creating a new one
      return Firestore.collection('Tracking-Shipments')
        .doc(props.selectedInbound.id)
        .collection('Weight-Tickets')
        .doc(selectedWeightTicket.id)
        .update({
          'HeavyWeight': parseInt(state.heavyWeight.value),
          'LightWeight': state.lightWeight.value ? parseInt(state.lightWeight.value) : '',
          'GrossWeight': state.lightWeight.value ?
            parseInt(state.heavyWeight.value) - parseInt(state.lightWeight.value) :
            null,
          'TicketNumber': state.ticketNumber.value,
          'System.UpdatedOn': firebase.firestore.Timestamp.now(),
          'System.UpdatedBy': props.currentUser.displayName,
        });
    }
    else {
      // if a new ticket is being created
      return Firestore.collection('Tracking-Shipments')
        .doc(props.selectedInbound.id)
        .collection('Weight-Tickets')
        .doc()
        .set({
          HeavyWeight: parseInt(state.heavyWeight.value),
          LightWeight: state.lightWeight.value ? parseInt(state.lightWeight.value) : null,
          GrossWeight: state.lightWeight.value ?
            parseInt(state.heavyWeight.value) - parseInt(state.lightWeight.value) :
            null,
          TicketNumber: state.ticketNumber.value,
          System: {
            CreatedBy: props.currentUser.displayName,
            CreatedOn: firebase.firestore.Timestamp.now(),
            UpdatedOn: firebase.firestore.Timestamp.now(),
            UpdatedBy: props.currentUser.displayName,
          },
        });
    }
  };
  const validateForm = () => {
    let dataError = false;

    if (!state.heavyWeight.value) {
      dataError = true;
      dispatch({type: 'error', field: 'heavyWeight', payload: 'Heavy Weight is a required field.'});
    }
    else if (parseInt(state.heavyWeight.value) <= parseInt(state.lightWeight.value)) {
      dataError = true;
      dispatch({type: 'error', field: 'heavyWeight', payload: 'Heavy Weight must be greater than Light Weight.'});
    }
    if (!state.ticketNumber.value) {
      dataError = true;
      dispatch({type: 'error', field: 'ticketNumber', payload: 'Ticket Number is a required field.'});
    }
    // if there is an error in the form, validateForm will return false, failing validation. otherwise the function will return true
    return !dataError;
  };
  const createButtons = () => {
    if (stageArray[state.stage] === 'success') {
      return (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      );
    }
    else {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>
      );
    }
  };
  const core = {
    dialog: {
      open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    cancelButton: {
      color: 'secondary',
      onClick: props.close,
      disabled: state.submitting,
    },
    submitButton: {
      text: 'Submit',
      loading: state.submitting,
      onClick: handleSubmit,
      color: 'primary',
    },
    submitConfirmation: {
      stage: stageArray[state.stage],
      text: selectedWeightTicket ? 'Weight ticket successfully edited.' : 'New weight ticket successfully added.',
    },
    closeButton: {
      onClick: props.close,
      color: 'primary',
    },
    weightField: {
      InputProps: {
        endAdornment: <InputAdornment position='end'>lbs</InputAdornment>,
      },
    },
    textField: key => {
      return {
        error: state[key].error ? true : false,
        fullWidth: true,
        helperText: state[key].error,
        id: key,
        label: state[key].display,
        margin: 'normal',
        value: state[key].value,
        type: 'number',
        onChange: e => {
          dispatch({type: 'value', field: key, payload: e.target.value});
          dispatch({type: 'error', field: key, payload: null});
        },
      };
    },
  };
  return (
    <Dialog {...core.dialog}>
      {stageArray[state.stage] !== 'success' && (
        <DialogTitle>{selectedWeightTicket ? 'Edit Weight Ticket' : 'Add New Weight Ticket'}</DialogTitle>
      )}
      <DialogContent>
        <Collapse in={stageArray[state.stage] === 'basic'}>
          <DialogContentText>Enter weight ticket information:</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField required {...core.textField('ticketNumber')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField required {...core.weightField} {...core.textField('heavyWeight')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.weightField} {...core.textField('lightWeight')} />
            </Grid>
          </Grid>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(state.formError)} text={state.formError} severity='error' />
      </DialogContent>
      {createButtons()}
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};
WeightTicketModal.propTypes = {
  selectedInbound: PropTypes.object.isRequired,
  selectedWeightTicket: PropTypes.object,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(WeightTicketModal);

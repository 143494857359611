/* eslint-disable guard-for-in */
import React from 'react';
import {Grid, Box, LinearProgress} from '@material-ui/core';
import PropTypes from 'prop-types';

class InvoiceSummary extends React.Component {
  generateTotal(piece, pieceSummary) {
    let returnedTotal = `${(piece.Weight * pieceSummary.Price).toFixed(2)}`;
    if (pieceSummary.Price === 'N/A') returnedTotal = 'N/A';
    else if (pieceSummary.BillType === 'Payment') returnedTotal = `($${returnedTotal})`;
    else returnedTotal = `$${returnedTotal}`;
    return returnedTotal;
  }
  createInventoryItemsForPieceSection(pieceObject) {
    const {StyledTypography, selectedInvoice} = this.props;
    const arrayOfItemDetails = [];
    for (const key in pieceObject) {
      if (key !== 'ShippedMaterial' && key !== 'id' && key !== 'Notes') {
        for (const flag in pieceObject[key]) {
          arrayOfItemDetails.push(
            <Grid item xs={11} container
              key={key + flag}
            >
              <Box width={'32.5%'}>
                <StyledTypography variant='subtitle2'>
                  {pieceObject[key][flag].Material.Name}
                </StyledTypography>
              </Box>
              <Box width={'17%'}>
                <StyledTypography variant='subtitle2'>
                  {pieceObject[key][flag].Type.Name}
                </StyledTypography>
              </Box>
              <Box width={'10.5%'}>
                <StyledTypography variant='subtitle2'>
                  {pieceObject[key][flag].Flag}
                </StyledTypography>
              </Box>
              <Box width={'12.5%'}>
                <StyledTypography variant='subtitle2'>
                  {selectedInvoice.Summary[key][flag]?.BillType || selectedInvoice.Summary[key].none?.BillType}
                </StyledTypography>
              </Box>
              <Box width={'10%'}>
                <StyledTypography variant='subtitle2'>
                  {pieceObject[key][flag].Weight}
                </StyledTypography>
              </Box>
              <Box width={'7.5%'}>
                <StyledTypography variant='subtitle2'>
                  {`$${selectedInvoice.Summary[key][flag]?.Price || selectedInvoice.Summary[key].none?.Price}`}
                </StyledTypography>
              </Box>
              <Box width={'10%'}>
                <StyledTypography variant='subtitle2'>
                  {this.generateTotal(pieceObject[key][flag], selectedInvoice.Summary[key][flag] ? selectedInvoice.Summary[key][flag] : selectedInvoice.Summary[key].none)}
                </StyledTypography>
              </Box>
            </Grid>,
          );
        }
      }
    }
    return arrayOfItemDetails;
  }
  createPieceNumberSection(pieceObject) {
    const {StyledTypography, classes} = this.props;
    return (
      <Grid key={pieceObject.id} container justify='space-around'
        className={`${classes.borders} ${classes.borderLeft} ${classes.newSection} ${classes.avoidPrint}`}
      >
        <Grid item xs={12}>
          <StyledTypography variant='subtitle2'>
            <span className={classes.bold}>Piece No. {pieceObject.id} Shipped Material: </span>{pieceObject.ShippedMaterial.Name}
          </StyledTypography>
        </Grid>
        <Grid item xs={11} container
          className={classes.borderBottom}
        >
          <Box width={'32.5%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Sorted Material
            </StyledTypography>
          </Box>
          <Box width={'17%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Type
            </StyledTypography>
          </Box>
          <Box width={'10.5%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Flag
            </StyledTypography>
          </Box>
          <Box width={'12.5%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Bill Type
            </StyledTypography>
          </Box>
          <Box width={'10%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Weight
            </StyledTypography>
          </Box>
          <Box width={'7.5%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Amount
            </StyledTypography>
          </Box>
          <Box width={'10%'}>
            <StyledTypography variant='subtitle2' className={classes.italic}>
              Total
            </StyledTypography>
          </Box>
        </Grid>
        {this.createInventoryItemsForPieceSection(pieceObject)}
      </Grid>);
  }
  render() {
    const {classes, StyledTypography, pieces} = this.props;

    if (!pieces) return <LinearProgress />;

    return (
      <div className='newPage'>
        <StyledTypography className={classes.bold}>Invoice Details</StyledTypography>
        {pieces.map(piece => this.createPieceNumberSection(piece))}
      </div>);
  }
}

InvoiceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  pieces: PropTypes.arrayOf(PropTypes.object),
  StyledTypography: PropTypes.object.isRequired,
  selectedInvoice: PropTypes.object.isRequired,
};

export default InvoiceSummary
;

import React, {Fragment} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Formik, Collapse, Alert} from '@kbi/component-library';
import {SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import {connect} from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
const {FormikForm, TextField, AutoCompleteObject, DateField, WeightField, SubmitButton, FormButton, validateAutoObject} = Formik;
validateAutoObject();

class AdminInventoryModal extends React.Component {
  state = {
    formError: '',
    stage: 0,
    shippingDocs: null,
    selectableCACodes: [],
    selectableRCRACodes: [],
    defaultFormik: this.props.selectedInventoryItem ? {
      accountRef: this.props.accounts[this.props.selectedInventoryItem.AccountRef],
      startDate: moment(this.props.selectedInventoryItem.AccumulationStartDate.toDate()).format('YYYY-MM-DD'),
      billable: {value: this.props.selectedInventoryItem.Billable, display: this.props.selectedInventoryItem.Billable ? 'True' : 'False'},
      classification: {value: this.props.selectedInventoryItem.Classification},
      incomingPieceNumber: this.props.selectedInventoryItem.IncomingPieceNo || '',
      manifested: {value: this.props.selectedInventoryItem.Manifested, display: this.props.selectedInventoryItem.Manifested ? 'True' : 'False'},
      material: this.props.materials.ref[this.props.selectedInventoryItem.Material.Ref] || '',
      purchaseOrderRef: this.props.purchaseOrders[this.props.selectedInventoryItem.AccountRef] ?
        this.props.purchaseOrders[this.props.selectedInventoryItem.AccountRef].find(po => po.id === this.props.selectedInventoryItem.PurchaseOrderRef) : '',
      shipmentInRef: this.props.trackingShipments.list.find(shipment => this.props.selectedInventoryItem.ShipmentInRef === shipment.id) || '',
      source: {value: this.props.selectedInventoryItem.Source},
      type: this.props.types[this.props.selectedInventoryItem.Material.Ref] ?
        this.props.types[this.props.selectedInventoryItem.Material.Ref].find(type => type.id === this.props.selectedInventoryItem.Type.Ref) : '',
      weight: this.props.selectedInventoryItem.Weight || '',
      flag: this.props.materials.ref[this.props.selectedInventoryItem.Material.Ref].Flags
        .find(flag => flag.Name === this.props.selectedInventoryItem.Flag) || '',
      rcraCodes: this.props.selectedInventoryItem.RCRACodes.map(code => ({value: code})),
      caCodes: this.props.selectedInventoryItem.CACodes.map(code => ({value: code})),
    } : {
      accountRef: '',
      startDate: '',
      billable: '',
      classification: '',
      incomingPieceNumber: '',
      manifested: '',
      material: '',
      purchaseOrderRef: '',
      shipmentInRef: '',
      shippingDocRef: '',
      source: '',
      type: '',
      weight: '',
      flag: '',
      rcraCodes: [],
      caCodes: [],
    },
  }
  validationSchema = yup.object().shape({
    accountRef: yup.object().exists('Account is a required field.'),
    startDate: yup.string().required('Start Date is a required field.'),
    billable: yup.object().exists('Billable is a required field.'),
    manifested: yup.object().exists('Manifested is a required field.'),
    material: yup.object().exists('Material is a required field.'),
    source: yup.object().exists('Source is a required field.'),
    weight: yup.number().required('Weight is a required field.'),
  })
  componentDidMount() {
    Firestore.collectionGroup('Shipping-Documents').get().then((snapshot) => {
      const shippingDocArray = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      const objectForState = {shippingDocs: shippingDocArray};
      if (this.props.selectedInventoryItem) {
        objectForState.defaultFormik = {...this.state.defaultFormik, shippingDocRef: shippingDocArray
          .find(doc => doc.id === this.props.selectedInventoryItem.ShippingDocRef) || ''};
      }
      this.setState(objectForState);
    });
    if (this.props.selectedInventoryItem) this.createCodeOptions(this.props.materials.ref[this.props.selectedInventoryItem.Material.Ref]);
  }
  createCodeOptions(material) {
    if (material) {
      this.setState({selectableRCRACodes: material.WasteCodes.RCRA
        .map(code => ({value: code})), selectableCACodes: material.WasteCodes.CA.map(code => ({value: code}))});
    }
    else {
      this.setState({selectableRCRACodes: [], selectableCACodes: []});
    }
  }
  handleSubmit(values, actions) {
    const firestoreBatch = Firestore.batch();
    const inventoryRef = this.props.selectedInventoryItem ?
      Firestore.collection('Tracking-Inventory').doc(this.props.selectedInventoryItem.id) : Firestore.collection('Tracking-Inventory').doc();
    const containerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedContainer.ShortNo);
    if (this.props.selectedInventoryItem) {
      firestoreBatch.update(inventoryRef, {
        ...this.createInventoryData(values),
        RCRACodes: values.classification && values.classification.value === 'Hazardous Waste' && values.manifested && values.manifested.value ?
          values.rcraCodes.map(code => code.value) : this.props.selectedInventoryItem.RCRACodes,
        CACodes: values.classification && values.classification.value === 'Hazardous Waste' && values.manifested && values.manifested.value ?
          values.caCodes.map(code => code.value) : this.props.selectedInventoryItem.CACodes,
      });
    }
    else {
      firestoreBatch.set(inventoryRef, {
        ...this.createInventoryData(values),
        RCRACodes: values.classification && values.classification.value === 'Hazardous Waste' && values.manifested && values.manifested.value ?
          values.rcraCodes.map(code => code.value) : [],
        CACodes: values.classification && values.classification.value === 'Hazardous Waste' && values.manifested && values.manifested.value ?
          values.caCodes.map(code => code.value) : [],
        ContainerRef: this.props.selectedContainer.ShortNo,
        FormHistory: [],
        ShipmentOutRef: null,
      });
    }
    firestoreBatch.update(containerRef, {
      NetWeight: this.props.selectedContainer.NetWeight - (this.props.selectedInventoryItem ?
        this.props.selectedInventoryItem.Weight : 0) + parseInt(values.weight),
      InventoryItems: this.props.selectedInventoryItem ?
        this.props.selectedContainer.InventoryItems : [...this.props.selectedContainer.InventoryItems, inventoryRef.id],
      AccumulationStartDate: moment(this.props.selectedContainer.AccumulationStartDate).isAfter(moment(new Date(values.startDate))) ?
        new Date(values.startDate) : this.props.selectedContainer.AccumulationStartDate,
    });
    firestoreBatch.commit().then(() => {
      actions.setSubmitting(false);
      this.setState({stage: this.state.stage + 1});
    }).catch(error => {
      this.setState({formError: 'There was an error during submission. Please try again.'});
      actions.setSubmitting(false);
    });
  }
  createInventoryData(values) {
    return {
      AccountRef: values.accountRef.AccountId || '',
      AccumulationStartDate: new Date(values.startDate) || new Date(),
      Billable: values.billable.value,
      Classification: values.classification.value || '',
      IncomingPieceNo: parseInt(values.incomingPieceNumber),
      Manifested: values.manifested.value || '',
      Material: values.material ? {
        Name: values.material.UnitDetails.MaterialName,
        Ref: values.material.MaterialId,
      } : {},
      Type: values.type ? {
        Name: values.type.TypeName,
        Ref: values.type.id,
      } : {},
      Flag: values.flag.Name || '',
      PurchaseOrderRef: values.purchaseOrderRef?.id || '',
      ShipmentInRef: values.shipmentInRef?.id || '',
      ShippingDocRef: values.shippingDocRef?.id || '',
      Source: values.source.value || '',
      Weight: parseInt(values.weight),
    };
  }
  render() {
    if (!this.state.shippingDocs) return null;
    const core = {
      dialog: {
        open: true,
        maxWidth: 'md',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      cancelButton: {
        onClick: this.props.close,
        color: 'secondary',
        variant: 'text',
      },
      closeButton: {
        onClick: this.props.close,
        color: 'primary',
      },
      submitButton: {
        color: 'primary',
        variant: 'text',
      },
      submitConfirmation: {
        text: this.props.selectedInventoryItem ? 'Item successfully updated.' : 'Item successfully created',
        stage: this.state.stage === 1 ? 'success' : 'not success',
      },
      accountAutoField: {
        name: 'accountRef',
        label: 'Account Name',
        options: Object.values(this.props.accounts),
        optionKey: 'AccountName',
        onChange: ({form, field}) => {
          form.setFieldValue('purchaseOrderRef', '');
        },
      },
      materialAutoField: {
        name: 'material',
        label: 'Material',
        options: Object.values(this.props.materials.ref),
        optionKey: 'UnitDetails.MaterialName',
        onChange: ({form, field}) => {
          form.setFieldValue('type', '');
          form.setFieldValue('flag', '');
          form.setFieldValue('rcraCodes', []);
          form.setFieldValue('caCodes', []);
          this.createCodeOptions(field.value);
        },
      },
      typeAutocomplete: (formikProps) => ({
        name: 'type',
        label: 'Type',
        loading: !formikProps.values.material,
        loadingText: 'Please select a material to populate options',
        noOptionsText: 'This material has no types',
        options: formikProps.values.material && this.props.types[formikProps.values.material.MaterialId] ?
          this.props.types[formikProps.values.material.MaterialId].filter(type => type.Active) : [],
        optionKey: 'TypeName',
      }),
      flagAutoComplete: (formikProps) => ({
        name: 'flag',
        label: 'Flag',
        loading: !formikProps.values.material,
        loadingText: 'Please select a material to populate options',
        noOptionsText: 'This material has no flags',
        options: formikProps.values.material ? formikProps.values.material.Flags : [],
        optionKey: 'Name',
      }),
      purchaseOrderField: formikProps => ({
        name: 'purchaseOrderRef',
        label: 'Purchase Order Number',
        loading: !formikProps.values.accountRef,
        loadingText: 'Please select an account to populate options',
        noOptionsText: 'This account has no PO',
        options: formikProps.values.accountRef && this.props.purchaseOrders[formikProps.values.accountRef.AccountId] ?
          this.props.purchaseOrders[formikProps.values.accountRef.AccountId] : [],
        optionKey: 'OrderNumber',
      }),
      shipmentInField: {
        name: 'shipmentInRef',
        label: 'Inbound Document Number',
        options: this.props.trackingShipments.list,
        optionKey: 'id',
      },
      shippingDocField: {
        name: 'shippingDocRef',
        label: 'Shipping Document Number',
        options: this.state.shippingDocs || [],
        optionKey: 'DocumentNumber',
        loading: !this.state.shippingDocs,
      },
      rcraCodesField: formikProps => ({
        name: 'rcraCodes',
        label: 'RCRA Codes',
        options: this.state.selectableRCRACodes,
        multiple: true,
        filterSelectedOptions: true,
        loading: !formikProps.values.material,
        loadingText: 'Please select a material to populate options',
        autoHighlight: false,
        autoSelect: false,
        placeholder: 'Select all that apply',
      }),
      caCodesField: formikProps => ({
        name: 'caCodes',
        label: 'CA Codes',
        options: this.state.selectableCACodes,
        multiple: true,
        filterSelectedOptions: true,
        loading: !formikProps.values.material,
        loadingText: 'Please select a material to populate options',
        autoHighlight: false,
        autoSelect: false,
        placeholder: 'Select all that apply',
      }),
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm initialValues={this.state.defaultFormik} onSubmit={this.handleSubmit.bind(this)} validationSchema={this.validationSchema} >
          {formikProps => (
            <Fragment>
              {this.state.stage === 0 && <DialogTitle>Admin Inventory {this.props.selectedInventoryItem ? 'Edit' : 'Add'}</DialogTitle>}
              <DialogContent>
                <Collapse in={this.state.stage === 0}>
                  <DialogContentText>Enter all data for the inventory item:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.accountAutoField} fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <DateField name='startDate' label='Start Date' fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject name='billable' label='Billable?' optionKey='display' fast
                        options={[{value: true, display: 'True'}, {value: false, display: 'False'}]}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject name='classification' label='Classification' fast
                        options={[{value: 'Universal Waste'}, {value: 'Hazardous Waste'}, {value: 'N/A'}, {value: 'Exempt per 49 CFR 173.159'}]}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField name='incomingPieceNumber' label='Incoming Piece Number' fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject name='manifested' label='Manifested?' optionKey='display' fast
                        options={[{value: true, display: 'True'}, {value: false, display: 'False'}]}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.materialAutoField} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.typeAutocomplete(formikProps)} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.flagAutoComplete(formikProps)} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.purchaseOrderField(formikProps)} fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.shipmentInField} fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...core.shippingDocField} fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject name='source' label='Source' options={[{value: 'Inbound'}, {value: 'Process'}, {value: 'Sort'}]} fast />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <WeightField name='weight' label='Weight' decimal={0} fast />
                    </Grid>
                    {formikProps.values.manifested &&
                    formikProps.values.manifested.value &&
                    formikProps.values.classification &&
                    formikProps.values.classification.value === 'Hazardous Waste' ? (
                        <Fragment>
                          <Grid item xs={12}>
                            <AutoCompleteObject {...core.rcraCodesField(formikProps)} />
                          </Grid>
                          <Grid item xs={12}>
                            <AutoCompleteObject {...core.caCodesField(formikProps)} />
                          </Grid>
                        </Fragment>
                      ) : null}
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {this.state.stage === 1 ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <Button {...core.closeButton}>Close</Button>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton {...core.cancelButton}>Cancel</FormButton>
                  <SubmitButton {...core.submitButton}>
                    Submit
                  </SubmitButton>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  purchaseOrders: state.firestore.purchaseOrders,
  trackingShipments: state.firestore.trackingShipments,
  materials: state.firestore.materials,
  types: state.firestore.types,
  accounts: state.firestore.accounts,
});

AdminInventoryModal.propTypes = {
  selectedInventoryItem: PropTypes.object,
  selectedContainer: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.object),
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  types: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of other items where id is an account id
  }),
  purchaseOrders: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  trackingShipments: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
};

export default connect(mapStateToProps)(AdminInventoryModal);

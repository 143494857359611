import React, {Fragment, useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogActions, DialogContent, Grid, DialogContentText} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {Firestore} from 'config.js';
import {useSelector} from 'react-redux';
const {TextField, WeightField, FormikForm, FormButton, SubmitButton} = Formik;

const stageArray = ['basic', 'success'];

const WeightAdjustmentForm = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const inventoryItems = useSelector(state => state.firestore.inventoryItems);
  const currentUser = useSelector(state => state.auth.currentUser);

  const getWeightPercent = useCallback(newNetWeight => newNetWeight / props.selectedContainer.NetWeight, [props.selectedContainer.NetWeight]);

  const core = useMemo(() => {
    return {
      dialog: {
        open: true,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: 'Container net weight successfully updated.',
        stage: stageArray[stage],
      },
    };
  }, [stage]);

  const formik = useMemo(() => {
    return {
      initialValues: {
        newNetWeight: '',
        note: '',
      },
      validationSchema: yup.object().shape({
        newNetWeight: yup.number().required('New Net Weight is a required field.').min(1, 'New Net Weight cannot be less than 1.'),
        note: yup.string().required('A reason for adjustment is required.').min(10, 'The reason must be at least 10 characters long.'),
      }),
      onSubmit: (values, actions) => {
        const batch = Firestore.batch();
        const deltaWeight = getWeightPercent(parseInt(values.newNetWeight));
        const formRef = Firestore.collection('Tracking-Forms').doc();

        props.selectedContainer.InventoryItems.forEach(itemRef => {
          const inventoryDoc = inventoryItems.ref[itemRef];
          batch.update(Firestore.collection('Tracking-Inventory').doc(itemRef), {
            Weight: parseInt(inventoryDoc.Weight * deltaWeight) > 0 ? parseInt(inventoryDoc.Weight * deltaWeight) : 1,
            FormHistory: [...inventoryDoc.FormHistory, formRef.id],
          });
        });

        batch.update(Firestore.collection('Tracking-Containers').doc(props.selectedContainer.ShortNo), {
          NetWeight: parseInt(values.newNetWeight),
        });

        batch.set(formRef, {
          ContainerRef: props.selectedContainer.ShortNo,
          InventoryAffected: props.selectedContainer.InventoryItems,
          NewContainerWeight: parseInt(values.newNetWeight),
          PreviousWeight: props.selectedContainer.NetWeight,
          Completed: true,
          Note: values.note.trim(),
          FormType: 'Weight Adjustment',
          System: {
            CreatedBy: currentUser.displayName,
            CreatedOn: new Date(),
          },
        });

        batch.commit().then(() => {
          setStage(stage + 1);
          actions.setSubmitting(false);
        }).catch(error => {
          setFormError('There was an error during submission. Please try again.');
          actions.setSubmitting(false);
        });
      },
    };
  }, [currentUser.displayName, getWeightPercent, inventoryItems.ref, props.selectedContainer, stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Edit Container Net Weight</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter the new net weight of the container, and explain why:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <WeightField decimal={0} name='newNetWeight' label='New Net Weight' required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField multiline name='note' label='Reason for Adjustment' required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton onClick={props.close} variant='text' color='primary'>Close</FormButton>
              </DialogActions>
            ) : <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
              <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
            </DialogActions>}          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

WeightAdjustmentForm.propTypes = {
  selectedContainer: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default WeightAdjustmentForm;

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {ProcessView, SortView, Consolidate, Inventory} from './Production/';
import {TopNavBar} from 'components/';
import {Collapse} from '@kbi/component-library';
import {useTypes, useMaterials, useInventory, useSortForms, useConsolidationForms, useContainers, useProcessForms, useFacilityUnits} from 'hooks';

const arrayOfTabs = [
  {label: 'Sort', path: '/production/sort'},
  {label: 'Process', path: '/production/process'},
  {label: 'Consolidation', path: '/production/consolidate'},
  {label: 'Onsite Inventory', path: '/production/inventory'},
];

const Production = props => {
  // setting up redux
  useTypes();
  useMaterials();
  useInventory();
  useContainers();
  useSortForms();
  useConsolidationForms();
  useProcessForms();
  useFacilityUnits();

  return (
    <Fragment>
      <TopNavBar tabArray={arrayOfTabs} />
      <Collapse in={props.history.location.pathname.includes('sort')}>
        <SortView />
      </Collapse>
      <Collapse in={props.history.location.pathname.includes('process')}>
        <ProcessView />
      </Collapse>
      <Collapse in={props.history.location.pathname.includes('consolidate')}>
        <Consolidate />
      </Collapse>
      <Collapse in={props.history.location.pathname.includes('inventory')}>
        <Inventory />
      </Collapse>
    </Fragment>
  );
};

Production.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Production);

import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {Typography, Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {DefaultLabelIcon, DynamicIcon} from '../../components/';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const SortedLabels = props => {
  const {listOfContainersToPrint} = props;

  const {materials, inventoryItems} = useSelector(state => state.firestore);

  const limitStringToOneLine = useCallback(string => {
    let reducedString = string;
    if (reducedString.length > 25) reducedString = reducedString.substring(0, 24) + '...';
    return reducedString;
  }, []);
  const createTypesString = useCallback(arrayOfItemRefs => {
    const arrayOfItemTypes = arrayOfItemRefs.map(ref => inventoryItems.ref[ref].Type.Name).filter(onlyUnique);
    return arrayOfItemTypes.join(', ');
  }, [inventoryItems.ref]);
  const core = useMemo(() => {
    return {
      text: {
        style: {fontSize: '14px', fontWeight: 600},
      },
      newLabel: (index, array) => ({
        className: index === 0 ? 'firstLabel' : '',
        container: true,
        alignItems: 'center',
        justify: 'center',
        style:
        index === array.length - 1 ?
          {width: '380px', height: '262px'} :
          {pageBreakAfter: 'always', width: '380px', height: '262px'},
      }),
      spaceAround: {
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'stretch',
        item: true,
        xs: 12,
      },
      row: {
        item: true,
        container: true,
        direction: 'column',
      },
    };
  }, []);
  const createLabels = useCallback(() => {
    if (!listOfContainersToPrint) return;
    return listOfContainersToPrint.map((container, index, array) => {
      let pathOfIcon;
      let nameOfIcon;
      const flagObject = materials.ref[container?.MaterialRef].Flags.find(flag => flag.Name === container.Flag);
      if (flagObject) {
        pathOfIcon = flagObject.Path;
        nameOfIcon = flagObject.Name;
      }
      else {
        nameOfIcon = 'None';
      }

      return (
        <Grid key={index} {...core.newLabel(index, array)}>
          <Grid {...core.spaceAround}>
            <Grid container item xs={12}
              spacing={2} style={{marginBottom: '5px'}}
            >
              <Grid container item xs={4}
                justify='center'
              >
                {flagObject ?
                  <DynamicIcon svgStringProp={pathOfIcon} style={{fontSize: '6em'}} /> : <DefaultLabelIcon style={{fontSize: '6em'}} />}
              </Grid>
              <Grid container item xs={8}>
                <Grid item xs={12}>
                  <Typography {...core.text}>{materials.ref[container?.MaterialRef].UnitDetails.MaterialName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>{limitStringToOneLine(createTypesString(container.InventoryItems))}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>Flag: {nameOfIcon}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid {...core.row} xs={4} style={{borderTop: '1px solid black', paddingTop: '7px'}}
              alignItems='center'
            >
              <Grid item>
                <QRCode size={95} value={container.ShortNo} />
              </Grid>
            </Grid>
            <Grid {...core.row} xs={8} style={{borderTop: '1px solid black', paddingTop: '7px'}}>
              <Grid item>
                <Typography {...core.text}>SORTED</Typography>
              </Grid>
              <Grid item>
                <Typography {...core.text}>SN: {container.ShortNo}</Typography>
              </Grid>
              <Grid item>
                <Typography {...core.text}>
                  Start Date: {moment(container.AccumulationStartDate.toDate()).format('MM/DD/YY')}
                </Typography>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item container>
                <Grid item xs={6}>
                  <Typography {...core.text}>GW: {container.TareWeight + container.NetWeight} lbs</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography {...core.text}>NW: {container.NetWeight} lbs</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }, [core, createTypesString, limitStringToOneLine, listOfContainersToPrint, materials.ref]);

  if (!listOfContainersToPrint || listOfContainersToPrint[0] === undefined) return null;

  return <div className='printOnlyWrapper'>{createLabels()}</div>;
};

SortedLabels.propTypes = {
  listOfContainersToPrint: PropTypes.arrayOf(PropTypes.object),
};

export default SortedLabels;

import React, {Component, Fragment} from 'react';
import ViewShippingNameModal from './ShippingName/ViewShippingNameModal';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {Table} from '@kbi/component-library';
import {ExpandMore, Edit} from '@material-ui/icons';

class ShippingNamePanel extends Component {
  state = {
    shippingNames: null,
    newShippingNameModalOpen: false,
    editPSN: false,
    editShippingName: null,
    shippingNameId: null,
    expanded: false,
  };
  componentDidUpdate(prevProps) {
    if (
      this.props.shippingNames !== prevProps.shippingNames ||
      (this.props.shippingNames && !this.state.shippingNames)
    ) {
      const shippingNames = this.props.shippingNames.map(name => {
        return {
          ...name,
          System: {...name.System, CreatedOn: moment(name.System.CreatedOn.seconds * 1000).format('MM/DD/YYYY')},
        };
      });
      this.setState({shippingNames});
    }
  }
  shippingNames = core => {
    if (!this.state.shippingNames) {
      return <LinearProgress />;
    }
    else if (this.state.shippingNames.length === 0) {
      return (
        <AccordionDetails>
          <Grid container justify='space-between'>
            <Typography variant='subtitle1'>No proper shipping names found for this contact.</Typography>
            <Button {...core.emptyShippingNameButton}>Add Shipping Name</Button>
          </Grid>
        </AccordionDetails>
      );
    }
    else {
      return (
        <AccordionDetails style={{padding: '0px'}}>
          <Grid container>
            <Grid item xs={12}>
              <Table {...core.shippingNameTable} />
            </Grid>
          </Grid>
        </AccordionDetails>
      );
    }
  };
  openViewShippingNameModal = core => {
    if (this.state.newShippingNameModalOpen) {
      return <ViewShippingNameModal {...core.newShippingNameModal} />;
    }
    else return null;
  };
  render() {
    const {shippingNames, newShippingNameModalOpen, expanded} = this.state;
    const {material} = this.props;

    const core = {
      shippingNameTable: {
        columns: [
          {accessor: 'ShippingName', Header: 'Proper Shipping Name'},
          {accessor: 'System.CreatedBy', Header: 'Created By'},
          {accessor: 'System.CreatedOn', Header: 'Created On'},
        ],
        sortInitial: [{id: 'ShippingName', desc: true}],
        data: shippingNames || [],
        actionsPerRow: [
          {
            icon: Edit,
            tooltip: 'Edit Shipping Name',
            onClick: ({rowData}) => {
              this.setState({
                shippingNameId: rowData.id,
                editShippingName: rowData.ShippingName,
                editPSN: true,
                newShippingNameModalOpen: true,
              });
            },
          },
        ],
      },

      newShippingNameModal: {
        newShippingNameModalOpen,
        close: () => this.setState({newShippingNameModalOpen: false, editPSN: false}),
        material,
        edit: this.state.editPSN,
        name: this.state.editShippingName,
        id: this.state.shippingNameId,
      },
      emptyShippingNameButton: {
        onClick: e => {
          e.stopPropagation();
          this.setState({newShippingNameModalOpen: true});
        },
        variant: 'outlined',
      },
    };
    return (
      <Fragment>
        <Accordion expanded={expanded} onChange={() => this.setState({expanded: !this.state.expanded})}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container justify='space-between'>
              <div>
                <Typography>Proper Shipping Names</Typography>
              </div>
              {expanded ? (
                <div>
                  <Button {...core.emptyShippingNameButton}>
                    Add Shipping Name
                  </Button>
                </div>
              ) : null}
            </Grid>
          </AccordionSummary>
          {this.shippingNames(core)}
        </Accordion>
        {this.openViewShippingNameModal(core)}
      </Fragment>
    );
  }
}

ShippingNamePanel.propTypes = {
  shippingNames: PropTypes.array,
  material: PropTypes.object,
};

export default ShippingNamePanel;

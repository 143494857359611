/* eslint-disable react/prop-types */
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {TableWithPaper} from 'components/';
import {Grid, Paper, Typography, Button, LinearProgress} from '@material-ui/core';
import {PlaylistAddCheck} from '@material-ui/icons';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, ResponsiveContainer, Legend, Cell, Label} from 'recharts';
import {Firestore} from 'config.js';
import {useContainers, useFacilityUnits, useInventory, useAccounts, useMaterials} from 'hooks/';
import moment from 'moment';
import {MarkBillableModal} from './Dashboard/';

const RenderLegend = (props) => {
  const {payload, onClick, selectedFacilityFilter} = props;

  return (
    <Grid container justify='center'>
      {
        payload.map((entry, index) => (
          <Grid item key={`item-${index}`}>
            <Button onClick={() => onClick(entry.value)} color={selectedFacilityFilter === entry.value ? 'primary' : 'default'}>{entry.value}</Button>
          </Grid>
        ))
      }
    </Grid>
  );
};
function determineBarColor(percentString) {
  const percent = parseFloat(percentString);
  if (percent > 100) {
    return '#D7260A';
  }
  else if (percent > 90) {
    return '#E75400';
  }
  else {
    return '#82ca9d';
  }
}
const colorArray = [
  '#e6194B',
  '#3cb44b',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#fabebe',
  '#469990',
  '#e6beff',
  '#9A6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#a9a9a9',
];

const Dashboard = () => {
  const [modal, setModal] = useState({items: null, type: null});
  const [materialChartDataForGraphs, setMaterialChartDataForGraphs] = useState(null);
  const [facilityChartDataForGraphs, setFacilityChartDataForGraphs] = useState(null);
  const [subcatagoryChartDataForGraphs, setSubcatagoryChartDataForGraphs] = useState(null);
  const [toggleCheckboxes, setToggleCheckboxes] = useState(null);

  const [unitSelected, setUnitSelected] = useState(null);
  const [selectedFacilityFilter, setSelectedFacilityFilter] = useState('');
  const containers = useContainers();
  const facilityUnits = useFacilityUnits();
  const inventoryItems = useInventory();
  const materials = useMaterials();
  const accounts = useAccounts();
  const {currentUser} = useSelector(state => state.auth);

  useEffect(() => {
    // Using an IIFE
    (async () => {
      let snap = await Firestore.collection('Tracking-Dashboard').doc('DashboardData').get();
      snap = snap.data();
      setMaterialChartDataForGraphs(snap.MaterialChartData);
      setFacilityChartDataForGraphs(snap.FacilityChartData.sort((a, b) => a.Unit > b.Unit ? 1 : -1));
      setSubcatagoryChartDataForGraphs(snap.SubcategoryChartData);
    })();
  }, []);

  const selectFacilityUnit = useCallback((e) => {
    if (e) setUnitSelected(e.activeLabel);
  }, []);
  const legendOnClick = useCallback((legend) => {
    if (legend) return legend === selectedFacilityFilter ? setSelectedFacilityFilter('') : setSelectedFacilityFilter(legend);
  }, [selectedFacilityFilter]);
  const chartProps = useMemo(() => ({
    MaterialWeight: {
      data: materialChartDataForGraphs?.[unitSelected]?.materials || [],
      dataKey: 'MaterialWeight',
      innerRadius: 60,
      outerRadius: 80,
      fill: '#82ca9d',
      paddingAngle: 5,
      label: true,
    },
    TotalWeight: {
      data: materialChartDataForGraphs?.[unitSelected]?.total,
      dataKey: 'TotalWeight',
      innerRadius: 35,
      outerRadius: 55,
      fill: '#1b1e22',
    },
  }), [materialChartDataForGraphs, unitSelected]);
  const markBillableModalProps = useMemo(() => ({
    ...modal,
    toggleCheckboxes,
    close: ({clear}) => {
      setToggleCheckboxes(null);
      setModal({items: null, type: null});
    },
  }), [modal, toggleCheckboxes]);

  const manifestedContainers = useMemo(() => {
    const getManifestedContainers = () => {
      const manifestedObject = {};
      inventoryItems.list.forEach(item => {
        if (!item.Billable && item.Manifested && containers.ref[item.ContainerRef]) {
          const container = containers.ref[item.ContainerRef];
          const inventoryItemRef = container.InventoryItems[0];
          const accountRef = inventoryItems.ref[inventoryItemRef].AccountRef || '';
          const today = moment();
          const daysFromStart = moment(container.AccumulationStartDate.toDate()).startOf('day');
          manifestedObject[item.ContainerRef] = {
            ...container,
            accountName: accounts[accountRef].AccountName || '',
            materialName: materials.ref[container.MaterialRef]?.UnitDetails.MaterialName || '',
            facilityUnit: facilityUnits.ref[container.FacilityUnitRef]?.Name || '',
            daysSinceReciept: today.diff(daysFromStart, 'days'),
          };
        }
      });
      return Object.values(manifestedObject);
    };
    if (accounts && containers && facilityUnits && inventoryItems && materials) return [...getManifestedContainers()];
    else return null;
  }, [accounts, containers, facilityUnits, inventoryItems, materials]);
  const manifestedTableProps = useMemo(() => {
    const tableProps = {
      columns: [
        {accessor: 'daysSinceReciept', Header: 'Days Since Reciept', type: 'numeric'}, // would like this colunm text to turn red when its value is over 15
        {accessor: 'ShortNo', Header: 'Short No.'},
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'NetWeight', Header: 'Net Weight', type: 'numeric'},
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'facilityUnit', Header: 'Facility Unit'},
      ],
      data: manifestedContainers || [],
      sortInitial: [{id: 'daysSinceReciept', desc: true}],
      selectRows: ['Manager', 'Admin'].includes(currentUser.roleTracking) ? true : false,
      title: {primary: 'Manifested Inbound Containers (15 days required)'},
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    };
    if (['Manager', 'Admin'].includes(currentUser.roleTracking)) {
      tableProps.actionsBar = [
        rtProps => ({
          buttonProps: {
            disabled: !rtProps.selectedFlatRows.length,
          },
          icon: PlaylistAddCheck,
          text: 'Mark as Billable',
          onClick: ({tableData}) => {
            const items = tableData.selectedRows?.map(item => {
              return {...item.original};
            });
            setToggleCheckboxes(() => tableData.toggleAllRowsSelected);
            setModal({items, type: 'manifestedContainers'});
          },
        }),
      ];
    }
    return tableProps;
  }, [currentUser.roleTracking, manifestedContainers]);

  const nonManifestedContainers = useMemo(() => {
    const getNonManifestedContainers = () => {
      const nonManifestedObject = {};
      inventoryItems.list.forEach(item => {
        if (!item.Billable && !item.Manifested && containers.ref[item.ContainerRef]) {
          const container = containers.ref[item.ContainerRef];
          const inventoryItemRef = container?.InventoryItems[0];
          const accountRef = inventoryItems.ref[inventoryItemRef]?.AccountRef || '';
          const today = moment();
          const daysFromStart = moment(container.AccumulationStartDate.toDate()).startOf('day');
          nonManifestedObject[item.ContainerRef] = {
            ...container,
            accountName: accounts[accountRef]?.AccountName || '',
            materialName: materials.ref[container.MaterialRef]?.UnitDetails.MaterialName || '',
            facilityUnit: facilityUnits.ref[container.FacilityUnitRef]?.Name || '',
            daysSinceReciept: today.diff(daysFromStart, 'days'),
          };
        }
      });
      return Object.values(nonManifestedObject);
    };
    if (accounts && containers && facilityUnits && inventoryItems && materials) return [...getNonManifestedContainers()];
    else return null;
  }, [accounts, containers, facilityUnits, inventoryItems, materials]);
  const nonManifestedTableProps = useMemo(() => {
    const tableProps = {
      columns: [
        {accessor: 'daysSinceReciept', Header: 'Days Since Reciept', type: 'numeric'}, // would like this colunm text to turn red when its value is over 30
        {accessor: 'ShortNo', Header: 'Short No.'},
        {accessor: 'accountName', Header: 'Account'},
        {accessor: 'NetWeight', Header: 'Net Weight', type: 'numeric'},
        {accessor: 'materialName', Header: 'Material'},
        {accessor: 'facilityUnit', Header: 'Facility Unit'},
      ],
      sortInitial: [{id: 'daysSinceReciept', desc: true}],
      data: nonManifestedContainers || [],
      selectRows: ['Manager', 'Admin'].includes(currentUser.roleTracking) ? true : false,
      title: {primary: 'Non-Manifested Inbound Containers (30 days preferred)'},
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    };
    if (['Manager', 'Admin'].includes(currentUser.roleTracking)) {
      tableProps.actionsBar = [
        rtProps => ({
          buttonProps: {
            disabled: !rtProps.selectedFlatRows.length,
          },
          icon: PlaylistAddCheck,
          text: 'Mark as Billable',
          onClick: ({tableData}) => {
            const items = tableData.selectedRows?.map(item => {
              return {...item.original};
            });
            setToggleCheckboxes(() => tableData.toggleAllRowsSelected);
            setModal({items, type: 'nonManifestedContainers'});
          },
        }),
      ];
    }
    return tableProps;
  }, [currentUser.roleTracking, nonManifestedContainers]);

  if (!materialChartDataForGraphs || !facilityChartDataForGraphs || !subcatagoryChartDataForGraphs || !nonManifestedContainers || !manifestedContainers) {
    return <LinearProgress />;
  }

  return (
    <Fragment>
      <Typography variant='h5' paragraph>Dashboard</Typography>
      <Grid container spacing={2} justify={'center'}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{padding: '16px'}}>
            <Typography variant='h6' paragraph style={{textAlign: 'center'}}>Unit Capacity Used</Typography>
            <ResponsiveContainer width='100%' height={250}>
              <BarChart data={facilityChartDataForGraphs
                .filter(data => selectedFacilityFilter ? facilityUnits.list.find(unit => unit.Name === data.Unit).Facility === selectedFacilityFilter : true)
              } onClick={selectFacilityUnit}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='Unit' />
                <YAxis><Label value='% Filled' angle={-90} position='insideLeft' /></YAxis>
                <Tooltip />
                <Legend payload={[{value: 'Anaheim-1314'}, {value: 'Brea-1125'}]}
                  content={<RenderLegend onClick={(legend) => legendOnClick(legend)} selectedFacilityFilter={selectedFacilityFilter} />}
                />
                <Bar dataKey='Used' stackId='a' fill='#82ca9d' unit='%'>
                  {facilityChartDataForGraphs
                    .filter(data => selectedFacilityFilter ?
                      facilityUnits.list.find(unit => unit.Name === data.Unit).Facility === selectedFacilityFilter : true)
                    .map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={determineBarColor(entry.Used)} />
                    ))}
                </Bar>
                <Bar dataKey='Available' stackId='a' fill='#8884d8' unit='%'>
                  {facilityChartDataForGraphs
                    .filter(data => selectedFacilityFilter ?
                      facilityUnits.list.find(unit => unit.Name === data.Unit).Facility === selectedFacilityFilter : true)
                    .map((entry, index) => {
                      return parseInt(entry.Available) > 0 ? <Cell key={`cell-${index}`} /> : <Cell key={`cell-${index}`} height={0} />;
                    })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {unitSelected && (
          <Grid item xs={12}>
            <Paper elevation={3} style={{marginTop: '16px', padding: '16px'}}>
              <Typography variant='h6' paragraph style={{textAlign: 'center'}}>{unitSelected}: Material Breakdown</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ResponsiveContainer width='100%' height={300}>
                    <PieChart>
                      <Pie {...chartProps.MaterialWeight}>
                        {materialChartDataForGraphs[unitSelected].materials.map((entry, index) => (
                          <Cell key={index} fill={colorArray[index % colorArray.length]} />
                        ))}
                      </Pie>
                      <Pie {...chartProps.TotalWeight} />
                      <Legend />
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ResponsiveContainer width='100%' height={300}>
                    <BarChart data={subcatagoryChartDataForGraphs[unitSelected]}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='name' />
                      <YAxis><Label value='% Filled' angle={-90} position='insideLeft' /></YAxis>
                      <Tooltip />
                      <Bar dataKey='Used' stackId='a' fill='#82ca9d' unit='%'>
                        {subcatagoryChartDataForGraphs[unitSelected].map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={determineBarColor(entry.Used)} />
                        ))}
                      </Bar>
                      <Bar dataKey='Available' stackId='a' fill='#8884d8' unit='%'>
                        {subcatagoryChartDataForGraphs[unitSelected]
                          .map((entry, index) => {
                            return parseInt(entry.Available) > 0 ? <Cell key={`cell-${index}`} /> : <Cell key={`cell-${index}`} height={0} />;
                          })}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            </Paper>
          </Grid>)}
        <Grid item xs={12}>
          <TableWithPaper {...manifestedTableProps} />
        </Grid>
        <Grid item xs={12}>
          <TableWithPaper {...nonManifestedTableProps} />
        </Grid>
      </Grid>
      <MarkBillableModal {...markBillableModalProps} />
    </Fragment>
  );
};

export default Dashboard;

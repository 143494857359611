import {
  SET_ACCOUNT_PROFILES,
  GET_SHIPMENTS,
  SET_SHIPMENT_LISTENER,
  SET_ACCOUNTS,
  SET_ACCOUNT_LISTENER,
  GET_LOCATIONS,
  SET_PURCHASE_ORDERS,
  SET_PURCHASE_ORDER_LISTENER,
  SET_MATERIALS,
  SET_ALL_MATERIAL_TYPES,
} from './types';

import {
  SET_FACILITIES,
  SET_SHIPMENTS,
  SET_CONTAINERS,
  SET_INVENTORY_ITEMS,
  SET_CONTAINER_LISTENER,
  SET_INVENTORY_LISTENER,
  SET_SORT_FORMS,
  SET_SORT_FORM_LISTENER,
  SET_PROCESS_FORMS,
  SET_PROCESS_FORM_LISTENER,
  SET_CONSOLIDATION_FORMS,
  SET_CONSOLIDATION_FORM_LISTENER,
} from './types';

const INITIAL_STATE = {
  accountProfiles: null,
  eventsAndTasks: null,
  shipments: null,
  accounts: null,
  locations: null,
  purchaseOrders: null,
  accountListener: null,
  purchaseOrderListener: null,
  shipmentListener: null,
  types: null,
  materials: null,
  facilityUnits: null,
  trackingShipments: null,
  inventoryItems: null,
  inventoryListener: null,
  containers: null,
  containerListener: null,
  sortForms: null,
  sortFormListener: null,
  processForms: null,
  processFormsListener: null,
  consolidationForms: null,
  consolidationFormsListener: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_ACCOUNT_PROFILES:
    return {...state, accountProfiles: payload};
  case SET_MATERIALS:
    return {...state, materials: {...payload}};
  case SET_ALL_MATERIAL_TYPES:
    return {...state, types: payload};
  case GET_SHIPMENTS:
    return {...state, shipments: payload};
  case SET_SHIPMENT_LISTENER:
    return {...state, shipmentListener: payload};
  case SET_ACCOUNTS:
    return {...state, accounts: payload};
  case SET_ACCOUNT_LISTENER:
    return {...state, accountListener: payload};
  case GET_LOCATIONS:
    return {...state, locations: payload};
  case SET_PURCHASE_ORDERS:
    return {...state, purchaseOrders: payload};
  case SET_PURCHASE_ORDER_LISTENER:
    return {...state, purchaseOrderListener: payload};

  case SET_FACILITIES:
    return {...state, facilityUnits: {listener: payload.listener, list: payload.list, ref: payload.ref, active: payload.active}};
  case SET_SHIPMENTS:
    return {...state, trackingShipments: {listener: payload.listener, list: payload.list, ref: payload.ref}};
  case SET_INVENTORY_ITEMS:
    return {...state, inventoryItems: {list: payload.list, ref: payload.ref}};
  case SET_INVENTORY_LISTENER:
    return {...state, inventoryListener: payload};
  case SET_CONTAINERS:
    return {...state, containers: {list: payload.list, ref: payload.ref}};
  case SET_CONTAINER_LISTENER:
    return {...state, containerListener: payload};
  case SET_SORT_FORMS:
    return {...state, sortForms: payload};
  case SET_SORT_FORM_LISTENER:
    return {...state, sortFormListener: payload};
  case SET_CONSOLIDATION_FORMS:
    return {...state, consolidationForms: payload};
  case SET_CONSOLIDATION_FORM_LISTENER:
    return {...state, consolidationFormListener: payload};
  case SET_PROCESS_FORMS:
    return {...state, processForms: payload};
  case SET_PROCESS_FORM_LISTENER:
    return {...state, processFormListener: payload};

  default:
    return state;
  }
};

export default reducer;

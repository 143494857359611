import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Collapse, Typography} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {Table, Alert} from '@kbi/component-library';
import {useSelector} from 'react-redux';
import {Firestore} from 'config';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const MarkBillableModal = ({close, items, type, toggleCheckboxes}) => {
  const [stage, setStage] = useState('verify');
  const [modalAlert, setModalAlert] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const inventoryItems = useSelector(state => state.firestore.inventoryItems);

  const createTypesString = useCallback(arrayOfItemRefs => {
    const arrayOfItemTypes = arrayOfItemRefs.map(ref => {
      if (inventoryItems.ref[ref]) {
        return inventoryItems.ref[ref]?.Type.Name;
      }
      else return '';
    }).filter(onlyUnique);
    return arrayOfItemTypes.join(', ');
  }, [inventoryItems]);
  const limitStringTo24Char = useCallback(string => {
    let reducedString = string;
    if (reducedString.length > 25) reducedString = reducedString.substring(0, 24) + '...';
    return reducedString;
  }, []);

  const dataForTable = useMemo(() => {
    return items?.map(item => ({
      ...item,
      typeString: limitStringTo24Char(createTypesString(item.InventoryItems)),
    }));
  }, [createTypesString, items, limitStringTo24Char]);

  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'md',
    open: type === 'nonManifestedContainers' || type === 'manifestedContainers',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [type]);
  const tableProps = useMemo(() => ({
    columns: [
      {accessor: 'ShortNo', Header: 'Short No.'},
      {accessor: 'accountName', Header: 'Account'},
      {accessor: 'NetWeight', Header: 'Net Weight'},
      {accessor: 'materialName', Header: 'Material'},
      {accessor: 'typeString', Header: 'Types'},
      {accessor: 'facilityUnit', Header: 'Facility Unit'},
    ],
    data: dataForTable || [],
    disableFilters: true,
    paginationActive: dataForTable?.length > 25 ? true : false,
    paginationInitialSize: 25,
    paginationSizes: [25, 50, 100, 350, 500],
  }), [dataForTable]);

  const handleClose = useCallback(({clear}) => {
    close({clear});
    setStage('verify');
    setModalAlert('');
  }, [close]);
  const handleSubmit = useCallback(async () => {
    await setSubmitting(true);
    try {
      if (items.length > 500) throw new Error('You cannot mark more than 500 containers as billable. Reduce the number of selected containers and try again.');
      if (items.length < 1) throw new Error('You must have selected at least one container to mark as billable.');
      const batch = Firestore.batch();
      items.forEach(item => {
        const inventoryRef = Firestore.collection('Tracking-Inventory').doc(item.InventoryItems[0]);
        batch.update(inventoryRef, {Billable: true});
      });
      await batch.commit();
      toggleCheckboxes(false);
      setStage('success');
      setTimeout(() => setSubmitting(false), 1000);
    }
    catch (error) {
      setSubmitting(false);
      setModalAlert(error.message);
    }
  }, [items, toggleCheckboxes]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
          Mark Containers as Billable
        </Collapse>
      </DialogTitle>
      <DialogContent style={{padding: '0px'}}>
        <Collapse in={stage === 'verify'} timeout={{enter: 500, exit: 500}}>
          <DialogContentText style={{padding: '0px 24px'}}>
            Confirm that the following containers are ready to be billed.
          </DialogContentText>
          <Table {...tableProps} />
          <div style={{padding: '16px 24px'}}>
            <Alert in={Boolean(modalAlert)} text={modalAlert} severity='error' />
          </div>
        </Collapse>
        <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CheckCircleOutline style={{fontSize: '10em'}} />
            <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
              {items?.length === 1 ? 'Container has' : 'Containers have'} been marked as billable.
            </Typography>
          </div>
        </Collapse>
      </DialogContent>
      <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogActions>
          <Button onClick={() => handleClose({clear: true})} color='primary'>Close</Button>
        </DialogActions>
      </Collapse>
      <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button onClick={() => handleClose({clear: false})} color='secondary' disabled={submitting}>Cancel</Button>
          <Button type='submit' color='primary' disabled={submitting} onClick={handleSubmit}>Confirm</Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

MarkBillableModal.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
  toggleCheckboxes: PropTypes.func,
};
export default MarkBillableModal;

import {SET_CURRENT_USER, UNSET_CURRENT_USER} from './types.js';

const INITIAL_STATE = {
  currentUser: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_CURRENT_USER:
    return {...state, currentUser: payload};
  case UNSET_CURRENT_USER:
    return {...state, currentUser: null};
  default:
    return state;
  }
};

export default reducer;

/* eslint-disable max-len */
import {Firestore} from 'config.js';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

class ReportGenerator {
  constructor(props) {
    this.inputValues = props.values;
    this.reportType = props.reportType;
    this.reduxValues = props.reduxValues;
    this.queryRef = null; // this will be the queries that are send to firestore. Can either be a single query, or an array of queries.
    this.arrayOfResults = []; // this is the results that are going to be returned to the report modal
    this.subcollectionResults = []; // the subcollectionResults are generated during async queries, which send off queries to other collections to compare results with after all results are returned.
  }

  // the function that is called by the report modal in order to begin the query process.
  async runQuery() {
    switch (this.reportType) {
    case 'inboundLoad':
      await this.generateInboundQuery();
      break;
    case 'annualInbound':
      this.generateAnnualInboundQuery();
      break;
    case 'annualOutbound':
      this.generateAnnualOutboundQuery();
      break;
    case 'returnedShippingDocuments':
      this.generateReturnedShippingDocumentQuery();
      break;
    case 'inventoryItems':
      await this.generateInventoryQuery();
      break;
    case 'outboundLoad':
      this.generateOutboundQuery();
      break;
    case 'proformaInvoice':
      this.generateProformaInvoiceQuery();
      break;
    case 'process':
      this.generateProcessFormQuery();
      break;
    case 'sort':
      this.generateSortFormQuery();
      break;
    case 'consolidation':
      this.generateConsolidationFormQuery();
      break;
    case 'container':
      this.generateContainerQuery();
      break;
    default:
      break;
    }

    if (this.queryRef.length) {
      return this.sendArryOfQueries();
    }
    else return this.sendSingleQuery();
  }

  // the below functions send the queries generated by the switch statement in this.runQuery. They set the value of this.arrayOfResults
  // if this.queryRef is a single query, this will fire.
  sendSingleQuery() {
    return this.queryRef.get().then(snap => {
      // snap has two options. either the this.queryRef was a query or collection ref, in which snap will be a snap of all documents,
      // or this.queryRef was a document ref, in which snap will be a document.
      // if snap.exists, that means that snap was a document, and needs to be handled as such. the reason for this was the user entered some ID of something they are looking for
      if (snap.exists) {
        let formattedItem = null;
        const item = snap.data();
        if (this.reportType === 'inboundLoad') {
          formattedItem = {
            ...item,
            id: snap.id,
            ShipmentTime: item.ShipmentTime.toDate(),
            ScheduledTime: item.ScheduledTime ? item.ScheduledTime.toDate() : null,
          };
        }
        else if (this.reportType === 'outboundLoad') {
          formattedItem = {
            ...item,
            id: snap.id,
            ShipmentTime: item.ShipmentTime.toDate(),
            accountName: this.reduxValues.accounts[item.AccountId].AccountName,
            transporterName: this.reduxValues.accounts[item.Transporter].AccountName,
            locationName: this.reduxValues.locations[item.AccountId].find(location => location.id === item.LocationRef).LocationName,
          };
        }
        this.arrayOfResults.push(formattedItem);
        // otherwise, snap.exists will be undefined. that means that snap is of all documents in a query. handle it as such
      }
      else if (snap.exists === undefined) {
        snap.forEach(doc => {
          let formattedItem = null;
          const item = doc.data();
          // the block below formats the data into what the tables are after.
          if (this.reportType === 'inboundLoad') {
            formattedItem = {
              ...item,
              id: doc.id,
              ShipmentTime: item.ShipmentTime.toDate(),
              ScheduledTime: item.ScheduledTime ? item.ScheduledTime.toDate() : null,
            };
          }
          else if (this.reportType === 'outboundLoad') {
            formattedItem = {
              ...item,
              id: doc.id,
              ShipmentTime: item.ShipmentTime.toDate(),
              accountName: this.reduxValues.accounts[item.AccountId].AccountName,
              transporterName: this.reduxValues.accounts[item.Transporter].AccountName,
              locationName: this.reduxValues.locations[item.AccountId].find(location => location.id === item.LocationRef).LocationName,
            };
          }
          else if (this.reportType === 'returnedShippingDocuments') {
            formattedItem = {
              ...item,
              id: doc.id,
              ShipmentTime: item.ShipmentTime.toDate(),
              accountName: this.reduxValues.accounts[item.AccountId].AccountName,
              locationName: this.reduxValues.locations[item.AccountId].find(location => location.id === item.LocationRef).LocationName,
              daysSinceShipped: moment().diff(moment(item.ShipmentTime.toDate()), 'days'),
              requiredReason: item.DocumentType === 'HW Manifest' ? 'Manifested' : 'Export',
            };
          }
          else if (this.reportType === 'proformaInvoice') {
            formattedItem = {
              ...item,
              id: doc.id,
              accountName: this.reduxValues.accounts[item.AccountRef].AccountName,
            };
          }
          else if (this.reportType === 'process') {
            formattedItem = {
              ...item,
              id: doc.id,
              Date: item.Date.toDate(),
              yieldName: item.YieldRef === 'mixed' ? 'mixed' : this.reduxValues.materials.ref[item.YieldRef]?.UnitDetails.MaterialName,
              typeName: item.YieldRef === 'mixed' ? '' : this.reduxValues.types[item.YieldRef].find(type => type.id === item.TypeRef)?.TypeName,
              facilityUnitName: this.reduxValues.facilityUnits.ref[item.FacilityUnitRef]?.Name,

            };
          }
          else if (this.reportType === 'sort') {
            formattedItem = {
              ...item,
              id: doc.id,
              Date: item.Date.toDate(),
              facilityUnitName: this.reduxValues.facilityUnits.ref[item.FacilityUnitRef]?.Name,
            };
          }
          else if (this.reportType === 'consolidation') {
            formattedItem = {
              ...item,
              id: doc.id,
              Date: item.Date.toDate(),
              materialName: this.reduxValues.materials.ref[item.MaterialRef]?.UnitDetails.MaterialName,
              facilityUnitName: this.reduxValues.facilityUnits.ref[item.FacilityUnitRef]?.Name,
            };
          }
          this.arrayOfResults.push(formattedItem);
        });
      }
      // if snap.exists === false, that means that the user entered an ID they were looking for, but the document does not exist. therefore we will not be setting anything new into this.arrayOfResults
      return this.returnResults();
    });
  }
  // if this.queryRef is an array of queries, this will fire.
  sendArryOfQueries() {
    return Promise.all(this.queryRef.map(query => query.get())).then(arrayOfResultArrays => {
      this.handleArrayOfQueryResults(arrayOfResultArrays);
      return this.returnResults(this.arrayOfResults);
    });
  }

  // if this.subcollectionResults has any values, this will fire this.filterResultsBySubcollectionResults and filter the results. then it send the filtered results back to modal.
  returnResults() {
    if (this.subcollectionResults.length) {
      this.filterResultsBySubcollectionResults();
    }
    return Promise.resolve(this.arrayOfResults);
  }

  // the below functions assign a single query to this.queryRef, triggering this.sendSingleQuery.
  generateOutboundQuery() {
    if (this.inputValues.outboundId) return this.queryRef = Firestore.collection('Tracking-Shipments').doc(this.inputValues.outboundId);
    this.queryRef = Firestore.collection('Tracking-Shipments').where('Direction', '==', 'Outbound');
    if (this.inputValues.shippingDocumentNumber) {
      this.queryRef = this.queryRef.where('DocumentNumber', '==', this.inputValues.shippingDocumentNumber);
    }
    if (this.inputValues.account) {
      // if an account was set, grab shipments with the accountId field that is the same as the selected account
      this.queryRef = this.queryRef.where('AccountId', '==', this.inputValues.account.AccountId);
    }
    if (this.inputValues.containerNo) {
      this.queryRef = this.queryRef.where('ContainerNumber', '==', this.inputValues.containerNo);
    }
    if (this.inputValues.bookingNo) {
      this.queryRef = this.queryRef.where('BookingNumber', '==', this.inputValues.bookingNo);
    }
    if (this.inputValues.facility) {
      this.queryRef = this.queryRef.where('Facility', '==', this.inputValues.facility);
    }
    if (this.inputValues.location) {
      this.queryRef = this.queryRef.where('LocationRef', '==', this.inputValues.location.id);
    }
    if (this.inputValues.purchaseOrderNumber) {
      this.queryRef = this.queryRef.where('PurchaseOrderNumber', '==', this.inputValues.purchaseOrderNumber);
    }
    if (this.inputValues.sealNo) {
      this.queryRef = this.queryRef.where('SealNumber', '==', this.inputValues.sealNo);
    }
    if (this.inputValues.accountingId) {
      this.queryRef = this.queryRef.where('AccountingId', '==', this.inputValues.accountingId);
    }
    if (this.inputValues.transporter) {
      this.queryRef = this.queryRef.where('Transporter', '==', this.inputValues.transporter.AccountId);
    }
    if (this.inputValues.completed) {
      this.queryRef = this.queryRef.where('InProgress', '==', false);
    }
    else {
      this.queryRef = this.queryRef.where('InProgress', '==', true);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('ShipmentTime', '>=', startDate).where('ShipmentTime', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('ShipmentTime', '>=', startDate).where('ShipmentTime', '<=', endDate);
    }
  }
  // the async is used to assign values to this.subcollectionResults, which is used to filter the results of inbounds
  async generateInboundQuery() {
    if (this.inputValues.inboundId) return this.queryRef = Firestore.collection('Tracking-Shipments').doc(this.inputValues.inboundId);
    this.queryRef = Firestore.collection('Tracking-Shipments').where('Direction', '==', 'Inbound');
    if (this.inputValues.shippingDocumentNumber) {
      const shippingDocumentSnap = await Firestore.collectionGroup('Shipping-Documents')
        .where('DocumentNumber', '==', this.inputValues.shippingDocumentNumber.toUpperCase())
        .get();
      let inboundId = null;
      shippingDocumentSnap.forEach(doc => {
        // there will only be one document that is inside the shippingDocumentSnap
        inboundId = doc.ref.parent.parent.id;
      });
      // push into the array of subcollection query results the id of the document that contained the shipping document queried for
      this.subcollectionResults = [...this.subcollectionResults, inboundId];
    }
    if (this.inputValues.generator) {
      const shippingDocumentSnap = await Firestore.collectionGroup('Shipping-Documents')
        .where('GeneratorRef', '==', this.inputValues.generator.id)
        .get();
      const inboundIds = [];
      shippingDocumentSnap.forEach(doc => {
        inboundIds.push(doc.ref.parent.parent.id);
      });
      // push into the array of subcollection query results the id of the document that contained the shipping document queried for
      this.subcollectionResults = [...this.subcollectionResults, ...inboundIds];
    }
    if (this.inputValues.weightTicketNumber) {
      const weightTicketSnap = await Firestore.collectionGroup('Weight-Tickets')
        .where('TicketNumber', '==', this.inputValues.weightTicketNumber)
        .get();
      let weightTicketId = null;
      weightTicketSnap.forEach(doc => {
        // there will only be one document that is inside the shippingDocumentSnap
        weightTicketId = doc.ref.parent.parent.id;
      });
      // push into the array of subcollection query results the id of the document that contained the weight ticket queried for
      this.subcollectionResults = [...this.subcollectionResults, weightTicketId];
    }
    if (this.inputValues.account) {
      // if an account was set, grab shipments with the accountId field that is the same as the selected account
      this.queryRef = this.queryRef.where('AccountId', '==', this.inputValues.account.AccountId);
    }
    if (this.inputValues.completed) {
      this.queryRef = this.queryRef.where('InProgress', '==', false);
    }
    else {
      this.queryRef = this.queryRef.where('InProgress', '==', true);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('ShipmentTime', '>=', startDate).where('ShipmentTime', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('ShipmentTime', '>=', startDate).where('ShipmentTime', '<=', endDate);
    }
  }
  generateProformaInvoiceQuery() {
    this.queryRef = Firestore.collection('Tracking-Invoices');
    if (this.inputValues.account) {
      this.queryRef = this.queryRef.where('AccountRef', '==', this.inputValues.account.AccountId);
    }
    if (this.inputValues.accountingId) {
      this.queryRef = this.queryRef.where('AccountingId', '==', this.inputValues.accountingId);
    }
    if (this.inputValues.inboundId) {
      this.queryRef = this.queryRef.where('InboundRef', '==', this.inputValues.inboundId);
    }
    if (this.inputValues.poNumber) {
      this.queryRef = this.queryRef.where('InternalPO', '==', this.inputValues.poNumber);
    }
    if (this.inputValues.isInvoiced !== '') {
      this.queryRef = this.queryRef.where('IsInvoiced', '==', this.inputValues.isInvoiced);
    }
    if (this.inputValues.shippingDocumentNumber) {
      this.queryRef = this.queryRef.where('ShippingDocNo', '==', this.inputValues.shippingDocumentNumber);
    }
    if (this.inputValues.weightTicketNumber) {
      this.queryRef = this.queryRef.where('WeightTickets', '==', this.inputValues.weightTicketNumber);
    }
  }
  generateProcessFormQuery() {
    if (this.inputValues.formId) {
      return this.queryRef = Firestore.collection('Tracking-Forms').doc(this.inputValues.formId);
    }
    this.queryRef = Firestore.collection('Tracking-Forms').where('FormType', '==', 'Process');
    if (this.inputValues.material) {
      this.queryRef = this.queryRef.where('YieldRef', '==', this.inputValues.material.MaterialId);
    }
    if (this.inputValues.materialType) {
      this.queryRef = this.queryRef.where('TypeRef', '==', this.inputValues.materialType.id);
    }
    if (this.inputValues.flag) {
      this.queryRef = this.queryRef.where('Flag', '==', this.inputValues.flag.Name);
    }
    if (this.inputValues.accountingId) {
      this.queryRef = this.queryRef.where('AccountingId', '==', this.inputValues.accountingId);
    }
    if (this.inputValues.facilityUnit) {
      this.queryRef = this.queryRef.where('FacilityUnitRef', '==', this.inputValues.facilityUnit.id);
    }
    if (this.inputValues.facility) {
      this.queryRef = this.queryRef.where('Facility', '==', this.inputValues.facility);
    }
    if (this.inputValues.shift) {
      this.queryRef = this.queryRef.where('Shift', '==', this.inputValues.shift);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
  }
  generateSortFormQuery() {
    if (this.inputValues.formId) {
      return this.queryRef = Firestore.collection('Tracking-Forms').doc(this.inputValues.formId);
    }
    this.queryRef = Firestore.collection('Tracking-Forms').where('FormType', '==', 'Sort');
    if (this.inputValues.facilityUnit) {
      this.queryRef = this.queryRef.where('FacilityUnitRef', '==', this.inputValues.facilityUnit.id);
    }
    if (this.inputValues.facility) {
      this.queryRef = this.queryRef.where('Facility', '==', this.inputValues.facility);
    }
    if (this.inputValues.shift) {
      this.queryRef = this.queryRef.where('Shift', '==', this.inputValues.shift);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
  }
  generateConsolidationFormQuery() {
    if (this.inputValues.formId) {
      return this.queryRef = Firestore.collection('Tracking-Forms').doc(this.inputValues.formId);
    }
    this.queryRef = Firestore.collection('Tracking-Forms').where('FormType', '==', 'Consolidation').where('MixedLoad', '==', this.inputValues.mixedLoad);
    if (this.inputValues.facilityUnit) {
      this.queryRef = this.queryRef.where('FacilityUnitRef', '==', this.inputValues.facilityUnit.id);
    }
    if (this.inputValues.facility) {
      this.queryRef = this.queryRef.where('Facility', '==', this.inputValues.facility);
    }
    if (this.inputValues.consolidationContainer) {
      this.queryRef = this.queryRef.where('ConsolidationContainer', '==', this.inputValues.consolidationContainer);
    }
    if (this.inputValues.material) {
      this.queryRef = this.queryRef.where('MaterialRef', '==', this.inputValues.material?.MaterialId);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Date', '>=', startDate).where('Date', '<=', endDate);
    }
  }
  generateReturnedShippingDocumentQuery() {
    return this.queryRef = Firestore.collection('Tracking-Shipments')
      .where('ReturnedShippingDocumentRequired', '==', true)
      .where('ReturnedShippingDocumentReceived', '==', false);
  }

  // the below functions assign this.queryRef to an array of queries, triggering the this.sendArrayOfQueries func.
  generateContainerQuery() {
    return this.queryRef = [
      Firestore.collectionGroup('Consolidated-Containers').where('OriginalShortNo', '==', this.inputValues.shortNumber),
      Firestore.collectionGroup('Inbound-Containers').where('ShortNo', '==', this.inputValues.shortNumber),
      Firestore.collectionGroup('Processed-Containers').where('ShortNo', '==', this.inputValues.shortNumber),
      Firestore.collectionGroup('Created-Yields').where('CreatedShortNo', '==', this.inputValues.shortNumber),
      Firestore.collectionGroup('Tracking-Forms'),
      Firestore.collection('Tracking-Forms').where('FormType', '==', 'KBI Generation').where('ContainerRef', '==', this.inputValues.shortNumber),
      Firestore.collection('Tracking-Shipments').where('ContainersShipped', 'array-contains', this.inputValues.shortNumber),
    ];
  }
  generateAnnualInboundQuery() {
    const reportYear = 2022;
    return this.queryRef = [
      Firestore.collection('Tracking-Shipments').where('Direction', '==', 'Inbound')
        .where('ShipmentTime', '>=', new Date(`${reportYear}-01-01`))
        .where('ShipmentTime', '<', new Date(`${reportYear + 1}-01-01`)), // get inbounds from 2021
      Firestore.collectionGroup('Shipping-Documents')
        .where('System.CreatedOn', '>=', new Date(`${reportYear}-01-01`))
        .where('System.CreatedOn', '<', new Date(`${reportYear + 1}-01-01`)), // get shippingDocs from 2021
      Firestore.collection('Tracking-Inventory')
        .where('AccumulationStartDate', '>=', new Date(`${reportYear}-01-01`)),
      // .where('Source', '!=', 'Process') // DK Removed Source query filter and replaced with AccumulationStartDate to better reduce amount of records pulled
    ];
  }
  generateAnnualOutboundQuery() {
    const reportYear = 2022;
    return this.queryRef = [
      Firestore.collection('Tracking-Shipments').where('Direction', '==', 'Outbound')
        .where('ShipmentTime', '>=', new Date(`${reportYear}-01-01`))
        .where('ShipmentTime', '<', new Date(`${reportYear + 1}-01-01`)), // get outbounds from 2021
      Firestore.collection('Tracking-Inventory')
        .where('AccumulationStartDate', '>=', new Date(`${reportYear - 1}-01-01`)),
      Firestore.collectionGroup('Lines')
        .where('System.CreatedOn', '>=', new Date(`${reportYear}-01-01`))
        .where('System.CreatedOn', '<', new Date(`${reportYear + 1}-01-01`)), // get lines from outbounds from 2021
    ];
  }
  // this function generates an inventory query based on the inputs from the modal, as well as other queries to send.
  // the async is used to assign values to this.subcollectionResults, which is used to filter the results of inventory items
  async generateInventoryQuery() {
    let inventoryQuery = Firestore.collection('Tracking-Inventory');
    if (this.inputValues.account) {
      inventoryQuery = inventoryQuery.where('AccountRef', '==', this.inputValues.account.AccountId);
    } if (this.inputValues.incomingPieceNo) {
      inventoryQuery = inventoryQuery.where('IncomingPieceNo', '==', parseInt(this.inputValues.incomingPieceNo));
    } if (this.inputValues.material) {
      inventoryQuery = inventoryQuery.where('Material.Ref', '==', this.inputValues.material.MaterialId);
    } if (this.inputValues.materialType) {
      inventoryQuery = inventoryQuery.where('Type.Ref', '==', this.inputValues.materialType.id);
    } if (this.inputValues.manifested !== '') {
      inventoryQuery = inventoryQuery.where('Manifested', '==', this.inputValues.manifested);
    } if (this.inputValues.billable !== '') {
      inventoryQuery = inventoryQuery.where('Billable', '==', this.inputValues.billable);
    } if (this.inputValues.shippingDocumentNumber) {
      const shippingDocumentSnap = await Firestore.collectionGroup('Shipping-Documents')
        .where('DocumentNumber', '==', this.inputValues.shippingDocumentNumber.toUpperCase())
        .get();
      let shippingDocId = null;
      shippingDocumentSnap.forEach(doc => {
        // there will only be one document that is inside the shippingDocumentSnap
        shippingDocId = doc.id;
      });
      // push into the array of subcollection query results the id of the document that contained the shipping document queried for
      this.subcollectionResults = [...this.subcollectionResults, shippingDocId];
    } if (this.inputValues.generator) {
      const locationSnap = await Firestore.collectionGroup('Locations')
        .get();
      let locationId = null;
      locationSnap.forEach(doc => {
        if (doc.id === this.inputValues.generator.id) {
          locationId = doc.id;
        }
      });
      // push into the array of subcollection query results the id of the location
      this.subcollectionResults = [...this.subcollectionResults, locationId];
    } if (this.inputValues.purchaseOrderNumber) {
      const purchaseOrderSnap = await Firestore.collectionGroup('Purchase-Orders')
        .get();
      let poId = null;
      purchaseOrderSnap.forEach(doc => {
        if (doc.data().OrderNumber === this.inputValues.purchaseOrderNumber) {
          poId = doc.id;
        }
      });
      // push into the array of subcollection query results the id of the po
      this.subcollectionResults = [...this.subcollectionResults, poId];
    } if (this.inputValues.inboundId) {
      inventoryQuery = inventoryQuery.where('ShipmentInRef', '==', this.inputValues.inboundId);
    } if (this.inputValues.outboundId) {
      inventoryQuery = inventoryQuery.where('ShipmentOutRef', '==', this.inputValues.outboundId);
    } if (this.inputValues.formId) {
      inventoryQuery = inventoryQuery.where('FormHistory', 'array-contains', this.inputValues.formId);
    }
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.startDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      inventoryQuery = inventoryQuery.where('AccumulationStartDate', '>=', startDate).where('AccumulationStartDate', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      inventoryQuery = inventoryQuery.where('AccumulationStartDate', '>=', startDate).where('AccumulationStartDate', '<=', endDate);
    }
    return this.queryRef = [
      Firestore.collectionGroup('Shipping-Documents'),
      inventoryQuery,
      Firestore.collectionGroup('Purchase-Orders'),
    ];
  }

  // the below functions are used to format the data after the return of query data
  handleArrayOfQueryResults(arrayOfResultArrays) {
    if (this.reportType === 'annualInbound') {
      return this.arrayOfResults = this.formatInboundInventoryData(arrayOfResultArrays);
    }
    else if (this.reportType === 'annualOutbound') {
      return this.arrayOfResults = this.formatOutboundInventoryData(arrayOfResultArrays);
    }
    else if (this.reportType === 'inventoryItems') {
      return this.arrayOfResults = this.formatInventoryData(arrayOfResultArrays);
    }
    else if (this.reportType === 'container') {
      return this.arrayOfResults = this.formatContainerData(arrayOfResultArrays);
    }
  }
  formatInboundInventoryData(arrayOfResultArrays) {
    const inboundMap = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[0]);
    const shippingDocumentMap = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[1]);
    // DK Added filter to remove Inventory created by Process form; Needed because initial filter removed from generateAnnualInboundQuery query;
    const inventoryInboundArray = arrayOfResultArrays[2].docs.filter(doc => doc.data().Source !== 'Process');
    const inventoryArray = inventoryInboundArray.filter(doc => inboundMap[doc.data().ShipmentInRef]).map(doc => {
      const docData = {...doc.data(), id: doc.id};
      return {
        ...docData,
        ShipmentTime: inboundMap[docData.ShipmentInRef].ShipmentTime.toDate(),
        LocationName: this.reduxValues.locations[docData.AccountRef].find(location => location.id === shippingDocumentMap[docData.ShippingDocRef]?.GeneratorRef)?.LocationName,
        LocationEpaId: this.reduxValues.locations[docData.AccountRef].find(location => location.id === shippingDocumentMap[docData.ShippingDocRef]?.GeneratorRef)?.EpaId,
        TransporterName: this.reduxValues.accounts[inboundMap[docData.ShipmentInRef].Transporter].AccountName,
        TransporterEpaId: inboundMap[docData.ShipmentInRef].TransporterEpaId,
        Facility: inboundMap[docData.ShipmentInRef].Facility,
        ShippingDocType: shippingDocumentMap[docData.ShippingDocRef]?.DocumentType,
        ShippingDocNumber: shippingDocumentMap[docData.ShippingDocRef]?.DocumentNumber,
        CACodeString: docData.CACodes.join(', '),
        RCRACodeString: docData.RCRACodes.join(', '),
        Classification: docData.Classification,
        HandlingCode: this.reduxValues.types[docData.Material.Ref].find(item => item.id === docData.Type.Ref)?.HandlingCode || docData.Type.Name,
      };
    });
    return inventoryArray;
  }
  formatOutboundInventoryData(arrayOfResultArrays) {
    const outboundMap = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[0]);
    this.attachLinesToOutbounds(arrayOfResultArrays[2], outboundMap);
    const inventoryArray = arrayOfResultArrays[1].docs.filter(doc => outboundMap[doc.data().ShipmentOutRef]).map(doc => {
      const docData = {...doc.data(), id: doc.id};
      const matchingOutbound = outboundMap[docData.ShipmentOutRef];
      const matchingLocation = this.reduxValues.locations[matchingOutbound.AccountId].find(location => location.id === matchingOutbound.LocationRef);
      return {
        ...docData,
        ShipmentTime: matchingOutbound.ShipmentTime.toDate(),
        LocationName: matchingLocation.LocationName,
        LocationEpaId: matchingLocation.EpaId,
        TransporterName: this.reduxValues.accounts[matchingOutbound.Transporter].AccountName,
        TransporterEpaId: matchingOutbound.TransporterEpaId,
        Facility: matchingOutbound.Facility,
        ShippingDocType: matchingOutbound.DocumentType,
        ShippingDocNumber: matchingOutbound.DocumentNumber,
        CACodeString: (() => {
          if (matchingOutbound.DocumentType !== 'HW Manifest') return '';
          else if (!matchingOutbound?.lineData) {
            console.log('No CACodeString lineData found: ', {docData, matchingOutbound});
            return '';
          }

          return matchingOutbound.lineData.find(line => line.Material.Ref === docData.Material.Ref)?.CACodes.join(', ') || matchingOutbound.lineData[0].CACodes.join('');
        })(),
        RCRACodeString: (() => {
          if (matchingOutbound.DocumentType !== 'HW Manifest') return '';
          else if (!matchingOutbound?.lineData) {
            console.log('No RCRACodeString lineData found: ', {docData, matchingOutbound});
            return '';
          }

          return matchingOutbound.lineData.find(line => line.Material.Ref === docData.Material.Ref)?.RCRACodes.join(', ') || matchingOutbound.lineData[0].CACodes.join('');
        })(),
        /* Old Gerry code causing report to crash when HW Manifest contains material with no waste codes
        CACodeString: matchingOutbound.DocumentType === 'HW Manifest' ?
          matchingOutbound.lineData.find(line => line.Material.Ref === docData.Material.Ref)?.CACodes.join(', ') ||
          matchingOutbound.lineData[0].CACodes.join('') : '',
        RCRACodeString: matchingOutbound.DocumentType === 'HW Manifest' ?
          matchingOutbound.lineData.find(line => line.Material.Ref === docData.Material.Ref)?.RCRACodes.join(', ')||
          matchingOutbound.lineData[0].CACodes.join('') : '',
        */
        Classification: docData.OutboundClassification,
        HandlingCode: this.reduxValues.types[docData.Material.Ref].find(item => item.id === docData.Type.Ref)?.HandlingCode || 'H010',
      };
    });
    return inventoryArray;
  }
  attachLinesToOutbounds(arrayOfLineDocs, outboundMap) {
    arrayOfLineDocs.forEach(doc => {
      const data = {...doc.data(), id: doc.id, parentRef: doc.ref.parent.parent.id};
      if (outboundMap?.[data?.parentRef]?.lineData) {
        outboundMap[data.parentRef].lineData.push(data);
      }
      else outboundMap[data.parentRef].lineData = [data];
    });
  }
  formatInventoryData(arrayOfResultArrays) {
    const shippingDocumentMap = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[0]);
    const purchaseOrderMap = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[2]);
    const inventoryArray = arrayOfResultArrays[1].docs.map(doc => {
      const docData = {...doc.data(), id: doc.id};
      return {
        ...docData,
        AccumulationStartDate: docData.AccumulationStartDate.toDate(),
        accountName: this.reduxValues.accounts[docData.AccountRef].AccountName,
        generator: this.reduxValues.locations[docData.AccountRef].find(location => location.id === shippingDocumentMap[docData.ShippingDocRef]?.GeneratorRef),
        generatorId: shippingDocumentMap[docData.ShippingDocRef]?.GeneratorRef,
        purchaseOrder: purchaseOrderMap[docData.PurchaseOrderRef],
        shippingDoc: shippingDocumentMap[docData.ShippingDocRef],
      };
    });
    return inventoryArray;
  }
  formatContainerData(arrayOfResultArrays) {
    const arrayOfFormsToReturn = [];
    const mapOfForms = this.createObjectMapFromFirestoreDocs(arrayOfResultArrays[4]);
    // this loop goes over the 4 results of the query (consolidate, sort, processed, yielded) and connects the query to the form
    for (let i = 0; i < 4; i++) {
      arrayOfResultArrays[i].forEach(doc => {
        if (mapOfForms[doc.ref.parent.parent.id]) {
          mapOfForms[doc.ref.parent.parent.id] = {
            ...mapOfForms[doc.ref.parent.parent.id],
            Date: mapOfForms[doc.ref.parent.parent.id].Date,
            facilityUnitName: this.reduxValues.facilityUnits.ref[mapOfForms[doc.ref.parent.parent.id]?.FacilityUnitRef]?.Name,
          };
          arrayOfFormsToReturn.push(mapOfForms[doc.ref.parent.parent.id]);
        }
      });
    }
    // this goes over the array of shipments and formats them to fit in the table
    arrayOfResultArrays[6].forEach(doc => {
      const docData = {...doc.data(), id: doc.id};
      arrayOfFormsToReturn.push({
        ...docData,
        Date: docData.ShipmentTime.toDate(),
        facilityUnitName: '',
        FormType: docData.Direction,
      });
    });
    arrayOfResultArrays[5].forEach(doc => {
      const docData = {...doc.data(), id: doc.id};
      arrayOfFormsToReturn.push({
        ...docData,
        Date: docData.DateCreated.toDate(),
        facilityUnitName: '',
      });
    });
    return arrayOfFormsToReturn;
  }
  // does any comparison needed between this.arrayOfResults, and this.subcollectionResults. once finished filtering, sets this.arrayOfResults to the new filtered values.
  filterResultsBySubcollectionResults() {
    const filteredArray = this.arrayOfResults.filter(resultDoc => {
      if (this.reportType === 'inventoryItems') {
        if (this.subcollectionResults.some(id => id === resultDoc.generatorId || id === resultDoc.PurchaseOrderRef ||id === resultDoc.ShippingDocRef)) {
          return true;
        }
        else return false;
      }
      else {
        if (this.subcollectionResults.includes(resultDoc.id)) {
          return true;
        }
        else return false;
      }
    });

    this.arrayOfResults = filteredArray;
  }
  createObjectMapFromFirestoreDocs(array) {
    const returnedMap = {};
    array.forEach(doc => {
      returnedMap[doc.id] = {...doc.data(), id: doc.id};
    });
    return returnedMap;
  }
}

export default ReportGenerator;

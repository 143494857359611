import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment} from '@material-ui/core';
import {FormControlLabel, FormControl, List, ListItem, ListItemText} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, TextField, Checkbox, Chip, IconButton} from '@material-ui/core';
import {Add, HighlightOff, HelpOutlined} from '@material-ui/icons';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Alert} from '@kbi/component-library';
import {DynamicIcon, SubmitButton, SubmitConfirmation} from 'components/';
import get from 'lodash.get';

const cleanState = {
  stage: 'material',
  addMetalModalOpen: false,
  formError: '',
  createdElements: [],
  submitting: false,
  active: {
    display: 'Active',
    error: '',
    value: true,
  },
  category: {
    display: 'Category',
    error: '',
    value: '',
  },
  yield: {
    display: 'Yield',
    error: '',
    value: false,
  },
  kbiGenerated: {
    display: 'KBI Generated?',
    error: '',
    value: false,
  },
  inboundFormAllowed: {
    display: 'Inbound Allowed',
    error: '',
    value: true,
  },
  name: {
    display: 'Material Name',
    error: '',
    value: '',
  },
  nameShort: {
    display: 'Short Name',
    error: '',
    value: '',
  },
  processFormAllowed: {
    display: 'Process Allowed',
    error: '',
    value: true,
  },
  shippingRegs: {
    display: 'Shipping Regulations',
    error: '',
    value: '',
    codes: [],
  },
  otherRegs: {
    display: 'Other Regulation',
    error: '',
    value: '',
    codes: [],
  },
  rcraWasteCodes: {
    display: 'Allowed RCRA Waste Codes',
    error: '',
    value: '',
    codes: [],
  },
  caWasteCodes: {
    display: 'Allowed CA Waste Codes',
    error: '',
    value: '',
    codes: [],
  },
  rq: {
    display: 'RQ',
    value: '',
  },
  secondaryHazardClass: {
    display: 'Second Hazard Class',
    value: '',
  },
  rqSubstance: {
    display: 'RQ-Substance',
    error: '',
    value: '',
  },
  inhalationHazard: {
    display: 'Inhalation Hazard',
    error: '',
    value: 'no',
  },
  pihZone: {
    display: 'PIH/Zone',
    error: '',
    value: '',
  },
  color: {
    display: 'Color',
    error: '',
    value: '',
  },
  odor: {
    display: 'Odor',
    error: '',
    value: '',
  },
  layers: {
    display: 'Layers',
    error: '',
    value: '',
  },
  liquid: {
    display: 'Liquid (%)',
    error: '',
    value: '',
  },
  solid: {
    display: 'Solid (%)',
    error: '',
    value: '',
  },
  sludge: {
    display: 'Sludge (%)',
    error: '',
    value: '',
  },
  powder: {
    display: 'Powder (%)',
    error: '',
    value: '',
  },
  compressedGas: {
    display: 'Compressed Gas (%)',
    error: '',
    value: '',
  },
  dispersable: {
    display: 'Dispersable Solids (%)',
    error: '',
    value: '',
  },
  nonDispersable: {
    display: 'Non-Dispersable (%)',
    error: '',
    value: '',
  },
  other: {
    display: 'Other',
    error: '',
    value: '',
  },
  specificGravity: {
    display: 'Specific Gravity',
    error: '',
    value: '',
  },
  pH: {
    display: 'pH',
    error: '',
    value: '',
  },
  ash: {
    display: 'Ash (weight) (%)',
    error: '',
    value: '',
  },
  flashPoint: {
    display: 'Flash Point (Open Cup)',
    error: '',
    value: '',
  },
  total: {
    display: 'Total (%)',
    error: '',
    value: '',
  },
  tclp: {
    display: 'TCLP (%)',
    error: '',
    value: '',
  },
  stlc: {
    display: 'STLC (%)',
    error: '',
    value: '',
  },
  ttlc: {
    display: 'TTLC (%)',
    error: '',
    value: '',
  },
  totalUnits: {
    display: 'Units',
    error: '',
    value: '',
  },
  tclpUnits: {
    display: 'Units',
    error: '',
    value: '',
  },
  stlcUnits: {
    display: 'Units',
    error: '',
    value: '',
  },
  ttlcUnits: {
    display: 'Units',
    error: '',
    value: '',
  },
  metalElement: {
    display: 'Element',
    error: '',
    value: '',
  },
  flagStatus: {
    display: 'Flags?',
    error: '',
    value: 'N/A',
  },
  flags: [],
  elements: [],
  constituents: [
    {
      constituent: {display: 'Constituent', value: '', error: '', key: 'constituent'},
      range: {display: 'Range (%)', value: '', error: '', key: 'range'},
    },
  ],
  lessThanReg: {
    checked: true,
    error: false,
  },
  articleNA: {
    checked: false,
  },
  analysis: {
    checked: true,
  },
  generatorKnowledge: {
    checked: false,
  },
  materialId: '',
};

class ModalMaterial extends Component {
  state = {
    ...cleanState,
  };
  componentDidMount() {
    if (this.props.modalMaterial.material) {
      // this is true if you are editing a material, and it populated modal with the materials data
      const material = this.props.modalMaterial.material;
      this.setState(prevState => ({
        active: {...prevState.active, value: material.Active},
        constituents: material.ChemicalConstituents.map(c => {
          return {
            constituent: {display: 'Constituent', value: c.constituent, error: '', key: 'constituent'},
            range: {display: 'Range (%)', value: c.range, error: '', key: 'range'},
          };
        }),
        elements: material.Metals.Elements ? [...material.Metals.Elements] : [],
        articleNA: {...prevState.articleNA, checked: material.Metals.Article},
        lessThanReg: {...prevState.lessThanReg, checked: material.Metals.LessThanRegulated},
        color: {...prevState.color, value: material.PhysicalCharacteristics.Color},
        odor: {...prevState.odor, value: material.PhysicalCharacteristics.Odor},
        layers: {...prevState.layers, value: material.PhysicalCharacteristics.Layers},
        liquid: {...prevState.liquid, value: material.PhysicalCharacteristics.Liquid},
        solid: {...prevState.solid, value: material.PhysicalCharacteristics.Solid},
        sludge: {...prevState.sludge, value: material.PhysicalCharacteristics.Sludge},
        powder: {...prevState.powder, value: material.PhysicalCharacteristics.Powder},
        compressedGas: {...prevState.compressedGas, value: material.PhysicalCharacteristics.CompressedGas},
        dispersable: {...prevState.dispersable, value: material.PhysicalCharacteristics.DispersableSolids},
        nonDispersable: {
          ...prevState.nonDispersable,
          value: material.PhysicalCharacteristics.NonDispersableSolids,
        },
        flagStatus: {...prevState.flagStatus, value: material.FlagStatus},
        flags: material.Flags.map(flag => ({Name: flag.Name, Path: flag.Path})),
        other: {...prevState.other, value: material.PhysicalCharacteristics.Other},
        specificGravity: {...prevState.specificGravity, value: material.PhysicalCharacteristics.SpecificGravity},
        pH: {...prevState.pH, value: material.PhysicalCharacteristics.PH},
        ash: {...prevState.ash, value: material.PhysicalCharacteristics.Ash},
        flashPoint: {...prevState.flashPoint, value: material.PhysicalCharacteristics.FlashPoint},
        shippingRegs: {...prevState.shippingRegs, codes: [...material.Regulations.Shipping]},
        otherRegs: {...prevState.otherRegs, codes: [...material.Regulations.Other]},
        rcraWasteCodes: {...prevState.rcraWasteCodes, codes: [...material.WasteCodes.RCRA]},
        caWasteCodes: {...prevState.caWasteCodes, codes: [...material.WasteCodes.CA]},
        inhalationHazard: {...prevState.inhalationHazard, value: material.ShippingInformation.InhalationHazard},
        pihZone: {...prevState.pihZone, value: material.ShippingInformation.PIHZone},
        rq: {...prevState.rq, value: material.ShippingInformation.RQ},
        rqSubstance: {...prevState.rqSubstance, value: material.ShippingInformation.RQSubstance},
        category: {...prevState.category, value: material.UnitDetails.Category},
        inboundFormAllowed: {...prevState.inboundFormAllowed, value: material.UnitDetails.InboundFormAllowed},
        processFormAllowed: {...prevState.processFormAllowed, value: material.UnitDetails.ProcessFormAllowed},
        name: {...prevState.name, value: material.UnitDetails.MaterialName},
        nameShort: {...prevState.nameShort, value: material.UnitDetails.MaterialNameShort ? material.UnitDetails.MaterialNameShort : ''},
        yield: {...prevState.yield, value: material.UnitDetails.Yield},
        kbiGenerated: {...prevState.kbiGenerated, value: material.UnitDetails.KbiGenerated},
        materialId: material.MaterialId,
      }));
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.modalMaterial.material !== this.props.modalMaterial.material) {
      // this is true if you are editing a material, and it populated modal with the materials data
      const material = this.props.modalMaterial.material;
      this.setState(prevState => ({
        active: {...prevState.active, value: material.Active},
        constituents: material.ChemicalConstituents.map(c => {
          return {
            constituent: {display: 'Constituent', value: c.constituent, error: '', key: 'constituent'},
            range: {display: 'Range (%)', value: c.range, error: '', key: 'range'},
          };
        }),
        elements: material.Metals.Elements ? [...material.Metals.Elements] : [],
        articleNA: {...prevState.articleNA, checked: material.Metals.Article},
        lessThanReg: {...prevState.lessThanReg, checked: material.Metals.LessThanRegulated},
        color: {...prevState.color, value: material.PhysicalCharacteristics.Color},
        odor: {...prevState.odor, value: material.PhysicalCharacteristics.Odor},
        layers: {...prevState.layers, value: material.PhysicalCharacteristics.Layers},
        liquid: {...prevState.liquid, value: material.PhysicalCharacteristics.Liquid},
        solid: {...prevState.solid, value: material.PhysicalCharacteristics.Solid},
        sludge: {...prevState.sludge, value: material.PhysicalCharacteristics.Sludge},
        powder: {...prevState.powder, value: material.PhysicalCharacteristics.Powder},
        compressedGas: {...prevState.compressedGas, value: material.PhysicalCharacteristics.CompressedGas},
        dispersable: {...prevState.dispersable, value: material.PhysicalCharacteristics.DispersableSolids},
        nonDispersable: {
          ...prevState.nonDispersable,
          value: material.PhysicalCharacteristics.NonDispersableSolids,
        },
        flagStatus: {...prevState.flagStatus, value: material.FlagStatus},
        flags: material.Flags.map(flag => ({Name: flag.Name, Path: flag.Path})),
        other: {...prevState.other, value: material.PhysicalCharacteristics.Other},
        specificGravity: {...prevState.specificGravity, value: material.PhysicalCharacteristics.SpecificGravity},
        pH: {...prevState.pH, value: material.PhysicalCharacteristics.PH},
        ash: {...prevState.ash, value: material.PhysicalCharacteristics.Ash},
        flashPoint: {...prevState.flashPoint, value: material.PhysicalCharacteristics.FlashPoint},
        shippingRegs: {...prevState.shippingRegs, codes: [...material.Regulations.Shipping]},
        otherRegs: {...prevState.otherRegs, codes: [...material.Regulations.Other]},
        rcraWasteCodes: {...prevState.rcraWasteCodes, codes: [...material.WasteCodes.RCRA]},
        caWasteCodes: {...prevState.caWasteCodes, codes: [...material.WasteCodes.CA]},
        inhalationHazard: {...prevState.inhalationHazard, value: material.ShippingInformation.InhalationHazard},
        pihZone: {...prevState.pihZone, value: material.ShippingInformation.PIHZone},
        rq: {...prevState.rq, value: material.ShippingInformation.RQ},
        rqSubstance: {...prevState.rqSubstance, value: material.ShippingInformation.RQSubstance},
        category: {...prevState.category, value: material.UnitDetails.Category},
        inboundFormAllowed: {...prevState.inboundFormAllowed, value: material.UnitDetails.InboundFormAllowed},
        processFormAllowed: {...prevState.processFormAllowed, value: material.UnitDetails.ProcessFormAllowed},
        name: {...prevState.name, value: material.UnitDetails.MaterialName},
        nameShort: {...prevState.nameShort, value: material.UnitDetails.MaterialNameShort ? material.UnitDetails.MaterialNameShort : ''},
        yield: {...prevState.yield, value: material.UnitDetails.Yield},
        materialId: material.MaterialId,
      }));
    }
  }
  getPhValue = input => {
    const regex = /[^<>\r]/g;
    return input.slice(input.search(regex)).trim();
  };
  handleBack = () => {
    if (this.state.stage === 'flags') {
      return this.setState({stage: 'material'});
    }
    if (this.state.stage === 'codes') {
      if (this.state.flagStatus.value === 'N/A') {
        return this.setState({stage: 'material'});
      }
      else {
        return this.setState({stage: 'flags'});
      }
    }
    if (this.state.stage === 'hazards') {
      this.setState({stage: 'codes'});
    }
    if (this.state.stage === 'characteristics') {
      this.setState({stage: 'hazards'});
    }
    if (this.state.stage === 'metals') {
      this.setState({stage: 'characteristics'});
    }
    if (this.state.stage === 'composition') {
      this.setState({stage: 'metals'});
    }
  };
  handleNext = () => {
    let dataError = false;
    this.setState({formError: ''});
    if (this.state.stage === 'material') {
      const {category, name, nameShort, flagStatus} = this.state;
      if (!name.value) {
        dataError = true;
        this.setState(prevState => ({
          name: {...prevState.name, error: 'Material Name field is required'},
        }));
      }
      if (!nameShort.value) {
        dataError = true;
        this.setState(prevState => ({
          nameShort: {...prevState.nameShort, error: 'Short Name field is required'},
        }));
      }
      if (!flagStatus.value) {
        dataError = true;
        this.setState(prevState => ({
          flagStatus: {...prevState.flagStatus, error: 'Flag status is a required field.'},
        }));
      }
      else if (flagStatus.value === 'N/A') {
        // if flagStatus is N/A, at this point we want to make sure there is no flags that are going to be lost in firestore.
        // the modal will skip over the flags collapse, and if there were old flags there, the user wont know to remove them.
        // this will ensure those flags dont remain
        this.setState({flags: []});
      }
      if (!category.value) {
        // this field is not required in a yield
        dataError = true;
        this.setState(prevState => ({
          category: {...prevState.category, error: 'Category field is required'},
        }));
      }
      if (!dataError) {
        if (this.state.flagStatus.value === 'N/A') {
          this.setState({stage: 'codes'});
        }
        else this.setState({stage: 'flags'});
      }
    }
    if (this.state.stage === 'flags') {
      const {flags} = this.state;
      for (let index = 0; index < flags.length; index++) {
        if ((flags[index].Name && !flags[index].Path) || (!flags[index].Name && flags[index].Path)) {
          dataError = true;
          this.setState({formError: 'Both flag fields are required. To remove a flag, please clear both of it\'s fields'});
        }
        else if (!flags[index].Name && !flags[index].Path) {
          flags.splice(index, 1);
          index--;
        }
      }
      if (!flags.length && this.state.flagStatus.value !== 'N/A') {
        dataError = true;
        this.setState({formError: 'At least one flag is required if the flag status of the material is not N/A.'});
      }
      if (!dataError) {
        this.setState({stage: 'codes'});
      }
    }
    if (this.state.stage === 'codes') {
      const {caWasteCodes, otherRegs, rcraWasteCodes, shippingRegs} = this.state;
      if (shippingRegs.codes.length === 0) {
        dataError = true;
        this.setState(prevState => ({
          shippingRegs: {...prevState.shippingRegs, error: 'Shipping Regulations field is required'},
        }));
      }
      if (otherRegs.codes.length === 0) {
        dataError = true;
        this.setState(prevState => ({
          otherRegs: {...prevState.otherRegs, error: 'Other Regulation field is required'},
        }));
      }
      if (rcraWasteCodes.codes.length === 0) {
        dataError = true;
        this.setState(prevState => ({
          rcraWasteCodes: {...prevState.rcraWasteCodes, error: 'RCRA Waste Codes field is required'},
        }));
      }
      if (caWasteCodes.codes.length === 0) {
        dataError = true;
        this.setState(prevState => ({
          caWasteCodes: {...prevState.caWasteCodes, error: 'California Waste Codes field is required'},
        }));
      }
      if (!dataError) {
        this.setState({stage: 'hazards'});
      }
    }
    if (this.state.stage === 'hazards') {
      const {inhalationHazard, pihZone, rq, rqSubstance} = this.state;
      if (!rq.value) {
        dataError = true;
        this.setState(prevState => ({rq: {...prevState.rq, error: 'This field is required'}}));
      }
      if (!rqSubstance.value && rq.value !== 'NA') {
        dataError = true;
        this.setState(prevState => ({rqSubstance: {...prevState.rqSubstance, error: 'This field is required'}}));
      }
      if (pihZone.value) {
        dataError = true;
        this.setState(prevState => ({
          pihZone: {...prevState.pihZone, error: 'This material is unacceptable'},
        }));
      }
      if (!inhalationHazard.value) {
        dataError = true;
        this.setState(prevState => ({
          inhalationHazard: {...prevState.inhalationHazard, error: 'This field is required'},
        }));
      }
      else if (inhalationHazard.value === 'yes') {
        dataError = true;
        this.setState(prevState => ({
          inhalationHazard: {...prevState.inhalationHazard, error: 'This material is unacceptable'},
        }));
      }
      if (!dataError) {
        this.setState({stage: 'metals'});
      }
    }
    if (this.state.stage === 'metals') {
      const {articleNA, elements, lessThanReg} = this.state;
      if (!lessThanReg.checked && !articleNA.checked && elements.length === 0) {
        dataError = true;
        this.setState({formError: 'Please select an option above or add a metal.'});
      }
      if (!dataError && !articleNA.checked) {
        this.setState({stage: 'characteristics'});
      }
      else if (!dataError && articleNA.checked) {
        this.setState({stage: 'composition'});
      }
    }
    if (this.state.stage === 'characteristics') {
      const {flashPoint, pH} = this.state;
      if (pH.value < 0 || pH.value > 12.5) {
        dataError = true;
        this.setState(prevState => ({
          pH: {...prevState.pH, error: 'Valid values are 0-12.5'},
        }));
      }
      /* Determine if code block is necessary
      const hasLetter = /\D/;
      if ((ash.value !== 'NA' && hasLetter.test(ash.value)) || ash.value < 0 || ash.value > 100) {
        dataError = true;
        this.setState(prevState => ({
          ash: { ...prevState.ash, error: 'Invalid percentage' }
        }));
      }
      */
      if (this.getPhValue(flashPoint.value) < 0 || this.getPhValue(flashPoint.value) > 12.5) {
        dataError = true;
        this.setState(prevState => ({
          flashPoint: {...prevState.flashPoint, error: 'Valid values are 0-12.5'},
        }));
      }
      if (!dataError) {
        this.setState({stage: 'composition'});
      }
    }
    if (this.state.stage === 'composition') {
      const {constituents} = this.state;
      const errorCheck = constituents.map((item, index) => {
        let constituent = {};
        let range = {};
        if (index === 0) {
          constituent = {
            ...item.constituent,
            error: !item.constituent.value ? 'Required Field' : '',
          };
          range = {
            ...item.range,
            error: !item.range.value ? 'Invalid Percentage' : '',
          };
          if (!item.constituent.value || !item.range.value) {
            dataError = true;
          }
        }
        else {
          if (item.constituent.value) {
            constituent = {
              ...item.constituent,
            };
            range = {
              ...item.range,
              error: !item.range.value ? 'Invalid Percetage' : '',
            };
            if (!item.range.value) {
              dataError = true;
            }
          }
          else if (item.range.value) {
            range = {
              ...item.range,
            };
            constituent = {
              ...item.constituent,
              error: !item.constituent.value ? 'Required Field' : '',
            };
            if (!item.constituent.value) {
              dataError = true;
            }
          }
        }
        return {constituent, range};
      });
      if (dataError) {
        this.setState({constituents: errorCheck});
      }
      else if (!dataError) {
        this.handleSubmit();
      }
    }
  };
  createDataForFirestore() {
    const returnedData = {
      Active: this.state.active.value,
      FlagStatus: this.state.flagStatus.value,
      Flags: this.state.flags,
      Metals: {
        LessThanRegulated: this.state.elements.length === 0 ? this.state.lessThanReg.checked : false,
        Article: this.state.elements.length === 0 ? this.state.articleNA.checked : false,
        Elements: this.state.elements.length > 0 ? this.state.elements : null,
      },
      PhysicalCharacteristics: {
        Ash: this.state.ash.value,
        Color: this.state.color.value.trim(),
        CompressedGas: this.state.compressedGas.value,
        DispersableSolids: this.state.dispersable.value,
        FlashPoint: this.state.flashPoint.value,
        Layers: this.state.layers.value,
        Liquid: this.state.liquid.value,
        NonDispersableSolids: this.state.nonDispersable.value,
        Odor: this.state.odor.value.trim(),
        Other: this.state.other.value,
        PH: this.state.pH.value.trim(),
        Powder: this.state.powder.value,
        Sludge: this.state.sludge.value,
        Solid: this.state.solid.value,
        SpecificGravity: this.state.specificGravity.value.trim(),
      },
      Regulations: {
        Shipping: this.state.shippingRegs.codes,
        Other: this.state.otherRegs.codes,
      },
      ShippingInformation: {
        RQ: this.state.rq.value,
        RQSubstance: this.state.rqSubstance.value.trim(),
        InhalationHazard: this.state.inhalationHazard.value,
        PIHZone: this.state.pihZone.value,
      },
      UnitDetails: {
        MaterialName: this.state.name.value.trim(),
        MaterialNameShort: this.state.nameShort.value.trim(),
        InboundFormAllowed: this.state.inboundFormAllowed.value,
        Yield: this.state.yield.value,
        ProcessFormAllowed: this.state.processFormAllowed.value,
        Category: this.state.category.value,
        KbiGenerated: this.state.kbiGenerated.value,
      },
      WasteCodes: {
        RCRA: this.state.rcraWasteCodes.codes,
        CA: this.state.caWasteCodes.codes,
      },
      ChemicalConstituents: this.state.constituents.map(c => {
        return {constituent: c.constituent.value, range: c.range.value};
      }),
      System: {
        CreatedOn: firebase.firestore.Timestamp.now(),
        CreatedBy: this.props.currentUser.displayName,
      },
    };
    return returnedData;
  }
  handleSubmit = () => {
    const {modalMaterial} = this.props;
    this.setState({submitting: true});
    const submitData = this.createDataForFirestore();
    if (modalMaterial.newMaterial) {
      Firestore.collection('Tracking-Materials')
        .doc()
        .set({...submitData})
        .then(() => this.setState({submitting: false, stage: 'success'}))
        .catch(err => {
          this.setState({submitting: false, formError: 'An error occured while trying to submit. Please try again.'});
        });
    }
    else {
      Firestore.collection('Tracking-Materials')
        .doc(this.state.materialId)
        .update({...submitData,
          System: {...submitData.System,
            UpdatedOn: new Date(),
            UpdatedBy: this.props.currentUser.displayName,
          },
        })
        .then(() => this.setState({submitting: false, stage: 'success'}))
        .catch(err => {
          this.setState({submitting: false, formError: 'An error occured while trying to submit. Please try again.'});
        });
    }
  };
  createChips = fieldName => {
    return this.state[fieldName].codes.map((code, i) => {
      return (
        <Chip
          key={code}
          style={{marginRight: 5, marginBottom: 5}}
          label={code}
          onDelete={() => {
            this.setState(prevState => ({
              [fieldName]: {
                ...prevState[fieldName],
                codes: prevState[fieldName].codes.filter(e => e !== code),
              },
            }));
          }}
        />
      );
    });
  };
  createElementField = (core, number) => {
    const element = (
      <Fragment>
        <Grid item xs={9}>
          <TextField {...core.textField(`element${number}`)} />
        </Grid>
        <Grid item xs={3}>
          <TextField {...core.textField(`element${number}Amount`)} />
        </Grid>
      </Fragment>
    );
    this.setState(prevState => ({createdElements: {...prevState.createdElements, element}}));
  };
  addRegulation = fieldName => {
    if (this.state[fieldName].value) {
      this.setState(prevState => ({
        [fieldName]: {
          ...prevState[fieldName],
          codes: [...prevState[fieldName].codes, prevState[fieldName].value.trim()],
          value: '',
        },
      }));
    }
  };
  handleMetalModalClose = () => {
    if (this.state.elements.length === 0) {
      this.setState(prevState => ({
        lessThanReg: {...prevState.lessThanReg, checked: true},
        articleNA: {checked: false},
      }));
    }
    this.setState({addMetalModalOpen: false});
  };
  addMetal = () => {
    let dataError = false;
    const {totalUnits, tclpUnits, stlcUnits, ttlcUnits, metalElement, total, tclp, stlc, ttlc} = this.state;
    this.setState(prevState => ({
      totalUnits: {...prevState.totalUnits, error: ''},
      tclpUnits: {...prevState.tclpUnits, error: ''},
      stlcUnits: {...prevState.stlcUnits, error: ''},
      ttlcUnits: {...prevState.ttlcUnits, error: ''},
      metalElement: {...prevState.metalElement, error: ''},
      total: {...prevState.total, error: ''},
      tclp: {...prevState.tclp, error: ''},
      stlc: {...prevState.stlc, error: ''},
      ttlc: {...prevState.ttlc, error: ''},
      total1: {...prevState.total1, error: ''},
      tclp1: {...prevState.tclp1, error: ''},
      stlc1: {...prevState.stlc1, error: ''},
      ttlc1: {...prevState.ttlc1, error: ''},
      total2: {...prevState.total2, error: ''},
      tclp2: {...prevState.tclp2, error: ''},
      stlc2: {...prevState.stlc2, error: ''},
      ttlc2: {...prevState.ttlc2, error: ''},
    }));
    if (!metalElement.value) {
      dataError = true;
      this.setState(prevState => ({metalElement: {...prevState.metalElement, error: 'This field is required'}}));
    }
    if (total.value && !totalUnits.value) {
      dataError = true;
      this.setState(prevState => ({totalUnits: {...prevState.totalUnits, error: 'This field is required'}}));
    }
    if (tclp.value && !tclpUnits.value) {
      dataError = true;
      this.setState(prevState => ({tclpUnits: {...prevState.tclpUnits, error: 'This field is required'}}));
    }
    if (stlc.value && !stlcUnits.value) {
      dataError = true;
      this.setState(prevState => ({stlcUnits: {...prevState.stlcUnits, error: 'This field is required'}}));
    }
    if (ttlc.value && !ttlcUnits.value) {
      dataError = true;
      this.setState(prevState => ({ttlcUnits: {...prevState.ttlcUnits, error: 'This field is required'}}));
    }
    if (!total.value && !tclp.value && !stlc.value && !ttlc.value) {
      dataError = true;
      this.setState(prevState => ({
        total: {...prevState.total, error: 'One or more of these are required'},
        tclp: {...prevState.tclp, error: 'One or more of these are required'},
        stlc: {...prevState.stlc, error: 'One or more of these are required'},
        ttlc: {...prevState.ttlc, error: 'One or more of these are required'},
      }));
    }
    if (!dataError) {
      const metal = {
        total: total.value,
        tclp: tclp.value,
        stlc: stlc.value,
        ttlc: ttlc.value,
        totalUnits: totalUnits.value,
        tclpUnits: tclpUnits.value,
        stlcUnits: stlcUnits.value,
        ttlcUnits: ttlcUnits.value,
        element: metalElement.value,
      };
      this.setState(
        prevState => ({
          elements: [...prevState.elements, metal],
          total: {...prevState.total, value: ''},
          tclp: {...prevState.tclp, value: ''},
          stlc: {...prevState.stlc, value: ''},
          ttlc: {...prevState.ttlc, value: ''},
          totalUnits: {...prevState.totalUnits, value: ''},
          tclpUnits: {...prevState.tclpUnits, value: ''},
          stlcUnits: {...prevState.stlcUnits, value: ''},
          ttlcUnits: {...prevState.ttlcUnits, value: ''},
          metalElement: {...prevState.metalElement, value: ''},
        }),
        () => this.handleMetalModalClose(),
      );
    }
  };
  deleteMetalFromList = index => {
    const newElements = this.state.elements.filter((e, i) => i !== index);
    if (newElements.length === 0) {
      this.setState(prevState => ({
        elements: newElements,
        lessThanReg: {...prevState.lessThanReg, checked: true},
        articleNA: {checked: false},
      }));
    }
    this.setState({elements: newElements});
  };
  createElementList = () => {
    const list = this.state.elements.map((e, i) => {
      return (
        <ListItem key={i} alignItems='flex-start'>
          <ListItemText
            primary={`${e.element}`}
            secondary={`${e.total && `Total: ${e.total}${e.totalUnits}`} ${e.tclp && `TCLP: ${e.tclp}${e.tclpUnits}`}
                ${e.stlc && `STLC: ${e.stlc}${e.stlcUnits}`} ${e.ttlc && `TTLC: ${e.ttlc}${e.ttlcUnits}`}`}
          />
          <IconButton onClick={() => this.deleteMetalFromList(i)}>
            <HighlightOff />
          </IconButton>
        </ListItem>
      );
    });
    return list;
  };
  createConstituentList = core => {
    const list = this.state.constituents.map((c, i) => {
      return (
        <Fragment key={i}>
          <Grid item xs={9}>
            <TextField {...core.constituentField(c.constituent, i)} required />
          </Grid>
          <Grid item xs={3}>
            <TextField {...core.constituentField(c.range, i)} required type='number' />
          </Grid>
        </Fragment>
      );
    });
    return list;
  };
  determineSubmitText() {
    const {modalMaterial} = this.props;
    if (modalMaterial.newMaterial) return 'New material successfully created.';
    else return 'Material successfully updated.';
  }
  determineTitle() {
    const {modalMaterial} = this.props;
    if (modalMaterial.newMaterial) return 'Create New Material';
    else return 'Edit Material';
  }
  handleClose = () => {
    this.setState({...cleanState});
    this.props.close();
  };
  render() {
    const {modalMaterial} = this.props;
    const newMaterial = get(modalMaterial, 'newMaterial', false);
    const core = {
      buttonAddConstituent: {
        color: 'primary',
        variant: 'outlined',
        onClick: () => {
          this.setState(prevState => ({
            constituents: [
              ...prevState.constituents,
              {
                constituent: {display: 'Constituent', value: '', error: '', key: 'constituent'},
                range: {display: 'Range (%)', value: '', error: '', key: 'range'},
              },
            ],
          }));
        },
      },
      buttonAddMetal: {
        color: 'primary',
        variant: 'outlined',
        onClick: () => {
          this.setState({
            addMetalModalOpen: true,
            lessThanReg: {error: false, checked: false},
            articleNA: {checked: false},
          });
        },
      },
      dialog: {
        open: modalMaterial !== null,
        scroll: 'body',
        transitionDuration: {exit: 0},
        maxWidth: 'md',
        fullWidth: true,
      },
      addFlagButton: {
        color: 'primary',
        variant: 'outlined',
        onClick: () => {
          this.setState({flags: [...this.state.flags, {Name: '', Path: ''}], formError: ''});
        },
      },
      svgPathField: index => ({
        fullWidth: true,
        label: 'Flag SVG Path',
        margin: 'normal',
        value: this.state.flags[index].Path || '',
        onChange: e => {
          const arrayForState = [...this.state.flags];
          arrayForState[index].Path = e.target.value;
          this.setState({flags: arrayForState, formError: ''});
        },
      }),
      flagField: index => ({
        fullWidth: true,
        label: 'Flag Name',
        margin: 'normal',
        value: this.state.flags[index].Name || '',
        InputProps: {
          endAdornment: (
            <InputAdornment position='end' style={{margin: '0 10px 10px 0'}}>
              {this.state.flags[index].Path ? <DynamicIcon svgStringProp={this.state.flags[index].Path} fontSize='large' /> :
                <HelpOutlined fontSize='large' />}
            </InputAdornment>),
        },
        onChange: e => {
          const arrayForState = [...this.state.flags];
          arrayForState[index].Name = e.target.value;
          this.setState({flags: arrayForState, formError: ''});
        },
      }),
      selectField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          label: this.state[key].display,
          margin: 'normal',
          select: true,
          value: this.state[key].value,
          onChange: e => {
            this.setState({[key]: {...cleanState[key], value: e.target.value}});
          },
        };
      },
      rangeField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          onChange: e => {
            this.setState({[key]: {...cleanState[key], value: e.target.value}});
          },
          onKeyDown: e => {
            // allows numbers
            if (e.keyCode >= 48 && e.keyCode <= 57) {
              return null;
              // numpad numbers
            }
            else if (e.keyCode >= 96 && e.keyCode <= 105) {
              return null;
              // < > - , . _
            }
            else if (e.keyCode >= 188 && e.keyCode <= 190) {
              return null;
              // arrow keys
            }
            else if (e.keyCode >= 37 && e.keyCode <= 40) {
              return null;
              // backspace, space, delete, tab -
            }
            else if (
              e.keyCode === 8 ||
              e.keyCode === 32 ||
              e.keyCode === 46 ||
              e.keyCode === 9 ||
              e.keyCode === 109
            ) {
              return null;
            }
            else {
              e.preventDefault();
            }
          },
        };
      },
      regulationField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'dense',
          value: this.state[key].value,
          onChange: e => {
            this.setState({[key]: {...cleanState[key], value: e.target.value, codes: this.state[key].codes}});
          },
          onKeyPress: e => {
            if (e.key === 'Enter') {
              this.addRegulation(key);
            }
          },
        };
      },
      rqField: {
        error: this.state.rq.error ? true : false,
        helperText: this.state.rq.error,
        fullWidth: true,
        label: this.state.rq.display,
        margin: 'normal',
        select: true,
        value: this.state.rq.value,
        onChange: e => {
          this.setState({
            rq: {...cleanState.rq, value: e.target.value},
            rqSubstance: e.target.value === 'NA' ? {...cleanState.rqSubstance} : {...this.state.rqSubstance},
          });
        },
      },
      percentField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          type: 'number',
          onChange: e => {
            this.setState({[key]: {display: this.state[key].display, value: e.target.value}});
          },
          onBlur: e => {
            if (e.target.value < 0 || e.target.value > 100) {
              this.setState({
                [key]: {...cleanState[key], value: ''},
              });
            }
          },
        };
      },
      shortnameField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          onChange: e => {
            if (e.target.value.includes(' ')) e.preventDefault(); // Do not allow spaces in short name
            else if (e.target.value.length <= 16) this.setState({[key]: {...cleanState[key], value: e.target.value}});
            else e.preventDefault();
          },
        };
      },
      textField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          onChange: e => {
            this.setState({[key]: {...cleanState[key], value: e.target.value}});
          },
        };
      },
      constituentField: (key, index) => {
        return {
          error: key.error ? true : false,
          helperText: key.error,
          fullWidth: true,
          id: 'Constituent' + index,
          label: key.display,
          margin: 'normal',
          value: key.value,
          onChange: e => {
            const constituents = this.state.constituents;
            constituents[index][key.key].value = e.target.value;
            constituents[index][key.key].error = '';
            this.setState({constituents});
          },
          onBlur: e => {
            if (parseInt(e.target.value) <= 0 || e.target.value > 100) {
              // if the field is a number between 1 and 100
              const constituents = this.state.constituents;
              constituents[index][key.key].value = 0;
              constituents[index][key.key].error = 'Invalid Percentage';
              this.setState({constituents});
            }
          },
        };
      },
      checkBox: key => {
        if (key === 'lessThanReg') {
          return {
            checked: this.state[key].checked,
            onChange: e =>
              this.setState(prevState => ({
                [key]: {checked: !prevState[key].checked},
                articleNA: {checked: false},
              })),
          };
        }
        if (key === 'articleNA') {
          return {
            checked: this.state[key].checked,
            onChange: e =>
              this.setState(prevState => ({
                [key]: {checked: !prevState[key].checked},
                lessThanReg: {checked: false},
              })),
          };
        }
        if (key === 'analysis') {
          return {
            checked: this.state[key].checked,
            onChange: e =>
              this.setState(prevState => ({
                [key]: {checked: !prevState[key].checked},
                generatorKnowledge: {checked: prevState[key].checked},
              })),
          };
        }
        if (key === 'generatorKnowledge') {
          return {
            checked: this.state[key].checked,
            onChange: e =>
              this.setState(prevState => ({
                [key]: {checked: !prevState[key].checked},
                analysis: {checked: prevState[key].checked},
              })),
          };
        }
      },
      submitConfirmation: {
        text: this.determineSubmitText(),
        stage: this.state.stage,
      },
      submitButton: {
        onClick: this.handleNext,
        color: 'primary',
        text: this.state.stage === 'composition' ? 'Submit' : 'Next',
        loading: this.state.submitting,
      },
    };
    const actionButtons = () => {
      if (this.state.stage === 'composition') {
        return (
          <div style={{justifyContent: 'space-between', width: '100%'}}>
            <Button onClick={this.handleClose} color='secondary' disabled={this.state.submitting}>
              Cancel
            </Button>
            <div style={{float: 'right'}}>
              <Button
                onClick={this.handleBack}
                color='primary'
                disabled={this.state.stage === 'material' || this.state.submitting}
              >
                Back
              </Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </div>
        );
      }
      if (this.state.stage === 'success') {
        return (
          <Button onClick={this.handleClose} color='primary'>
            Close
          </Button>
        );
      }
      else {
        return (
          <div style={{justifyContent: 'space-between', width: '100%'}}>
            <Button onClick={this.handleClose} color='secondary' disabled={this.state.submitting}>
              Cancel
            </Button>
            <div style={{float: 'right'}}>
              <Button
                onClick={this.handleBack}
                color='primary'
                disabled={this.state.stage === 'material' || this.state.submitting}
              >
                Back
              </Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </div>
        );
      }
    };
    return (
      <Dialog {...core.dialog}>
        <DialogTitle>{this.determineTitle()}</DialogTitle>
        <DialogContent>
          <Collapse in={this.state.stage === 'material'}>
            <DialogContentText>Enter primary information about material.</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <TextField {...core.textField('name')} required />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField {...core.shortnameField('nameShort')} required />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextField {...core.selectField('flagStatus')} required>
                  {['N/A', 'Optional', 'Required'].map(status => {
                    return (<MenuItem key={status} value={status}>{status}</MenuItem>);
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextField {...core.selectField('active')} disabled={newMaterial} required>
                  {[
                    <MenuItem key={true} value={true}>
                      True
                    </MenuItem>,
                    <MenuItem key={false} value={false}>
                      False
                    </MenuItem>,
                  ]}
                </TextField>
              </Grid>
              <Grid item xs={6} sm>
                <TextField {...core.selectField('category')} required>
                  {['Battery', 'eWaste', 'Onsite Process', 'Other'].map(category => {
                    return (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm>
                <TextField {...core.selectField('inboundFormAllowed')} required>
                  {[true, false].map(allowed => {
                    return (
                      <MenuItem key={allowed} value={allowed}>
                        {allowed ? 'True' : 'False'}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm>
                <TextField {...core.selectField('processFormAllowed')} required>
                  {[true, false].map(allowed => {
                    return (
                      <MenuItem key={allowed} value={allowed}>
                        {allowed ? 'True' : 'False'}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm>
                <TextField {...core.selectField('yield')} required>
                  {[true, false].map(allowed => {
                    return (
                      <MenuItem key={allowed} value={allowed}>
                        {allowed ? 'True' : 'False'}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm>
                <TextField {...core.selectField('kbiGenerated')} required>
                  {[true, false].map(allowed => {
                    return (
                      <MenuItem key={allowed} value={allowed}>
                        {allowed ? 'True' : 'False'}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'flags'}>
            <Grid container spacing={2}>
              {this.state.flags.map((flag, index) => {
                return (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                      <TextField {...core.flagField(index)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField multiline {...core.svgPathField(index)}>
                      </TextField>
                    </Grid>
                  </Fragment>);
              })}
              <Grid item xs={12} container
                justify='flex-end'
              >
                <Grid item xs={12} sm={3}>
                  <Button {...core.addFlagButton}><Add style={{marginRight: '8px'}} />Add Flag</Button>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'codes'}>
            <DialogContentText>Enter all regulations and waste codes.</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('shippingRegs')} required />
                  <Button color='primary' onClick={() => this.addRegulation('shippingRegs')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.shippingRegs.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('shippingRegs')}
                </Grid>
              )}
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('otherRegs')} required />
                  <Button color='primary' onClick={() => this.addRegulation('otherRegs')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.otherRegs.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('otherRegs')}
                </Grid>
              )}
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('rcraWasteCodes')} required />
                  <Button color='primary' onClick={() => this.addRegulation('rcraWasteCodes')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.rcraWasteCodes.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('rcraWasteCodes')}
                </Grid>
              )}
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('caWasteCodes')} required />
                  <Button color='primary' onClick={() => this.addRegulation('caWasteCodes')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.caWasteCodes.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('caWasteCodes')}
                </Grid>
              )}
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'hazards'}>
            <DialogContentText>Enter reportable quantity and hazard information.</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField {...core.rqField} required>
                  {[1, 10, 100, 1000, 5000, 'NA'].map(rq => {
                    return (
                      <MenuItem key={rq} value={rq}>
                        {rq === 'NA' ? 'NA' : `${rq} lbs.`}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  {...core.textField('rqSubstance')}
                  disabled={this.state.rq.value === 'NA' ? true : false}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.selectField('inhalationHazard')} required>
                  {['yes', 'no'].map(hazard => {
                    return (
                      <MenuItem key={hazard} value={hazard}>
                        {hazard === 'yes' ? 'Yes' : 'No'}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField {...core.textField('pihZone')} />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'characteristics'}>
            <DialogContentText>Enter the materials physical characteristics (if available).</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField {...core.textField('color')} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.textField('odor')} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.selectField('layers')}>
                  {['Single Phase', 'Bi-Layered', 'Multi-Layered', 'NA'].map(layer => {
                    return (
                      <MenuItem key={layer} value={layer}>
                        {layer}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.percentField('liquid')} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.percentField('solid')} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.percentField('sludge')} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.percentField('powder')} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.percentField('compressedGas')} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.percentField('dispersable')} />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.percentField('nonDispersable')} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.textField('other')} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.textField('specificGravity')} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.textField('pH')} type='number' />
              </Grid>
              <Grid item xs={4}>
                <TextField {...core.percentField('ash')} />
              </Grid>
              <Grid item xs={5}>
                <TextField {...core.textField('flashPoint')} />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'metals'}>
            <DialogContentText>Specify metals content in material (if any).</DialogContentText>
            <Grid container spacing={1}>
              {this.state.elements.length === 0 && (
                <Fragment>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<Checkbox {...core.checkBox('lessThanReg')} />}
                      label='None/Below Regulatory Levels'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl error={true}>
                      <FormControlLabel control={<Checkbox {...core.checkBox('articleNA')} />} label='Article (N/A)' />
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
              {this.state.elements.length > 0 && (
                <Grid item xs={12}>
                  <List>{this.createElementList()}</List>
                </Grid>
              )}
              <Grid item xs={3}>
                <Button {...core.buttonAddMetal}>Add Metal</Button>
              </Grid>
              <Dialog open={this.state.addMetalModalOpen} scroll='body'>
                <DialogTitle>Add Metal</DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControlLabel control={<Checkbox {...core.checkBox('analysis')} />} label='Analysis' />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={<Checkbox {...core.checkBox('generatorKnowledge')} />}
                        label='Generator Knowledge'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField {...core.textField('metalElement')} required margin='dense' />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.rangeField('total')} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.selectField('totalUnits')} required>
                        {['ppm', 'mg/l', 'mg/kg', '%'].map(unit => {
                          return (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.rangeField('tclp')} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.selectField('tclpUnits')} required>
                        {['ppm', 'mg/l', 'mg/kg'].map(unit => {
                          return (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.rangeField('stlc')} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.selectField('stlcUnits')} required>
                        {['ppm', 'mg/l', 'mg/kg'].map(unit => {
                          return (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.rangeField('ttlc')} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField {...core.selectField('ttlcUnits')} required>
                        {['ppm', 'mg/l', 'mg/kg'].map(unit => {
                          return (
                            <MenuItem key={unit} value={unit}>
                              {unit}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Button onClick={this.handleMetalModalClose} color='secondary'>
                      Cancel
                    </Button>
                    <Button onClick={this.addMetal} color='primary'>
                      Add
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </Grid>
          </Collapse>
          <Collapse in={this.state.stage === 'composition'}>
            <DialogContentText>Chemical Composition</DialogContentText>
            <Grid container spacing={2}>
              {this.createConstituentList(core)}
              <Grid item xs={9}>
                <Button {...core.buttonAddConstituent}>Add Constituent</Button>
              </Grid>
            </Grid>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
          <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
        </DialogContent>
        <DialogActions>{actionButtons()}</DialogActions>
      </Dialog >
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser};
};
ModalMaterial.propTypes = {
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  modalMaterial: PropTypes.shape({
    newMaterial: PropTypes.bool, // will be true if the modal is to be used for a new material
    material: PropTypes.object, // this will either be a selected material to edit, or not exist
  }),
};
export default connect(
  mapStateToProps,
  null,
)(ModalMaterial);

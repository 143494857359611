/* eslint-disable react/display-name */
import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Grid,
  Paper,
} from '@material-ui/core';
import {Add, Edit, Search} from '@material-ui/icons';
import {NewLineModal, LineContainerPanel} from './OutboundSummaryPanel/';
import {TableWithPaper} from 'components/';

const OutboundSummaryPanel = props => {
  const [newLineModalOpen, setNewLineModalOpen] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);

  const details = () => {
    if (props.listOfLines.length) {
      return (
        <TableWithPaper {...core.lineTable} />
      );
    }
    else {
      return (
        <Paper>
          <Grid container justify='space-between' style={{padding: '16px'}}>
            <Grid item xs={12}>
              <Typography variant='h6' paragraph>
                Outbound Summary
              </Typography>
            </Grid>
            <Grid item>
              <Typography>No item lines have been added to this outbound load.</Typography>
            </Grid>
            <Grid item>
              <Button {...core.addLineButton}><Add style={{marginRight: '8px'}} />Add Line</Button>
            </Grid>
          </Grid>
        </Paper>
      );
    }
  };


  const core = {
    lineTable: {
      columns: [
        {accessor: 'LineNumber', Header: 'Line'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'Classification', Header: 'Classification'},
        {accessor: 'ContainerCode', Header: 'Container Code'},
        {accessor: 'Quantity', Header: 'Quantity', type: 'numeric'},
        {accessor: 'totalGrossWeight', Header: 'Total Gross', type: 'numeric'},
        {accessor: 'totalNetWeight', Header: 'Total Net', type: 'numeric'},
      ],
      sortInitial: [{id: 'LineNumber', desc: false}],
      data: props.listOfLines,
      title: 'Outbound Summary',
      actionsPerRow:
        [
          {
            icon: Search,
            tooltip: 'View Containers',
            onClick: ({rowData}) => setSelectedLine(rowData),
          },
          {
            icon: Edit,
            tooltip: 'Edit Line',
            hide: !props.selectedOutbound.InProgress,
            onClick: ({rowData}) => {
              setNewLineModalOpen(true);
              setSelectedLine(rowData);
            },
          },
        ],
      actionsBar: [
        {
          icon: Add,
          text: 'Add Line',
          buttonProps: {style: {display: !props.selectedOutbound.InProgress ? 'none' : 'inline-flex'}},
          onClick: () => {
            setNewLineModalOpen(true);
            setSelectedLine(null);
          },
        },
      ],
      paperProps: {style: {margin: 0}},
    },
    addLineButton: {
      variant: 'outlined',
      onClick: () => {
        setNewLineModalOpen(true);
        setSelectedLine(null);
      },
    },
    newLineModal: {
      open: newLineModalOpen,
      close: () => {
        setNewLineModalOpen(false);
        setSelectedLine(null);
      },
      selectedOutbound: props.selectedOutbound,
      selectedLine,
    },
  };

  return (
    <Fragment>
      {details()}
      {newLineModalOpen && <NewLineModal {...core.newLineModal} />}
      {selectedLine && <LineContainerPanel selectedLine={selectedLine} />}
    </Fragment>
  );
};

OutboundSummaryPanel.propTypes = {
  selectedOutbound: PropTypes.object.isRequired,
  listOfLines: PropTypes.arrayOf(PropTypes.object),
};

export default OutboundSummaryPanel;

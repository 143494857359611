import React, {Fragment, useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  MenuItem,
  Button,
} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {containerCodes, SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import {Add, Clear} from '@material-ui/icons';
import * as yup from 'yup';
import {FieldArray} from 'formik';
import {useLocations, usePurchaseOrders} from 'hooks';
const {FormikForm, AutoCompleteObject, TextField, NumberField, SelectField, validateAutoObject, FormButton, SubmitButton} = Formik;
validateAutoObject();

const ShippingDocumentModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const purchaseOrders = usePurchaseOrders();
  const locations = useLocations();
  const {materials, accountProfiles} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const determineIfWasteCodesShown = useCallback((values, index) => {
    // set the value of displayWasteCodes. lots of conditions apply to if it should be shown, and it is checked in multiple places
    if (
      values.lineItems[index].classification === 'Hazardous Waste' &&
      values.documentType === 'HW Manifest'
    ) {
      return true;
    }
    else return false;
  }, []);

  const createLineItemData = useCallback(lineItemArray => {
    // formats line item data to be put into the database
    const firebaseLineItems = [];
    let counter = 0;
    // eslint-disable-next-line guard-for-in
    lineItemArray.forEach(lineData => {
      for (let quantityCounter = 0; quantityCounter < lineData.quantity; quantityCounter++) {
        firebaseLineItems.push({
          Line: lineData.line,
          PieceNumber: ++counter,
          ContainerCode: lineData.containerCode,
          Shipped: lineData.material,
          Status: '',
          Classification: lineData.classification,
          CACodes: lineData.caCodes?.map(codeObj => codeObj.value) || [],
          RCRACodes: lineData.rcraCodes?.map(codeObj => codeObj.value) || [],
        });
      }
    });
    return {pieceCount: counter, lineArray: firebaseLineItems};
  }, []);

  const createDataForFirestore = useCallback(values => {
    const firebaseObj = {
      DocumentNumber: values.documentNumber.trim(),
      DocumentType: values.documentType,
      PONumber: values.poNumber,
      WeightTickets: values.weightTickets?.length ?
        values.weightTickets.map(ticketDoc => ({TicketNumber: ticketDoc.TicketNumber, TicketRef: ticketDoc.id || ticketDoc.TicketRef})) : [],
      GeneratorRef: values.generator.id,
      Invoiced: false,
    };
    if (!props.selectedShippingDocument) {
      const lineItemData = createLineItemData(values.lineItems);
      firebaseObj.LineItems = lineItemData.lineArray;
      firebaseObj.PieceCount = lineItemData.pieceCount;
      firebaseObj.NextItemPieceNumber = lineItemData.pieceCount + 1;
    }
    return firebaseObj;
  }, [createLineItemData, props.selectedShippingDocument]);

  const stageArray = useMemo(() => {
    if (props.selectedShippingDocument) {
      return ['basic', 'success'];
    }
    else {
      return ['basic', 'lineItems', 'success'];
    }
  }, [props.selectedShippingDocument]);

  const handleBack = useCallback(() => {
    setStage(stage - 1);
  }, [stage]);

  const listOfSelectablePurchaseOrders = useMemo(() => {
    if (props.selectedInbound && purchaseOrders) {
      const purchaseOrderNumbers = [];
      purchaseOrders[props.selectedInbound?.AccountId].forEach(purchaseOrder => {
        if (purchaseOrder.Active && purchaseOrder.POType !== 'Sale') {
          purchaseOrderNumbers.push({Ref: purchaseOrder.id, Number: purchaseOrder.OrderNumber});
        }
      });
      return purchaseOrderNumbers;
    }
  }, [props.selectedInbound, purchaseOrders]);

  const listOfSelectableMaterials = useMemo(() => {
    if (accountProfiles) {
      const approvedMaterials = accountProfiles.profiles.find(profile => profile.Active).Materials;
      return approvedMaterials;
    }
  }, [accountProfiles]);

  const coreProps = useMemo(() => {
    return {
      dialog: {
        open: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
        maxWidth: 'md',
        fullWidth: true,
      },
      submitConfirmation: {
        stage: stageArray[stage] || '',
        text: props.selectedShippingDocument ?
          'Shipping document successfully updated.' :
          'New shipping document successfully added.',
      },
    };
  }, [props.selectedShippingDocument, stage, stageArray]);

  const formikProps = useMemo(() => {
    return {
      initialValues: {
        documentNumber: props.selectedShippingDocument?.DocumentNumber || '',
        documentType: props.selectedShippingDocument?.DocumentType || '',
        generator: locations[props.selectedInbound.AccountId]
          ?.find(location => location.id === props.selectedShippingDocument?.GeneratorRef) || '',
        poNumber: props.selectedShippingDocument?.PONumber || '',
        weightTickets: props.selectedShippingDocument?.WeightTickets || props.listOfWeightTickets.length === 1 ? [props.listOfWeightTickets[0]] : [],
        lineItems: [
          {
            line: '',
            material: '',
            classification: '',
            containerCode: '',
            quantity: '',
          },
        ],
      },
      onSubmit: (values, actions) => {
        if (stage !== stageArray.length - 2) {
          setStage(stage + 1);
          actions.setSubmitting(false);
          actions.setTouched({});
        }
        else {
          const selectedInboundRef = Firestore.collection('Tracking-Shipments').doc(props.selectedInbound.id);
          const firestoreData = createDataForFirestore(values);
          const batch = Firestore.batch();
          if (props.selectedShippingDocument) {
            batch.update(selectedInboundRef.collection('Shipping-Documents').doc(props.selectedShippingDocument.id), {
              ...firestoreData,
              'System.UpdatedOn': new Date(),
              'System.UpdatedBy': currentUser.displayName,
            });
          }
          else {
            batch.set(selectedInboundRef.collection('Shipping-Documents').doc(), {
              ...firestoreData,
              System: {
                CreatedOn: new Date(),
                CreatedBy: currentUser.displayName,
              },
            });
          }
          batch.commit()
            .then(() => {
              actions.setSubmitting(false);
              setStage(stage + 1);
            }).catch(error => {
              actions.setSubmitting(false);
              setFormError('There was an issue during submission. Please try again.');
            });
        }
      },
      validationSchema: (() => {
        if (stage === 0) {
          return yup.object().shape({
            documentNumber: yup.string().required('Document Number is a required field.'),
            documentType: yup.string().required('Document Type is a required field.'),
            generator: yup.object().nullable().exists('Generator is a required field.'),
            poNumber: yup.object().nullable().exists('Purchase Order is a required field.'),
            weightTickets: yup.array().test({
              name: 'need weight ticket',
              test: function(value) {
                if (!value && props.selectedInbound.Facility !== 'Brea-1125') {
                  return false;
                }
                else return true;
              },
              message: 'Must select at least one weight ticket.',
            }),
          });
        }
        else {
          return yup.object().shape({
            lineItems: yup.array().of(
              yup.object().shape({
                line: yup.string().required('Line is a required field.'),
                material: yup.object().nullable().exists('Material is a required field.'),
                classification: yup.string().required('Classification is a required field.'),
                containerCode: yup.string().required('Container is a required field.'),
                quantity: yup.number().required('Pieces is a required field.').min(1, 'Pieces is a required field.' ),
                rcraCodes: yup.mixed().test({
                  name: 'rcra codes required',
                  test: function(values) {
                    if (!values?.length && this.parent.classification === 'Hazardous Waste') {
                      return false;
                    }
                    else return true;
                  },
                  message: 'At least one RCRA code must be selected.',
                }),
                caCodes: yup.mixed().test({
                  name: 'ca codes required',
                  test: function(values) {
                    if (!values?.length && this.parent.classification === 'Hazardous Waste') {
                      return false;
                    }
                    else return true;
                  },
                  message: 'At least one CA Codes must be selected.',
                }),
              })),
          });
        }
      })(),
    };
  // eslint-disable-next-line max-len
  }, [createDataForFirestore, currentUser.displayName, locations, props.listOfWeightTickets, props.selectedInbound.AccountId, props.selectedInbound.Facility, props.selectedInbound.id, props.selectedShippingDocument, stage, stageArray.length]);

  const inputProps = useMemo(() => {
    return {
      documentNameField: {
        required: true,
        name: 'documentNumber',
        label: 'Document Number',
        onBlur: ({field, form}) => {
          if (field.value) {
            form.setFieldValue('documentNumber', field.value.toUpperCase());
          }
        },
      },
      documentTypeField: {
        required: true,
        name: 'documentType',
        label: 'Document Type',
      },
      poField: {
        required: true,
        name: 'poNumber',
        label: 'Purchase Order',
        options: listOfSelectablePurchaseOrders,
        optionKey: 'Number',
      },
      generatorField: {
        required: true,
        name: 'generator',
        label: 'Generator',
        options: locations[props.selectedInbound?.AccountId].filter(location => location.Active),
        optionKey: 'LocationName',
      },
      weightTicketsField: {
        multiple: true,
        name: 'weightTickets',
        label: 'Weight Tickets',
        optionKey: 'TicketNumber',
        options: props.listOfWeightTickets,
        autoSelect: false,
        autoHighlight: false,
        placeholder: 'Select all weight tickets that apply...',
      },
      materialField: index => ({
        required: true,
        name: `lineItems[${index}].material`,
        label: 'Material',
        options: listOfSelectableMaterials || [],
        optionKey: 'Name',
      }),
      caCodes: (formikProps, index) => ({
        required: true,
        options: materials.ref[formikProps.values.lineItems[index].material?.Ref]?.WasteCodes.CA.map(code => ({value: code})) || [],
        optionKey: 'value',
        name: `lineItems[${index}].caCodes`,
        label: 'CA Codes',
        placeholder: 'Select all codes that apply...',
        loading: !formikProps.values.lineItems[index].material,
        loadingText: 'Please select a material to populate',
        multiple: true,
        autoSelect: false,
      }),
      rcraCodes: (formikProps, index) => ({
        required: true,
        options: materials.ref[formikProps.values.lineItems[index].material?.Ref]?.WasteCodes.RCRA.map(code => ({value: code})) || [],
        optionKey: 'value',
        name: `lineItems[${index}].rcraCodes`,
        label: 'RCRA Codes',
        placeholder: 'Select all codes that apply...',
        loading: !formikProps.values.lineItems[index].material,
        loadingText: 'Please select a material to populate',
        multiple: true,
        autoSelect: false,
      }),
      addButton: arrayHelpers => ({
        color: 'primary',
        onClick: () => arrayHelpers.push({
          line: '',
          material: '',
          classification: '',
          containerCode: '',
          quantity: '',
        }),
      }),
      removeButton: (arrayHelpers, index) => ({
        color: 'secondary',
        onClick: () => arrayHelpers.remove(index),
      }),
    };
  }, [listOfSelectableMaterials, listOfSelectablePurchaseOrders, locations, materials.ref, props.listOfWeightTickets, props.selectedInbound]);

  return (
    <Dialog {...coreProps.dialog}>
      <FormikForm {...formikProps}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && (
              <DialogTitle>
                {props.selectedShippingDocument ? 'Edit Shipping Document' : 'New Shipping Document'}
              </DialogTitle>)}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter shipping document information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField {...inputProps.documentNameField} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectField {...inputProps.documentTypeField}>
                      {['Bill of Lading', 'HW Manifest', 'Non-HW Manifest'].map(type => (
                        <MenuItem value={type} key={type}>{type}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoCompleteObject {...inputProps.poField} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoCompleteObject {...inputProps.generatorField} />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <AutoCompleteObject {...inputProps.weightTicketsField} />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stageArray[stage] === 'lineItems'}>
                <DialogContentText>Enter line items for the shipping document:</DialogContentText>
                <Grid container spacing={2} style={{marginTop: '0px', marginBottom: '0px'}}>
                  <FieldArray name='lineItems'>
                    {arrayHelpers => (formikProps.values.lineItems.map((item, i) => (
                      <Fragment key={i}>
                        <Grid container spacing={2} item xs={10}>
                          <Grid item xs={6} md={1}>
                            <TextField name={`lineItems[${i}].line`} label='Line' required />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <AutoCompleteObject {...inputProps.materialField(i)} />
                          </Grid>
                          <Grid item xs={6} md>
                            <SelectField name={`lineItems[${i}].classification`} label='Classification' required>
                              {formikProps.values.documentType === 'HW Manifest' && (
                                <MenuItem value={'Hazardous Waste'}>Hazardous Waste</MenuItem>
                              )}
                              <MenuItem value={'Universal Waste'}>Universal Waste</MenuItem>
                              {formikProps.values.documentType !== 'HW Manifest' && <MenuItem value={'N/A'}>N/A</MenuItem>}
                              {formikProps.values.documentType === 'HW Manifest' && (
                                <MenuItem value={'Exempt per 49 CFR 173.159'}>Exempt per 49 CFR 173.159</MenuItem>
                              )}
                            </SelectField>
                          </Grid>
                          <Grid item xs={6} md>
                            <SelectField name={`lineItems[${i}].containerCode`} label='Container' required>
                              {containerCodes.map((codeArray, index) => (
                                <MenuItem value={codeArray[0]} key={index}>{codeArray[0]}</MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                          <Grid item xs={6} md>
                            <NumberField name={`lineItems[${i}].quantity`} label='Pieces' required />
                          </Grid>
                          {determineIfWasteCodesShown(formikProps.values, i) && (
                            <Fragment>
                              <Grid item xs={6}>
                                <AutoCompleteObject {...inputProps.caCodes(formikProps, i)} />
                              </Grid>
                              <Grid item xs={6}>
                                <AutoCompleteObject {...inputProps.rcraCodes(formikProps, i)} />
                              </Grid>
                            </Fragment>
                          )}

                        </Grid>
                        <Grid item xs={2} container alignItems='center'>
                          {formikProps.values.lineItems.length > 1 && (
                            <Button {...inputProps.removeButton(arrayHelpers, i)} disabled={formikProps.isSubmitting}>
                              <Clear />
                            </Button>
                          )}
                          {i === formikProps.values.lineItems.length - 1 && (
                            <Button {...inputProps.addButton(arrayHelpers)} disabled={formikProps.isSubmitting}>
                              <Add />
                            </Button>
                          )}
                        </Grid>
                      </Fragment>
                    )))}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...coreProps.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton onClick={props.close} variant='text' color='primary'>Close</FormButton>
              </DialogActions>
            ) : <DialogActions style={{justifyContent: 'space-between'}}>
              <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
              <div>
                <FormButton variant='text' color='secondary' onClick={handleBack} disabled={stage === 0}>Back</FormButton>
                <SubmitButton variant='text' color='primary'>{stage === stageArray.length - 2 ? 'Submit' : 'Next'}</SubmitButton>
              </div>
            </DialogActions>}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ShippingDocumentModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedShippingDocument: PropTypes.object,
  selectedInbound: PropTypes.object.isRequired,
  listOfWeightTickets: PropTypes.arrayOf(PropTypes.object).isRequired,

};

export default ShippingDocumentModal;

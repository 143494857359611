import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Chip,
  Typography,
} from '@material-ui/core';
import {connect} from 'react-redux';
import {Alert} from '@kbi/component-library';
import {SubmitConfirmation, SubmitButton, facilitiesConst} from 'components/';
import get from 'lodash.get';
import {Clear} from '@material-ui/icons';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';

const cleanState = {
  active: {
    display: 'Active',
    error: '',
    value: true,
  },
  description: {
    display: 'Description',
    error: '',
    value: '',
  },
  permittedStorage: {
    display: 'Permitted Storage?',
    error: '',
    value: '',
  },
  name: {
    display: 'Unit Name',
    error: '',
    value: '',
  },
  maxWeight: {
    display: 'Max Weight',
    error: '',
    value: '',
  },
  facility: {
    display: 'Facility',
    error: '',
    value: '',
  },
  newCategoryName: {
    display: 'Category Name',
    error: '',
    value: '',
  },
  newCategoryWeight: {
    display: 'Max Weight for Category',
    error: '',
    value: '',
  },
  rcraWasteCodes: {
    display: 'Allowed RCRA Waste Codes',
    error: '',
    value: '',
    codes: [],
  },
  caWasteCodes: {
    display: 'Allowed CA Waste Codes',
    error: '',
    value: '',
    codes: [],
  },
  mixedYields: {
    display: 'Mixed Yields?',
    value: 'false',
    error: '',
  },
  categories: [],
  submitting: false,
  stage: 0,
  formError: null,
};

class ModalFacilityUnit extends Component {
  state = {...cleanState, listOfSelectableYields: [], listOfSelectableMaterials: []};
  stageArray = ['unitDetails', 'allowedTypes', 'codes', 'success'];
  componentDidMount() {
    const {selectedFacilityUnit} = this.props.modalFacilityUnit;
    // is true if a facilityUnit is selected to edit, as opposed to creating a new facility unit
    if (selectedFacilityUnit) {
      // objCreatedForState is filled with the values of the selected facilityUnit, and created to match the layout of state.
      const objCreatedForState = {
        description: {...cleanState.description, value: selectedFacilityUnit.Description},
        facility: {...cleanState.facility, value: selectedFacilityUnit.Facility},
        maxWeight: {...cleanState.maxWeight, value: selectedFacilityUnit.MaxUnitWeight},
        name: {...cleanState.name, value: selectedFacilityUnit.Name},
        mixedYields: {...cleanState.mixedYields, value: selectedFacilityUnit.MixedYields},
        active: {...cleanState.active, value: selectedFacilityUnit.Active},
        permittedStorage: {...cleanState.permittedStorage, value: selectedFacilityUnit.PermittedStorage},
        caWasteCodes: {...cleanState.caWasteCodes, codes: []},
        rcraWasteCodes: {...cleanState.rcraWasteCodes, codes: []},
        categories: [],
        listOfSelectableMaterials: [...this.props.materials.materialList],
        listOfSelectableYields: [...this.props.materials.yieldList],
      };
      selectedFacilityUnit.WasteCodes.CA.forEach(caCode => {
        // loops through the CA codes and pushes them into the codes, which are rendered as the chips
        objCreatedForState.caWasteCodes.codes.push(caCode);
      });
      selectedFacilityUnit.WasteCodes.RCRA.forEach(rcraCode => {
        // same as the CA codes, but with RCRA codes
        objCreatedForState.rcraWasteCodes.codes.push(rcraCode);
      });
      // eslint-disable-next-line guard-for-in
      for (const categoryName in selectedFacilityUnit.AllowedItems) {
        // loops through the facility unit that came from the DB, and forms it to match the modal object/state.
        objCreatedForState.categories.push({
          name: categoryName,
          maxWeight: selectedFacilityUnit.AllowedItems[categoryName].MaxWeight,
          allowedTypes: [],
        });
        if (selectedFacilityUnit.AllowedItems[categoryName].Materials) {
          // if there are materials that are within the category, loops over them and pushes a new object controlled by state to handle input fields
          selectedFacilityUnit.AllowedItems[categoryName].Materials.forEach(materialId => {
            objCreatedForState.categories[objCreatedForState.categories.length - 1].allowedTypes.push({
              newAllowedWasteType: {
                display: 'Yield or Material?',
                error: '',
                value: 'material',
              },
              newTypeId: {
                display: 'Name',
                error: '',
                value: materialId,
              },
            });
            // removed the new item from the list of selectable items
            objCreatedForState.listOfSelectableMaterials = objCreatedForState.listOfSelectableMaterials.filter(
              testedMaterial => testedMaterial.MaterialId !== materialId,
            );
          });
        }
        if (selectedFacilityUnit.AllowedItems[categoryName].Yields) {
          // if there are yields that are within the category, loops over them and pushes a new object controlled by state to handle input fields
          selectedFacilityUnit.AllowedItems[categoryName].Yields.forEach(yieldId => {
            objCreatedForState.categories[objCreatedForState.categories.length - 1].allowedTypes.push({
              newAllowedWasteType: {
                display: 'Yield or Material?',
                error: '',
                value: 'yield',
              },
              newTypeId: {
                display: 'Name',
                error: '',
                value: yieldId,
              },
            });
            // removed the new item from the list of selectable items
            objCreatedForState.listOfSelectableYields = objCreatedForState.listOfSelectableYields.filter(
              testedYield => testedYield.MaterialId !== yieldId,
            );
          });
        }
      }
      // the object is finally spread through state, all values have been structured to match those of state
      this.setState({...objCreatedForState});
    }
    else {
      this.setState({
        listOfSelectableMaterials: [...this.props.materials.materialList],
        listOfSelectableYields: [...this.props.materials.yieldList],
      });
    }
  }
  createDataForFirestore() {
    const {
      categories,
      maxWeight,
      name,
      description,
      active,
      permittedStorage,
      rcraWasteCodes,
      caWasteCodes,
      facility,
      mixedYields,
    } = this.state;
    // the the format that the firestore object will be in
    const databaseObj = {
      Active: active.value,
      Facility: facility.value,
      MaxUnitWeight: parseInt(maxWeight.value),
      Name: name.value.trim(),
      MixedYields: mixedYields.value,
      PermittedStorage: permittedStorage.value,
      Description: description.value.trim(),
      WasteCodes: {CA: caWasteCodes.codes, RCRA: rcraWasteCodes.codes},
      AllowedItems: {},
    };
    categories.forEach(category => {
      databaseObj.AllowedItems[category.name.trim()] = {
        MaxWeight: !isNaN(parseInt(category.maxWeight)) ? parseInt(category.maxWeight) : 0,
        Yields: [],
        Materials: [],
      };
      const databaseCategoryRef = databaseObj.AllowedItems[category.name.trim()];
      category.allowedTypes.forEach(type => {
        // since types are stored in a single array within the modal to allow for state controlled inputs,
        // the array must be split up into a Yields and a Materials array for the database
        // the if/else takes the type and checks if its a yield, else it is a material
        if (type.newAllowedWasteType.value === 'yield') {
          databaseCategoryRef.Yields.push(type.newTypeId.value);
        }
        else {
          // same functionality as within the Yields field, if there is an array, push into it, else create the array
          databaseCategoryRef.Materials.push(type.newTypeId.value);
        }
      });
    });
    return databaseObj;
  }
  handleSubmit = () => {
    const {modalFacilityUnit, currentUser} = this.props;
    this.setState({submitting: true}, () => {
      const firestoreData = this.createDataForFirestore();
      if (modalFacilityUnit.selectedFacilityUnit) {
        Firestore.collection('Tracking-Facility-Units')
          .doc(modalFacilityUnit.selectedFacilityUnit.FacilityId)
          .update({
            ...firestoreData,
            'System.UpdatedBy': currentUser.displayName,
            'System.UpdatedOn': firebase.firestore.Timestamp.now(),
          })
          .then(() => {
            this.setState({stage: this.state.stage + 1, submitting: false});
          })
          .catch(error => {
            this.setState({formError: 'Error submitting data, please try again.', submitting: false});
          });
      }
      else {
        Firestore.collection('Tracking-Facility-Units')
          .doc()
          .set({
            ...firestoreData,
            System: {
              CreatedBy: currentUser.displayName,
              CreatedOn: firebase.firestore.Timestamp.now(),
              UpdatedOn: firebase.firestore.Timestamp.now(),
              UpdatedBy: currentUser.displayName,
            },
          })
          .then(() => {
            this.setState({stage: this.state.stage + 1, submitting: false});
          })
          .catch(error => {
            this.setState({formError: 'Error submitting data, please try again.', submitting: false});
          });
      }
    });
  };
  validateData() {
    const {
      name,
      facility,
      description,
      maxWeight,
      permittedStorage,
      caWasteCodes,
      rcraWasteCodes,
      categories,
      stage,
    } = this.state;
    let dataError = false; // will be set to true if there is any part of the form that fails the test
    if (this.stageArray[stage] === 'unitDetails') {
      if (!name.value) {
        this.setState({name: {...name, error: 'Name is a required field.'}});
        dataError = true;
      }
      if (!facility.value) {
        this.setState({facility: {...facility, error: 'Facility is a required field.'}});
        dataError = true;
      }
      if (!maxWeight.value) {
        this.setState({maxWeight: {...maxWeight, error: 'Max weight is a required field.'}});
        dataError = true;
      }
      if (permittedStorage.value === '') {
        this.setState({permittedStorage: {...permittedStorage, error: 'Permitted storage is a required field.'}});
        dataError = true;
      }
      if (!description.value) {
        this.setState({description: {...description, error: 'Description is a required field.'}});
        dataError = true;
      }
    }
    if (this.stageArray[stage] === 'allowedTypes') {
      if (!categories.length) {
        this.setState({formError: 'There must be at least one category.'});
        dataError = true;
      }
      categories.forEach(category => {
        const lastAllowedTypeInCategory = category.allowedTypes[category.allowedTypes.length - 1];
        if (!lastAllowedTypeInCategory.newTypeId.value) {
          this.setState({formError: 'Please either finish adding, or delete, the allowed types.'});
          dataError = true;
        }
      });
    }
    if (this.stageArray[stage] === 'codes') {
      if (!caWasteCodes.codes.length) {
        this.setState({caWasteCodes: {...caWasteCodes, error: 'At least one waste code is required.'}});
        dataError = true;
      }
      if (!rcraWasteCodes.codes.length) {
        this.setState({rcraWasteCodes: {...rcraWasteCodes, error: 'At least one waste code is required.'}});
        dataError = true;
      }
    }
    return !dataError; // if all tests passed, dataError will be false, but that means the validation was successful, so we will return true;
  }
  handleNext = () => {
    const {stage} = this.state;
    if (stage === this.stageArray.length - 2 && this.validateData()) {
      this.handleSubmit();
    }
    else if (this.validateData()) {
      this.setState({stage: stage + 1});
    }
  };
  handleClose = () => {
    this.setState({...cleanState});
    this.props.close();
  };
  handleBack = () => {
    this.setState({stage: this.state.stage - 1});
  };
  createChips = fieldName => {
    return this.state[fieldName].codes.map((code, i) => {
      return (
        <Chip
          key={code}
          style={{marginRight: 5, marginBottom: 5}}
          label={code}
          onDelete={() => {
            this.setState(prevState => ({
              [fieldName]: {
                ...prevState[fieldName],
                codes: prevState[fieldName].codes.filter(e => e !== code),
              },
            }));
          }}
        />
      );
    });
  };
  addRegulation = fieldName => {
    // this pushes a new code for either CA codes or RCRA codes into their respective codes array in order to be rendered as a chip
    if (this.state[fieldName].value) {
      this.setState(prevState => ({
        [fieldName]: {
          ...prevState[fieldName],
          codes: [...prevState[fieldName].codes, prevState[fieldName].value.trim()],
          value: '',
        },
      }));
    }
  };
  addCategoryHandler = () => {
    // if there is no name within the field, it fails this check
    if (this.state.newCategoryName.value) {
      // pushes a new category into the categories state, as well as sets up the first allowed type field within itself.
      this.setState(prevState => ({
        categories: [
          ...prevState.categories,
          {
            name: prevState.newCategoryName.value,
            maxWeight: prevState.newCategoryWeight.value,
            allowedTypes: [
              {
                newAllowedWasteType: {
                  display: 'Yield or Material?',
                  error: '',
                  value: '',
                },
                newTypeId: {
                  display: 'Name',
                  error: '',
                  value: '',
                },
              },
            ],
          },
        ],
        // clears out the values within the fields
        newCategoryName: cleanState.newCategoryName,
        newCategoryWeight: cleanState.newCategoryWeight,
        formError: cleanState.formError,
      }));
    }
    else {
      // if there is no name when the add category button is hit, set an error under the name
      this.setState(prevState => ({
        newCategoryName: {...prevState.newCategoryName, error: 'Name is a required field.'},
      }));
    }
  };
  editSelectableList(categoryIndex, typeIndex, refToStateObj, newValue = null) {
    // newValue gets passed to function if there is a new item selected. if a row is deleted, thats when no newValue is passed
    // the yield/material field of the changed row
    const yieldOrMaterial = this.state.categories[categoryIndex].allowedTypes[typeIndex].newAllowedWasteType.value;
    // determines which list is going to be updated
    const nameOfListToUpdate = yieldOrMaterial === 'yield' ? 'listOfSelectableYields' : 'listOfSelectableMaterials';
    // this is a reference to the value in state before there was a change
    const valueOfFieldBeforeUpdate = this.state.categories[categoryIndex].allowedTypes[typeIndex].newTypeId.value;
    // if an item is selected, this will fire
    if (newValue) {
      // sets the list of selectables to the current list minus the selected item
      refToStateObj[nameOfListToUpdate] = this.state[nameOfListToUpdate].filter(
        testedItem => testedItem.MaterialId !== newValue,
      );
    }
    else {
      // sets the list of selectables to the current list
      // this will be when an item is deleted, and there is not an item to remove from the list
      refToStateObj[nameOfListToUpdate] = this.state[nameOfListToUpdate];
    }
    // this if checks if the previous value exists, and if it is a new value that was selected.
    if (valueOfFieldBeforeUpdate && valueOfFieldBeforeUpdate !== newValue) {
      // if true, creates an object to pass back into the list of selectable values.
      // if false, either the old value didnt exist, so there is nothing to push back into list,
      // or the value selected was the same, so it does not need to be updated
      const objToPassBackIntoList = {
        MaterialId: valueOfFieldBeforeUpdate,
        UnitDetails: {
          MaterialName: this.props.materials.ref[valueOfFieldBeforeUpdate].UnitDetails.MaterialName,
        },
      };
      refToStateObj[nameOfListToUpdate].push(objToPassBackIntoList);
    }
  }
  updateTypeDetails = (categoryIndex, typeIndex, key, value) => {
    // the types are housed with an array within a category, which are within an array.
    // the indices are needed for correct placement of the new type within the categories.
    const objToBePassedToSetState = {};
    // creates deep clone of the categories obj in order to not modify state before setState is called
    objToBePassedToSetState.categories = JSON.parse(JSON.stringify(this.state.categories));
    // updated the value of the input that was changed.
    objToBePassedToSetState.categories[categoryIndex].allowedTypes[typeIndex][key].value = value;
    objToBePassedToSetState.categories[categoryIndex].allowedTypes[typeIndex][key].error = '';
    // if a new item was selected, this if will fire
    if (key === 'newTypeId') {
      this.editSelectableList(categoryIndex, typeIndex, objToBePassedToSetState, value);
    }
    // if the material/yield is changed, this will be true
    if (
      key === 'newAllowedWasteType' &&
      this.state.categories[categoryIndex].allowedTypes[typeIndex][key].value !== value
    ) {
      // this resets the value of the chosen typeId/name of the item.
      // it solves the issue of a user adding a allowedItem, seleing either yield or material,
      // and then a name, and changing to the other type, and not selecting a new name
      objToBePassedToSetState.categories[categoryIndex].allowedTypes[typeIndex].newTypeId.value = '';
      this.editSelectableList(categoryIndex, typeIndex, objToBePassedToSetState);
    }
    objToBePassedToSetState.formError = cleanState.formError;
    this.setState(objToBePassedToSetState);
  };
  createListOfRenderedTypes(categoryIndex, typeIndex) {
    const refToState = this.state.categories[categoryIndex].allowedTypes[typeIndex]; // the current text field that is being rendered
    let listToRender = null; // the list of selectable item that comes from state
    let refFromProps = null; // the list of materials or yields that comes from props;
    if (refToState.newAllowedWasteType.value === 'yield') {
      listToRender = this.state.listOfSelectableYields;
      refFromProps = this.props.materials.ref;
    }
    else {
      listToRender = this.state.listOfSelectableMaterials;
      refFromProps = this.props.materials.ref;
    }
    if (refToState.newTypeId.value) {
      // if there is a value assigned to the current text field, the list of available items is prepended with an object that
      // mimics the structure of the others, in order to maintain the selected item in the field
      listToRender = [
        {
          MaterialId: refToState.newTypeId.value,
          UnitDetails: {
            MaterialName: refFromProps[refToState.newTypeId.value].UnitDetails.MaterialName,
          },
        },
        ...listToRender,
      ];
    }
    // sorts array alphabetically, and renders the remaining items as selectable items
    return listToRender
      .sort((a, b) => (a.UnitDetails.MaterialName > b.UnitDetails.MaterialName ? 1 : -1))
      .map((item, index) => {
        return (
          <MenuItem key={index} value={item.MaterialId}>
            {item.UnitDetails.MaterialName}
          </MenuItem>
        );
      });
  }
  removeTypeFromCategory = (categoryIndex, typeIndex) => {
    // functions the similar to updateTypeDetails, except instead of setting new values to the type, it removes the type from the array
    const objToBePassedToSetState = {};
    objToBePassedToSetState.categories = JSON.parse(JSON.stringify(this.state.categories));
    objToBePassedToSetState.categories[categoryIndex].allowedTypes.splice(typeIndex, 1);
    this.editSelectableList(categoryIndex, typeIndex, objToBePassedToSetState);
    if (!objToBePassedToSetState.categories[categoryIndex].allowedTypes.length) {
      // if there are no types in the category after removing the selected one, remove the category from the list
      objToBePassedToSetState.categories.splice(categoryIndex, 1);
    }
    this.setState(objToBePassedToSetState);
  };
  addNewTypeRow = categoryIndex => {
    const categoryObjForState = JSON.parse(JSON.stringify(this.state.categories));
    const lastAllowedTypeInCategory =
      categoryObjForState[categoryIndex].allowedTypes[categoryObjForState[categoryIndex].allowedTypes.length - 1];
    if (lastAllowedTypeInCategory.newTypeId.value) {
      // is true if the last added item has an id that has been chosen, otherwise dont let them add a new type
      // adds an object within this.state.categories.allowedTypes that will be edited by updateTypeDetails
      categoryObjForState[categoryIndex].allowedTypes.push({
        newAllowedWasteType: {
          display: 'Yield or Material?',
          error: '',
          value: '',
        },
        newTypeId: {
          display: 'Name',
          error: '',
          value: '',
        },
      });
    }
    else if (!lastAllowedTypeInCategory.newAllowedWasteType.value) {
      lastAllowedTypeInCategory.newAllowedWasteType.error = 'Type is required to continue adding more types.';
    }
    else {
      lastAllowedTypeInCategory.newTypeId.error = 'Name is required to continue adding types.';
    }
    this.setState({categories: categoryObjForState});
  };
  render() {
    const {modalFacilityUnit} = this.props;
    const {stage, categories} = this.state;
    const newFacilityUnit = get(modalFacilityUnit, 'newFacilityUnit', false);
    const core = {
      dialog: {
        open: modalFacilityUnit !== null,
        scroll: 'body',
        transitionDuration: {exit: 0},
        maxWidth: 'md',
        fullWidth: true,
      },
      selectField: {
        select: true,
      },
      categorySection: {
        item: true,
        xs: 12,
        container: true,
        style: {
          width: '100%',
          padding: 16,
          margin: '10px',
          border: '2px solid rgb(200, 200, 200)',
          borderRadius: '5px',
        },
      },
      deleteTypeButton: (categoryIndex, typeIndex) => ({
        size: 'small',
        onClick: () => this.removeTypeFromCategory(categoryIndex, typeIndex),
      }),
      submitButton: {
        onClick: this.handleNext,
        color: 'primary',
        loading: this.state.submitting,
        text: this.state.stage === this.stageArray.length - 2 ? 'Submit' : 'Next',
      },
      backButton: {
        color: 'primary',
        disabled: this.state.stage === 0 || this.state.submitting ? true : false,
        onClick: this.handleBack,
      },
      submitConfirmation: {
        stage: this.stageArray[this.state.stage],
        text: newFacilityUnit ? 'New facility unit successfully created.' : 'Facility unit successfully updated.',
      },
      textFieldAdornment: {
        InputProps: {
          endAdornment: <InputAdornment>Lbs</InputAdornment>,
        },
      },
      addCategoryButton: {
        variant: 'outlined',
        color: 'primary',
        onClick: this.addCategoryHandler,
      },
      addTypeButton: categoryIndex => {
        return {
          variant: 'outlined',
          color: 'primary',
          onClick: () => this.addNewTypeRow(categoryIndex),
        };
      },
      textField: (key, required = false) => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          required: required,
          value: this.state[key].value,
          onChange: e =>
            this.setState({
              [key]: {...this.state[key], error: null, value: e.target.value},
              formError: cleanState.formError,
            }),
        };
      },
      regulationField: key => {
        return {
          error: this.state[key].error ? true : false,
          helperText: this.state[key].error,
          fullWidth: true,
          id: key,
          label: this.state[key].display,
          required: true,
          value: this.state[key].value,
          onChange: e => {
            this.setState({
              [key]: {...cleanState[key], value: e.target.value, codes: this.state[key].codes},
              formError: cleanState.formError,
            });
          },
          onKeyPress: e => {
            if (e.key === 'Enter') {
              this.addRegulation(key);
            }
          },
        };
      },
      indexBasedTextField: (categoryIndex, typeIndex, key) => ({
        // these are used in order to navigate the nested arrays within state, in order to keep all input fields unique and accounted for
        error: categories[categoryIndex].allowedTypes[typeIndex][key].error ? true : false,
        helperText: categories[categoryIndex].allowedTypes[typeIndex][key].error,
        fullWidth: true,
        label: categories[categoryIndex].allowedTypes[typeIndex][key].display,
        required: typeIndex === 0,
        value: categories[categoryIndex].allowedTypes[typeIndex][key].value,
        onChange: e => {
          this.updateTypeDetails(categoryIndex, typeIndex, key, e.target.value);
        },
      }),
    };
    const createActionButtons = () => {
      if (this.stageArray[this.state.stage] === 'success') {
        return (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button onClick={this.handleClose} color='primary'>
              Close
            </Button>
          </DialogActions>
        );
      }
      else {
        return (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button onClick={this.handleClose} color='secondary' disabled={this.state.submitting}>
              Cancel
            </Button>
            <div>
              <Button {...core.backButton}>Back</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        );
      }
    };
    return (
      <Dialog {...core.dialog}>
        <DialogTitle>{newFacilityUnit ? 'Create New' : 'Edit'} Facility Unit</DialogTitle>
        <DialogContent>
          <Collapse in={this.stageArray[stage] === 'unitDetails'}>
            <DialogContentText>Enter facility unit details below:</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField {...core.textField('name', true)} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField type='number' {...core.textField('maxWeight', true)} {...core.textFieldAdornment} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField {...core.textField('facility', true)} {...core.selectField}>
                  {facilitiesConst.map((facility, index) => {
                    return (
                      <MenuItem key={index} value={facility}>
                        {facility}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField select {...core.textField('permittedStorage', true)}>
                  <MenuItem key={true} value={true}>
                    True
                  </MenuItem>
                  <MenuItem key={false} value={false}>
                    False
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField {...core.textField('mixedYields', true)} {...core.selectField}>
                  <MenuItem key={true} value={true}>
                    True
                  </MenuItem>
                  <MenuItem key={false} value={false}>
                    False
                  </MenuItem>
                </TextField>
              </Grid>
              {!newFacilityUnit && (
                <Grid item xs={12} sm={2}>
                  <TextField {...core.textField('active', true)} {...core.selectField}>
                    <MenuItem key={true} value={true}>
                      True
                    </MenuItem>
                    <MenuItem key={false} value={false}>
                      False
                    </MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={!newFacilityUnit ? 8 : 10}>
                <TextField {...core.textField('description', true)} />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={this.stageArray[stage] === 'allowedTypes'}>
            <DialogContentText>Create all categories and their respective allowed types:</DialogContentText>
            <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={5}>
                <TextField {...core.textField('newCategoryName', !categories.length)} />
              </Grid>
              <Grid item xs={4}>
                <TextField type='number' {...core.textField('newCategoryWeight')} {...core.textFieldAdornment} />
              </Grid>
              <Grid item xs={3}>
                <Button {...core.addCategoryButton}>Add Category</Button>
              </Grid>
              {categories.length ?
                categories.map((createdCategory, categoryIndex) => {
                  return (
                    <Fragment key={categoryIndex}>
                      <Grid {...core.categorySection}>
                        <Grid item xs={12}>
                          <Typography>
                            {createdCategory.name}
                            {createdCategory.maxWeight ? `: ${createdCategory.maxWeight.toLocaleString()} lbs` : ''}
                          </Typography>
                          <Grid container spacing={2} alignItems='flex-end'>
                            {categories[categoryIndex].allowedTypes.map((type, typeIndex) => {
                              return (
                                <Fragment key={typeIndex}>
                                  <Grid item xs={1}>
                                    <Button {...core.deleteTypeButton(categoryIndex, typeIndex)}>
                                      <Clear color='secondary' />
                                    </Button>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      select
                                      {...core.indexBasedTextField(categoryIndex, typeIndex, 'newAllowedWasteType')}
                                    >
                                      <MenuItem key='material' value='material'>
                                        Material
                                      </MenuItem>
                                      <MenuItem key='yield' value='yield'>
                                        Yield
                                      </MenuItem>
                                    </TextField>
                                  </Grid>
                                  {type.newAllowedWasteType.value && (
                                    <Grid item xs={6}>
                                      <TextField
                                        select
                                        {...core.indexBasedTextField(categoryIndex, typeIndex, 'newTypeId')}
                                      >
                                        {this.createListOfRenderedTypes(categoryIndex, typeIndex)}
                                      </TextField>
                                    </Grid>
                                  )}
                                </Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                        <Grid item xs={1} style={{marginTop: '16px'}} />
                        {/* This line is needed to push the button in line with the yield/material input */}
                        <Grid item xs={3} style={{marginTop: '16px'}}>
                          <Button {...core.addTypeButton(categoryIndex)}>Add Allowed Type</Button>
                        </Grid>
                      </Grid>
                    </Fragment>
                  );
                }) :
                null}
            </Grid>
          </Collapse>
          <Collapse in={this.stageArray[stage] === 'codes'}>
            <DialogContentText>Enter all waste codes.</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('rcraWasteCodes')} required />
                  <Button color='primary' onClick={() => this.addRegulation('rcraWasteCodes')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.rcraWasteCodes.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('rcraWasteCodes')}
                </Grid>
              )}
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField {...core.regulationField('caWasteCodes')} required />
                  <Button color='primary' onClick={() => this.addRegulation('caWasteCodes')}>
                    Add
                  </Button>
                </div>
              </Grid>
              {this.state.caWasteCodes.codes.length > 0 && (
                <Grid item xs={12}>
                  {this.createChips('caWasteCodes')}
                </Grid>
              )}
            </Grid>
          </Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
          <Alert in={Boolean(this.state.formError)} text={this.state.formError || ''} severity='error' />
        </DialogContent>
        {createActionButtons()}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {currentUser: state.auth.currentUser, materials: state.firestore.materials};
};

ModalFacilityUnit.propTypes = {
  close: PropTypes.func.isRequired,
  modalFacilityUnit: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps)(ModalFacilityUnit);

import React, {useReducer, useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, Grid, TextField, Collapse, DialogActions, Button, DialogContentText, MenuItem} from '@material-ui/core';
import {SubmitButton, SubmitConfirmation, facilitiesConst} from 'components/';
import {Alert} from '@kbi/component-library';
import {Firestore} from 'config.js';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import firebase from 'firebase/app';
import 'firebase/firestore';

const stageArray = ['basic', 'success'];
const cleanState = {
  date: {
    display: 'Date',
    value: '',
    error: '',
  },
  facility: {
    display: 'Facility',
    value: '',
    error: '',
  },
  facilityUnit: {
    display: 'Facility Unit',
    value: '',
    error: '',
  },
  shift: {
    display: 'Shift',
    value: '',
    error: '',
  },
  formError: '',
  deleteConfirmation: false,
  submitting: false,
  stage: 0,
};

function reducer(state, action) {
  switch (action.type) {
  case 'value':
    return {...state, [action.field]: {...state[action.field], value: action.payload}};
  case 'error':
    return {...state, [action.field]: {...state[action.field], error: action.payload}};
  case 'stage':
    return {...state, stage: action.payload};
  case 'submitting':
    return {...state, submitting: action.payload};
  case 'formError':
    return {...state, formError: action.payload};
  case 'deleteConfirmation':
    return {...state, deleteConfirmation: action.payload};
  default:
    throw new Error();
  }
}

const NewSortFormModal = props => {
  const [state, dispatch] = useReducer(reducer, {...cleanState, date: {...cleanState.date, value: moment().format('YYYY-MM-DD')}});
  const [newSortFormId, setNewSortFormId] = useState(null);
  const facilityUnits = useSelector(state => state.firestore.facilityUnits);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (props.selectedSortForm) {
      dispatch({type: 'value', field: 'date', payload: moment(props.selectedSortForm.Date).format('YYYY-MM-DD')});
      dispatch({type: 'value', field: 'shift', payload: props.selectedSortForm.Shift});
      dispatch({type: 'value', field: 'facility', payload: props.selectedSortForm.Facility});
      dispatch({type: 'value', field: 'facilityUnit', payload: props.selectedSortForm.FacilityUnitRef});
    }
  }, [props.selectedSortForm]);

  const isFormValid = () => {
    let validForm = true;
    if (!state.shift.value) {
      validForm = false;
      dispatch({type: 'error', field: 'shift', payload: 'Shift is a required field.'});
    }
    if (!state.facility.value) {
      validForm = false;
      dispatch({type: 'error', field: 'facility', payload: 'Facility is a required field.'});
    }
    if (!state.facilityUnit.value) {
      validForm = false;
      dispatch({type: 'error', field: 'facilityUnit', payload: 'Facility Unit is a required field.'});
    }
    if (!state.date.value) {
      validForm = false;
      dispatch({type: 'error', field: 'date', payload: 'Date is a required field.'});
    }
    else if (new Date(state.date.value) > new Date()) {
      validForm = false;
      dispatch({type: 'error', field: 'date', payload: 'Date cannot be in the future.'});
    }
    return validForm;
  };
  const handleSubmit = () => {
    dispatch({type: 'deleteConfirmation', payload: false});
    if (isFormValid()) {
      dispatch({type: 'submitting', payload: true});
      const dataForFirestore = {
        // sets timestamp to midnight of the current date.
        Date: firebase.firestore.Timestamp.fromDate(new Date(new Date(state.date.value).setHours(24, 0, 0, 0))),
        Facility: state.facility.value,
        Shift: state.shift.value,
        FacilityUnitRef: state.facilityUnit.value,
        FormType: 'Sort',
        Completed: false,
        Active: true,
      };
      let sortFormRef;
      if (props.selectedSortForm) {
        dataForFirestore['System.UpdatedOn'] = firebase.firestore.Timestamp.now();
        dataForFirestore['System.UpdatedBy'] = {Name: currentUser.displayName, Email: currentUser.email};
        sortFormRef = Firestore.collection('Tracking-Forms').doc(props.selectedSortForm.id);
        sortFormRef.update(dataForFirestore)
          .then(() => {
            dispatch({type: 'submitting', payload: false});
            dispatch({type: 'stage', payload: state.stage + 1});
          })
          .catch(error => {
            dispatch({type: 'submitting', payload: false});
            dispatch({type: 'formError', payload: 'There was an error in submitting the form. Please try again.'});
          });
      }
      else {
        dataForFirestore.System = {
          CreatedBy: {Name: currentUser.displayName, Email: currentUser.email},
          CreatedOn: firebase.firestore.Timestamp.now(),
        };
        sortFormRef = Firestore.collection('Tracking-Forms').doc();
        sortFormRef.set(dataForFirestore)
          .then(() => {
            setNewSortFormId(sortFormRef.id);
            dispatch({type: 'submitting', payload: false});
            dispatch({type: 'stage', payload: state.stage + 1});
          })
          .catch(error => {
            dispatch({type: 'submitting', payload: false});
            dispatch({type: 'formError', payload: 'There was an error in submitting the form. Please try again.'});
          });
      }
    }
  };
  const handleDelete = () => {
    if (!state.deleteConfirmation) {
      dispatch({type: 'deleteConfirmation', payload: true});
    }
    else {
      dispatch({type: 'submitting', payload: true});
      const sortFormRef = Firestore.collection('Tracking-Forms').doc(props.selectedSortForm.id);
      sortFormRef.update({
        Active: false,
      })
        .then(() => {
          dispatch({type: 'submitting', payload: false});
          dispatch({type: 'stage', payload: state.stage + 1});
        })
        .catch(error => {
          dispatch({type: 'submitting', payload: false});
          dispatch({type: 'formError', payload: 'There was an error during submission. Please try again.'});
        });
    }
  };

  const core = {
    dialog: {
      open: props.open,
      fullWidth: true,
      maxWidth: 'sm',
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    closeButton: {
      color: 'primary',
      onClick: () => {
        if (newSortFormId) {
          props.history.push(`/production/sort/${newSortFormId}`);
        }
        else if (state.deleteConfirmation) {
          props.history.push('/production/sort');
        }
        else props.close();
      },
    },
    cancelButton: {
      color: 'secondary',
      onClick: props.close,
      disabled: state.submitting,
    },
    submitButton: {
      loading: state.submitting,
      text: 'Submit',
      color: 'primary',
      onClick: handleSubmit,
    },
    deleteButton: {
      text: state.deleteConfirmation ? 'Confirm?' : 'Delete',
      color: 'secondary',
      loading: state.submitting,
      onClick: handleDelete,
      disabled: !props.deleteAllowed,
    },
    dateField: {
      type: 'date',
      disabled: props.selectedSortForm ? true : false,
      InputLabelProps: {
        shrink: true,
      },
    },
    textfield: key => ({
      value: state[key].value,
      error: state[key].error ? true : false,
      helperText: state[key].error,
      fullWidth: true,
      label: state[key].display,
      onChange: e => {
        if (key === 'facility') {
          dispatch({type: 'value', field: 'facilityUnit', payload: ''});
        }
        dispatch({type: 'value', field: key, payload: e.target.value});
        dispatch({type: 'error', field: key, payload: ''});
        dispatch({type: 'formError', payload: ''});
      },
    }),
    submitConfirmation: {
      text: !props.selectedSortForm ? 'Inbound sort form successfully created. You will be redirected there upon closing this form.' :
        `Sort form sucessfully ${state.deleteConfirmation ? 'deleted' : 'updated'}.`,
      stage: stageArray[state.stage],
      delete: state.deleteConfirmation,
    },
  };

  return (
    <Dialog {...core.dialog}>
      {stageArray[state.stage] !== 'success' && <DialogTitle>{props.selectedSortForm ? 'Edit Inbound Sort Form' : 'Add Inbound Sort Form'}</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[state.stage] === 'basic'}>
          <DialogContentText>Enter the date, facility, shift, and unit related to sortation.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField {...core.dateField} {...core.textfield('date')} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField select {...core.textfield('facility')}>
                {facilitiesConst.map((facility, index) => {
                  return <MenuItem key={index} value={facility}>{facility}</MenuItem>;
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField select {...core.textfield('shift')} >
                <MenuItem value='First'>First</MenuItem>
                <MenuItem value='Second'>Second</MenuItem>
                <MenuItem value='Third'>Third</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField select {...core.textfield('facilityUnit')} disabled={!state.facility.value}>
                {facilityUnits.active.filter(unit => {
                  if (unit.Facility === state.facility.value) return true;
                  return false;
                }).sort((a, b) => a.Name > b.Name ? 1 : -1)
                  .map((unit, index) => {
                    return <MenuItem key={index} value={unit.FacilityId}>{unit.Name}</MenuItem>;
                  })}
              </TextField>
            </Grid>
          </Grid>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(state.formError)} text={state.formError} severity='error' />
      </DialogContent>
      {stageArray[state.stage] === 'success' ? (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      ) : <DialogActions style={{justifyContent: 'space-between'}}>
        {props.selectedSortForm ?
          <Fragment>
            <SubmitButton {...core.deleteButton} />
            <div>
              <Button {...core.cancelButton}>Cancel</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </Fragment> :
          <Fragment>
            <Button {...core.cancelButton}>Cancel</Button>
            <SubmitButton {...core.submitButton} />
          </Fragment>}
      </DialogActions>}
    </Dialog >);
};

NewSortFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  selectedSortForm: PropTypes.object,
  deleteAllowed: PropTypes.bool,
};

export default flowright(withRouter)(NewSortFormModal);

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, withStyles, Paper, List, ListItem, LinearProgress} from '@material-ui/core';
import {LocalShipping, Edit, Print} from '@material-ui/icons';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import flowRight from 'lodash.flowright';
import {AddFileModal, FileViewModal, BackButton, DetailsFieldList, DetailsTitle} from 'components/';
import moment from 'moment';
import {NotesPanel} from '@kbi/component-library';
import {acGetAccountProfiles, acClearAccountProfiles} from 'state/firestore/actions.js';
import {Firestore, Storage, Functions} from 'config.js';
import {WeightTickets, ShippingDocumentPanel, ManifestedItemsMissingDataPanel} from '../panels/';
import {ConfirmationModal, PrintLabels, PrintableIncoming} from './InboundDetails/';
import {InboundModal} from './Shipments/';
import {useLocations, useMaterials, useTypes, useAccounts, usePurchaseOrders, useTrackingShipments, useContainers, useAxios} from 'hooks';

const InboundDetails = props => {
  const {classes, match} = props;
  // setting up redux listeners
  const locations = useLocations();
  const trackingShipments = useTrackingShipments();
  const accounts = useAccounts();
  const {axios, getAuthToken, appEngine} = useAxios();
  useMaterials();
  useTypes();
  useLocations();
  usePurchaseOrders();
  useContainers();
  // the reference to the inbound that is being viewed
  const [selectedInbound, setSelectedInbound] = useState(trackingShipments?.ref[match.params.InboundId] || null);
  // from firestore, all weight tickets in the current inbound's weight ticket subcollection
  const [listOfWeightTickets, setListOfWeightTickets] = useState(null);
  const [listOfNotes, setListOfNotes] = useState(null);
  // from firestore, all shipping documents in the current inbound's shipping document subcollection
  const [listOfShippingDocuments, setListOfShippingDocuments] = useState(null);
  // the portions of state that are used to handle the primary file upload and inbound load progress based on the file
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadedFileRef, setUploadedFileRef] = useState(null);
  const [uploadFileModalOpen, setUploadFileModalOpen] = useState(false);

  const [listOfItemsMissingData, setListOfItemsMissingData] = useState(null);

  const [fileViewModalOpen, setFileViewModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [inboundModalOpen, setInboundModalOpen] = useState(false);
  const [listOfSupplementalFiles, setListOfSupplementalFiles] = useState([]);
  const [printView, setPrintView] = useState(false);


  useEffect(() => {
    if (isFileUploaded) setUploadedFileRef(Storage.ref(`Inbound_Forms/${selectedInbound.id}_primary_file.pdf`));
  }, [isFileUploaded, selectedInbound]);
  useEffect(() => {
    if (trackingShipments?.ref) {
      if (!trackingShipments.ref[match.params.InboundId]) {
        props.history.push('/shipments');
        return;
      }
      setSelectedInbound(trackingShipments.ref[match.params.InboundId]);
      setIsFileUploaded(!!trackingShipments.ref[match.params.InboundId].UploadedBy);
    }
  }, [trackingShipments, match, uploadFileModalOpen]);
  useEffect(() => {
    if (selectedInbound) {
      props.acGetAccountProfiles(selectedInbound.AccountId);
      return () => {
        props.acClearAccountProfiles(props.accountProfiles);
      };
    }
  }, [selectedInbound]);
  useEffect(() => {
    /** this useEffect is to set the listOfWeightTickets. */
    if (!listOfWeightTickets) {
      const listener = Firestore.collection('Tracking-Shipments')
        .doc(match.params.InboundId)
        .collection('Weight-Tickets')
        .onSnapshot(snap => {
          const arrayOfTickets = [];
          snap.forEach(weightTicket => {
            arrayOfTickets.push({...weightTicket.data(), id: weightTicket.id});
          });
          setListOfWeightTickets(
            arrayOfTickets.sort((a, b) => (parseInt(a.TicketNumber) > parseInt(b.TicketNumber) ? 1 : -1)),
          );
        });
      return () => {
        listener();
      };
    }
  }, []);
  useEffect(() => {
    /** this useEffect is to set the listOfWeightTickets. */
    if (!listOfNotes) {
      const listener = Firestore.collection('Tracking-Shipments')
        .doc(match.params.InboundId)
        .collection('Notes')
        .onSnapshot(snap => {
          const arrayOfNotes = [];
          snap.forEach(note => {
            arrayOfNotes.push({...note.data(), CreatedOn: note.data().CreatedOn.toDate(), id: note.id, numberOfAttachedFiles: note.data().FileNames?.length});
          });
          setListOfNotes(arrayOfNotes);
        });
      return () => {
        listener();
      };
    }
  }, []);
  useEffect(() => {
    /** this useEffect is to set the listOfShippingDocuments. */
    if (!listOfShippingDocuments) {
      const listener = Firestore.collection('Tracking-Shipments')
        .doc(match.params.InboundId)
        .collection('Shipping-Documents')
        .onSnapshot(snap => {
          const arrayOfDocuments = [];
          snap.forEach(shippingDoc => {
            arrayOfDocuments.push({
              ...shippingDoc.data(),
              id: shippingDoc.id,
              weightTicketString: shippingDoc.data().WeightTickets.map(ticket => ticket.TicketNumber).join(', '),
              generatorName: locations?.[selectedInbound?.AccountId]?.find(
                location => location.id === shippingDoc.data().GeneratorRef,
              ).LocationName,
            });
          });
          setListOfShippingDocuments(arrayOfDocuments);
        });
      return () => {
        listener();
      };
    }
  }, []);
  useEffect(() => {
    // this useEffect is to set the listOfItemsMissingData.
    if (!listOfItemsMissingData) {
      const listener = Firestore.collection('Tracking-Inventory').where('ShipmentInRef', '==', match.params.InboundId).onSnapshot(snap => {
        const itemsThatQualifyForPanel = [];
        snap.forEach(doc => {
          if (doc.data().Manifested && !doc.data().Classification && doc.data().Source !== 'Process') {
            itemsThatQualifyForPanel.push(
              {...doc.data(), id: doc.id},
            );
          }
        });
        setListOfItemsMissingData(itemsThatQualifyForPanel);
      });
      return () => listener();
    }
  }, []);
  useEffect(() => {
    // this useEffect is used to loop over all shipping documents and verify that they have a valid generatorName assigned to them
    // it is necessary because of the way we handle the locations redux state.
    if (listOfShippingDocuments && selectedInbound && locations) {
      setListOfShippingDocuments(listOfShippingDocuments.map(doc => ({
        ...doc,
        generatorName: locations?.[selectedInbound.AccountId]?.find(
          location => location.id === doc.GeneratorRef,
        ).LocationName,
      })));
    }
  }, [selectedInbound, locations]);
  useEffect(() => {
    // grabs all file references from files subcollection through a listener
    const arrayForState = [];
    const listener = Firestore.collection('Tracking-Shipments')
      .doc(match.params.InboundId)
      .collection('Supplemental-Files')
      .onSnapshot(
        {includeMetadataChanges: true},
        snap => {
          snap.docChanges().forEach(change => {
            arrayForState.push({...change.doc.data(), Date: change.doc.data().Date.toDate()});
          });
          setListOfSupplementalFiles([...arrayForState]);
        },
        error => {
          throw new Error(error);
        },
      );
    return () => {
      // removes the listener on dismount
      listener();
    };
  }, []);
  useEffect(() => {
    if (printView) {
      window.print();
      setPrintView(false);
    }
  }, [printView]);

  const sendStatusEmail = arrayOfErrors => {
    if (process.env.NODE_ENV !== 'production') return null;
    const emailList = [accounts[selectedInbound.AccountId].Manager.Email];
    Functions.httpsCallable('sendTrackingInboundNotificationEmail')({
      shippingDocs: arrayOfErrors,
      listToSendTo: emailList,
      accountName: accounts[selectedInbound.AccountId].AccountName,
      inboundId: selectedInbound.id,
    });
  };

  const core = {
    button: {
      size: 'small',
      variant: 'outlined',
      className: 'hidePrint',
      style: {marginLeft: '8px'},
    },
    printLabelsButton: {
      onClick: () => window.print(),
    },
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    weightTicketPanel: {
      listOfWeightTickets,
      selectedInbound,
      listOfShippingDocuments,
    },
    manifestedItemsPanel: {
      selectedInbound,
      listOfItemsMissingData,
      listOfShippingDocuments,
    },
    shippingDocumentPanel: {
      shippingDocuments: listOfShippingDocuments,
      selectedInbound,
      listOfWeightTickets,
    },
    inboundModal: {
      listOfWeightTickets,
      listOfShippingDocuments,
      selectedInbound,
      close: () => {
        setInboundModalOpen(false);
      },
    },
    uploadFileModal: {
      addFileModalOpen: uploadFileModalOpen,
      close: () => setUploadFileModalOpen(false),
      selectedInbound,
      mimeType: fileViewModalOpen ? 'application/pdf, image/*' : 'application/pdf',
      // if fileViewModal is open, that means the file uploaded is a supplemental file, and that is requires a name
      supplementalFile: fileViewModalOpen,
      fileNameNotNeeded: !fileViewModalOpen,
    },
    fileButton: {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      onClick: () => {
        setUploadFileModalOpen(true);
      },
    },
    viewFileButton: {
      variant: 'outlined',
      color: 'primary',
      fullWidth: true,
      onClick: () => {
        uploadedFileRef.getDownloadURL().then(url => {
          window.open(url, '__blank');
        });
      },
    },
    confirmationModal: {
      open: confirmationModalOpen,
      selectedInbound,
      listOfWeightTickets,
      listOfShippingDocuments,
      sendEmailAfterConfirmation: arrayOfErrors => sendStatusEmail(arrayOfErrors),
      close: () => setConfirmationModalOpen(false),
    },
    completeButton: {
      variant: 'outlined',
      color: 'secondary',
      fullWidth: true,
      onClick: () => setConfirmationModalOpen(true),
    },
    editInboundButton: {
      onClick: () => setInboundModalOpen(true),
    },
    printFormButton: {
      onClick: () => {
        setPrintView(true);
      },
    },
    link: accountId => ({
      onClick: () => window.open(`https//crm.kbi.works/accounts/${accountId}`, '__blank'),
    }),
    fileViewModal: {
      open: fileViewModalOpen,
      classes,
      selectedInbound,
      uploadButtonOnClick: () => setUploadFileModalOpen(true),
      files: listOfSupplementalFiles,
      close: () => setFileViewModalOpen(false),
    },
    openFileViewButton: {
      variant: 'outlined',
      fullWidth: true,
      color: 'primary',
      onClick: () => setFileViewModalOpen(true),
    },
    printLabels: {
      listOfShippingDocuments,
      selectedInbound,
    },
    notesPanel: {
      Storage,
      notesForTable: listOfNotes,
      modalSubmission: async (newNote, fileArray) => {
        const formData = new FormData();
        formData.append('newNote', JSON.stringify(newNote));
        formData.append('noteCollectionPath', `Tracking-Shipments/${selectedInbound?.id}/Notes`);
        formData.append('storagePath', `Inbound_Forms/${selectedInbound?.id}`);

        for (let i = 0; i < fileArray.length; i++) {
          // this is here in order to convert the images uploaded through JIMP to be converted into blobs, able to be sent over formData.
          // if they are a pdf, and did not go through JIMP, then just take the file prop.
          const blob = fileArray[i].buffer ? new Blob([fileArray[i].buffer], {type: 'image/jpg'}) : fileArray[i].file;
          formData.append('fileData', blob, fileArray[i].fileName);
        }

        try {
          await axios({
            method: 'POST',
            url: `${appEngine}/shared/createNote`,
            headers: {
              'Authorization': await getAuthToken(),
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            data: formData,
          });
          return {success: true};
        }
        catch (error) {
          return {success: false};
        }
      },
      parentDocumentId: selectedInbound?.id,
      storagePath: 'Inbound_Forms',
      currentUser: props.currentUser,
    },
  };

  if (!selectedInbound || !listOfWeightTickets || !listOfShippingDocuments || !locations || !listOfItemsMissingData) return <LinearProgress />;

  return (
    <Fragment>
      <div className={classes.sideScrollingContainer}>
        <BackButton />
        {selectedInbound.InProgress && (
          <Fragment>
            <Button {...core.button} {...core.editInboundButton}>
              <Edit className={classes.extendedIcon} />
              Edit Inbound
            </Button>
          </Fragment>)}
        {!selectedInbound.InProgress && (
          <Button {...core.button} {...core.printLabelsButton}>
            <Print className={classes.extendedIcon} />
            Print Labels
          </Button>)}
        <Button {...core.button} {...core.printFormButton}><Print className={classes.extendedIcon} />Print Form</Button>
      </div>
      <Grid className='hidePrint' container spacing={5} style={{ marginTop: '8px' }}> {/* eslint-disable-line */}
        <Grid item xs={12} md={3}>
          <Paper {...core.paperHeader}>
            <LocalShipping style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
            <DetailsTitle title='Inbound Load' />
            <DetailsFieldList fields={[
              {label: 'Inbound Id', value: selectedInbound.id},
              {label: 'Date Received', value: moment(selectedInbound.ShipmentTime).format('MM/DD/YYYY')},
              {label: 'Facility', value: selectedInbound.Facility},
              {label: 'Account', value: accounts[selectedInbound.AccountId].AccountName, link: `https://crm.kbi.works/accounts/${selectedInbound.AccountId}`},
              {label: 'Transporter', value: accounts[selectedInbound.Transporter].AccountName, link: `https://crm.kbi.works/accounts/${selectedInbound.Transporter}`},
              {label: 'Comments', value: selectedInbound.Comments, visible: Boolean(selectedInbound.Comments)},
            ]}
            />
            <List style={{minWidth: '100%', maxWidth: '100%'}}>
              {selectedInbound.InProgress && (
                <ListItem>
                  <Button {...core.button} {...core.fileButton}>
                    {isFileUploaded ? 'Reupload Primary File' : 'Upload Primary File'}
                  </Button>
                </ListItem>)}
              {isFileUploaded && uploadedFileRef && (
                <ListItem>
                  <Button {...core.button} {...core.viewFileButton}>View Primary File</Button>
                </ListItem>)}
              {isFileUploaded && selectedInbound.InProgress && (
                <ListItem>
                  <Button {...core.button} {...core.completeButton}>Mark as Complete</Button>
                </ListItem>)}
              <ListItem>
                <Button {...core.button} {...core.openFileViewButton}>Supplemental Files</Button>
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <ManifestedItemsMissingDataPanel {...core.manifestedItemsPanel} />
          <WeightTickets {...core.weightTicketPanel} />
          <ShippingDocumentPanel {...core.shippingDocumentPanel} />
          <NotesPanel {...core.notesPanel} />
        </Grid>
      </Grid>
      {inboundModalOpen && <InboundModal {...core.inboundModal} />}
      {uploadFileModalOpen && <AddFileModal {...core.uploadFileModal} />}
      {confirmationModalOpen && <ConfirmationModal {...core.confirmationModal} />}
      {fileViewModalOpen && <FileViewModal {...core.fileViewModal} />}
      {printView ? <PrintableIncoming {...core.weightTicketPanel} /> : null}
      {!selectedInbound.InProgress && !printView ? <PrintLabels {...core.printLabels} /> : null}
    </Fragment>
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
  sideScrollingContainer: {
    'display': 'flex',
    'flexWrap': 'nowrap',
    'overflowX': 'auto',
    '& div': {
      flex: '0 0 auto',
    },
  },
});

const mapStateToProps = state => {
  return {
    accountProfiles: state.firestore.accountProfiles,
    currentUser: state.auth.currentUser,
  };
};
const mapActionsToProps = {
  acGetAccountProfiles,
  acClearAccountProfiles,
};
InboundDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  acGetAccountProfiles: PropTypes.func.isRequired,
  acClearAccountProfiles: PropTypes.func.isRequired,
  accountProfiles: PropTypes.shape({
    profiles: PropTypes.arrayOf(PropTypes.object.isRequired),
    listener: PropTypes.func.isRequired,
  }),
};

export default flowRight(
  connect(
    mapStateToProps,
    mapActionsToProps,
  ),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(InboundDetails);

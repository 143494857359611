import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import PropTypes from 'prop-types';
import KbiLogo from 'media/KBI-logo(small).png';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

const BillingInformation = ({selectedInvoice, classes, StyledTypography, history, selectedInbound}) => {
  const core = {
    link: type => ({
      className: 'printableLinks',
      onClick: () => {
        switch (type) {
        case 'PO':
          window.open(`https://crm.kbi.works/accounts/${selectedInvoice.AccountRef}/purchase-order/${selectedInvoice.PORef}`, '__blank');
          break;
        case 'Inbound':
          history.push(`/shipments/inbound/${selectedInvoice.InboundRef}`);
          break;
        case 'Shipping Doc':
          history.push(`/shipments/inbound/${selectedInvoice.InboundRef}/${selectedInvoice.ShippingDocRef}`);
          break;
        default:
          break;
        }
      },
    }),
  };
  return (
    <Fragment>
      <Grid container justify='space-between' className={`firstPage ${classes.newSection}`}>
        <Grid item xs={9}>
          <img alt='KBI logo' src={KbiLogo} style={{height: '80px'}} />
        </Grid>
        <Grid item xs={3} md={2}
          container justify='flex-start' direction='column'
          alignItems='flex-end'
        >
          <StyledTypography variant='body2' className={classes.bold}>
            Proforma Invoice
          </StyledTypography>
          <StyledTypography variant='body2'>125 E Commercial St. A</StyledTypography>
          <StyledTypography variant='body2'>Anaheim, CA 92801</StyledTypography>
          <StyledTypography variant='body2'>www.kbirecycling.com</StyledTypography>
        </Grid>
      </Grid>
      <Grid container className={`${classes.newSection} ${classes.paddingLeft}`}>
        <Grid item xs={4} className={`${classes.borderLeft} ${classes.borders}`}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Billing Address
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>{selectedInvoice.BillingAddress.LocationName}</StyledTypography>
          <StyledTypography variant='body2'>{selectedInvoice.BillingAddress.Address.DoubleLine.Line1}</StyledTypography>
          <StyledTypography variant='body2'>{selectedInvoice.BillingAddress.Address.DoubleLine.Line2}</StyledTypography>
          <StyledTypography variant='body2' paragraph>{selectedInvoice.BillingAddress.Address.Country}</StyledTypography>
        </Grid>
        <Grid item xs={4} className={classes.borders}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Material Generator
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>{selectedInvoice.GeneratorName}</StyledTypography>
          <StyledTypography variant='body2'>
            {selectedInvoice.GeneratorAddress.DoubleLine.Line1}
          </StyledTypography>
          <StyledTypography variant='body2'>
            {selectedInvoice.GeneratorAddress.DoubleLine.Line2}
          </StyledTypography>
          <StyledTypography variant='body2' paragraph>
            {selectedInvoice.GeneratorAddress.Country}
          </StyledTypography>
        </Grid>
        <Grid item xs={4} className={classes.borders}>
          <Grid item xs={12} className={`${classes.textChildrenPadding} ${classes.titleSection}`}>
            <StyledTypography variant='subtitle2' className={classes.bold}>
              Billing Details
            </StyledTypography>
          </Grid>
          <StyledTypography variant='body2'>Facility: {selectedInbound.Facility}</StyledTypography>
          <StyledTypography variant='body2'>Date: {moment(selectedInbound.ShipmentTime.toDate()).format('MM/DD/YYYY')}</StyledTypography>
          <StyledTypography variant='body2'>Inbound Form: <span {...core.link('Inbound')}>{selectedInvoice.InboundRef}</span></StyledTypography>
          <StyledTypography variant='body2' >
            Shipping Doc: <span {...core.link('Shipping Doc')}>{selectedInvoice.ShippingDocNo}</span>
          </StyledTypography>
          <StyledTypography variant='body2'>
            Weight Ticket(s): {selectedInvoice.WeightTickets}
          </StyledTypography>
          <StyledTypography variant='body2' paragraph>KBI Internal PO: <span {...core.link('PO')}>{selectedInvoice.InternalPO}</span></StyledTypography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

BillingInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  StyledTypography: PropTypes.object.isRequired,
  selectedInvoice: PropTypes.object.isRequired,
  selectedInbound: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(BillingInformation);

import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetActiveConsolidationForms} from 'state/firestore/actions.js';

const useConsolidationForms = () => {
  const dispatch = useDispatch();
  const consolidationForms = useSelector(state => state.firestore.consolidationForms);

  useEffect(() => {
    if (!consolidationForms) dispatch(acGetActiveConsolidationForms);
  }, [dispatch, consolidationForms]);

  return consolidationForms;
};

export default useConsolidationForms;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Collapse,
  TextField,
  Grid,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Switch,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import {SubmitButton, SubmitConfirmation, containerCodes} from 'components/';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Alert} from '@kbi/component-library';

const cleanState = {
  material: {
    value: '',
    error: '',
    display: 'Material',
  },
  type: {
    value: '',
    error: '',
    display: 'Type',
  },
  grossWeight: {
    value: '',
    error: '',
    display: 'Gross Weight',
  },
  tareWeight: {
    value: '',
    error: '',
    display: 'Tare Weight',
  },
  lineNumber: {
    value: '',
    error: '',
    display: 'Line',
  },
  status: {
    value: 'Accepted',
    error: '',
    display: 'Status',
  },
  flag: {
    value: '',
    error: '',
    display: 'Flag',
  },
  notes: {
    value: '',
    error: '',
    display: 'Notes',
  },
  containerCode: {
    value: '',
    error: '',
    display: 'Container Code',
  },
  containerCodeType: {
    value: '',
    error: '',
    display: 'Code Type',
  },
  classification: {
    value: '',
    error: '',
    display: 'Classification',
  },
  caCodes: [],
  rcraCodes: [],
};

export default function EditLineItemModal(props) {
  const {selectedLineItem} = props;
  const [stageArray, setStageArray] = useState(['basic', 'success']);
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(''); // passed to Alert component, used to show errors that are modal wide
  const [formState, setFormState] = useState(JSON.parse(JSON.stringify(cleanState))); // the state of the form, with its values and errors
  const [deleteConfirmation, setDeleteConfirmation] = useState(false); // used to control the delete button, making the user click twice to confirm delete
  const [itemDeleted, setItemDeleted] = useState(false); // used to help the modal display correct text if an item is deleted instead of updated
  const [listOfSelectableMaterials, setListOfSelectableMaterials] = useState(null); // the list of materials that the user can select, based of material profile
  // anything that has to do with the waste codes will first access this value to make sure this value is true
  const [wasteCodesAvailable, setWasteCodesAvailable] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const {materials, types, accountProfiles, purchaseOrders} = useSelector(state => state.firestore);

  const controlCollapses = () => {
    if (wasteCodesAvailable) {
      setStageArray(['basic', 'codes', 'success']);
    }
    else {
      setStageArray(['basic', 'success']);
      setFormState({...formState, caCodes: [], rcraCodes: []});
    }
  };
  useEffect(controlCollapses, [wasteCodesAvailable]);
  const determineIfWasteCodesAvailable = () => {
    // if the qualifications for allowing waste codes to be changed have all been met, this will set the value of wasteCodesAvailable to true.
    // if the item is on a HW Manifest, and is marked as discrepancy. Also must be marked as hazardous waste.
    // if selectedLineItem is undefined, that means the user is creating a new line item and therefore, must enter codes if it is a haz waste.
    if (
      (props.shippingDocument.DocumentType === 'HW Manifest' &&
        formState.status.value === 'Discrepancy' &&
        formState.classification.value === 'Hazardous Waste') ||
      (!selectedLineItem && formState.classification.value === 'Hazardous Waste')
    ) {
      setWasteCodesAvailable(true);
    }
    else {
      setWasteCodesAvailable(false);
    }
  };
  useEffect(determineIfWasteCodesAvailable, [
    props.shippingDocument.DocumentType,
    formState.status.value,
    formState.classification.value,
  ]);
  const assignSelectedPurchaseOrder = () => {
    const correctPO = purchaseOrders[props.selectedInbound.AccountId].find(po => po.id === props.shippingDocument.PONumber.Ref);
    setSelectedPurchaseOrder(correctPO);
  };
  useEffect(assignSelectedPurchaseOrder, []);
  const prepopulateModalWithLineItem = () => {
    // component did mount. prepopulates the modal with values of selected line item
    if (selectedLineItem) {
      setFormState({
        ...formState,
        status: {...formState.status, value: selectedLineItem.Status || 'Accepted'},
        classification: {...formState.classification, value: selectedLineItem.Classification},
        notes: {...formState.notes, value: selectedLineItem.Notes || ''},
        material: {
          ...formState.material,
          value: selectedLineItem.AcceptedMaterial ?
            selectedLineItem.AcceptedMaterial.Ref :
            selectedLineItem.Shipped.Ref,
        },
        type: {...formState.type, value: selectedLineItem.AcceptedType ? selectedLineItem.AcceptedType.Ref : ''},
        flag: {...formState.flag, value: selectedLineItem && selectedLineItem.Flag ? selectedLineItem.Flag : ''},
        grossWeight: {...formState.grossWeight, value: selectedLineItem.GrossWeight || ''},
        tareWeight: {...formState.tareWeight, value: selectedLineItem.TareWeight || ''},
        lineNumber: {...formState.lineNumber, value: selectedLineItem.Line},
        containerCode: {
          ...formState.containerCode,
          value: containerCodes.find(code => code[0] === selectedLineItem.ContainerCode),
        },
        containerCodeType: {...formState.containerCodeType, value: selectedLineItem.ContainerCodeType || ''},
      });
    }
  };
  useEffect(prepopulateModalWithLineItem, [selectedLineItem]);
  useEffect(() => {
    if (accountProfiles) {
      const approvedMaterials = accountProfiles.profiles.find(profile => profile.Active).Materials;
      setListOfSelectableMaterials(approvedMaterials);
    }
  }, [accountProfiles]);
  const setAndDisplayCodes = () => {
    // sets the codes that will be rendered on the 'codes' collapse
    if (formState.material.value) {
      const caCodes = materials.ref[formState.material.value].WasteCodes.CA.map(caCode => {
        const codeSwitch = {value: caCode, checked: false};
        if (selectedLineItem && selectedLineItem.CACodes.find(code => code === caCode) && formState.status.value !== 'Discrepancy') {
          // this if keeps the values of the line items codes in place, even if the user switches between classifications and status's
          // once the user saves the item, all old values will be overwritten, so they will need to reselect.
          codeSwitch.checked = true;
        }
        return codeSwitch;
      });
      const rcraCodes = materials.ref[formState.material.value].WasteCodes.RCRA.map(rcraCode => {
        const codeSwitch = {value: rcraCode, checked: false};
        if (selectedLineItem && selectedLineItem.RCRACodes.find(code => code === rcraCode) && formState.status.value !== 'Discrepancy') {
          // this if keeps the values of the line items codes in place, even if the user switches between classifications and status's
          // once the user saves the item, all old values will be overwritten, so they will need to reselect.
          codeSwitch.checked = true;
        }
        return codeSwitch;
      });
      setFormState({...formState, caCodes, rcraCodes});
    }
  };
  useEffect(setAndDisplayCodes, [formState.material.value, formState.classification.value, formState.status.value]);

  const handleSubmit = () => {
    if (validateForm()) {
      setSubmitting(true);
      const newLineItemArrayForDB = createDataForFirestore();
      const batch = Firestore.batch();
      const firebaseDocRef = Firestore.collection('Tracking-Shipments')
        .doc(props.selectedInbound.id)
        .collection('Shipping-Documents')
        .doc(props.shippingDocument.id);
      if (selectedLineItem) {
        // if selectedLineItem, then the user is updating a specific item. we will replace the entire array in the DB, with the new array including the new item
        batch.update(firebaseDocRef, {LineItems: newLineItemArrayForDB});
      }
      else {
        // if a new item is added, there needs to be a change in the piece count for the shipping doc
        batch.update(firebaseDocRef, {
          PieceCount: props.shippingDocument.PieceCount + 1,
          NextItemPieceNumber: props.shippingDocument.NextItemPieceNumber + 1,
          LineItems: firebase.firestore.FieldValue.arrayUnion(...newLineItemArrayForDB),
        });
      }

      batch
        .commit()
        .then(() => {
          setStage(stage + 1);
          setSubmitting(false);
        })
        .catch(error => {
          setSubmitting(false);
          setFormError('Error in submission. Please try again.');
        });
    }
  };
  const createCodesForFirestore = arrayOfCodes => {
    const codeArray = [];
    arrayOfCodes.forEach(code => {
      if (code.checked) codeArray.push(code.value);
    });
    return codeArray;
  };
  const createDataForFirestore = () => {
    const firestoreObj = {};
    if (selectedLineItem) {
      // if user is editing an item, these values come from the firestore obj
      firestoreObj.Shipped = selectedLineItem.Shipped;
      firestoreObj.PieceNumber = selectedLineItem.PieceNumber;
    }
    else {
      // if a new item is being created, these values are created on the spot
      firestoreObj.Shipped = {
        Name: materials.ref[formState.material.value].UnitDetails.MaterialName,
        Ref: formState.material.value,
      };
      firestoreObj.Codes = materials.ref[formState.material.value].WasteCodes;
      firestoreObj.PieceNumber = props.shippingDocument.NextItemPieceNumber;
    }
    // this section always comes from state.
    firestoreObj.Line = formState.lineNumber.value;
    firestoreObj.ContainerCode = formState.containerCode.value ? formState.containerCode.value[0] : '';
    firestoreObj.Classification = formState.classification.value;
    firestoreObj.CACodes = createCodesForFirestore(formState.caCodes);
    firestoreObj.RCRACodes = createCodesForFirestore(formState.rcraCodes);
    firestoreObj.ContainerCodeType = formState.containerCodeType.value;
    firestoreObj.Status = formState.status.value;
    firestoreObj.Flag = formState.flag.value;
    firestoreObj.GrossWeight = parseInt(formState.grossWeight.value);
    firestoreObj.TareWeight = parseInt(formState.tareWeight.value);
    firestoreObj.NetWeight = parseInt(formState.grossWeight.value) - parseInt(formState.tareWeight.value) || null;
    firestoreObj.Notes = formState.notes.value.trim();

    if (formState.status.value !== 'Rejected') {
      // if the form isnt rejected, create the accepted names for the item
      firestoreObj.AcceptedMaterial = {
        Name: materials.ref[formState.material.value].UnitDetails.MaterialName,
        Ref: formState.material.value,
      };
      firestoreObj.AcceptedType = {
        Name: types[formState.material.value].find(type => type.id === formState.type.value).TypeName,
        Ref: formState.type.value,
      };
      firestoreObj.AcceptedName = `${materials.ref[formState.material.value].UnitDetails.MaterialName} - ${
        types[formState.material.value].find(type => type.id === formState.type.value).TypeName
      }`;
    }
    else {
      // if the form is rejected, create the fields with the values expected for a rejected item
      firestoreObj.AcceptedName = 'Rejected';
      firestoreObj.AcceptedMaterial = null;
      firestoreObj.AcceptedType = null;
    }

    const indexOfItem = props.shippingDocument.LineItems.findIndex(
      item => item.PieceNumber === firestoreObj.PieceNumber,
    );
    const updatedArrayOfLineItems = [
      ...props.shippingDocument.LineItems.slice(0, indexOfItem),
      firestoreObj,
      ...props.shippingDocument.LineItems.slice(indexOfItem + 1),
    ];
    return updatedArrayOfLineItems;
  };
  const handleDelete = () => {
    // functions similar to the handleSubmit
    // removes the item from the array of line items, and lowers the piece count of the shipping document
    if (deleteConfirmation) {
      setSubmitting(true);
      const indexOfItem = props.shippingDocument.LineItems.findIndex(
        item => item.PieceNumber === selectedLineItem.PieceNumber,
      );
      const arrayWithoutDeletedItem = [
        ...props.shippingDocument.LineItems.slice(0, indexOfItem),
        ...props.shippingDocument.LineItems.slice(indexOfItem + 1),
      ];
      const firebaseDocRef = Firestore.collection('Tracking-Shipments')
        .doc(props.selectedInbound.id)
        .collection('Shipping-Documents')
        .doc(props.shippingDocument.id);

      firebaseDocRef
        .update({
          PieceCount: props.shippingDocument.PieceCount - 1,
          LineItems: arrayWithoutDeletedItem,
        })
        .then(() => {
          setStage(stage + 1);
          setSubmitting(false);
          setItemDeleted(true);
        })
        .catch(error => {
          setSubmitting(false);
          setFormError('Error in submission. Please try again.');
        });
    }
    else {
      setDeleteConfirmation(true);
    }
  };
  const validateForm = () => {
    let dataError = false;
    const formStateObj = {...formState};
    // if status is not rejected, verify form values
    if (formState.status.value !== 'Rejected') {
      if (!formState.type.value) {
        dataError = true;
        formStateObj.type.error = 'Type is a required field.';
      }
      if (!formState.material.value) {
        dataError = true;
        formStateObj.material.error = 'Material is a required field.';
      }
      if (!formState.grossWeight.value) {
        dataError = true;
        formStateObj.grossWeight.error = 'Gross Weight is a required field.';
      }
      else if (formState.grossWeight.value.length > 4) {
        dataError = true;
        formStateObj.grossWeight.error = 'Gross Weight cannot be that large.';
      }
      if (!formState.tareWeight.value) {
        dataError = true;
        formStateObj.tareWeight.error = 'Tare Weight is a required field.';
      }
      else if (parseInt(formState.grossWeight.value) - parseInt(formState.tareWeight.value) < 0) {
        dataError = true;
        formStateObj.tareWeight.error = 'Tare Weight must be less than Gross Weight.';
      }
      if (!formState.containerCode.value) {
        dataError = true;
        formStateObj.containerCode.error = 'Container Code is a required field.';
      }
      if (formState.material.value && materials.ref[formState.material.value].FlagStatus === 'Required' && !formState.flag.value) {
        formStateObj.flag = {...formState.flag, error: 'This material requires a flag.'};
        dataError = true;
      }
      if (
        formState.containerCode.value &&
        formState.containerCode.value[1].length !== 0 &&
        !formState.containerCodeType.value
      ) {
        dataError = true;
        formStateObj.containerCodeType.error = 'Code Type is required for this Container Code.';
      }
      if (!formState.lineNumber.value) {
        dataError = true;
        formStateObj.lineNumber.error = 'Line Number is a required field.';
      }
    }
    // only thing required if status is rejected is a note
    // also requires a note for anything that is not status === Accepted
    if (!formState.notes.value && formState.status.value !== 'Accepted') {
      dataError = true;
      formStateObj.notes.error = `A note is required for any status besides 'Accepted'.`;
    }

    setFormState({...formStateObj});
    return !dataError;
  };
  const createButtons = () => {
    if (stageArray[stage] === 'success' || !props.selectedInbound.InProgress) {
      return (
        <DialogActions>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      );
    }
    else if (!props.selectedLineItem) {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>{stageArray[stage] !== 'basic' ? 'Back' : 'Cancel'}</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>);
    }
    else {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <SubmitButton {...core.deleteButton} />
          <div>
            <Button {...core.cancelButton}>{stageArray[stage] !== 'basic' ? 'Back' : 'Cancel'}</Button>
            <SubmitButton {...core.submitButton} />
          </div>
        </DialogActions>
      );
    }
  };
  const createFlagOptions = () => {
    const arrayToRender = [...materials.ref[formState.material.value].Flags].sort((a, b) => a.Name > b.Name ? 1 : -1);
    if (materials.ref[formState.material.value].FlagStatus === 'Optional') {
      arrayToRender.unshift({Name: '(none)'});
    }
    return arrayToRender.map((flag, index) => {
      return (
        <MenuItem key={index} value={flag.Name === '(none)' ? '' : flag.Name}>{flag.Name}</MenuItem>
      );
    });
  };

  const core = {
    dialog: {
      open: props.open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'md',
      fullWidth: true,
    },
    gridContainer: {
      container: true,
      spacing: 2,
    },
    cancelButton: {
      color: 'primary',
      onClick: () => {
        if (stageArray[stage] === 'basic') props.close();
        else {
          setStage(stage - 1);
        }
      },
      disabled: submitting,
    },
    closeButton: {
      color: 'primary',
      onClick: props.close,
    },
    submitButton: {
      color: 'primary',
      text: stage !== stageArray.length - 2 ? 'Next' : 'Submit',
      loading: submitting && !deleteConfirmation,
      disabled: submitting,
      onClick: () => {
        if (stage === stageArray.length - 2) handleSubmit();
        else {
          if (validateForm()) setStage(stage + 1);
        }
      },
    },
    deleteButton: {
      color: 'secondary',
      text: deleteConfirmation ? 'Confirm?' : 'Delete',
      loading: submitting && deleteConfirmation,
      disabled: submitting,
      onClick: handleDelete,
    },
    materialsSelect: {
      disabled: (selectedLineItem && formState.status.value === 'Accepted') || formState.status.value === 'Rejected',
      select: true,
      required: formState.status.value !== 'Rejected',
    },
    typeSelect: {
      disabled: !formState.material.value || formState.status.value === 'Rejected',
      select: true,
      required: formState.status.value !== 'Rejected',
    },
    flagSelect: {
      disabled: !formState.material.value || formState.status.value === 'Rejected' || materials.ref[formState.material.value].FlagStatus === 'N/A' ?
        true : false,
      select: true,
      required: formState.status.value !== 'Rejected' && formState.material.value && materials.ref[formState.material.value].FlagStatus === 'Required' ?
        true : false,
    },
    weightField: {
      disabled: formState.status.value === 'Rejected',
      required: formState.status.value !== 'Rejected',
      type: 'number',
      InputProps: {
        endAdornment: <InputAdornment position='end'>lbs</InputAdornment>,
      },
    },
    notesField: {
      required: formState.status.value !== 'Accepted',
    },
    classificationField: {
      select: true,
      required: true,
      disabled: formState.status.value !== 'Discrepancy' && selectedLineItem ? true : false,
    },
    submitConfirmation: {
      text: itemDeleted ? 'Line item successfully deleted.' : 'Line item successfully updated.',
      stage: stageArray[stage],
      delete: itemDeleted,
    },
    containerCodeTypeField: {
      disabled:
        !formState.containerCode.value ||
        (formState.containerCode.value && formState.containerCode.value[1].length === 0),
      select: true,
      required: formState.containerCode.value && formState.containerCode.value[1].length !== 0 ? true : false,
    },
    containerCodeField: {
      disabled: formState.status.value === 'Rejected',
      required: formState.status.value !== 'Rejected',
      select: true,
    },
    textField: key => {
      return {
        error: formState[key].error ? true : false,
        fullWidth: true,
        helperText: formState[key].error,
        id: key,
        label: formState[key].display,
        margin: 'normal',
        InputProps: {
          readOnly: !props.selectedInbound.InProgress,
        },
        value: formState[key].value,
        onChange: e => {
          const formStateObj = {...formState, [key]: {...formState[key], error: '', value: e.target.value}};
          if (key === 'containerCode') {
            // if container is changed, clear out the container type
            formStateObj.containerCodeType = {...cleanState.containerCodeType};
          }
          else if (key === 'material') {
            // if material is changed, clear out the type
            formStateObj.type = {...cleanState.type};
            formStateObj.flag = {...cleanState.flag};
          }
          else if (key === 'type') {
            const typeFromPO = selectedPurchaseOrder.ApprovedTypes[e.target.value];
            if (typeFromPO) {
              const arrayOfFlagsFromTypes = Object.values(typeFromPO);
              if (arrayOfFlagsFromTypes.length === 1) {
                formStateObj.flag = {...formStateObj.flag, value: arrayOfFlagsFromTypes[0].Flag};
              }
            }
            else {
              formStateObj.flag = {
                ...formStateObj.flag, value: listOfSelectableMaterials.find(material => material.Ref === formState.material.value) ?
                  listOfSelectableMaterials.find(material => material.Ref === formState.material.value).PreferredFlag : '',
              };
            }
          }
          else if (e.target.value === 'Rejected') {
            // if status is set to rejected, clear out the values of nearly everything.
            formStateObj.grossWeight = {...cleanState.grossWeight};
            formStateObj.tareWeight = {...cleanState.tareWeight};
            formStateObj.material = {...cleanState.material};
            formStateObj.type = {...cleanState.type};
            formStateObj.flag = {...cleanState.flag};
            formStateObj.containerCode = {...cleanState.containerCode};
            formStateObj.containerCodeType = {...cleanState.containerCodeType};
            formStateObj.classification = {...cleanState.classification};
          }
          else if (e.target.value === 'Accepted' && selectedLineItem) {
            // if status is set to Accepted, return values of form back to their original values, pulled from the line item data
            formStateObj.material = {...cleanState.material, value: selectedLineItem.Shipped.Ref};
            formStateObj.type = {...cleanState.type};
            formStateObj.flag = {...cleanState.flag};
            formStateObj.notes = {...formState.notes, error: ''};
            formStateObj.classification = {...cleanState.classification, value: selectedLineItem.Classification};
          }
          else if (e.target.value === 'Exception' && selectedLineItem) {
            // if status set to exception, set the value of the classification to its original value from line item.
            // on an exception, only the classification is set back, because it is the only thing that must be the same as accepted, everything else can change
            formStateObj.classification = {...cleanState.classification, value: selectedLineItem.Classification};
          }
          setFormState(formStateObj);
        },
      };
    },
    switchProps: (switchItem, index, stateKey) => ({
      checked: formState[stateKey][index] ? formState[stateKey][index].checked : false,
      value: switchItem.ticketNumber,
      color: 'primary',
      onChange: e => {
        setFormState({
          ...formState,
          [stateKey]: [
            ...formState[stateKey].slice(0, index),
            {value: switchItem, checked: e.target.checked},
            ...formState[stateKey].slice(index + 1),
          ],
        });
        setFormError('');
      },
    }),
    paddingRight: {
      style: {paddingRight: 0},
    },
  };
  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && (
        <DialogTitle>
          {!props.selectedInbound.InProgress ?
            'View Line Item Details' : selectedLineItem ?
              `Editting Piece No. ${selectedLineItem.PieceNumber}` : 'Create New Line Item'}
        </DialogTitle>
      )}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <Grid {...core.gridContainer}>
            <Grid container spacing={2} item
              xs={12} {...core.paddingRight}
            >
              <Grid item xs={12} sm>
                <TextField select required {...core.textField('status')}>
                  <MenuItem value='Accepted'>Accepted</MenuItem>
                  <MenuItem value='Rejected'>Rejected</MenuItem>
                  {props.shippingDocument.DocumentType !== 'Bill of Lading' && (
                    <MenuItem value='Discrepancy'>Discrepancy</MenuItem>
                  )}
                  <MenuItem value='Exception'>Exception</MenuItem>
                </TextField>
              </Grid>
              {!selectedLineItem && (
                <Grid item xs={12} sm>
                  <TextField required {...core.textField('lineNumber')} />
                </Grid>
              )}
              <Grid item xs={12} sm
                {...core.paddingRight}
              >
                <TextField {...core.classificationField} {...core.textField('classification')}>
                  <MenuItem value={'Universal Waste'}>Universal Waste</MenuItem>
                  {props.shippingDocument.DocumentType !== 'HW Manifest' && <MenuItem value={'N/A'}>N/A</MenuItem>}
                  {props.shippingDocument.DocumentType === 'HW Manifest' && (
                    <MenuItem value={'Hazardous Waste'}>Hazardous Waste</MenuItem>
                  )}
                  {props.shippingDocument.DocumentType === 'HW Manifest' && (
                    <MenuItem value={'Exempt per 49 CFR 173.159'}>Exempt per 49 CFR 173.159</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm>
                <TextField {...core.materialsSelect} {...core.textField('material')}>
                  {listOfSelectableMaterials &&
                    listOfSelectableMaterials
                      .sort((a, b) => (a.Name > b.Name ? 1 : -1))
                      .map((material, index) => {
                        return (
                          <MenuItem key={index} value={material.Ref}>
                            {material.Name}
                          </MenuItem>
                        );
                      })}
                </TextField>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.typeSelect} {...core.textField('type')}>
                {types[formState.material.value] ? (
                  types[formState.material.value]
                    .filter(type => type.Active)
                    .sort((a, b) => (a.TypeName > b.TypeName ? 1 : -1))
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.TypeName}
                        </MenuItem>
                      );
                    })
                ) : (
                  <MenuItem value={null}>null</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.flagSelect} {...core.textField('flag')}>
                {formState.material.value ? (
                  createFlagOptions()
                ) : (
                  <MenuItem value={null}>null</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.weightField} {...core.textField('grossWeight')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.weightField} {...core.textField('tareWeight')} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.containerCodeField} {...core.textField('containerCode')}>
                {containerCodes.map((code, index) => (
                  <MenuItem key={index} value={code}>
                    {code[0]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField {...core.containerCodeTypeField} {...core.textField('containerCodeType')}>
                {formState.containerCode.value && formState.containerCode.value[1].length ? (
                  formState.containerCode.value[1].map((codeType, index) => (
                    <MenuItem key={index} value={codeType}>
                      {codeType}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={null}>null</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField required {...core.textField('notes')} multiline
                {...core.notesField}
              />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={wasteCodesAvailable && stageArray[stage] === 'codes'}>
          <DialogContentText>Please select all waste codes that apply to this item</DialogContentText>
          <Grid {...core.gridContainer}>
            <Grid item xs={12} sm={6}
              container
            >
              <Grid item xs={12}>
                <Typography>CA Codes:</Typography>
              </Grid>
              <Grid item xs={12}>
                {formState.material.value &&
                  materials.ref[formState.material.value].WasteCodes.CA.map((caCode, index) => (
                    <FormControlLabel
                      key={index}
                      label={caCode}
                      control={<Switch {...core.switchProps(caCode, index, 'caCodes')} />}
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}
              container
            >
              <Grid item xs={12}>
                <Typography>RCRA Codes:</Typography>
              </Grid>
              <Grid item xs={12}>
                {formState.material.value &&
                  ['N/A', ...materials.ref[formState.material.value].WasteCodes.RCRA].map((rcraCode, index) => (
                    <FormControlLabel
                      key={index}
                      label={rcraCode}
                      control={<Switch {...core.switchProps(rcraCode, index, 'rcraCodes')} />}
                    />
                  ))}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {createButtons()}
    </Dialog >
  );
}

EditLineItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedLineItem: PropTypes.object,
  selectedInbound: PropTypes.object.isRequired,
  shippingDocument: PropTypes.object.isRequired,
};

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Formik} from '@kbi/component-library';
const {AutoCompleteObject, SelectField, DateField, TextField} = Formik;

const ProcessForm = ({values}) => {
  const {materials, types, facilityUnits} = useSelector(reduxState => reduxState.firestore);

  const core = useMemo(() => ({
    materialAutocomplete: {
      name: 'material',
      label: 'Yield',
      options: [...materials.yieldList, {MaterialId: 'mixed', UnitDetails: {MaterialName: 'mixed'}}],
      optionKey: 'UnitDetails.MaterialName',
      onChange: ({form, field}) => {
        form.setFieldValue('materialType', '');
        form.setFieldValue('flag', '');
      },
    },
    typeAutocomplete: {
      name: 'materialType',
      label: 'Type',
      loading: !values.material,
      loadingText: 'Select a material to populate',
      options: values.material ? types[values.material.MaterialId] : [],
      optionKey: 'TypeName',
    },
    flagAutocomplete: {
      name: 'flag',
      label: 'Flag',
      loading: !values.material,
      loadingText: 'Select a material to populate',
      options: values.material ? values.material.Flags : [],
      optionKey: 'Name',
    },
    facilityUnitAutocomplete: {
      name: 'facilityUnit',
      label: 'Facility Unit',
      loading: !values.facility,
      loadingText: 'Select a facility to populate',
      options: values.facility ? facilityUnits.list.filter(unit => unit.Facility === values.facility) : [],
      optionKey: 'Name',
    },
    facilityField: {
      name: 'facility',
      label: 'Facility',
      onChange: ({form}) => {
        form.setFieldValue('facilityUnit', '');
      },
    },
  }), [facilityUnits, materials.yieldList, types, values.facility, values.material]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.materialAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.typeAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.flagAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='accountingId' label='Accounting Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField {...core.facilityField}>
          <MenuItem value='Brea-1125'>Brea-1125</MenuItem>
          <MenuItem value='Anaheim-1314'>Anaheim-1314</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.facilityUnitAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='startDate' label='Start Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='endDate' label='End Date' disabled={!values.startDate} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='formId' label='Form Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField name='shift' label='Shift'>
          <MenuItem value='First'>First</MenuItem>
          <MenuItem value='Second'>Second</MenuItem>
          <MenuItem value='Third'>Third</MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};

ProcessForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ProcessForm;

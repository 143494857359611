import React from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
const {SelectField, DateField} = Formik;

const MaterialsAndTypesForm = ({values}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <SelectField name='activeOnly' label='Active Only?' required>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='addedSinceDate' label='Added Since Date' />
      </Grid>
    </Grid>
  );
};

MaterialsAndTypesForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default MaterialsAndTypesForm;

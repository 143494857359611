import React, {Fragment, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import {ExpandMore, Edit} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {UpdateItemMissingDataModal} from './ManifestedItemsMissingDataPanel/';

const ManifestedItemsMissingDataPanel = ({selectedInbound, listOfShippingDocuments, listOfItemsMissingData}) => {
  const [selectedItemMissingData, setSelectedItemMissingData] = useState(null);

  const dataForTable = useMemo(() => {
    return listOfItemsMissingData.map(item => ({...item, ShippingDocNumber: listOfShippingDocuments.find(shipDoc => shipDoc.id === item.ShippingDocRef).DocumentNumber}));
  }, [listOfItemsMissingData, listOfShippingDocuments]);

  if (selectedInbound.InProgress || !listOfShippingDocuments.find(doc => doc.DocumentType === 'HW Manifest')) return null;

  const core = {
    itemMissingDataTable: {
      columns: [
        {accessor: 'ShippingDocNumber', Header: 'Shipping Doc No.'},
        {accessor: 'IncomingPieceNo', Header: 'Piece No.'},
        {accessor: 'Material.Name', Header: 'Material'},
        {accessor: 'Type.Name', Header: 'Type'},
      ],
      data: dataForTable,
      actionsPerRow: [
        {
          icon: Edit,
          tooltip: 'Edit Holes',
          onClick: ({rowData}) => setSelectedItemMissingData(rowData),
        },
      ],
    },
    itemMissingDataModal: {
      close: () => setSelectedItemMissingData(null),
      selectedInbound: selectedInbound,
      selectedItemMissingData,
    },
  };

  return (
    <Fragment>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Manifested Items Missing Data</Typography>
        </AccordionSummary>
        <Table {...core.itemMissingDataTable} />
      </Accordion>
      {selectedItemMissingData && <UpdateItemMissingDataModal {...core.itemMissingDataModal} />}
    </Fragment>
  );
};

ManifestedItemsMissingDataPanel.propTypes = {
  selectedInbound: PropTypes.object.isRequired,
  listOfShippingDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  listOfItemsMissingData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ManifestedItemsMissingDataPanel;

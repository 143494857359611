import {useState} from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetShipments} from 'state/firestore/actions.js';

const useShipments = () => {
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();
  const shipments = useSelector(state => state.firestore.shipments);

  useEffect(() => {
    if (!shipments && !touched) {
      setTouched(true);
      setTimeout(() => dispatch(acGetShipments), 5000 * Math.random());
    }
  }, [dispatch, shipments, touched]);

  return shipments;
};

export default useShipments;

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
  DialogContentText,
  Grid,
  MenuItem,
} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import {object, string, date} from 'yup';
import {connect} from 'react-redux';
import {Alert, Formik} from '@kbi/component-library';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import moment from 'moment';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
const {AutoCompleteObject, SelectField, DateField, TextField, FormikForm, SubmitButton, FormButton, validateAutoObject} = Formik;
validateAutoObject();

class OutboundModal extends Component {
  state = {
    formError: '',
    stage: 0,
    newCreatedOutboundId: null,
    deleteConfirmation: false,
    isDeleting: false,
    defaultFormik: {
      date: moment().format('YYYY-MM-DD[T]HH:mm'),
      facility: '',
      account: '',
      location: '',
      transporter: '',
      transporterEpaId: '',
      shippingPaperType: '',
      shippingPaperNumber: '',
      weightTicket: '',
      sealNumber: '',
      containerNumber: '',
      bookingNumber: '',
      customerPONumber: '',
      kbiPONumber: '',
      comments: '',
    },
  };
  stageArray = ['basic', 'success']; // is here for the SubmitConfirmation modal, as well as in case the modal needs to grow and have more collapses
  // the object from redux that holds all accounts, converted into an array of just their values
  arrayOfAccounts = Object.values(this.props.accounts).sort((a, b) => (a.AccountName > b.AccountName ? 1 : -1));
  validationSchema = object().shape({
    date: date().required('Date Shipped is a required field.'),
    facility: string().required('Facility is a required field.'),
    account: object().exists('Downstream is a required field.'),
    location: object().exists('Location is a required field.'),
    transporter: object().exists('Transporter is a required field.'),
    transporterEpaId: string().required('Transporter EPA Id is a required field.'),
    shippingPaperType: string().required('Shipping Paper Type is a required field.'),
    weightTicket: string(),
    shippingPaperNumber: string(),
    sealNumber: string(),
    containerNumber: string(),
    bookingNumber: string(),
    customerPONumber: string(),
    kbiPONumber: string(),
    comments: string(),
  });

  componentDidMount() {
    // conditional is true if the user clicked on a shipment event in the calendar, otherwise selectedShipment is null
    if (this.props.selectedShipment) {
      const {selectedShipment} = this.props;
      this.setState({
        defaultFormik: {
          ...this.state.defaultFormik,
          comments: this.props.selectedShipment.Notes,
          facility: selectedShipment.Facility,
          account: this.props.accounts[selectedShipment.AccountId],
        },
      });
    }
    if (this.props.selectedOutbound) {
      const {selectedOutbound} = this.props;
      this.setState({
        defaultFormik: {
          date: moment(selectedOutbound.ShipmentTime).format('YYYY-MM-DD[T]HH:mm'),
          facility: selectedOutbound.Facility,
          account: this.props.accounts[selectedOutbound.AccountId],
          location: this.props.locations?.[selectedOutbound.AccountId]?.find(location => location.id === selectedOutbound.LocationRef) || '',
          transporter: this.props.accounts[selectedOutbound.Transporter],
          shippingPaperType: selectedOutbound.DocumentType,
          shippingPaperNumber: selectedOutbound.DocumentNumber,
          weightTicket: selectedOutbound.WeightTicket || '',
          transporterEpaId: selectedOutbound.TransporterEpaId || '',
          sealNumber: selectedOutbound.SealNumber,
          containerNumber: selectedOutbound.ContainerNumber,
          bookingNumber: selectedOutbound.BookingNumber,
          customerPONumber: selectedOutbound.customerPONumber || '',
          kbiPONumber: this.props.purchaseOrders?.[selectedOutbound.AccountId]?.find(po => po.OrderNumber === selectedOutbound.KbiPONumber) || '',
          comments: selectedOutbound.Comments,
        },
      });
    }
  }
  createDataForFirestore(values) {
    // returns an object that is in the format Firestore is expecting
    const firestoreObj = {
      Facility: values.facility,
      AccountId: values.account.AccountId,
      ShipmentTime: firebase.firestore.Timestamp.fromDate(new Date(values.date)),
      Transporter: values.transporter.AccountId,
      TransporterEpaId: values.transporterEpaId,
      LocationRef: values.location.id,
      DocumentType: values.shippingPaperType,
      DocumentNumber: values.shippingPaperNumber,
      SealNumber: values.sealNumber,
      ContainerNumber: values.containerNumber,
      BookingNumber: values.bookingNumber,
      PurchaseOrderNumber: values.customerPONumber,
      KbiPONumber: values.kbiPONumber?.OrderNumber || null,
      WeightTicket: values.weightTicket,
      ReturnedShippingDocumentReceived: false,
      BillingRef: null,
      InProgress: true,
      Comments: values.comments,
      Direction: 'Outbound',
    };
    firestoreObj.ReturnedShippingDocumentRequired = firestoreObj.DocumentType === 'HW Manifest' ||
      this.props.locations[firestoreObj.AccountId].find(location => location.id === firestoreObj.LocationRef).Address.Country !== 'United States' ?
      true : false;
    if (this.props.selectedShipment) {
      firestoreObj.ScheduledTime = firebase.firestore.Timestamp.fromDate(
        new Date(this.props.selectedShipment.StartDateTime),
      );
      firestoreObj.ShipmentId = this.props.selectedShipment.id;
    }
    else {
      firestoreObj.ShipmentId = null;
      firestoreObj.ScheduledTime = null;
    }
    return firestoreObj;
  }
  handleSubmit = (values, actions) => {
    this.setState({deleteConfirmation: false});
    const firestoreData = this.createDataForFirestore(values);
    const batch = Firestore.batch();
    let docToWriteRef;
    if (this.props.selectedOutbound) {
      docToWriteRef = Firestore.collection('Tracking-Shipments').doc(this.props.selectedOutbound.id);
    }
    else {
      docToWriteRef = Firestore.collection('Tracking-Shipments').doc();
    }
    if (this.props.selectedOutbound) {
      batch.update(docToWriteRef, {
        ...firestoreData,
        'System.UpdatedOn': firebase.firestore.Timestamp.now(),
        'System.UpdatedBy': this.props.currentUser.displayName,
      });
    }
    else {
      batch.set(docToWriteRef, {
        ...firestoreData, System: {
          CreatedBy: this.props.currentUser.displayName,
          CreatedOn: firebase.firestore.Timestamp.now(),
        },
      });
    }
    if (this.props.selectedShipment) {
      batch.update(
        Firestore.collection('CRM-Accounts')
          .doc(this.props.selectedShipment.AccountId)
          .collection('Shipments')
          .doc(this.props.selectedShipment.id),
        {ShipmentId: docToWriteRef.id, Active: false},
      );
    }
    batch
      .commit()
      .then(() => {
        actions.setSubmitting(false);
        this.setState({stage: this.state.stage + 1, newCreatedOutboundId: docToWriteRef.id});
      })
      .catch(error => {
        actions.setSubmitting(false);
        this.setState({formError: 'Error uploading. Please try again.'});
      });
  };
  handleDelete = (values) => {
    if (!this.state.deleteConfirmation) {
      this.setState({deleteConfirmation: true});
    }
    else {
      this.setState({isDeleting: true, stage: this.state.stage + 1}, () => {
        setTimeout(() => {
          const batch = Firestore.batch();
          batch.delete(Firestore.collection('Tracking-Shipments').doc(this.props.selectedOutbound.id));
          if (this.props.selectedOutbound.ShipmentId) {
            batch.update(Firestore.collection('CRM-Accounts')
              .doc(values.account.AccountId)
              .collection('Shipments')
              .doc(this.props.selectedOutbound.ShipmentId), {
              ShipmentId: null,
              Active: true,
            });
          }
          batch.commit().then(() => {
            this.props.history.push('/shipments');
          }).catch(error => {
            this.setState({
              stage: this.state.stage - 1,
              isDeleting: false,
              formError: 'There was an error during deletion. Please try again.',
            });
          });
        }, 2000);
      });
    }
  };
  determineButtons(formikProps) {
    const buttonProps = {
      closeButton: {
        disabled: this.state.isDeleting,
        onClick: () => {
          this.props.close(this.state.newCreatedOutboundId);
        },
        color: 'primary',
        variant: 'text',
      },
      submitButton: {
        color: 'primary',
        variant: 'text',
        disabled: this.state.isDeleting,
      },
      cancelButton: {
        onClick: () => this.props.close(),
        color: 'secondary',
        variant: 'text',
        disabled: this.state.isDeleting,
      },
      deleteButton: {
        loading: this.state.isDeleting,
        variant: 'text',
        color: 'secondary',
        disabled: this.state.isDeleting || !!this.props.selectedOutbound?.UploadDate || !!this.props.listOfLines?.length,
        onClick: () => this.handleDelete(formikProps.values),
      },
    };

    if (this.stageArray[this.state.stage] === 'success') {
      return (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...buttonProps.closeButton}>Close</Button>
        </DialogActions>
      );
    }
    else if (this.props.selectedOutbound) {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormButton {...buttonProps.deleteButton}>
            {this.state.deleteConfirmation ? 'Confirm?' : 'Delete'}
          </FormButton>
          <div>
            <FormButton {...buttonProps.cancelButton}>Cancel</FormButton>
            <SubmitButton {...buttonProps.submitButton}>
              Submit
            </SubmitButton>
          </div>
        </DialogActions>
      );
    }
    else {
      return (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormButton {...buttonProps.cancelButton}>Cancel</FormButton>
          <SubmitButton {...buttonProps.submitButton}>Submit</SubmitButton>
        </DialogActions>
      );
    }
  }
  render() {
    const {stage} = this.state;
    const core = {
      dialog: {
        open: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
        maxWidth: 'md',
        fullWidth: true,
      },
      submitConfirmation: {
        text: this.state.deleteConfirmation ? 'Outbound deleted. You will be redirected shortly.' :
          (this.props.selectedOutbound ? 'Outbound load successfully edited.' : 'Outbound load successfully created.'),
        stage: this.stageArray[stage],
        delete: this.state.deleteConfirmation,
      },
      accountSelectField: formikProps => ({
        disabled: formikProps.isSubmitting || formikProps.isValidating || this.props.selectedShipment ? true : false,
        name: 'account',
        label: 'Downstream',
        options: this.arrayOfAccounts.filter(account => account.AccountType.Downstream),
        optionKey: 'AccountName',
        required: true,
        onChange: ({form, field}) => {
          form.setFieldValue('location', '');
        },
      }),
      transporterSelectField: {
        options: this.arrayOfAccounts.filter(account => account.AccountType.Transporter),
        optionKey: 'AccountName',
        name: 'transporter',
        label: 'Transporter',
        required: true,
        onChange: ({form}) => {
          form.setFieldValue('transporterEpaId', '');
        },
      },
      locationSelect: formikProps => ({
        name: 'location',
        label: 'Location',
        required: true,
        loading: !formikProps.values.account,
        loadingText: 'Select a downstream to populate',
        options: this.props.locations[formikProps.values?.account?.AccountId] || [],
        optionKey: 'LocationName',
      }),
      toUpperOnBlur: fieldName => ({
        onBlur: ({form}) => {
          form.setFieldValue(fieldName, form.values[fieldName].toUpperCase());
        },
      }),
      poSelect: formikProps => ({
        name: 'kbiPONumber',
        label: 'KBI PO No.',
        loading: !formikProps.values.account,
        loadingText: 'Please select a downstream to populate',
        options: this.props.purchaseOrders?.[formikProps.values.account?.AccountId]?.filter(po => po.POType === 'Sale' && po.Active) || [],
        optionKey: 'OrderNumber',
      }),
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm
          initialValues={this.state.defaultFormik}
          validationSchema={this.validationSchema}
          onSubmit={this.handleSubmit}
        >
          {formikProps => (
            <Fragment>
              {this.stageArray[stage] !== 'success' && (
                <DialogTitle>{this.props.selectedOutbound ? 'Edit Outbound Load' : 'Create New Outbound Load'}</DialogTitle>
              )}
              <DialogContent >
                <Collapse in={this.stageArray[stage] === 'basic'}>
                  <DialogContentText>Enter outbound information:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                      <DateField name='date' fast label='Date Shipped'
                        required type='datetime-local'
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <SelectField name='facility' label='Facility'
                        required fast
                      >
                        {facilitiesConst
                          .map(docType => {
                            return (
                              <MenuItem key={docType} value={docType}>
                                {docType}
                              </MenuItem>
                            );
                          })}
                      </SelectField>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <AutoCompleteObject {...core.accountSelectField(formikProps)} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <AutoCompleteObject {...core.locationSelect(formikProps)} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <AutoCompleteObject {...core.transporterSelectField} />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <SelectField name='transporterEpaId' label='Transporter EPA Id' required
                        disabled={!formikProps.values.transporter}
                        onBlur={({field}) => {
                          console.log(field);
                        }}
                      >
                        {this.props.locations[formikProps.values.transporter?.AccountId] ?
                          [...new Set(this.props.locations[formikProps.values.transporter?.AccountId]
                            .map(location => location.EpaId),
                          )]
                            .map((location, index) => {
                              return <MenuItem key={index} value={location}>{location}</MenuItem>;
                            }) : []
                        }
                      </SelectField>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <SelectField name='shippingPaperType' label='Shipping Paper Type'
                        required fast
                      >
                        {['Bill of Lading', 'HW Manifest', 'Non-HW Manifest']
                          .map(docType => {
                            return (
                              <MenuItem key={docType} value={docType}>
                                {docType}
                              </MenuItem>
                            );
                          })}
                      </SelectField>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='shippingPaperNumber' fast label='Shipping Paper No.'
                        {...core.toUpperOnBlur('shippingPaperNumber')}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='weightTicket' fast label='Weight Ticket No.' />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='sealNumber' fast label='Seal No.' />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='containerNumber' fast label='Container No.' />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='bookingNumber' fast label='Booking No.' />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField name='customerPONumber' fast label='Customer PO No.' />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <AutoCompleteObject {...core.poSelect(formikProps)} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='comments' fast label='Comments'
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {this.determineButtons(formikProps)}
            </Fragment>
          )}
        </FormikForm>
      </Dialog >
    );
  }
}

const mapStateToProps = state => {
  return {
    accounts: state.firestore.accounts,
    currentUser: state.auth.currentUser,
    locations: state.firestore.locations,
    purchaseOrders: state.firestore.purchaseOrders,
  };
};

OutboundModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedShipment: PropTypes.object,
  accounts: PropTypes.objectOf(PropTypes.object).isRequired,
  locations: PropTypes.shape({
    listener: PropTypes.func.isRequired,
    // any number of account ids as keys
  }),
  history: PropTypes.object.isRequired,
  purchaseOrders: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedOutbound: PropTypes.object,
  listOfLines: PropTypes.array,
};

export default flowright(withRouter, connect(mapStateToProps))(OutboundModal);

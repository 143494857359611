import React from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper} from 'components/';

const LineContainerPanel = ({selectedLine}) => {
  const core = {
    selectedLineContainerTable: {
      columns: [
        {accessor: 'ShortNo', Header: 'Short No.'},
        {accessor: 'GrossWeight', Header: 'Gross Weight', type: 'numeric'},
        {accessor: 'TareWeight', Header: 'Tare Weight', type: 'numeric'},
        {accessor: 'NetWeight', Header: 'Net Weight', type: 'numeric'},
      ],
      sortInitial: [{id: 'ShortNo', desc: true}],
      data: selectedLine.AddedContainers,
      title: {primary: 'Line Containers', secondary: `A list of all containers within line ${selectedLine.LineNumber}`},
    },
  };
  return (
    <TableWithPaper {...core.selectedLineContainerTable} />
  );
};

LineContainerPanel.propTypes = {
  selectedLine: PropTypes.object.isRequired,
};

export default LineContainerPanel;

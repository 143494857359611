/* eslint-disable no-var */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  Typography,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import {SubmitButton, SubmitConfirmation} from '.';
import {Alert} from '@kbi/component-library';
import {connect} from 'react-redux';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {CloudUpload} from '@material-ui/icons';

const cleanState = {
  fileName: {
    display: 'File Name',
    error: null,
    value: '',
  },
  file: null,
  submitting: false,
  stage: 'basic',
  formError: null,
  firestoreRef: null,
  uploadProgress: null,
  fileExt: null,
};

class AddFileModal extends Component {
  state = {...cleanState};

  componentDidMount() {
    if (this.props.selectedInboundContainer) {
      this.setState({fileName: {...this.state.fileName, display: 'Description'}});
    }
  }
  handleClose = () => {
    this.setState({...cleanState});
    this.props.close();
  };
  handleBack = () => {
    const {stage} = this.state;
    if (stage === 'fileDetails') {
      this.setState({stage: 'basic'});
    }
  };
  uploadFile = () => {
    const {file, firestoreRef} = this.state;
    const storageRef = firebase.storage().ref();
    if (this.props.selectedProfile) {
      var fileRef = storageRef.child(`Material_Profiles/${this.props.selectedProfile.id}.pdf`);
    }
    else if (this.props.selectedPurchaseOrder) {
      fileRef = storageRef.child(`Purchase_Orders/${this.props.selectedPurchaseOrder.id}/${firestoreRef.id}.${this.state.fileExt}`);
    }
    else if (this.props.supplementalFile) {
      if (this.props.selectedInbound) {
        fileRef = storageRef.child(`Inbound_Forms/${this.props.selectedInbound.id}/${this.state.fileName.value}.${this.state.fileExt}`);
      }
      else if (this.props.selectedOutbound) {
        fileRef = storageRef.child(`Outbound_Forms/${this.props.selectedOutbound.id}/${this.state.fileName.value}.${this.state.fileExt}`);
      }
    }
    else if (this.props.selectedInbound) {
      fileRef = storageRef.child(`Inbound_Forms/${this.props.selectedInbound.id}_primary_file.pdf`);
    }
    else if (this.props.selectedOutbound) {
      fileRef = storageRef.child(`Outbound_Forms/${this.props.selectedOutbound.id}_primary_file.pdf`);
    }
    else if (this.props.selectedInboundContainer && this.props.selectedSortForm) {
      fileRef = storageRef.child(`Inbound_Containers/${this.props.selectedInboundContainer.id}/${firestoreRef.id}.${this.state.fileExt}`);
    }
    else if (this.props.wastewater) {
      fileRef = storageRef.child(`Wastewater_Files/${firestoreRef.id}.${this.state.fileExt}`);
    }
    else if (this.props.selectedStormwaterDoc) {
      fileRef = storageRef.child(`Stormwater_Files/${this.props.selectedStormwaterDoc.stormwaterId}/${firestoreRef.id}.${this.state.fileExt}`);
    }
    else if (this.props.stormwater) {
      fileRef = storageRef.child(`Stormwater_Files/${firestoreRef.id}.${this.state.fileExt}`);
    }
    else {
      fileRef = storageRef.child(`Account_Files/${firestoreRef.id}.${this.state.fileExt}`);
    }
    const uploadTask = fileRef.put(file[0]);
    uploadTask.on(
      'state_changed',
      snapshot => {
        const uploadProgress = parseFloat(((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(1));
        this.setState({uploadProgress});
      },
      error => { },
      () => {
        this.setState({submitting: false, stage: 'success'});
        if (this.props.successfulUpload) {
          this.props.successfulUpload();
        }
      },
    );
  };
  handleSubmit = () => {
    const {currentUser, selectedProfile, selectedInbound, selectedOutbound, supplementalFile} = this.props;
    const {fileName, firestoreRef, fileExt} = this.state;
    const dataError = this.formVerification();
    if (!dataError) {
      // this creates data for firestore based on if you are in a material profile, the document panel, or a purchase order
      if (selectedProfile || (selectedInbound && !supplementalFile) || (selectedOutbound && !supplementalFile)) {
        var firestoreData = {
          UploadedBy: {Name: currentUser.displayName, Uid: currentUser.uid},
          UploadDate: firebase.firestore.Timestamp.now(),
        };
      }
      else if (this.props.selectedInboundContainer) {
        firestoreData = {
          Owner: {
            Name: currentUser.displayName,
            Uid: currentUser.uid,
          },
          Date: firebase.firestore.Timestamp.now(),
          Description: `${fileName.value.trim()}.${fileExt}`,
        };
      }
      else if (this.props.wastewater) {
        firestoreData = {
          Owner: {
            Name: currentUser.displayName,
            Uid: currentUser.uid,
          },
          Date: firebase.firestore.Timestamp.now(),
          FileName: `${fileName.value.trim()}.${fileExt}`,
          FileExt: fileExt,
          EHSType: 'Wastewater',
        };
      }
      else if (this.props.stormwater) {
        firestoreData = {
          Owner: {
            Name: currentUser.displayName,
            Uid: currentUser.uid,
          },
          Date: firebase.firestore.Timestamp.now(),
          FileName: `${fileName.value.trim()}.${fileExt}`,
          FileExt: fileExt,
          EHSType: 'Stormwater',
        };
      }
      else {
        firestoreData = {
          Owner: {
            Name: currentUser.displayName,
            Uid: currentUser.uid,
          },
          Date: firebase.firestore.Timestamp.now(),
          FileName: `${fileName.value.trim()}.${fileExt}`,
          FileExt: fileExt,
        };
      }
      this.setState({submitting: true, stage: 'upload'}, () => {
        firestoreRef
          .set(firestoreData, {merge: true})
          .then(() => {
            this.uploadFile();
          })
          .catch(error => {
            this.setState({
              formError: 'Error in uploading file.',
              submitting: false,
            });
            if (selectedProfile || selectedInbound || selectedOutbound) {
              firestoreRef.update({UploadedBy: '', UploadDate: ''});
            }
            else {
              firestoreRef.delete();
            }
          });
      });
    }
  };
  formVerification = () => {
    const {fileName} = this.state;
    let dataError = false;
    if (!fileName.value && !this.props.fileNameNotNeeded) {
      dataError = true;
      this.setState({fileName: {...fileName, error: 'File name is a required field.'}});
    }
    return dataError;
  };
  determineFirestoreRef() {
    const {selectedProfile, selectedPurchaseOrder, currentAccount, selectedInbound, supplementalFile, selectedOutbound} = this.props;
    if (selectedProfile) {
      return Firestore.collection('CRM-Accounts')
        .doc(currentAccount.AccountId)
        .collection('Material-Profiles')
        .doc(selectedProfile.id);
    }
    else if (selectedPurchaseOrder) {
      return Firestore.collection('CRM-Accounts')
        .doc(currentAccount.AccountId)
        .collection('Purchase-Orders')
        .doc(selectedPurchaseOrder.id)
        .collection('Files')
        .doc();
    }
    else if (supplementalFile) {
      return Firestore.collection('Tracking-Shipments')
        .doc(selectedOutbound ? selectedOutbound.id : selectedInbound.id)
        .collection('Supplemental-Files')
        .doc();
    }
    else if (selectedOutbound) {
      return Firestore.collection('Tracking-Shipments').doc(selectedOutbound.id);
    }
    else if (selectedInbound) {
      return Firestore.collection('Tracking-Shipments').doc(selectedInbound.id);
    }
    else if (this.props.selectedInboundContainer && this.props.selectedSortForm) {
      return Firestore.collection('Tracking-Forms')
        .doc(this.props.selectedSortForm.id)
        .collection('Inbound-Containers')
        .doc(this.props.selectedInboundContainer.id)
        .collection('Files')
        .doc();
    }
    else if (this.props.selectedStormwaterDoc) {
      return Firestore.collection('EHS-Stormwater-Inspections')
        .doc(this.props.selectedStormwaterDoc.stormwaterId)
        .collection('Files')
        .doc();
    }
    else if (this.props.wastewater || this.props.stormwater) {
      return Firestore.collection('EHS-Documents').doc();
    }
    else {
      return Firestore.collection('CRM-Accounts')
        .doc(currentAccount.AccountId)
        .collection('Files')
        .doc();
    }
  }

  createDetailsCollapse(core) {
    if (!this.props.fileNameNotNeeded) {
      return (
        <Fragment>
          <DialogContentText>Enter file details.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField {...core.textField('fileName')} required />
            </Grid>
          </Grid>
          <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
        </Fragment>
      );
    }
    else {
      return (
        <Fragment>
          <DialogContentText>File received. Click submit to proceed.</DialogContentText>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CloudUpload style={{fontSize: '8em'}} />
          </div>
          <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
        </Fragment>
      );
    }
  }
  render() {
    const {addFileModalOpen} = this.props;
    const {stage, submitting, uploadProgress} = this.state;
    const core = {
      dialog: {
        open: addFileModalOpen,
        scroll: 'body',
        transitionDuration: {exit: 0},
        fullWidth: true,
      },
      submitButton: {
        onClick: this.handleSubmit,
        color: 'primary',
        text: 'Submit',
        loading: submitting,
        disabled: stage !== 'fileDetails' ? true : false,
      },
      submitConfirmation: {
        text: 'File successfully added.',
        stage,
        uploadProgress,
      },
      dropzone: {
        accept: this.props.mimeType,
        maxSize: this.props.selectedInbound || this.props.selectedOutbound ? 26214400 : 10485760,
        multiple: false,
        onDropAccepted: file => {
          const [fileName, fileExt] = file[0].name.split('.');
          this.setState({
            formError: '',
            file,
            stage: 'fileDetails',
            firestoreRef: this.determineFirestoreRef(),
            fileName: {...this.state.fileName, value: fileName},
            fileExt,
          });
        },
        onDropRejected: (file, event) => {
          if (file[0].size > (this.props.selectedInbound || this.props.selectedOutbound ? 26214400 : 5242880)) {
            this.setState({formError: 'The file you selected was too large.'});
          }
          else {
            this.setState({formError: this.props.errorMessage || 'The file type you selected in not allowed.'});
          }
        },
      },
      textField: key => {
        return {
          error: this.state[key].error ? true : false,
          fullWidth: true,
          helperText: this.state[key].error,
          id: key,
          label: this.state[key].display,
          margin: 'normal',
          value: this.state[key].value,
          onChange: e =>
            this.setState({[key]: {error: null, display: this.state[key].display, value: e.target.value}}),
        };
      },
      fileDrop: {
        style: {
          alignItems: 'center',
          backgroundColor: 'aliceblue',
          border: '2px gray dotted',
          display: 'flex',
          height: '200px',
          justifyContent: 'center',
        },
      },
      divStyles: {
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        },
      },
      backButton: {
        onClick: () => this.handleBack(),
        color: 'primary',
        disabled: stage === 'basic',
      },
      cancelButton: {
        onClick: this.handleClose,
        color: 'secondary',
        disabled: submitting,
      },
      closeButton: {
        onClick: this.handleClose,
        color: 'primary',
        disabled: stage === 'upload',
      },
    };
    return (
      <Dialog {...core.dialog}>
        {this.state.stage !== 'success' && <DialogTitle>{this.props.selectedInboundContainer ? 'New Image' : 'New File'}</DialogTitle>}
        <DialogContent>
          <Collapse in={stage === 'basic'}>
            <Fragment>
              <Dropzone {...core.dropzone}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} {...core.fileDrop}>
                    <input {...getInputProps()} />
                    <div {...core.divStyles}>
                      <Typography variant='subtitle1'>
                        Drop file here or click inside the box to select a file.
                      </Typography>
                      <Typography variant='body2'>
                        ({`File must under ${this.props.selectedInbound || this.props.selectedOutbound ? '25mb' : '5mb'}`})
                      </Typography>
                    </div>
                  </div>
                )}
              </Dropzone>
              <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
            </Fragment>
          </Collapse>
          <Collapse in={stage === 'fileDetails'}>{this.createDetailsCollapse(core)}</Collapse>
          <SubmitConfirmation {...core.submitConfirmation} />
        </DialogContent>
        {stage === 'success' || stage === 'upload' ? (
          <DialogActions style={{justifyContent: 'flex-end'}}>
            <Button {...core.closeButton}>Close</Button>
          </DialogActions>
        ) : (
          <DialogActions style={{justifyContent: 'space-between'}}>
            <Button {...core.cancelButton}>Cancel</Button>
            <div>
              <Button {...core.backButton}>Back</Button>
              <SubmitButton {...core.submitButton} />
            </div>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const currentUser = state.auth.currentUser;
  return {currentUser};
};

AddFileModal.defaultProps = {
  // eslint-disable-next-line max-len
  mimeType: 'application/pdf, image/*, text/*, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
AddFileModal.propTypes = {
  close: PropTypes.func.isRequired,
  addFileModalOpen: PropTypes.bool.isRequired,
  currentAccount: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  selectedProfile: PropTypes.object,
  selectedPurchaseOrder: PropTypes.object,
  selectedInbound: PropTypes.object,
  selectedOutbound: PropTypes.object,
  successfulUpload: PropTypes.func,
  fileNameNotNeeded: PropTypes.bool,
  supplementalFile: PropTypes.bool,
  selectedInboundContainer: PropTypes.object,
  selectedSortForm: PropTypes.object,
  mimeType: PropTypes.string,
  wastewater: PropTypes.bool,
  stormwater: PropTypes.bool,
  selectedStormwaterDoc: PropTypes.string,
  errorMessage: PropTypes.string,
};
export default connect(mapStateToProps)(AddFileModal);

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Table, TableCell, TableHead, TableRow, TableBody, withStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import moment from 'moment';

const PrintableIncoming = props => {
  const locations = useSelector(state => state.firestore.locations);
  const accounts = useSelector(state => state.firestore.accounts);

  const createShippingDocumentInfo = (shippingDoc) => {
    return (
      <Grid container spacing={1} style={{border: '2px solid lightgrey', margin: '16px 0px'}}>
        <Grid item xs={6}>
          <Typography>
            {shippingDoc.DocumentType} - {shippingDoc.DocumentNumber}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Generator - {locations[props.selectedInbound.AccountId].find(location => location.id === shippingDoc.GeneratorRef).LocationName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Weight Ticket(s) - {shippingDoc.WeightTickets.map(ticket => ticket.TicketNumber).join(', ')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Purchase Order - {shippingDoc.PONumber.Number}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const createLineItemsInfo = (arrayOfLines) => {
    return (
      <Fragment>
        <Table size='small' className={props.classes.tableFont} padding='none'>
          <TableHead>
            <TableRow>
              <TableCell>Line</TableCell>
              <TableCell>Piece</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Flag</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Short No.</TableCell>
              <TableCell>Gross</TableCell>
              <TableCell>Tare</TableCell>
              <TableCell>Net</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrayOfLines.map(line => line.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.Line}</TableCell>
                <TableCell>{item.PieceNumber}</TableCell>
                <TableCell>{item.Status === 'Rejected' ? 'Rejected' : item.AcceptedMaterial?.Name || 'WAP Incomplete'}</TableCell>
                <TableCell>{item.AcceptedType?.Name}</TableCell>
                <TableCell>{item.Flag}</TableCell>
                <TableCell>{item.ContainerCode + (item.ContainerCodeType ? ` - ${item.ContainerCodeType}` : '')}</TableCell>
                <TableCell>{item.ContainerRef}</TableCell>
                <TableCell>{item.Status === 'Rejected' ? 0 : item.GrossWeight}</TableCell>
                <TableCell>{item.Status === 'Rejected' ? 0 : item.TareWeight}</TableCell>
                <TableCell>{item.Status === 'Rejected' ? 0 : item.NetWeight}</TableCell>
                <TableCell>{item.Status}</TableCell>
              </TableRow>
            )),
            )}
            <TableRow>
              <TableCell colSpan={6} />
              <TableCell colSpan={1}>Totals:</TableCell>
              <TableCell>Gross</TableCell>
              <TableCell>Tare</TableCell>
              <TableCell>Net</TableCell>
              <TableCell colSpan={1} />
            </TableRow>
            <TableRow>
              <TableCell colSpan={6} />
              <TableCell colSpan={1} />
              <TableCell align='center'>
                {arrayOfLines.map(line => line.reduce((total, item) => total + (item.Status === 'Rejected' ? 0 : parseInt(item.GrossWeight)), 0))
                  .reduce((total, lineTotal) => total + lineTotal, 0)}
              </TableCell>
              <TableCell align='center'>
                {arrayOfLines.map(line => line.reduce((total, item) => total + (item.Status === 'Rejected' ? 0 : parseInt(item.TareWeight)), 0))
                  .reduce((total, lineTotal) => total + lineTotal, 0)}
              </TableCell>
              <TableCell align='center'>
                {arrayOfLines.map(line => line.reduce((total, item) => total + (item.Status === 'Rejected' ? 0 : parseInt(item.NetWeight)), 0))
                  .reduce((total, lineTotal) => total + lineTotal, 0)}
              </TableCell>
              <TableCell colSpan={1} />
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    );
  };
  const createLinesSection = (arrayOfLines) => {
    return (
      <Table size='small' className={props.classes.tableFont} padding='none'>
        <TableHead>
          <TableRow>
            <TableCell>Line</TableCell>
            <TableCell>Material</TableCell>
            <TableCell>Pieces</TableCell>
            <TableCell>Gross</TableCell>
            <TableCell>Tare</TableCell>
            <TableCell>Net</TableCell>
            <TableCell>RCRA Codes</TableCell>
            <TableCell>CA Codes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arrayOfLines.map((line, index) => (
            <TableRow key={index}>
              <TableCell>{line[0].Line}</TableCell>
              <TableCell>{line[0].Shipped.Name}</TableCell>
              <TableCell>{line.length}</TableCell>
              <TableCell>{line.reduce((prev, curr) => prev + (curr.Status === 'Rejected' ? 0 : curr.GrossWeight), 0)}</TableCell>
              <TableCell>{line.reduce((prev, curr) => prev + (curr.Status === 'Rejected' ? 0 : curr.TareWeight), 0)}</TableCell>
              <TableCell>{line.reduce((prev, curr) => prev + (curr.Status === 'Rejected' ? 0 : curr.NetWeight), 0)}</TableCell>
              <TableCell>{line[0].RCRACodes.join(', ')}</TableCell>
              <TableCell>{line[0].CACodes.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className='firstPage printOnlyWrapper' style={{minWidth: '612px', marginLeft: '25px', marginRight: '25px'}}>
      <Grid container spacing={1} style={{border: '2px solid lightgrey', margin: '16px 0px'}}>
        <Grid item xs={12}>
          <Typography variant='h5' style={{textAlign: 'center'}}>Inbound Load</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Date Received - {moment(props.selectedInbound.ShipmentTime).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Facility - {props.selectedInbound.Facility}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Account - {accounts[props.selectedInbound.AccountId].AccountName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Transporter - {accounts[props.selectedInbound.Transporter].AccountName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Comments - {props.selectedInbound.Comments}
          </Typography>
        </Grid>
      </Grid>
      {props.listOfShippingDocuments.map((shippingDoc, index) => {
        const mapOfFilteredLineItems = {};
        shippingDoc.LineItems.forEach(item => {
          if (mapOfFilteredLineItems[item.Line]) {
            mapOfFilteredLineItems[item.Line].push(item);
          }
          else {
            mapOfFilteredLineItems[item.Line] = [item];
          }
        });
        return (
          <div key={index} {...index !== 0 ? {style: {pageBreakBefore: 'always', marginTop: '30px'}} : null}>
            {createShippingDocumentInfo(shippingDoc)}
            <Grid container spacing={2} style={{margin: '0px'}}>
              <Typography className={props.classes.bold}>Lines</Typography>
              {createLinesSection(Object.values(mapOfFilteredLineItems))}
              <Typography className={props.classes.bold}>Pieces</Typography>
              {createLineItemsInfo(Object.values(mapOfFilteredLineItems))}
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

const styles = theme => ({
  tableFont: {
    '& td': {
      fontSize: '10pt',
    },
    '& th': {
      fontSize: '10pt',
    },
  },
  bold: {
    fontWeight: 600,
  },
});

PrintableIncoming.propTypes = {
  listOfShippingDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  listOfWeightTickets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedInbound: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrintableIncoming);

import React, {useState, useEffect} from 'react';
import {Button, Typography, Grid} from '@material-ui/core';
import {CloudUpload} from '@material-ui/icons';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {useSelector} from 'react-redux';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import {AsyncLoader} from 'components/';

const mimeType = 'image/*';

const NewFileItem = props => {
  const [showDropzone, setShowDropzone] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const currentUser = useSelector(state => state.auth.currentUser);
  const inventoryItems = useSelector(state => state.firestore.inventoryItems);

  useEffect(() => {
    if (uploading && file) {
      props.setFormError('');
      const firestoreRef = Firestore.collection('Tracking-Forms')
        .doc(props.selectedSortForm.id)
        .collection('Inbound-Containers')
        .doc(props.selectedInboundContainer.id)
        .collection('Files')
        .doc();
      const fileRef = firebase.storage()
        .ref()
        .child(`Sort_Forms/${props.selectedSortForm.id}/${props.selectedInboundContainer.id}/${firestoreRef.id}.${file.name.split('.').pop()}`);
      const firestoreData = {
        UploadedBy: currentUser.displayName,
        UploadedOn: new Date(),
        IncomingPieceNo: inventoryItems.ref[props.selectedInboundContainer.SortedInventoryItems[0].InventoryRef].IncomingPieceNo,
        ShippingDocRef: inventoryItems.ref[props.selectedInboundContainer.SortedInventoryItems[0].InventoryRef].ShippingDocRef,
        FilePath: `Sort_Forms/${props.selectedSortForm.id}/${props.selectedInboundContainer.id}/${firestoreRef.id}.${file.name.split('.').pop()}`,
        FileName: `${firestoreRef.id}.${file.name.split('.').pop()}`,
      };
      const uploadTask = fileRef.put(file);
      uploadTask.on('state_changed', {
        next: snap => { },
        error: error => {
          props.setFormError('Error during upload. Please try again');
        },
        complete: () => {
          firestoreRef
            .set(firestoreData)
            .then(() => {
              setUploading(false);
              setShowDropzone(false);
              setFile(null);
            }).catch(error => {
              props.setFormError('Error during upload. Please try again.');
              fileRef.delete();
              setUploading(false);
            });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading, file]);

  const core = {
    button: {
      style: {
        height: '250px',
        width: '250px',
      },
      variant: 'contained',
      onClick: () => setShowDropzone(true),
    },
    dropzone: {
      accept: mimeType,
      maxSize: 5242880,
      multiple: false,
      onDropAccepted: file => {
        setUploading(true);
        setFile(file[0]);
        setShowDropzone(false);
      },
      onDropRejected: (file, event) => {
        if (file[0].size > 5242880) {
          props.setFormError('The file you selected was too large.');
        }
        else {
          props.setFormError('The file type must be an image.');
        }
      },
    },
    divStyles: {
      style: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'lightgray',
        padding: '8px',
        height: '250px',
      },
    },
  };

  if (showDropzone) {
    return (
      <Dropzone {...core.dropzone}>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps()} {...core.fileDrop}>
            <input {...getInputProps()} />
            <div {...core.divStyles}>
              <Typography variant='subtitle1'>
                Drag and drop or click to select a file.
              </Typography>
              <Typography variant='body2'>
                (Must under 5mb)
              </Typography>
            </div>
          </div>
        )}
      </Dropzone>);
  }
  else if (uploading) {
    return (
      <div {...core.divStyles}>
        <AsyncLoader style={{marginTop: '20px'}} />
      </div>
    );
  }
  else {
    return (
      <Button {...core.button}>
        <Grid container>
          <Grid item xs={12}>
            <CloudUpload fontSize='large' />
          </Grid>
          <Grid item xs={12}>
            <Typography>Click to Add Image</Typography>
          </Grid>
        </Grid>
      </Button>
    );
  }
};

NewFileItem.propTypes = {
  selectedInboundContainer: PropTypes.object.isRequired,
  selectedSortForm: PropTypes.object.isRequired,
  setFormError: PropTypes.func.isRequired,
};

export default NewFileItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography, Grid, TextField, withStyles, LinearProgress} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {withRouter} from 'react-router-dom';
import {Firestore} from 'config.js';
import {TableWithPaper} from 'components/';
import flowRight from 'lodash.flowright';

const InventoryDetailsPanel = props => {
  const [forms, setForms] = useState(null);

  useEffect(() => {
    Firestore.collection('Tracking-Inventory').doc(props.selectedInventoryItem.id).collection('Form-History').get().then(snap => {
      const formHistoryDocs = snap.docs.map(doc => ({...doc.data(), id: doc.id}));
      setForms(formHistoryDocs);
    });
  }, [props.selectedInventoryItem]);


  const displayInventoryData = () => {
    return (
      <Fragment>
        <Typography variant='h6' paragraph>Inventory Item: {props.selectedInventoryItem.id}</Typography>
        <Grid container spacing={2} style={{padding: '8px'}}>
          <Grid item xs={12} container
            style={{border: '1px solid darkgray', backgroundColor: 'ghostwhite', padding: '8px'}}
          >
            <Grid item xs={6} md={4}>
              <TextField {...core.textFields} label='Inbound ID' value={props.selectedInventoryItem.ShipmentInRef || ''}
                {...core.inboundLink()}
                inputProps={{style: {color: '#3f51b5', cursor: 'pointer'}}}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField {...core.textFields} label='Shipping Document ID' value={props.selectedInventoryItem.ShippingDocRef || ''}
                {...core.inboundLink(props.selectedInventoryItem.ShippingDocRef)}
                inputProps={{style: {color: '#3f51b5', cursor: 'pointer'}}}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField {...core.textFields} label='Purchase Order ID' value={props.selectedInventoryItem.PurchaseOrderRef || ''}
                {...core.purchaseOrderLink}
                inputProps={{style: {color: '#3f51b5', cursor: 'pointer'}}}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField {...core.textFields} label='Source' value={props.selectedInventoryItem.Source}
                className={props.classes.disabled}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField {...core.textFields} label='Manifested' value={props.selectedInventoryItem.Manifested ? 'True' : 'False'}
                className={props.classes.disabled}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField {...core.textFields} label='CA Codes:' value={props.selectedInventoryItem.CACodes.join(', ') || 'None'}
                className={props.classes.disabled}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField {...core.textFields} label='RCRA Codes:' value={props.selectedInventoryItem.RCRACodes.join(', ') || 'None'}
                className={props.classes.disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };
  const displayFormData = () => {
    if (!forms) return <LinearProgress />;
    else if (!forms.length) {
      return (
        <Typography>This inventory item does not appear on any forms.</Typography>
      );
    }
    else {
      return <TableWithPaper {...core.formHistoryTable} />;
    }
  };

  const core = {
    inboundLink: (path = '') => ({
      placeholder: 'This item has no Inbound',
      onClick: () => {
        if (props.selectedInventoryItem.ShipmentInRef) {
          props.history.push(`/shipments/inbound/${props.selectedInventoryItem.ShipmentInRef}${path ? `/${path}` : ''}`);
        }
      },
    }),
    purchaseOrderLink: {
      placeholder: 'This item has no PO',
      onClick: () => {
        if (props.selectedInventoryItem.PurchaseOrderRef) {
          window.open(
            `https://crm.kbi.works/accounts/${props.selectedInventoryItem.AccountRef}/purchase-order/${props.selectedInventoryItem.PurchaseOrderRef}`,
            '__blank',
          );
        }
      },
    },
    textFields: {
      InputProps: {
        readOnly: true,
      },
      InputLabelProps: {
        shrink: true,
      },
      variant: 'outlined',
      margin: 'dense',
    },
    formHistoryTable: {
      data: forms || [],
      columns: [
        {Header: 'Date', accessor: 'Date', type: 'timestamp'},
        {Header: 'Form Type', accessor: 'FormType'},
        {Header: 'Start Container', accessor: 'StartContainer'},
        {Header: 'Start Material', accessor: 'StartMaterial'},
        {Header: 'Start Type', accessor: 'StartType'},
        {Header: 'Start Weight', accessor: 'StartWeight'},
        {Header: 'Start Flag', accessor: 'StartFlag'},
        {Header: 'End Container', accessor: 'EndContainer'},
        {Header: 'End Material', accessor: 'EndMaterial'},
        {Header: 'End Type', accessor: 'EndType'},
        {Header: 'End Weight', accessor: 'EndWeight'},
        {Header: 'End Flag', accessor: 'EndFlag'},
      ],
      title: {primary: 'Form History'},
      sortInitial: [{id: 'Date', desc: true}],
      actionsPerRow: [
        (rowData) => {
          if (rowData.FormType === 'KBI Generation') {
            return {
              hide: true,
            };
          }
          else {
            return {
              icon: Search,
              tooltip: 'View Form',
              onClick: ({rowData}) => {
                switch (rowData.FormType) {
                case 'Sort':
                  props.history.push(`/production/sort/${rowData.FormId}`);
                  break;
                case 'Process':
                  props.history.push(`/production/process/${rowData.FormId}`);
                  break;
                case 'Consolidation':
                  props.history.push(`/production/consolidate/${rowData.FormId}`);
                  break;
                case 'Outbound':
                  props.history.push(`/shipments/outbound/${rowData.FormId}`);
                  break;
                case 'Inbound':
                  props.history.push(`/shipments/inbound/${rowData.FormId}`);
                  break;
                default:
                  break;
                }
              },
            };
          }
        },
      ],
    },
  };

  return (
    <Fragment>
      <Paper style={{padding: '16px', marginTop: '16px'}}>
        {displayInventoryData()}
      </Paper>
      {displayFormData()}
    </Fragment>
  );
};

const styles = theme => ({
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
});

InventoryDetailsPanel.propTypes = {
  selectedInventoryItem: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default flowRight(withStyles(styles, {withTheme: true}), withRouter)(InventoryDetailsPanel);

import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, withStyles, Paper, List, ListItem, MenuItem, LinearProgress} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {Send, Edit, Print} from '@material-ui/icons';
import {SortSummaryPanel, InboundContainerPanel} from '../panels/';
import {NewSortFormModal} from './Production/';
import flowright from 'lodash.flowright';
import moment from 'moment';
import {MovementFormModal, ContainerIcon, BackButton, DetailsTitle, DetailsFieldList} from 'components/';
import {SortedLabels, MarkAsCompleteModal} from './SortFormDetails/';
import {Firestore} from 'config.js';
import {useMaterials, useSortForms, useFacilityUnits, usePurchaseOrders, useContainers, useInventory, useTypes} from 'hooks/';

const SortFormDetails = props => {
  const {classes, match} = props;

  const [movementFormModalOpen, setMovementFormModalOpen] = useState(false);
  const [sortFormModalOpen, setSortFormModalOpen] = useState(false);
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);
  const [selectedSortForm, setSelectedSortForm] = useState(null);
  const [containersInCurrentFacilityUnit, setContainersInCurrentFacilityUnit] = useState([]);
  const [sortFormInboundContainers, setSortFormInboundContainers] = useState(null);
  const [listOfContainersToPrint, setListOfContainersToPrint] = useState(null);
  const [paginationOffForPrint, setPaginationOffForPrint] = useState(false);

  // setting up redux
  const facilityUnits = useFacilityUnits();
  const sortForms = useSortForms();
  const materials = useMaterials();
  useTypes();
  usePurchaseOrders();
  useContainers();
  useInventory();

  // set selected sort form to the SortFormId param, either through redux or by stand alone request
  useEffect(() => {
    if (sortForms && sortForms.ref[match.params.SortFormId]) {
      setSelectedSortForm(sortForms.ref[match.params.SortFormId]);
    }
    else {
      Firestore.collection('Tracking-Forms').doc(match.params.SortFormId).get().then(doc => {
        setSelectedSortForm({...doc.data(), Date: doc.data().Date.toDate(), id: doc.id});
      });
    }
  }, [match.params.SortFormId, sortForms]);

  // listener useEffect
  const createFacilityUnitContainerListener = () => {
    // create a listener on the containers collection for all containers that match the current sort forms facility unit
    const containerListener = Firestore.collection('Tracking-Containers')
      .where('FacilityUnitRef', '==', selectedSortForm.FacilityUnitRef)
      .onSnapshot(snap => {
        const arrayOfContainers = [];
        snap.forEach(doc => {
          const docData = {...doc.data(), ShortNo: doc.id};
          arrayOfContainers.push(docData);
        });
        setContainersInCurrentFacilityUnit(arrayOfContainers);
      });
    return containerListener;
  };
  const createInboundContainerListener = () => {
    const inboundContainerListener = Firestore.collection('Tracking-Forms')
      .doc(selectedSortForm.id)
      .collection('Inbound-Containers')
      .onSnapshot(snap => {
        const arrayOfContainers = [];
        snap.docs.forEach((doc, index) => {
          const docData = {...doc.data(), id: doc.id, LineNo: index + 1};
          arrayOfContainers.push(docData);
        });
        setSortFormInboundContainers([...arrayOfContainers]);
      });
    return inboundContainerListener;
  };
  useEffect(() => {
    if (selectedSortForm) {
      const unitContainerListener = createFacilityUnitContainerListener();
      const inboundContainerListener = createInboundContainerListener();
      return () => {
        unitContainerListener();
        inboundContainerListener();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortForm]);

  // print useEffects
  useEffect(() => {
    if (listOfContainersToPrint) {
      window.print();
      setListOfContainersToPrint(null);
    }
  }, [listOfContainersToPrint]);
  useEffect(() => {
    if (paginationOffForPrint) {
      window.print();
      setPaginationOffForPrint(false);
    }
  }, [paginationOffForPrint]);

  if (!selectedSortForm || !materials || !facilityUnits) return <LinearProgress />;

  const core = {
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    markAsCompleteButton: {
      variant: 'outlined',
      color: 'secondary',
      fullWidth: true,
      className: 'hidePrint',
      disabled: sortFormInboundContainers && sortFormInboundContainers.length === 0 ? true : false,
      onClick: () => setMarkAsCompleteModalOpen(true),
    },
    printButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setPaginationOffForPrint(true),
    },
    editSortFormButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setSortFormModalOpen(true),
    },
    sortFormModal: {
      open: sortFormModalOpen,
      close: () => setSortFormModalOpen(false),
      selectedSortForm,
      deleteAllowed: sortFormInboundContainers && sortFormInboundContainers.length === 0 ? true : false,
    },
    sortSummaryPanel: {
      paginationOffForPrint: paginationOffForPrint,
      inboundContainers: sortFormInboundContainers,
      setListOfContainersToPrint: arrayOfContainers => setListOfContainersToPrint(arrayOfContainers),
    },
    movementFormButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setMovementFormModalOpen(true),
    },
    movementFormModal: {
      open: movementFormModalOpen,
      defaultFacility: selectedSortForm.Facility,
      defaultUnit: selectedSortForm.FacilityUnitRef,
      close: () => setMovementFormModalOpen(false),
    },
    inboundContainerPanel: {
      StyledMenuItem,
      classes,
      paginationOffForPrint: paginationOffForPrint,
      inboundContainers: sortFormInboundContainers,
      containersInCurrentFacilityUnit,
      selectedSortForm,
    },
    markAsCompleteModal: {
      open: markAsCompleteModalOpen,
      close: () => setMarkAsCompleteModalOpen(false),
      selectedSortForm,
    },
  };

  return (
    <Fragment>
      <div className={listOfContainersToPrint ? 'hidePrint' : ''}>
        <div className='sideScrollingContainer hidePrint'>
          <BackButton />
          {!selectedSortForm.Completed &&
            <Fragment>
              {!sortFormInboundContainers?.length &&
                <Button {...core.editSortFormButton}><Edit className={classes.extendedIcon} />Edit Sort Form</Button>}
              <Button {...core.movementFormButton}><Send className={classes.extendedIcon} />Create Movement Form</Button>
            </Fragment>}
          <Button {...core.printButton}><Print className={classes.extendedIcon} />Print Form</Button>
        </div>
        <Grid className={listOfContainersToPrint ? 'hidePrint' : ''} container spacing={5}
          style={{marginTop: '8px'}}
        >
          <Grid item xs={12} md={3}>
            <Paper {...core.paperHeader}>
              <ContainerIcon style={{fontSize: '10em', fill: 'slategray', width: '100%', marginTop: '25px'}} />
              <DetailsTitle title='Sort Form' subtitle='Inbound Containers' />
              <DetailsFieldList fields={[
                {label: 'Form Id', value: selectedSortForm.id},
                {label: 'Date', value: moment(selectedSortForm.Date).format('MM/DD/YYYY')},
                {label: 'Facility', value: selectedSortForm.Facility},
                {label: 'Shift', value: selectedSortForm.Shift},
                {label: 'Facility Unit', value: facilityUnits?.ref[selectedSortForm.FacilityUnitRef].Name},
                {label: 'Containers Sorted', value: sortFormInboundContainers?.length || 0},
                {label: 'Weight Sorted', value: sortFormInboundContainers && sortFormInboundContainers.length ?
                  sortFormInboundContainers.map((container, index) => {
                    return container.SortedInventoryItems.reduce((a, b) => a + parseInt(b.NetWeight), 0);
                  }).reduce((a, b) => a + b, 0) :
                  0},

              ]} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                {!selectedSortForm.Completed &&
                  <ListItem>
                    <Button {...core.markAsCompleteButton}>Mark As Complete</Button>
                  </ListItem>}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            {sortFormInboundContainers ? (
              <Fragment>
                <InboundContainerPanel {...core.inboundContainerPanel} />
                <SortSummaryPanel {...core.sortSummaryPanel} />
              </Fragment>
            ) :
              <LinearProgress />}
          </Grid>
        </Grid>
        {movementFormModalOpen && <MovementFormModal {...core.movementFormModal} />}
        {markAsCompleteModalOpen && <MarkAsCompleteModal {...core.markAsCompleteModal} />}
        {sortFormModalOpen && <NewSortFormModal {...core.sortFormModal} />}
      </div>
      {listOfContainersToPrint && <SortedLabels listOfContainersToPrint={listOfContainersToPrint} />}
    </Fragment >
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
});

const StyledMenuItem = withStyles({
  root: {
    minHeight: '0px',
    whiteSpace: 'normal',
  },
})(MenuItem);

SortFormDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default flowright(withStyles(styles, {withTheme: true}), withRouter)(SortFormDetails);

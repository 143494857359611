import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import {withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';
import flowright from 'lodash.flowright';

const stageArray = ['basic', 'success'];

const MarkAsCompleteModal = props => {
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState('');
  const currentUser = useSelector(state => state.auth.currentUser);

  const handleSubmit = () => {
    setSubmitting(true);
    const docRef = Firestore.collection('Tracking-Forms').doc(props.selectedConsolidationForm.id);
    docRef.update({Completed: true, CompletedBy: {Name: currentUser.displayName, Email: currentUser.email}})
      .then(() => {
        setSubmitting(false);
        setStage(stage + 1);
      })
      .catch(error => {
        setFormError('There was an error during submission. Please try again.');
      });
  };

  const core = {
    dialog: {
      open: true,
      maxWidth: 'sm',
      fullWidth: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    cancelButton: {
      onClick: props.close,
      color: 'secondary',
      disabled: submitting,
    },
    closeButton: {
      onClick: () => {
        props.history.push('/production/consolidate');
      },
      color: 'primary',
    },
    submitButton: {
      text: 'Submit',
      color: 'primary',
      loading: submitting,
      onClick: handleSubmit,
    },
    submitConfirmation: {
      text: `Consolidation Form successfully completed. You will be redirected to the Production page after closing this.`,
      stage: stageArray[stage],
    },
  };

  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle >Mark Consolidation Form as Complete</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>
            Only mark as complete if there will be no more consolidating done on this form. Once it is completed, you will no longer be able to add any containers to this form.
          </DialogContentText>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] !== 'success' ? (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      )}
    </Dialog >
  );
};

MarkAsCompleteModal.propTypes = {
  selectedConsolidationForm: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default flowright(withRouter)(MarkAsCompleteModal);

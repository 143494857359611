import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Formik} from '@kbi/component-library';
const {AutoCompleteObject, SelectField, TextField} = Formik;

const InvoiceForm = ({values, toUpper}) => {
  const accounts = useSelector(reduxState => reduxState.firestore.accounts);

  const core = useMemo(() => ({
    accountAutocomplete: {
      name: 'account',
      label: 'Account',
      options: accounts ? Object.values(accounts) : [],
      optionKey: 'AccountName',
      onChange: ({form, field}) => {
        form.setFieldValue('generator', '');
      },
    },
  }), [accounts]);

  if (!accounts) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.accountAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='accountingId' label='Accounting Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='inboundId' label='Inbound Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='poNumber' label='PO Number' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField name='isInvoiced' label='Invoiced?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='shippingDocumentNumber' label='Shipping Document Number' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='weightTicketNumber' label='Weight Ticket Number' />
      </Grid>
    </Grid>
  );
};

InvoiceForm.propTypes = {
  values: PropTypes.object.isRequired,
  toUpper: PropTypes.func.isRequired,
};

export default InvoiceForm;

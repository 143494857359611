import {useState} from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetTrackingShipments} from 'state/firestore/actions.js';

const useTrackingShipments = () => {
  const [touched, setTouched] = useState(false);
  const dispatch = useDispatch();
  const trackingShipments = useSelector(state => state.firestore.trackingShipments);

  useEffect(() => {
    if (!trackingShipments && !touched) {
      setTouched(true);
      setTimeout(() => dispatch(acGetTrackingShipments), 5000 * Math.random());
    }
  }, [dispatch, trackingShipments, touched]);

  return trackingShipments;
};

export default useTrackingShipments;

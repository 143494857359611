import React, {Fragment, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, Grid, DialogContentText, DialogActions} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {Firestore} from 'config.js';
const {SubmitButton, FormButton, FormikForm, WeightField} = Formik;

const stageArray = ['basic', 'success'];

const EditTareWeightModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const core = useMemo(() => {
    return {
      dialog: {
        open: true,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitConfirmation: {
        text: 'Container tare weight successfully updated.',
        stage: stageArray[stage],
      },
    };
  }, [stage]);
  const formik = useMemo(() => {
    return {
      initialValues: {
        tareWeight: props.selectedContainer.TareWeight,
      },
      validationSchema: yup.object().shape({
        tareWeight: yup.number().required('Tare Weight is a required field.').min(0, 'Tare Weight cannot be less than 0.'),
      }),
      onSubmit: (values, actions) => {
        const containerRef = Firestore.collection('Tracking-Containers').doc(props.selectedContainer.ShortNo);
        containerRef.update({
          TareWeight: parseInt(values.tareWeight),
        }).then(() => {
          setStage(stage + 1);
          actions.setSubmitting(false);
        }).catch(error => {
          setFormError('There was an error during submission. Please try again.');
          actions.setSubmitting(false);
        });
      },
    };
  }, [props.selectedContainer.ShortNo, props.selectedContainer.TareWeight, stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Edit Container Tare Weight</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter the new tare weight the container:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <WeightField decimal={0} name='tareWeight' label='Tare Weight' required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] !== 'success' ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>close</FormButton>
              </DialogActions>
            )}          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

EditTareWeightModal.propTypes = {
  selectedContainer: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};

export default EditTareWeightModal;

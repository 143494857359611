import React, {Fragment, useMemo, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Alert} from '@kbi/component-library';
import {DefaultModalButtons, SubmitConfirmation} from 'components/';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Grid,
  Collapse,
} from '@material-ui/core';
import * as yup from 'yup';
import {Formik} from '@kbi/component-library';
import {Functions, Firestore} from 'config.js';

const {CheckboxGroup, SelectField, FormikForm, TextField, CurrencyField} = Formik;
const stageArray = ['basic', 'success'];

const ViewTypeModal = props => {
  const [formError, setFormError] = useState('');
  const [stage, setStage] = useState(0);
  const currentUser = useSelector(state => state.auth.currentUser);

  const rcraCodes = useMemo(() => props.material.WasteCodes.RCRA.map(code => ({value: code, label: code})), [props.material.WasteCodes.RCRA]);

  const caCodes = useMemo(() => props.material.WasteCodes.CA.map(code => ({value: code, label: code})), [props.material.WasteCodes.CA]);

  const createDataForFirestore = useCallback(values => {
    return {
      TypeName: values.name,
      TypeNameShort: values.nameShort,
      Active: values.active,
      Price: values.price,
      BillType: values.billType,
      MaterialId: props.material.MaterialId,
      HandlingCode: values.handlingCode,
      ShowOnProfile: values.showOnProfile,
      WasteCodes: {
        CA: values['CA Codes'],
        RCRA: values['RCRA Codes'],
      },
    };
  }, [props.material.MaterialId]);

  const formik = useMemo(() => ({
    initialValues: {
      'name': props.name || '',
      'nameShort': props.nameShort || '',
      'price': props.price || '',
      'showOnProfile': props.showOnProfile || true,
      'handlingCode': props.handlingCode ||'',
      'active': props.active || true,
      'billType': props.billType || '',
      'RCRA Codes': props.codes?.RCRA?.filter(docCode => rcraCodes.find(codeField => docCode === codeField.value)) || [],
      'CA Codes': props.codes?.CA?.filter(docCode => caCodes.find(codeField => docCode === codeField.value)) || [],
    },
    validationSchema: yup.object().shape({
      'name': yup.string().required('Name is a required field.'),
      'nameShort': yup.string().max(12, 'Short Name is too long (12 characters).').test({
        name: 'short name',
        test: function(value) {
          if (value && value.split('').find(char => char === ' ')) {
            return false;
          }
          else return true;
        },
        message: 'Short Name cannot have any spaces.',
      }),
      'price': yup.mixed().when('billType', {
        is: 'No Charge',
        then: yup.number().max(0, 'Price must be $0.00 when Bill Type is "No Charge"'),
      }).when('billType', {
        is: 'Market',
        then: yup.string().oneOf(['N/A'], 'Price must be "N/A" when Bill Type is "Market"'),
      }).when('billType', {
        is: value => value === 'Charge' || value === 'Payment',
        then: yup.number().min(0.0001, 'Price must be above 0 for this Bill Type.'),
      }),
      'showOnProfile': yup.bool().required('Show On Profile is a required field.'),
      'handlingCode': yup.string().required('Handling Code is a required field.'),
      'active': yup.bool().required('Active is a required field.'),
      'billType': yup.string().required('Bill Type is a required field.'),
      'RCRA Codes': yup.array().required('RCRA Codes are a required field.'),
      'CA Codes': yup.array().required('CA Codes are a required field.'),
    }),
    onSubmit: (values, actions) => {
      const dataForType = createDataForFirestore(values);
      if (props.edit) {
        Functions.httpsCallable('updateMaterialType')({
          id: props.id,
          materialId: props.material.MaterialId,
          dataForType,
          currentUser,
        }).then(result => {
          actions.setSubmitting(false);
          setStage(stage + 1);
        }).catch(error => {
          console.log(error);
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
      }
      else {
        Firestore.collection('Tracking-Materials').doc(props.material.MaterialId).collection('Types').doc( ).set({
          ...dataForType,
          System: {
            CreatedBy: currentUser.displayName,
            CreatedOn: new Date(),
          },
        }).then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
        }).catch(error => {
          console.log(error);
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
      }
    },
  // eslint-disable-next-line max-len
  }), [caCodes, createDataForFirestore, currentUser, props.active, props.billType, props.codes, props.edit, props.handlingCode, props.id, props.material.MaterialId, props.name, props.nameShort, props.price, props.showOnProfile, rcraCodes, stage]);

  const buttonProps = useMemo(() => ({
    submitButtonProps: {},
    stage: stageArray[stage],
    close: props.close,
  }), [props.close, stage]);

  const core = useMemo(() => ({
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'md',
      fullWidth: true,
    },
    activeField: {
      name: 'active',
      label: 'Active?',
      disabled: !props.edit,
      required: true,
    },
    billTypeField: {
      name: 'billType',
      label: 'Bill Type',
      required: true,
      onChange: ({form, event}) => {
        if (event.target.value === 'Market') {
          form.setFieldValue('price', 'N/A');
        }
        else if (event.target.value === 'No Charge') {
          form.setFieldValue('price', '0.00');
        }
        else {
          form.setFieldValue('price', '');
        }
      },
    },
    submitConfirmation: {
      text: props.edit ? 'Type successfully edited.' : 'New type successfully added.',
      stage: stageArray[stage],
    },
  }), [props.edit, stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>{props.edit ? 'Edit Type' : 'New Type'}</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter type name and the default price to appear on POs.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <SelectField {...core.activeField}>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='name' label='Name' required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='nameShort' label='Short Name' required />
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name='showOnProfile' label='Show On Profile?' required>
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField {...core.billTypeField}>
                      <MenuItem value='Charge'>Charge</MenuItem>
                      <MenuItem value='No Charge'>No Charge</MenuItem>
                      <MenuItem value='Market'>Market</MenuItem>
                      <MenuItem value='Payment'>Payment</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm>
                    {formikProps.values?.billType === 'Market' ? <TextField name='price' label='Price text' required disabled /> :
                      <CurrencyField name='price' label='Price number' required disabled={formikProps.values?.billType === 'No Charge'} />}
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name='handlingCode' label='Handling Code' required>
                      <MenuItem value='H010'>H010</MenuItem>
                      <MenuItem value='H141'>H141</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxGroup name='RCRA Codes' boxes={rcraCodes} row required />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxGroup name='CA Codes' boxes={caCodes} row required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            <DefaultModalButtons isSubmitting={formikProps.isSubmitting} {...buttonProps} />
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ViewTypeModal.propTypes = {
  close: PropTypes.func.isRequired,
  material: PropTypes.object.isRequired,
  newTypeModalOpen: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  name: PropTypes.string,
  nameShort: PropTypes.string,
  price: PropTypes.string,
  id: PropTypes.string,
  codes: PropTypes.object,
  handlingCode: PropTypes.string,
  billType: PropTypes.string,
  showOnProfile: PropTypes.bool,
};

export default ViewTypeModal;

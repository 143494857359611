import React, {useState, Fragment, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, DialogContentText, Grid, DialogActions} from '@material-ui/core';
import {SubmitConfirmation} from 'components/';
import {Alert, Formik, Collapse} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {Firestore} from 'config.js';
const {TextField, FormikForm, FormButton, SubmitButton} = Formik;

const stageArray = ['basic', 'success'];

const MarkAsInvoicedModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const currentUser = useSelector(state => state.auth.currentUser);

  const checkIfUniqueAccountingId = useCallback(accountingId => {
    switch (props.documentType) {
    case 'Outbound':
      return Firestore.collection('Tracking-Shipments').where('AccountingId', '==', accountingId).get().then(snap => {
        if (snap.empty) return true;
        else return false;
      });
    case 'Invoice':
      return Firestore.collection('Tracking-Invoices').where('AccountingId', '==', accountingId).get().then(snap => {
        if (snap.empty) return true;
        else return false;
      });
    case 'Process':
      return Firestore.collection('Tracking-Forms').where('FormType', '==', 'Process').where('AccountingId', '==', accountingId).get().then(snap => {
        if (snap.empty) return true;
        else return false;
      });
    default:
      break;
    }
  }, [props.documentType]);
  const addInvoiceToBatch = useCallback((values, batch) => {
    batch.update(Firestore.collection('Tracking-Invoices').doc(props.selectedInvoice.id), {
      'IsInvoiced': true,
      'AccountingId': values.accountingId.trim(),
      'System.InvoicedBy': currentUser.displayName,
      'System.InvoicedOn': new Date(),
    });
    batch.update(Firestore.collection('Tracking-Shipments')
      .doc(props.selectedInvoice.InboundRef)
      .collection('Shipping-Documents')
      .doc(props.selectedInvoice.ShippingDocRef), {
      Invoiced: true,
      AccountingId: values.accountingId.trim(),
    },
    );
    return;
  }, [currentUser.displayName, props.selectedInvoice]);
  const addOutboundToBatch = useCallback((values, batch) => {
    batch.update(Firestore.collection('Tracking-Shipments').doc(props.selectedOutbound.id), {
      'IsInvoiced': true,
      'AccountingId': values.accountingId.trim(),
      'System.InvoicedBy': currentUser.displayName,
      'System.InvoicedOn': new Date(),
    });
  }, [currentUser.displayName, props.selectedOutbound]);
  const addProcessToBatch = useCallback((values, batch) => {
    batch.update(Firestore.collection('Tracking-Forms').doc(props.selectedProcessForm.id), {
      'IsInvoiced': true,
      'AccountingId': values.accountingId.trim(),
      'System.InvoicedBy': currentUser.displayName,
      'System.InvoicedOn': new Date(),
    });
  }, [currentUser.displayName, props.selectedProcessForm]);

  const formik = useMemo(() => ({
    initialValues: {
      accountingId: '',
    },
    validationSchema: yup.object().shape({
      accountingId: yup.string().required('Accounting Id is a required field.').test({
        name: 'uniqueId',
        test: async function(value) {
          if (value) {
            const unique = await checkIfUniqueAccountingId(value);
            if (unique) return true;
            else return false;
          }
          else return false;
        },
        message: 'The Accounting Id entered is already in use.',
      }),
    }),
    onSubmit: (values, actions) => {
      const batch = Firestore.batch();
      switch (props.documentType) {
      case 'Outbound':
        addOutboundToBatch(values, batch);
        break;
      case 'Invoice':
        addInvoiceToBatch(values, batch);
        break;
      case 'Process':
        addProcessToBatch(values, batch);
        break;
      default:
        break;
      }
      batch.commit()
        .then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
        }).catch(error => {
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    },
  }), [addInvoiceToBatch, addOutboundToBatch, addProcessToBatch, checkIfUniqueAccountingId, props.documentType, stage]);
  const core = useMemo(() => ({
    submitConfirmation: {
      text: 'Successfully marked as invoiced.',
      stage: stageArray[stage],
    },
    dialog: {
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
      open: true,
    },
  }), [stage]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {() => (
          <Fragment>
            {stageArray[stage] !== 'success' && <DialogTitle>Mark As Invoiced</DialogTitle>}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Please enter the accounting Id that is associated with this document:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name='accountingId' label='Accounting Id' required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] !== 'success' ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton variant='text' color='secondary' onClick={props.close}>close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

MarkAsInvoicedModal.propTypes = {
  close: PropTypes.func.isRequired,
  documentType: PropTypes.oneOf(['Process', 'Invoice', 'Outbound']),
  selectedInvoice: PropTypes.object,
  selectedProcessForm: PropTypes.object,
  selectedOutbound: PropTypes.object,
};

export default MarkAsInvoicedModal;

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Search, Add} from '@material-ui/icons';
import {TableWithPaper} from 'components/';
import {LinearProgress} from '@material-ui/core';

class ViewFacilityUnits extends Component {
  render() {
    const {facilityUnits} = this.props;
    const core = {
      tableFacilities: {
        columns: [
          {accessor: 'Name', Header: 'Name'},
          {accessor: 'Description', Header: 'Description'},
          {accessor: 'Facility', Header: 'Facility'},
          {accessor: 'MaxUnitWeight', Header: 'Max Unit Weight', type: 'numeric'},
        ],
        sortInitial: [{id: 'Name', desc: false}],
        data: facilityUnits ? facilityUnits.list : [],
        paginationInitialSize: 10,
        actionsPerRow: [
          {
            icon: Search,
            tooltip: 'View Facilty',
            onClick: ({rowData}) => this.props.onFacilitySelect(rowData),
          },
        ],
        actionsBar: [
          {
            icon: Add,
            text: 'Add Facility Unit',
            onClick: this.props.footerClick,
          },
        ],
        title: {primary: 'Facility Units'},
      },
    };
    if (!facilityUnits) return <LinearProgress />;
    return (
      <TableWithPaper {...core.tableFacilities} />
    );
  }
}

ViewFacilityUnits.propTypes = {
  onFacilitySelect: PropTypes.func.isRequired,
  facilityUnits: PropTypes.object.isRequired,
  footerClick: PropTypes.func.isRequired,
};
export default ViewFacilityUnits;

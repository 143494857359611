import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Table, TableCell, TableHead, TableRow, TableBody, withStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import moment from 'moment';

const PrintableOutbound = props => {
  const {selectedOutbound} = props;
  const {locations, accounts} = useSelector(state => state.firestore);

  const createLineItemsInfo = useCallback((lines) => {
    let pieceNumber = 1;
    return (
      <Fragment>
        <Table size='small' className={`${props.classes.tableFont}`} padding='none'>
          <TableHead>
            <TableRow>
              <TableCell>Line No.</TableCell>
              <TableCell>Piece No.</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Container</TableCell>
              <TableCell>Short No.</TableCell>
              <TableCell>Gross</TableCell>
              <TableCell>Tare</TableCell>
              <TableCell>Net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.sort((a, b) => parseInt(a.LineNumber) > parseInt(b.LineNumber) ? 1 : -1).map(line => line.AddedContainers.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{line.LineNumber}</TableCell>
                <TableCell>{pieceNumber++}</TableCell>
                <TableCell>{line.Material.Name}</TableCell>
                <TableCell>{line.ContainerCode}</TableCell>
                <TableCell>{item.ShortNo}</TableCell>
                <TableCell>{item.GrossWeight}</TableCell>
                <TableCell>{item.TareWeight}</TableCell>
                <TableCell>{item.NetWeight}</TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
        <div className={props.classes.pageBreak} />

      </Fragment>
    );
  }, [props.classes.pageBreak, props.classes.tableFont]);
  const createLinesSection = useCallback((lines) => {
    return (
      <Table size='small' className={`${props.classes.tableFont}`} padding='none'>
        <TableHead>
          <TableRow>
            <TableCell>Line No.</TableCell>
            <TableCell>Material</TableCell>
            <TableCell>Gross Weight</TableCell>
            <TableCell>Net Weight</TableCell>
            <TableCell>Pieces</TableCell>
            <TableCell>RCRA Codes</TableCell>
            <TableCell>CA Codes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.sort((a, b) => parseInt(a.LineNumber) > parseInt(b.LineNumber) ? 1 : -1).map((line, index) => (
            <TableRow key={index}>
              <TableCell>{line.LineNumber}</TableCell>
              <TableCell>{line.Material.Name}</TableCell>
              <TableCell>{line.AddedContainers.reduce((prev, curr) => prev + curr.GrossWeight, 0)}</TableCell>
              <TableCell>{line.AddedContainers.reduce((prev, curr) => prev + curr.NetWeight, 0)}</TableCell>
              <TableCell>{line.AddedContainers.length}</TableCell>
              <TableCell>{line.RCRACodes.join(', ')}</TableCell>
              <TableCell>{line.CACodes.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [props.classes.tableFont]);
  const createInventoryInfo = useCallback(() => {
    if (props.inventoryItemData) {
      return (
        <Fragment>
          <Table size='small' className={`${props.classes.tableFont}`} padding='none'>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Material</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Flag</TableCell>
                <TableCell>Weight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.inventoryItemData.arrayOfItems.sort((a, b) => a.Type?.Name > b.Type?.Name ? 1 : -1).map((item, index) =>
                <TableRow key={index}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.Material.Name}</TableCell>
                  <TableCell>{item.Type.Name}</TableCell>
                  <TableCell>{item.Flag}</TableCell>
                  <TableCell>{item.Weight}</TableCell>
                </TableRow>,
              )}
            </TableBody>
          </Table>
        </Fragment>
      );
    }
    else return null;
  }, [props.classes.tableFont, props.inventoryItemData]);
  const createGroupedInventoryInfo = useCallback(() => {
    if (props.inventoryItemData) {
      return (
        <Table size='small' className={`${props.classes.tableFont}`} padding='none'>
          <TableHead>
            <TableRow>
              <TableCell>Material</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Flag</TableCell>
              <TableCell>Total Weight</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.inventoryItemData.totals.sort((a, b) => a.type > b.type ? 1 : -1).map((totalObj, index) => (
              <TableRow key={index}>
                <TableCell>{totalObj.material}</TableCell>
                <TableCell>{totalObj.type}</TableCell>
                <TableCell>{totalObj.flag}</TableCell>
                <TableCell>{totalObj.weight}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    else return null;
  }, [props.classes.tableFont, props.inventoryItemData]);

  return (
    <div className='firstPage printOnlyWrapper'>
      <Grid container spacing={1} style={{border: '2px solid lightgrey', margin: '16px 0px'}}>
        <Grid item xs={12}>
          <Typography variant='h5' style={{textAlign: 'center'}}>Outbound Load</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Date Shipped - {moment(selectedOutbound.ShipmentTime).format('MM/DD/YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Facility - {selectedOutbound.Facility}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Account - {accounts[selectedOutbound.AccountId].AccountName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Location - {locations[selectedOutbound.AccountId].find(location => location.id === selectedOutbound.LocationRef).LocationName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Transporter - {accounts[selectedOutbound.Transporter].AccountName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {`${selectedOutbound.DocumentType}${selectedOutbound.DocumentNumber && ` - ${selectedOutbound.DocumentNumber}`}`}
          </Typography>
        </Grid>
        {selectedOutbound.AccountingId && (
          <Grid item xs={6}>
            <Typography>
              Accounting Id - {selectedOutbound.AccountingId}
            </Typography>
          </Grid>)}
        {selectedOutbound.BookingNumber && (
          <Grid item xs={6}>
            <Typography>
              Booking No. - {selectedOutbound.BookingNumber}
            </Typography>
          </Grid>)}
        {selectedOutbound.ContainerNumber && (
          <Grid item xs={6}>
            <Typography>
              Container No. - {selectedOutbound.ContainerNumber}
            </Typography>
          </Grid>)}
        {selectedOutbound.SealNumber && (
          <Grid item xs={6}>
            <Typography>
              Seal No. - {selectedOutbound.SealNumber}
            </Typography>
          </Grid>)}
        {selectedOutbound.PurchaseOrderNumber && (
          <Grid item xs={6}>
            <Typography>
              Purchase Order No. - {selectedOutbound.PurchaseOrderNumber}
            </Typography>
          </Grid>)}
        {selectedOutbound.Comments && (
          <Grid item xs={12}>
            <Typography>
              Comments - {props.selectedOutbound.Comments}
            </Typography>
          </Grid>)}
      </Grid>
      <Grid container spacing={2} style={{margin: '0px'}}>
        <Grid item xs={12}>
          <Typography className={props.classes.bold}>Lines</Typography>
          {createLinesSection(props.listOfLines)}
          <Typography className={props.classes.bold}>Pieces</Typography>
          {createLineItemsInfo(props.listOfLines)}
        </Grid>
        {props.inventoryItemData && (
          <Fragment>
            <Typography className={props.classes.bold}>Inventory Total</Typography>
            {createGroupedInventoryInfo(props.listOfLines)}
            <Typography className={props.classes.bold}>Inventory</Typography>
            {createInventoryInfo(props.listOfLines)}
          </Fragment> ) }
      </Grid>
    </div>
  );
};

const styles = theme => ({
  tableFont: {
    '& td': {
      fontSize: '10pt',
    },
    '& th': {
      fontSize: '10pt',
    },
  },
  pageBreak: {
    // pageBreakAfter: 'always',
    // display: 'block',
  },
  bold: {
    fontWeight: 600,
  },
});

PrintableOutbound.propTypes = {
  listOfLines: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOutbound: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  inventoryItemData: PropTypes.shape({
    totals: PropTypes.arrayOf(PropTypes.object),
    arrayOfItems: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default withStyles(styles)(PrintableOutbound);

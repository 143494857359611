import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
const {TextField} = Formik;

const ContainerForm = ({values}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField name='shortNumber' label='Short Number' placeholder='Must match exactly' />
      </Grid>
    </Grid>
  );
};

ContainerForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ContainerForm;

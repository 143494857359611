import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Search} from '@material-ui/icons';
import {TableWithPaper} from 'components/';
import flowRight from 'lodash.flowright';
import {withRouter} from 'react-router-dom';

const InboundTable = props => {
  const formatInbounds = () => {
    const formattedInbounds = [];
    props.trackingShipments.forEach(shipment => {
      formattedInbounds.push({
        ...shipment,
        accountName: props.accounts[shipment.AccountId].AccountName,
        transporterName: props.accounts[shipment.Transporter].AccountName,
      });
    });
    return formattedInbounds;
  };
  const tableInbounds = {
    columns: props.columns || [
      {accessor: 'Direction', Header: 'Direction'},
      {accessor: 'Facility', Header: 'Facility'},
      {accessor: 'accountName', Header: 'Account'},
      {accessor: 'transporterName', Header: 'Transporter'},
      {accessor: 'ShipmentTime', Header: 'Time Arrived', type: 'datetime'},
      {accessor: 'ScheduledTime', Header: 'Scheduled Time', type: 'datetime'},
    ],
    sortInitial: [{id: 'ShipmentTime', desc: false}],
    data: formatInbounds() || [],
    paginationInitialSize: 10,
    paginationSizes: [5, 10, 20],
    actionsPerRow: [
      {
        icon: Search,
        tooltip: 'View Inbound Load',
        onClick: ({rowData}) => {
          if (rowData.Direction === 'Inbound') props.history.push(`/shipments/inbound/${rowData.id}`);
          else props.history.push(`/shipments/outbound/${rowData.id}`);
        },
      },
    ],
    paperProps: {
      style: {
        margin: '0px',
      },
    },
    title: {primary: props.title},
  };
  return (
    <TableWithPaper {...tableInbounds} />
  );
};

const mapStateToProps = state => {
  return {accounts: state.firestore.accounts};
};
InboundTable.propTypes = {
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      filter: PropTypes.string,
      dateFormat: PropTypes.string, // a valid moment format string
    }),
  ),
  defaultSort: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  trackingShipments: PropTypes.arrayOf(PropTypes.object).isRequired,
  accounts: PropTypes.object,
  history: PropTypes.object,
};

export default flowRight(
  connect(mapStateToProps),
  withRouter,
)(InboundTable);

/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import {Add, Delete} from '@material-ui/icons';
import {NewProcessedContainerModal, ConfirmDeleteModal} from './ProcessedContainerPanel/';
import {TableWithPaper} from 'components/';

const ProcessedContainerPanel = ({...props}) => {
  const [newProcessedContainerModalOpen, setNewProcessedContainerModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [selectedProcessedContainer, setSelectedProcessedContainer] = useState(null);

  const details = () => {
    if (!props.processedContainers.length) {
      return (
        <Paper style={{width: '100%', padding: '16px'}}>
          <Typography variant='h6' paragraph>Processed Containers</Typography>
          <Grid container justify='space-between'>
            <Grid item>
              <Typography>There are currently no processed containers on this process form. Please add one to begin.</Typography>
            </Grid>
            <Grid item>
              <Button {...core.addProcessedContainerButton}><Add style={{marginRight: '8px'}} />Add Processed Container</Button>
            </Grid>
          </Grid>
        </Paper>);
    }
    else {
      return (
        <TableWithPaper {...core.processedContainerTable} />
      );
    }
  };

  const core = {
    processedContainerTable: {
      data: props.processedContainers,
      columns: [
        {accessor: 'ScannedNumber', Header: '#', type: 'numeric', filterDisable: true},
        {accessor: 'ShortNo', Header: 'Short No.'},
        {accessor: 'Material', Header: 'Material'},
        {accessor: 'Flag', Header: 'Flag'},
        {accessor: 'NetWeight', Header: 'Net Weight', type: 'numeric', filterDisable: true},
        {accessor: 'RemainingWeight', Header: 'Remaining Net Weight', type: 'numeric', filterDisable: true},
        {accessor: 'ProcessStatus', Header: 'Process Status'},
      ],
      sortInitial: [{id: 'ScannedNumber', desc: false}],
      title: {
        primary: 'Processed Containers', secondary: 'Summary table of all containers that have been processed on this form',
      },
      paginationActive: props.printView ? false : true,
      actionsPerRow: [
        {
          icon: Delete,
          tooltip: 'Remove Container',
          hide: props.selectedProcessForm.Completed ? true : false,
          onClick: ({rowData}) => {
            setConfirmDeleteModalOpen(true);
            setSelectedProcessedContainer(rowData);
          },
        },
      ],
      actionsBar: [
        {
          icon: Add,
          buttonProps: {style: {display: props.selectedProcessForm.Completed ? 'none' : 'inline-flex'}},
          text: 'Add Processed Containers',
          onClick: () => setNewProcessedContainerModalOpen(true),
        },
      ],
      paperProps: {
        style: {
          margin: '0px',
        },
      },
    },
    newProcessedContainerModal: {
      open: newProcessedContainerModalOpen,
      close: () => setNewProcessedContainerModalOpen(false),
      selectedProcessForm: props.selectedProcessForm,
      processedContainers: props.processedContainers,
      createdYields: props.createdYields,
    },
    addProcessedContainerButton: {
      variant: 'outlined',
      onClick: () => setNewProcessedContainerModalOpen(true),
    },
    confirmDeleteModal: {
      open: confirmDeleteModalOpen,
      close: () => setConfirmDeleteModalOpen(false),
      selectedProcessForm: props.selectedProcessForm,
      selectedProcessedContainer,
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {details()}
      </Grid>
      {newProcessedContainerModalOpen && <NewProcessedContainerModal {...core.newProcessedContainerModal} />}
      {confirmDeleteModalOpen && <ConfirmDeleteModal {...core.confirmDeleteModal} />}
    </Grid>
  );
};

ProcessedContainerPanel.propTypes = {
  selectedProcessForm: PropTypes.object.isRequired,
  createdYields: PropTypes.arrayOf(PropTypes.object).isRequired,
  processedContainers: PropTypes.arrayOf(PropTypes.object),
  printView: PropTypes.bool.isRequired,
};

export default ProcessedContainerPanel;

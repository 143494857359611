/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
import React, {useState, useEffect, Fragment, useMemo, useCallback, useReducer} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Collapse,
  DialogContentText,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';
import {shortNumberGenerator} from '@kbi/utility-library';
import {Alert} from '@kbi/component-library';
import {SubmitConfirmation, DefaultModalButtons, containerCodes} from 'components/';
import {NewContainerForm, ExistingContainerForm} from './NewYieldContainerModal/';
import {Firestore} from 'config.js';
import {useSelector} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

const stageArray = ['basic', 'details', 'success'];
function reducer(state, action) {
  switch (action.type) {
  case 'value':
    return {...state, [action.field]: {...state[action.field], value: action.payload}};
  case 'error':
    return {...state, [action.field]: {...state[action.field], error: action.payload}};
  case 'setNewContainers':
    return {...state, newContainerArray: action.payload};
  case 'setExistingContainers':
    return {...state, existingContainerArray: action.payload};
  case 'formikEditContainer':
    return {...state, [action.key]: [
      ...state[action.key].slice(0, action.index),
      action.payload,
      ...state[action.key].slice(action.index + 1),
    ]};
  case 'stage':
    return {...state, stage: action.payload};
  case 'buttonLoading':
    return {...state, buttonLoading: action.payload};
  case 'submitting':
    return {...state, submitting: action.payload};
  case 'formError':
    return {...state, formError: action.payload};
  case 'getChildErrors':
    return {...state, getChildErrors: action.payload};
  case 'formikErrorArray':
    return {...state, formikErrorArray: [
      ...state.formikErrorArray.slice(0, action.index),
      action.payload,
      ...state.formikErrorArray.slice(action.index + 1),
    ]};
  case 'submissionArray':
    return {...state, submissionPromiseArray: [...state.submissionPromiseArray, action.payload]};
  case 'clearSubmissionArray':
    return {...state, submissionPromiseArray: []};
  default:
    throw new Error();
  }
}
const cleanState = {
  numberOfNewContainers: {
    value: '',
    display: '# of New Containers',
    error: '',
  },
  numberOfExistingContainers: {
    value: '',
    display: '# of Existing Containers',
    error: '',
  },
  defaultContainerCode: {
    value: '',
    display: 'Default Container Code',
    error: '',
  },
  defaultCodeType: {
    value: '',
    display: 'Default Code Type',
    error: '',
  },
  newContainerArray: [],
  existingContainerArray: [],
  submissionPromiseArray: [],
  formikErrorArray: [],
  getChildErrors: false,
  buttonLoading: false,
  stage: 0,
  submitting: false,
  formError: '',
};

// I am sorry if you have to deal with this modal. It is stupid complicated due to formik not handling more than ~20 fields.
// It uses an array of formik forms, each handling it's own errors, and passing them back up to the top level modal form.
const NewYieldContainerModal = props => {
  const [state, dispatch] = useReducer(reducer, cleanState);

  // yieldsContainersInUnit is populated by filtering all containers where facilityUnitRef === selectedProcessForm.facilityUnitRef
  // and if the form is not a mixed yield form, where the container material === the processform yield ref
  const [yieldContainersInUnit, setYieldContainersInUnit] = useState([]);
  const [selectableYields, setSelectableYields] = useState([]);

  const {types, containers, materials, facilityUnits} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (containers) {
      // grabs all containers in the current facility unit, where the material ref is a yield if form is mixed, otherwise any with the same yield as form
      let containersAllowedToSelect = containers.list.filter(container => container.FacilityUnitRef === props.selectedProcessForm.FacilityUnitRef &&
        (props.selectedProcessForm.YieldRef === 'mixed' ? materials.yieldList.find(yieldObj => yieldObj.MaterialId === container.MaterialRef) :
          container.MaterialRef === props.selectedProcessForm.YieldRef));

      // if there are already processedContainers, do this. otherwise this step can be skipped
      if (props.processedContainers) {
        // then remove any containers from the list that have already been entered as processed containers. this is to ensure a container does not end up
        // on both the list of processed containers, and the yield containers.
        containersAllowedToSelect = containersAllowedToSelect.filter(yieldCont => {
          let alreadyAProcessedContainer = false;
          props.processedContainers.forEach(processCont => {
            if (processCont.ShortNo === yieldCont.ShortNo) alreadyAProcessedContainer = true;
          });
          return !alreadyAProcessedContainer;
        });
      }
      setYieldContainersInUnit(containersAllowedToSelect);
    }
  }, [containers, materials, props.processedContainers, props.selectedProcessForm]);
  useEffect(() => {
    if (props.selectedProcessForm.YieldRef === 'mixed') {
      // eslint-disable-next-line array-callback-return
      setSelectableYields(materials.yieldList.filter((yieldObj, index) => {
        for (const sectionName in facilityUnits.ref[props.selectedProcessForm.FacilityUnitRef].AllowedItems) {
          // eslint-disable-next-line max-len
          if (facilityUnits.ref[props.selectedProcessForm.FacilityUnitRef].AllowedItems[sectionName].Yields.find(yieldRef => yieldRef === yieldObj.MaterialId)) {
            return true;
          }
          else {
            return false;
          }
        }
      }));
    }
    else {
      setSelectableYields(materials.yieldList);
    }
  }, [facilityUnits, materials, props.selectedProcessForm]);
  useEffect(() => {
    // this useEffect responds to the submissionPromiseArray changes. on submission, the formik forms submit, and return a promise. those all get put into the array
    if (state.submissionPromiseArray.length) {
      Promise.all(state.submissionPromiseArray).then(() => {
        // at this point, all the forms have finished their submission and validation process, and the modal can move forward
        // the next step is to get the errors that formik has assigned to fields based on validation.
        dispatch({type: 'getChildErrors', payload: true});
        dispatch({type: 'clearSubmissionArray'});
      });
    }
  }, [state.submissionPromiseArray]);

  // if duplicateShortNumbers is a truthy value, the submit button will be disabled.
  const [checkFlagMatch, duplicateShortNumbers] = useMemo(() => {
    // this functions as a useEffect, and a useState to track existing container errors
    const nonMatchedFlagContainers = []; // will be displayed with the flag warning
    const duplicatedContainers = []; // will be displayed with the duplicate container error
    const returnArray = []; // returned to checkFlagMatch and duplicateShortNumbers, so the component can render them in Alerts
    state.existingContainerArray.forEach((containerFields, containerIndex, self) => {
      // this conditional checks the container flags again the flag selected for the form. if they do not match, add a container to the list of problems
      if (containerFields.existingShortNo) {
        const flagName = containerFields.flag?.Name || '';
        if (flagName !== props.selectedProcessForm.Flag) {
          nonMatchedFlagContainers.push(containerFields.existingShortNo.ShortNo);
        }
      }

      // the next two loops are checking to make sure a contianer is only on the form once.
      // this one checks each container against the rest of the modal's containers, ensuring the user has only entered it within one formik form
      for (let checkIndex = containerIndex + 1; checkIndex < self.length; checkIndex++ ) {
        if (self[checkIndex].existingShortNo && self[checkIndex].existingShortNo?.ShortNo === containerFields.existingShortNo?.ShortNo) {
          // if there is a match, push the short number into the array
          duplicatedContainers.push(self[checkIndex].existingShortNo?.ShortNo);
        }
      }
      // this one checks against all previously created yields to ensure that the user does not enter the same container on two different modals
      props.createdYields.forEach(yieldDoc => {
        if (yieldDoc.CreatedShortNo === containerFields.existingShortNo?.ShortNo) {
          duplicatedContainers.push(yieldDoc.CreatedShortNo);
        }
      });
    });

    // if the array length is > 0, there is a flag warning. return the string to be rendered for all flag warnings.
    if (nonMatchedFlagContainers.length) {
      returnArray[0] = `The following containers' flags do not match the form's flag. Are you sure you want to continue? (${nonMatchedFlagContainers.join(', ')})`;
    }
    else returnArray[0] = '';

    // if the array length > 0, there is a duplicate container.
    if (duplicatedContainers.length) {
      // use a set to make all values of duplicate containers unique, returning the string to render the duplicate container error.
      const setOfShortNo = new Set(duplicatedContainers);
      returnArray[1] = `The following containers have been put on this process form multiple times. Please correct before continuing (${[...setOfShortNo].join(', ')})`;
    }
    else returnArray[1] = '';

    return returnArray;
  }, [props.createdYields, props.selectedProcessForm.Flag, state.existingContainerArray]);

  const createYieldForSubcollection = useCallback((values, newContainer, numberEntered) => {
    // returns a yield object that will be send to the selectedProcessForm document's Yields-Created subcollection
    const returnedYieldObject = {
      ContainerCode: values.containerCode,
      ContainerCodeType: values['codeType'],
      TareWeight: parseInt(values.tareWeight),
      Yield: {
        Name: values.yield.UnitDetails.MaterialName,
        Ref: values.yield.MaterialId,
      },
      Flag: values.flag?.Name || '',
      Type: {
        Name: values.type.TypeName,
        Ref: values.type.id,
      },
      NumberEntered: props.createdYields.length ?
        props.createdYields
          .sort((a, b) => a.NumberEntered > b.NumberEntered ? 1 : -1)[props.createdYields.length - 1].NumberEntered + numberEntered :
        numberEntered,
    };
    returnedYieldObject.NetWeight = parseInt(values.grossWeight) - returnedYieldObject.TareWeight;
    if (newContainer) {
      returnedYieldObject.ExistingContainer = false;
      returnedYieldObject.CreatedShortNo = values.newShortNo;
      // YieldedWeight is used to track the lbs of yield added to the container. if it is a new yield container, all its net weight is yieldedweight.
      returnedYieldObject.YieldedWeight = returnedYieldObject.NetWeight;
    }
    else {
      returnedYieldObject.ExistingContainer = true;
      returnedYieldObject.CreatedShortNo = values.existingShortNo.ShortNo;
      // if the container exists, then we subtract its original net weight
      returnedYieldObject.YieldedWeight = returnedYieldObject.NetWeight - values.existingShortNo.NetWeight;
    }
    returnedYieldObject.System = {
      CreatedBy: {Name: currentUser.displayName, Email: currentUser.email},
      CreatedOn: firebase.firestore.Timestamp.now(),
    };

    return returnedYieldObject;
  }, [currentUser, props.createdYields]);

  const createNewShortNo = useCallback(() => {
    // creates a short number, then checks it against all containers that have been created in order to verify its unique
    let newShortNumber;
    do {
      newShortNumber = shortNumberGenerator();
    } while (containers.ref[newShortNumber]);
    return newShortNumber;
  }, [containers]);

  const validateFirstCollapse = useCallback(() => {
    let validForm = true;
    if (state.stage === 0) {
      if (parseInt(state.numberOfNewContainers.value) < 0) {
        validForm = false;
        dispatch({type: 'error', field: 'numberOfNewContainers', payload: 'Number of New Containers must be positive.'});
      }
      if (parseInt(state.numberOfExistingContainers.value) < 0) {
        validForm = false;
        dispatch({type: 'error', field: 'numberOfExistingContainers', payload: 'Number of Existing Containers must be positive.'});
      }
      if ((parseInt(state.numberOfNewContainers.value) < 1 || isNaN(parseInt(state.numberOfNewContainers.value))) &&
       (parseInt(state.numberOfExistingContainers.value) < 1 || isNaN(parseInt(state.numberOfExistingContainers.value)))) {
        validForm = false;
        dispatch({type: 'error', field: 'numberOfNewContainers', payload: 'At least one container must be yielded.'});
        dispatch({type: 'error', field: 'numberOfExistingContainers', payload: 'At least one container must be yielded.'});
      }
      if (!state.defaultContainerCode.value && state.numberOfNewContainers.value > 0) {
        validForm = false;
        dispatch({type: 'error', field: 'defaultContainerCode', payload: 'Default Container Code is a required field.'});
      }
      if (state.defaultContainerCode.value &&
        containerCodes.find(codeArray => codeArray[0] === state.defaultContainerCode.value)[1].length &&
       !state.defaultCodeType.value) {
        validForm = false;
        dispatch({type: 'error', field: 'defaultCodeType', payload: 'This Container Code required a Code Type.'});
      }
      return validForm;
    }
  }, [state]);

  const beginSubmitProcess = useCallback(() => {
    if (state.stage === 0) {
      // the first collapse does not use formik. it must be validated, and then move to next stage
      if (validateFirstCollapse()) {
        dispatch({type: 'stage', payload: state.stage + 1});
      }
    }
    else {
      /* this block is here due to the form possibly being very large. Formik was having a problem with submission and rerendering
      * It would begin submitting, but the rerender would be very choppy and slow, and the submit button and forms would not disable before the form slowed down
      * Adding this extra step to the submission process makes sure the form becomes disabled properly, before the intense validation and submission run.
      */
      dispatch({type: 'buttonLoading', payload: true});
      dispatch({type: 'formError', payload: ''});
      setTimeout(() => {
        dispatch({type: 'submitting', payload: true});
      }, 200);
    }
  }, [state.stage, validateFirstCollapse]);

  const writeToFirestore = useCallback(() => {
    const batch = Firestore.batch();
    state.newContainerArray.forEach((newContainerFields, index) => {
      const newYieldRef = Firestore.collection('Tracking-Forms')
        .doc(props.selectedProcessForm.id)
        .collection('Created-Yields')
        .doc();
      const data = createYieldForSubcollection(newContainerFields, true, index + 1);
      batch.set(newYieldRef, data);
    });
    state.existingContainerArray.forEach((existingContainerFields, index) => {
      const newYieldRef = Firestore.collection('Tracking-Forms')
        .doc(props.selectedProcessForm.id)
        .collection('Created-Yields')
        .doc();
      const data = createYieldForSubcollection(existingContainerFields, false, state.newContainerArray.length + index + 1);
      batch.set(newYieldRef, data);
    });
    batch.commit().then(() => {
      dispatch({type: 'submitting', payload: false});
      dispatch({type: 'buttonLoading', payload: false});
      dispatch({type: 'stage', payload: state.stage + 1});
    }).catch(error => {
      dispatch({type: 'submitting', payload: false});
      dispatch({type: 'buttonLoading', payload: false});
      dispatch({type: 'formError', payload: 'There was an error during submission. Please try again.'});
    });
  }, [createYieldForSubcollection, props.selectedProcessForm.id, state.existingContainerArray, state.newContainerArray, state.stage]);

  useEffect(() => {
    // this will fire after the useEffect that handled the formik submissions. Once the form has grabbed the errors from the formik forms, this will run
    if (state.formikErrorArray.length) {
      // each formik form will return the number of errors within it. this loops over the array of numbers of errors for each form
      // if every form has 0 errors, then we can proceed with the submission, and begin the firestore write.
      if (state.formikErrorArray.every(errorNumber => errorNumber === 0 )) {
        writeToFirestore();
      }
      else {
        // otherwise, there are still form errors, and the form will revert back to the non-submitting stage.
        dispatch({type: 'submitting', payload: false});
        dispatch({type: 'buttonLoading', payload: false});
        dispatch({type: 'getChildErrors', payload: false});
      }
    }
    // writeToFirestore left out of dep array because it is not meant to trigger this useeffect. Only the update of state.formikErrorArray should trigger this.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.formikErrorArray]);

  const core = useMemo(() =>({
    dialog: {
      open: true,
      fullWidth: true,
      maxWidth: 'lg',
      scroll: 'body',
      transitionDuration: {exit: 0},
    },
    containerNoField: key => ({
      required: true,
      type: 'number',
      onChange: (e) => {
        dispatch({type: 'value', field: key, payload: e.target.value});
        dispatch({type: 'error', field: 'numberOfExistingContainers', payload: ''});
        dispatch({type: 'error', field: 'numberOfNewContainers', payload: ''});

        if (key === 'numberOfNewContainers') {
          const arrayOfNewContainers = [];
          for (let i = 0; i < parseInt(e.target.value); i++) {
            arrayOfNewContainers.push({
              newShortNo: createNewShortNo(),
              containerCode: state.defaultContainerCode.value,
              codeType: state.defaultCodeType.value,
              tareWeight: '',
              grossWeight: '',
              yield: props.selectedProcessForm.YieldRef === 'mixed' ? '' : materials.ref[props.selectedProcessForm.YieldRef],
              flag: props.selectedProcessForm.YieldRef === 'mixed' ?
                '' : materials.ref[props.selectedProcessForm.YieldRef].Flags.find(flag => flag.Name === props.selectedProcessForm.Flag),
              type: props.selectedProcessForm.YieldRef === 'mixed' ?
                '' : types[props.selectedProcessForm.YieldRef].find(type => type.id === props.selectedProcessForm.TypeRef),
            });
          }
          dispatch({type: 'setNewContainers', payload: arrayOfNewContainers});
        }
        else {
          const existingContainerArray = [];
          for (let i = 0; i < parseInt(e.target.value); i++) {
            existingContainerArray.push({
              existingShortNo: '',
              containerCode: '',
              codeType: '',
              tareWeight: '',
              grossWeight: '',
              yield: '',
              flag: '',
              type: '',
            });
          }
          dispatch({type: 'setExistingContainers', payload: existingContainerArray});
        }
      },
    }),
    textField: key => ({
      disabled: state.submitting,
      error: state[key].error ? true : false,
      fullWidth: true,
      helperText: state[key].error && state[key].error,
      label: state[key].display,
      margin: 'dense',
      value: state[key].value,
    }),
    defaultCodeTypeField: {
      required: state.defaultContainerCode.value && containerCodes.find(
        codeArray => codeArray[0] === state.defaultContainerCode.value,
      )?.[1].length ?
        true : false,
      disabled: !state.defaultContainerCode.value ||
        !containerCodes.find(codeArray => codeArray[0] === state.defaultContainerCode.value)?.[1].length ||
        state.submitting,
      onChange: e => {
        dispatch({type: 'value', field: 'defaultCodeType', payload: e.target.value});
        dispatch({type: 'error', field: 'defaultCodeType', payload: ''});

        const arrayOfNewContainers = state.newContainerArray.map(container => ({...container, codeType: e.target.value}));
        dispatch({type: 'setNewContainers', payload: arrayOfNewContainers});
      },
    },
    defaultContainerCodeField: {
      onChange: (e) => {
        dispatch({type: 'value', field: 'defaultContainerCode', payload: e.target.value});
        dispatch({type: 'error', field: 'defaultContainerCode', payload: ''});

        dispatch({type: 'value', field: 'defaultCodeType', payload: ''});
        dispatch({type: 'error', field: 'defaultCodeType', payload: ''});

        const arrayOfNewContainers = state.newContainerArray.map(container => ({...container, containerCode: e.target.value}));
        dispatch({type: 'setNewContainers', payload: arrayOfNewContainers});
      },
      required: state.newContainerArray.length ? true : false,
    },
    containerForm: {
      selectedProcessForm: props.selectedProcessForm,
      stage: state.stage,
      selectableYields,
      parentSubmitting: state.submitting,
      attemptSubmission: submissionPromise => {
        dispatch({type: 'submissionArray', payload: submissionPromise});
      },
      getChildErrors: state.getChildErrors,
    },
    submitConfirmation: {
      text: 'Yield container successfully created.',
      stage: stageArray[state.stage],
    },
  }), [createNewShortNo, materials.ref, props.selectedProcessForm, selectableYields, state, types]);

  const buttonProps = useMemo(() => ({
    submitButtonProps: {
      text: stageArray[state.stage] === 'details' ? 'Submit' : 'Next',
      disabled: Boolean(duplicateShortNumbers),
      onClick: () => beginSubmitProcess(),
    },
    stage: stageArray[state.stage],
    close: props.close,
    handleBack: () => dispatch({type: 'stage', payload: state.stage - 1}),
  }), [beginSubmitProcess, duplicateShortNumbers, props.close, state.stage]);

  return (
    <Dialog {...core.dialog}>
      <Fragment>
        {stageArray[state.stage] !== 'success' && <DialogTitle>Create Yield Containers</DialogTitle>}
        <DialogContent>
          <Collapse in={stageArray[state.stage] === 'basic'}>
            <DialogContentText>Select the starting properties of the yield containers that were created:</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={3}>
                <TextField {...core.textField('numberOfNewContainers')} {...core.containerNoField('numberOfNewContainers')} />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField {...core.textField('defaultContainerCode')} {...core.defaultContainerCodeField} select>
                  {containerCodes.map((codeArray, index) => {
                    return (<MenuItem value={codeArray[0]} key={index}>{codeArray[0]}</MenuItem>);
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField {...core.textField('defaultCodeType')} {...core.defaultCodeTypeField} select>
                  {state.defaultContainerCode.value ?
                    containerCodes.find(codeArray => codeArray[0] === state.defaultContainerCode.value)[1]
                      .map((code, index) => {
                        return (<MenuItem value={code} key={index}>{code}</MenuItem>);
                      }) : []}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField {...core.textField('numberOfExistingContainers')} {...core.containerNoField('numberOfExistingContainers')} />
              </Grid>
            </Grid>
          </Collapse>
          {stageArray[state.stage] === 'details' && <Collapse in={stageArray[state.stage] === 'details'}>
            <DialogContentText>Fill out the information for the yielded containers:</DialogContentText>
            <Grid container>
              {state.newContainerArray.length ?
                (<Fragment>
                  <Grid item xs={12}>
                    <Typography variant='h6'>New Containers:</Typography>
                  </Grid>
                  {state.newContainerArray.map((valuesObj, index) => (
                    <NewContainerForm key={index} index={index} values={valuesObj} {...core.containerForm}
                      updateState={(key, payload) => dispatch({type: 'formikEditContainer', key, index, payload})}
                      setErrorLength={number => dispatch({type: 'formikErrorArray', index, payload: number})}
                    />
                  ))}
                </Fragment>
                ) : null
              }
              {state.existingContainerArray.length ?
                (<Fragment>
                  <Grid item xs={12}>
                    <Typography variant='h6'>Existing Containers:</Typography>
                  </Grid>
                  {state.existingContainerArray.map((valuesObj, index) => (
                    <ExistingContainerForm key={index} index={index} values={valuesObj} {...core.containerForm}
                      updateState={(key, payload) => dispatch({type: 'formikEditContainer', key, index, payload})}
                      setErrorLength={number => dispatch({type: 'formikErrorArray', index, payload: number})}
                      yieldContainersInUnit={yieldContainersInUnit}
                    />
                  ))}
                </Fragment>
                ) : null
              }
            </Grid>
            <Alert in={Boolean(duplicateShortNumbers)} text={duplicateShortNumbers} severity='error' />
            <Alert in={Boolean(checkFlagMatch)} text={checkFlagMatch} severity='warning' />
          </Collapse>}
          <SubmitConfirmation {...core.submitConfirmation} />
          <Alert in={Boolean(state.formError)} text={state.formError} severity='warning' />
        </DialogContent>
        <DefaultModalButtons {...buttonProps} isSubmitting={state.buttonLoading} />
      </Fragment>
    </Dialog >);
};

NewYieldContainerModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedProcessForm: PropTypes.object.isRequired,
  processedContainers: PropTypes.arrayOf(PropTypes.object),
  createdYields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default NewYieldContainerModal;

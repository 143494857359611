import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {DefaultLabelIcon, DynamicIcon} from '../../components/';
import {Typography, Grid} from '@material-ui/core';
import {useSelector} from 'react-redux';
import moment from 'moment';

const PrintLabels = props => {
  const {listOfShippingDocuments} = props;
  const {locations, accounts, materials} = useSelector(state => state.firestore);

  const core = useMemo(() => {
    return {
      text: {style: {fontSize: '14px', fontWeight: 600}},
      newLabel: (index, array) => ({
        className: index === 0 ? 'firstLabel' : '',
        container: true,
        alignItems: 'center',
        justify: 'center',
        style:
          index === array.length - 1 ?
            {width: '380px', height: '262px'} :
            {pageBreakAfter: 'always', width: '380px', height: '262px'},
      }),
      spaceAround: {
        container: true,
        direction: 'row',
        justify: 'space-between',
        alignItems: 'stretch',
        item: true,
        xs: 12,
      },
      row: {
        item: true,
        container: true,
        direction: 'column',
      },
    };
  }, []);
  const combineLineItemsOfInbound = useCallback(() => {
    if (!listOfShippingDocuments) return;
    const arrayOfAllLineItems = [];
    listOfShippingDocuments.forEach(shippingDoc => {
      shippingDoc.LineItems.forEach(lineItem => {
        arrayOfAllLineItems.push({...lineItem, shippingDoc});
      });
    });
    return arrayOfAllLineItems;
  }, [listOfShippingDocuments]);
  const limitStringToOneLine = useCallback(string => {
    let reducedString = string;
    if (reducedString.length > 25) reducedString = reducedString.substring(0, 24) + '...';
    return reducedString;
  }, []);

  const createLabels = useCallback(listOfLineItems => {
    if (!listOfLineItems) return;
    const nonRejectedListOfLineItems = listOfLineItems.filter(item => {
      return item.ContainerRef;
    });

    return nonRejectedListOfLineItems.map((item, index, array) => {
      if (!item.AcceptedMaterial) return null;
      let pathOfIcon;
      let nameOfIcon;
      const flagObject = materials.ref[item.AcceptedMaterial.Ref].Flags.find(flag => flag.Name === item.Flag);
      if (flagObject) {
        pathOfIcon = flagObject.Path;
        nameOfIcon = flagObject.Name;
      }
      else {
        nameOfIcon = 'None';
      }

      return (
        <Grid key={index} {...core.newLabel(index, array)}>
          <Grid {...core.spaceAround}>
            <Grid container item xs={12} spacing={2} style={{marginBottom: '5px'}}>
              <Grid container item xs={4} justify='center'>
                {flagObject ?
                  <DynamicIcon svgStringProp={pathOfIcon} style={{fontSize: '6em'}} /> : <DefaultLabelIcon style={{fontSize: '6em'}} />}
              </Grid>
              <Grid container item xs={8}>
                <Grid item xs={12}>
                  <Typography {...core.text}>{item.AcceptedMaterial.Name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>{item.AcceptedType.Name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>Acct: {limitStringToOneLine(accounts[props.selectedInbound.AccountId].AccountName)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>
                    Gen:{' '}
                    {
                      limitStringToOneLine(locations[props.selectedInbound.AccountId].find(
                        location => location.id === item.shippingDoc.GeneratorRef,
                      ).LocationName)
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography {...core.text}>Flag: {nameOfIcon}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid {...core.row} xs={4} style={{borderTop: '1px solid black', paddingTop: '7px'}} alignItems='center'>
              <Grid item>
                <QRCode size={95} value={item.ContainerRef} />
              </Grid>
            </Grid>
            <Grid {...core.row} xs={8} style={{borderTop: '1px solid black', paddingTop: '7px'}}>
              <Grid item>
                <Typography {...core.text}>INBOUND</Typography>
              </Grid>
              <Grid item>
                <Typography {...core.text}>SN: {item.ContainerRef}</Typography>
              </Grid>
              <Grid item>
                <Typography {...core.text}>
                  Start Date: {moment(props.selectedInbound.ShipmentTime).format('MM/DD/YY')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography {...core.text}>
                  SD: {item.shippingDoc.DocumentNumber} ({item.PieceNumber})
                </Typography>
              </Grid>
              <Grid item container>
                <Grid item xs={6}>
                  <Typography {...core.text}>GW: {item.GrossWeight} lbs</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography {...core.text}>NW: {item.NetWeight} lbs</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }, [accounts, core, limitStringToOneLine, locations, materials.ref, props.selectedInbound.AccountId, props.selectedInbound.ShipmentTime]);

  if (
    !listOfShippingDocuments ||
    !listOfShippingDocuments.length ||
    listOfShippingDocuments[0].LineItems[0].ContainerRef === undefined
  ) {
    // this will be true normally on the first rerender of the inbound details view,
    // where the container refs havent been updated, and the qr code will return an error
    return null;
  }

  return <div className='printOnlyWrapper'>{createLabels(combineLineItemsOfInbound())}</div>;
};

PrintLabels.propTypes = {
  listOfShippingDocuments: PropTypes.arrayOf(PropTypes.object),
  selectedInbound: PropTypes.object.isRequired,
};

export default PrintLabels;

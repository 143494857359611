import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Formik, Alert, Collapse} from '@kbi/component-library';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import {connect} from 'react-redux';
import * as yup from 'yup';
import moment from 'moment';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
const {FormikForm, SubmitButton, FormButton, AutoCompleteObject, validateAutoObject, WeightField} = Formik;
validateAutoObject();

const stageArray = ['basic', 'success'];

class NewConsolidatedContainerModal extends React.Component {
  state = {
    stage: 0,
    formError: '',
    formWarning: '',
    selectableContainers: this.determineSelectableContainers()
      .filter(container => container.ShortNo !== this.props.selectedConsolidationForm.ConsolidationContainer),
    consolidatedContainerRef: Firestore.collection('Tracking-Forms').doc(this.props.selectedConsolidationForm.id).collection('Consolidated-Containers').doc(),
  }
  validationSchema = yup.object().shape({
    container: yup.object().nullable().exists('Consolidated Container is a required field.'),
    weightConsolidated: yup.number().required('Consolidated Weight is a required field.').test({
      name: 'less than',
      test: function(value) {
        return this.parent.container && value && value <= this.parent.container.NetWeight ? true : false;
      },
      message: 'Consolidated Weight must be less than or equal to the initial weight.',
    }),
  })
  determineSelectableContainers() {
    // if the consolidation form marked as mixed material, then anything can go inside
    if (this.props.selectedConsolidationForm.MixedLoad) {
      return this.props.containers.list;
    }
    else {
      // otherwise the material must match
      return this.props.containers.list
        .filter(container => container.MaterialRef === this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].MaterialRef);
    }
  }
  handleSubmit(values, actions) {
    this.setState({formWarning: ''});
    const [arrayOfItemsNotConsolidated, arrayOfItemsToConsolidate] = this.createNewInventoryOfContainer(values);
    const firestoreBatch = Firestore.batch();
    [...arrayOfItemsNotConsolidated, ...arrayOfItemsToConsolidate].forEach(item => {
      // loop over each of the items and send an update
      // the items were created in this.createNewInventoryOfContainer
      const {id, ...otherProps} = item;
      firestoreBatch.set(Firestore.collection('Tracking-Inventory').doc(id), otherProps);

      // add form history to each item
      firestoreBatch.set(Firestore.collection('Tracking-Inventory').doc(id).collection('Form-History').doc(), this.createFormHistoryData(otherProps, values));
    });

    // update the consolidated container
    const originalContainerRef = Firestore.collection('Tracking-Containers').doc(values.container.ShortNo);
    firestoreBatch.update(originalContainerRef, this.updateOriginalContainerData(values, arrayOfItemsNotConsolidated));

    // update the container that the form is refering to
    const consolidationContainerRef = Firestore.collection('Tracking-Containers').doc(this.props.selectedConsolidationForm.ConsolidationContainer);
    firestoreBatch.update(consolidationContainerRef, this.createConsolidationContainerData(values, arrayOfItemsToConsolidate));

    // add an item to the form subcollection to track the consolidate. this will be used in data display as well as the delete process
    firestoreBatch.set(this.state.consolidatedContainerRef,
      this.createConsolidatedContainerData(values, [...arrayOfItemsNotConsolidated, ...arrayOfItemsToConsolidate]),
    );

    firestoreBatch.commit().then(() => {
      actions.setSubmitting(false);
      this.setState({stage: this.state.stage + 1});
    }).catch(error => {
      actions.setSubmitting(false);
      this.setState({formError: 'There was an error during submission. Please try again.'});
    });
  }
  createNewInventoryOfContainer(values) {
    // map over the array of item ids and assign the item docs to arrayOfInventoryItems
    let arrayOfInventoryItems = values.container.InventoryItems.map(itemId => ({
      ...this.props.inventoryItems.ref[itemId],
      Billable: true,
    }));
    // the first index will start at the end of the array.
    let indexToSplitAt = arrayOfInventoryItems.length - 1;
    // if the weight consilidated is not 100%, enter if
    if (parseInt(values.weightConsolidated) !== values.container.NetWeight) {
      let weightAccountedFor = 0;
      // begin itterating through the array of inventory items in the consolidated container starting at the back
      for (let itemIndex = arrayOfInventoryItems.length - 1; itemIndex >= 0; itemIndex--) {
        // if the weight is exact, break out of loop
        if (weightAccountedFor === parseInt(values.weightConsolidated)) {
          indexToSplitAt = itemIndex;
          break;
          // otherwise if the current item will push the weight past the amount consolidated, split the item
        }
        else if (weightAccountedFor + arrayOfInventoryItems[itemIndex].Weight > parseInt(values.weightConsolidated) ||
          (weightAccountedFor < parseInt(values.weightConsolidated) && itemIndex === 0)) {
          const itemToBeSplit = arrayOfInventoryItems[itemIndex];
          indexToSplitAt = itemIndex; // assign the index
          // split the item
          const arrayOfNewItems = [
            {
              ...itemToBeSplit,
              Weight: itemToBeSplit.Weight - (parseInt(values.weightConsolidated) - weightAccountedFor),
            },
            {
              ...itemToBeSplit,
              id: Firestore.collection('Tracking-Inventory').doc().id,
              Weight: parseInt(values.weightConsolidated) - weightAccountedFor,
            },
          ];
          // insert the new items back into the array
          arrayOfInventoryItems = [...arrayOfInventoryItems.slice(0, itemIndex), ...arrayOfNewItems, ...arrayOfInventoryItems.slice(itemIndex + 1)];
          break;
          // otherwise, the weight is still under the consolidated weight, and take the entire item
        }
        else weightAccountedFor += arrayOfInventoryItems[itemIndex].Weight;
      }
    }
    else indexToSplitAt = -1;
    // return both the array of items not consolidated, as well as the list of items that will be consolidated.
    return [
      arrayOfInventoryItems.slice(0, indexToSplitAt + 1),
      arrayOfInventoryItems.slice(indexToSplitAt + 1).map(item => ({
        ...item,
        ContainerRef: this.props.selectedConsolidationForm.ConsolidationContainer,
        FormHistory: [...item.FormHistory, this.props.selectedConsolidationForm.id],
      })),
    ];
  }
  createConsolidatedContainerData(values, arrayOfItemsInContainer) {
    return {
      NetWeight: values.container.NetWeight,
      InventoryItems: arrayOfItemsInContainer.map(item => item.id),
      OriginalShortNo: values.container.ShortNo,
      WeightConsolidated: parseInt(values.weightConsolidated),
      FacilityUnitRef: values.container.FacilityUnitRef,
      Flag: values.container.Flag,
      MaterialRef: values.container.MaterialRef,
      System: {
        CreatedBy: this.props.currentUser.displayName,
        CreatedOn: new Date(),
      },
    };
  }
  createConsolidationContainerData(values, arrayOfItemsToConsolidate) {
    // determines the oldest item in the container and sets it as the container AccumulationStartDate
    let oldestDate = moment(arrayOfItemsToConsolidate[0].AccumulationStartDate.toDate());
    [...arrayOfItemsToConsolidate,
      ...this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].InventoryItems,
    ].forEach(itemRef => {
      const inventoryRef = typeof itemRef === 'string' ? this.props.inventoryItems.ref[itemRef] : itemRef;
      if (moment(inventoryRef.AccumulationStartDate.toDate()).isBefore(oldestDate)) {
        oldestDate = moment(inventoryRef.AccumulationStartDate.toDate());
      }
    });
    return {
      AccumulationStartDate: oldestDate.toDate(),
      InventoryItems: firebase.firestore.FieldValue.arrayUnion(...arrayOfItemsToConsolidate.map(item => item.id)),
      NetWeight: this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].NetWeight + parseInt(values.weightConsolidated),
    };
  }
  updateOriginalContainerData(values, arrayOfItemsNotConsolidated) {
    // if the consolidate was 100%, the container will be set to inactive.
    if (values.container.NetWeight - parseInt(values.weightConsolidated) === 0) {
      return {
        InventoryItems: [],
        Active: false,
        FacilityUnitRef: null,
        Flag: null,
        MaterialRef: null,
        NetWeight: null,
        AccumulationStartDate: null,
      };
      // otherwise the container will have its weight and inventory item list adjusted
    }
    else {
      // find out what the current oldest inventory item that will be within the container.
      let oldestDate = moment(arrayOfItemsNotConsolidated[0].AccumulationStartDate.toDate());
      arrayOfItemsNotConsolidated.forEach(itemDoc => {
        if (moment(itemDoc.AccumulationStartDate.toDate()).isBefore(oldestDate)) {
          oldestDate = moment(itemDoc.AccumulationStartDate.toDate());
        }
      });
      return {
        AccumulationStartDate: oldestDate.toDate(),
        InventoryItems: arrayOfItemsNotConsolidated.map(item => item.id),
        NetWeight: values.container.NetWeight - parseInt(values.weightConsolidated),
      };
    }
  }
  createFormHistoryData(itemData, values) {
    return {
      Date: new Date(),
      FormId: this.props.selectedConsolidationForm.id,
      SubcollectionId: this.state.consolidatedContainerRef,
      FormType: 'Consolidation',
      StartContainer: values.container.ShortNo,
      StartMaterial: itemData.Material.Name,
      StartType: itemData.Type.Name,
      StartWeight: itemData.Weight,
      StartFlag: itemData.Flag,
      EndContainer: this.props.selectedConsolidationForm.ConsolidationContainer,
      EndMaterial: itemData.Material.Name,
      EndType: itemData.Type.Name,
      EndWeight: itemData.Weight,
      EndFlag: itemData.Flag,
    };
  }
  render() {
    const core = {
      dialog: {
        open: true,
        maxWidth: 'md',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      formik: {
        initialValues: {
          container: '',
          weightConsolidated: '',
        },
        onSubmit: this.handleSubmit.bind(this),
        validationSchema: this.validationSchema,
      },
      submitConfirmation: {
        text: 'Container successfully added to consolidation form.',
        stage: stageArray[this.state.stage],
      },
      cancelButton: {
        onClick: this.props.close,
        color: 'secondary',
        variant: 'text',
      },
      closeButton: {
        onClick: this.props.close,
        color: 'primary',
      },
      textField: (value) => ({
        margin: 'dense',
        fullWidth: true,
        value: value || value === 0 ? value : '',
        disabled: true,
      }),
      containerAutocomplete: {
        name: 'container',
        label: 'Consolidated Container',
        options: this.state.selectableContainers,
        optionKey: 'ShortNo',
        onChange: ({form, field}) => {
          if (field.value && field.value.Flag !== this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].Flag) {
            this.setState({
              // eslint-disable-next-line max-len
              formWarning: `The flag of the selected container (${field.value.Flag || ''}) does not match the consolidation form container's flag (${this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer].Flag || 'None'}). Only continue if you are sure these flags are compatible, otherwise please contact a supervisor.`,
            });
          }
          else this.setState({formWarning: ''});
          // this is used to set the weight consolidated to 100% of weight if the container is an inbound container
          if (field.value?.InboundContainer) {
            form.setFieldValue('weightConsolidated', field.value.NetWeight);
          }
        },
      },
    };

    return (
      <Dialog {...core.dialog}>
        <FormikForm {...core.formik}>
          {({values}) => (
            <Fragment>
              {stageArray[this.state.stage] !== 'success' && <DialogTitle>Add Container to the Consolidation</DialogTitle>}
              <DialogContent>
                <Collapse in={stageArray[this.state.stage] === 'basic'}>
                  <DialogContentText>Please select a container, and the weight, that you are consolidating:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <AutoCompleteObject {...core.containerAutocomplete} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label='Material'
                        {...core.textField(this.props.materials.ref[values.container?.MaterialRef]?.UnitDetails.MaterialName)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label='Flag' {...core.textField(values.container?.Flag)} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label='Initial Weight (Net)' {...core.textField(values.container?.NetWeight)} />
                    </Grid>
                    <Grid item xs={4}>
                      <WeightField name='weightConsolidated' label='Weight Consolidated (Net)' decimal={0} disabled={Boolean(values.container?.InboundContainer)} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label='Final Weight (Net)'
                        {...core.textField(values.container?.NetWeight - parseInt(values.weightConsolidated))}
                      />
                    </Grid>
                  </Grid>
                  <Alert in={Boolean(values.container?.InboundContainer)} severity='warning' text='This container is an Inbound Container, you must consolidate 100% of the containers weight.' />
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
                <Alert in={Boolean(this.state.formWarning)} severity='warning' text={this.state.formWarning} />
              </DialogContent>
              {stageArray[this.state.stage] === 'success' ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <Button {...core.closeButton}>Close</Button>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton {...core.cancelButton}>Cancel</FormButton>
                  <SubmitButton variant='text'>
                    Submit
                  </SubmitButton>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  materials: state.firestore.materials,
  containers: state.firestore.containers,
  inventoryItems: state.firestore.inventoryItems,
  currentUser: state.auth.currentUser,
});
NewConsolidatedContainerModal.propTypes = {
  close: PropTypes.func.isRequired,
  materials: PropTypes.shape({
    materialList: PropTypes.arrayOf(PropTypes.object).isRequired,
    yieldList: PropTypes.arrayOf(PropTypes.object).isRequired,
    processList: PropTypes.arrayOf(PropTypes.object).isRequired,
    inboundList: PropTypes.arrayOf(PropTypes.object).isRequired,
    kbiGeneratedList: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  containers: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  inventoryItems: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  currentUser: PropTypes.object.isRequired,
  selectedConsolidationForm: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NewConsolidatedContainerModal);

/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@material-ui/core';

const ContainerIcon = props => {
  return (
    <SvgIcon viewBox='0 0 64 64' {...props}>
      <g id='Barrel-7' data-name='Barrel'><path d='m56 7a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-48a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h2v22h-2a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h2v22h-2a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h48a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-2v-22h2a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-2v-22zm-47-4h46v2h-46zm46 58h-46v-2h46zm-3-4h-40v-22h40zm3-24h-46v-2h46zm-3-4h-40v-22h40z' /><path d='m36 55h9a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z' /><path d='m48 55h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z' /><path d='m20 19h24a1 1 0 0 0 1-1v-6a1 1 0 0 0 -1-1h-24a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1zm1-6h22v4h-22z' /><path d='m40 20h-16a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2z' /><path d='m35 23h-6a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2z' /></g>
    </SvgIcon>);
};

export default ContainerIcon;

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Formik} from '@kbi/component-library';
const {DateField, TextField, SelectField, AutoCompleteObject} = Formik;

const InventoryItemsForm = ({values, toUpper}) => {
  const {accounts, locations, materials, types} = useSelector(reduxState => reduxState.firestore);

  const core = useMemo(() => ({
    accountAutocomplete: {
      name: 'account',
      label: 'Account',
      loading: !accounts,
      options: accounts ? Object.values(accounts) : [],
      optionKey: 'AccountName',
      onChange: ({form, field}) => {
        form.setFieldValue('generator', '');
      },
    },
    generatorAutocomplete: {
      name: 'generator',
      label: 'Generator',
      loading: !values.account,
      loadingText: 'Select an account to populate',
      options: values.account ? locations[values.account.AccountId].sort((a, b) => (a.LocationName < b.LocationName ? 1 : -1)) : [],
      optionKey: 'LocationName',
    },
    materialAutocomplete: {
      name: 'material',
      label: 'Material',
      loading: !materials,
      options: materials?.completeList || [],
      optionKey: 'UnitDetails.MaterialName',
      onChange: ({form, field}) => {
        form.setFieldValue('materialType', '');
      },
    },
    typeAutocomplete: {
      name: 'materialType',
      label: 'Type',
      loading: !values.material,
      loadingText: 'Select a material to populate',
      options: values.material ? types[values.material.MaterialId] : [],
      optionKey: 'TypeName',
    },
  }), [accounts, locations, materials, types, values.account, values.material]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.accountAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.generatorAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.materialAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.typeAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='startDate' label='Start Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='endDate' label='End Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='inboundId' label='Inbound Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='incomingPieceNo' label='Incoming Piece Number' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='shippingDocumentNumber' label='Shipping Document Number' onBlur={() => toUpper('shippingDocumentNumber')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='outboundId' label='Outbound Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='purchaseOrderNumber' label='Purchase Order No.' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='formId' label='Form Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField name='manifested' label='Manifested?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField name='billable' label='Billable?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};

InventoryItemsForm.propTypes = {
  values: PropTypes.shape({
    material: null,
    materialType: null,
    shippingDocumentNumber: null,
    account: null,
    generator: null,
    containerShortNo: null,
    startDate: null,
    endDate: null,
    purchaseOrderNumber: null,
    inboundId: null,
    outboundId: null,
    formId: null,
  }).isRequired,
  toUpper: PropTypes.func.isRequired,
};

export default InventoryItemsForm;

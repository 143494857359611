import React, {Fragment, useState, useEffect, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, withStyles, Paper, List, LinearProgress, ListItem, Chip} from '@material-ui/core';
import {Send, Edit, Print, ImportExport, Check} from '@material-ui/icons';
import {NewYieldPanel, ProcessedContainerPanel} from '../panels/';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
import moment from 'moment';
import {AssemblyIcon, MovementFormModal, MarkAsInvoicedModal, BackButton, DetailsFieldList, DetailsTitle} from 'components/';
import {MarkAsCompleteModal, ProcessedLabels} from './ProcessFormDetails/';
import {NewProcessFormModal} from './Production/';
import {Firestore} from 'config.js';
import {exportToCSV} from '@kbi/utility-library';
import {useHistory} from 'react-router-dom';
import {useTypes, useProcessForms, useMaterials, useFacilityUnits, useInventory, useContainers} from 'hooks';

const ProcessFormDetails = props => {
  const {classes} = props;
  const [selectedProcessForm, setSelectedProcessForm] = useState(null);
  const [listOfContainersToPrint, setListOfContainersToPrint] = useState(null);
  const [movementFormModalOpen, setMovementFormModalOpen] = useState(false);
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);
  const [markAsInvoicedModalOpen, setMarkAsInvoicedModalOpen] = useState(false);
  const [processFormModalOpen, setProcessFormModalOpen] = useState(false);
  const [createdYields, setCreatedYields] = useState(null);
  const [processedContainers, setProcessedContainers] = useState(null);
  const [printView, setPrintView] = useState(false);

  const history = useHistory();

  // setting up redux listeners
  const types = useTypes();
  const processForms = useProcessForms();
  const materials = useMaterials();
  const facilityUnits = useFacilityUnits();
  useInventory();
  useContainers();

  useEffect(() => {
    if (selectedProcessForm) {
      const createdYieldListener = Firestore.collection('Tracking-Forms')
        .doc(selectedProcessForm.id)
        .collection('Created-Yields').onSnapshot(snap => {
          const arrayOfYields = [];
          snap.forEach(doc => {
            const docData = {...doc.data(), id: doc.id};
            arrayOfYields.push(docData);
          });
          setCreatedYields(arrayOfYields);
        });
      return () => {
        createdYieldListener();
      };
    }
  }, [selectedProcessForm]);
  useEffect(() => {
    if (selectedProcessForm) {
      const processedContainersListener = Firestore.collection('Tracking-Forms')
        .doc(selectedProcessForm.id)
        .collection('Processed-Containers').onSnapshot(snap => {
          const arrayOfContainers = [];
          snap.forEach(doc => {
            const docData = {...doc.data(), id: doc.id};
            arrayOfContainers.push(docData);
          });
          setProcessedContainers(arrayOfContainers);
        });
      return () => {
        processedContainersListener();
      };
    }
  }, [selectedProcessForm]);
  useEffect(() => {
    if (processForms?.ref[props.match.params.ProcessFormId]) {
      const matchedProductionItem = processForms.ref[props.match.params.ProcessFormId];
      if (!matchedProductionItem) {
        return;
      }
      setSelectedProcessForm(matchedProductionItem);
    }
    else {
      Firestore.collection('Tracking-Forms').doc(props.match.params.ProcessFormId).get().then(doc => {
        setSelectedProcessForm({...doc.data(), id: doc.id, Date: doc.data().Date.toDate()});
      });
    }
  }, [processForms, props.history, props.match.params.ProcessFormId]);
  useEffect(() => {
    if (listOfContainersToPrint) {
      window.print();
      setListOfContainersToPrint(null);
    }
  }, [listOfContainersToPrint]);
  useEffect(() => {
    if (printView) {
      window.print();
      setPrintView(false);
    }
  }, [printView]);

  const getInventorySummary = useCallback(() => {
    return Firestore.collection('Tracking-Forms').doc(selectedProcessForm.id).collection('Inventory-Summary').get();
  }, [selectedProcessForm]);
  const showSubtitle = useCallback(() => {
    if (selectedProcessForm.Flag) {
      return {subtitle: `Flag: ${selectedProcessForm?.Flag}`};
    }
    else return {};
  }, [selectedProcessForm]);

  const core = useMemo(() => ({
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    markAsCompleteButton: {
      variant: 'outlined',
      color: 'secondary',
      fullWidth: true,
      disabled: !createdYields || !createdYields.length || !processedContainers || !processedContainers.length,
      onClick: () => setMarkAsCompleteModalOpen(true),
    },
    createReportButton: {
      variant: 'outlined',
      className: 'hidePrint',
      fullWidth: true,
      onClick: () => {
        history.push(`/reports/inventoryItems/?formId=${selectedProcessForm.id}`);
      },
    },
    movementFormButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => {
        setMovementFormModalOpen(true);
      },
    },
    printButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => {
        setPrintView(true);
      },
    },
    exportButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: async () => {
        if (createdYields && processedContainers) {
          const itemSnap = await getInventorySummary();
          const itemData = itemSnap.docs.map(doc => ({...doc.data()}));
          itemData.sort((a, b) => a.Yield ? 1 : -1);
          const columns = [
            {accessor: 'ContainerRef', Header: 'Container'},
            {accessor: 'Material', Header: 'Material'},
            {accessor: 'Type', Header: 'Type'},
            {accessor: 'Weight', Header: 'Weight', type: 'numeric'},
            {accessor: 'Yield', Header: 'Yield?', type: 'boolean'},
            {accessor: 'Flag', Header: 'Flag'},
          ];
          exportToCSV(itemData, columns, `Process Form_${selectedProcessForm?.id}.csv`);
        }
      },
    },
    markAsInvoicedButton: {
      onClick: () => setMarkAsInvoicedModalOpen(true),
      style: {marginLeft: '8px'},
      variant: 'outlined',
      size: 'small',
    },
    markAsInvoicedModal: {
      close: () => setMarkAsInvoicedModalOpen(false),
      documentType: 'Process',
      selectedProcessForm,
    },
    editProcessFormButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => {
        setProcessFormModalOpen(true);
      },
    },
    editProcessFormModal: {
      open: processFormModalOpen,
      selectedProcessForm,
      close: () => setProcessFormModalOpen(false),
    },
    movementFormModal: {
      open: movementFormModalOpen,
      defaultFacility: selectedProcessForm?.Facility,
      defaultUnit: selectedProcessForm?.FacilityUnitRef,
      close: () => setMovementFormModalOpen(false),
    },
    newYieldPanelProps: {
      createdYields,
      processedContainers,
      selectedProcessForm,
      printView,
      setLabelsToPrint: container => setListOfContainersToPrint(container),
    },
    processedContainerPanel: {
      selectedProcessForm,
      processedContainers,
      createdYields,
      printView,
    },
    markAsCompleteModal: {
      open: markAsCompleteModalOpen,
      close: () => setMarkAsCompleteModalOpen(false),
      selectedProcessForm,
      createdYields,
      processedContainers,
    },
  // eslint-disable-next-line max-len
  }), [classes.menuDividers, classes.menuPrimaryText, classes.paperHeader, createdYields, getInventorySummary, history, markAsCompleteModalOpen, movementFormModalOpen, printView, processFormModalOpen, processedContainers, selectedProcessForm]);

  const displayNewYieldPanel = useCallback(() => {
    if (!createdYields) {
      return <LinearProgress />;
    }
    else {
      return (
        <NewYieldPanel {...core.newYieldPanelProps} />
      );
    }
  }, [core.newYieldPanelProps, createdYields]);
  const displayInvoicedStatus = useMemo(() => {
    if (selectedProcessForm?.AccountingId) {
      return <Chip label={`Invoiced - Id:${selectedProcessForm.AccountingId}`} variant='outlined' color='primary' style={{marginLeft: '8px'}} />;
    }
    else {
      return (
        <Button {...core.markAsInvoicedButton}>
          <Check className={classes.extendedIcon} />Mark as Invoiced
        </Button>);
    }
  }, [classes.extendedIcon, core.markAsInvoicedButton, selectedProcessForm]);

  if (!selectedProcessForm || !materials) return <LinearProgress />;

  return (
    <Fragment>
      <div className={listOfContainersToPrint ? 'hidePrint' : ''}>
        <div className='sideScrollingContainer hidePrint'>
          <BackButton />
          {!selectedProcessForm.Completed &&
            <Fragment>
              {!processedContainers?.length && !createdYields?.length ?
                <Button {...core.editProcessFormButton}><Edit className={classes.extendedIcon} />Edit Process Form</Button> : null}
              <Button {...core.movementFormButton}><Send className={classes.extendedIcon} />Create Movement Form</Button>
            </Fragment>
          }
          <Button {...core.printButton}><Print className={classes.extendedIcon} />Print Form</Button>
          <Button {...core.exportButton}><ImportExport className={classes.extendedIcon} />Export to CSV</Button>
          {selectedProcessForm.Completed && displayInvoicedStatus}
        </div>
        <Grid className={listOfContainersToPrint ? 'hidePrint' : ''} container spacing={5}
          style={{marginTop: '8px'}}
        >
          <Grid item xs={12} md={3}>
            <Paper {...core.paperHeader}>
              <AssemblyIcon style={{fontSize: '10em', fill: 'slategray', width: '100%', marginTop: '25px'}} />
              <DetailsTitle title={
                // eslint-disable-next-line max-len
                `${materials.ref[selectedProcessForm.YieldRef]?.UnitDetails.MaterialName || 'Mixed'}${selectedProcessForm.YieldRef !== 'mixed' ? ` - ${types[selectedProcessForm.YieldRef].find(type => type.id === selectedProcessForm.TypeRef)?.TypeName}` : '' } Process Form`
              }
              {...showSubtitle()}
              />
              <DetailsFieldList fields={[
                {label: 'Form Id', value: selectedProcessForm.id},
                {label: 'Date', value: moment(selectedProcessForm.Date).format('MM/DD/YYYY')},
                {label: 'Facility', value: selectedProcessForm.Facility},
                {label: 'Facility Unit', value: facilityUnits?.ref[selectedProcessForm.FacilityUnitRef]?.Name || ''},
                {label: 'Shift', value: selectedProcessForm.Shift},
                {label: 'Weight Processed',
                  value: processedContainers?.reduce((a, b) => a + (b.NetWeight - b.RemainingWeight), 0) || 0,
                  visable: processedContainers},
                {label: 'Weight Yielded', value: createdYields?.reduce((a, b) => a + b.YieldedWeight, 0) || 0, visable: createdYields},
                {label: 'Accounting Id', value: selectedProcessForm.AccountingId, visible: Boolean(selectedProcessForm.AccountingId)},
                {label: 'Notes', value: selectedProcessForm.Notes, visible: Boolean(selectedProcessForm.Notes)},
              ]} />

              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                <ListItem style={{flexDirection: 'column'}}>
                  {!selectedProcessForm.Completed ? (
                    <Button {...core.markAsCompleteButton}>Mark As Complete</Button>
                  ) : (
                    <Button {...core.createReportButton}>Open Inventory Report for this Form</Button>
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            {processedContainers ? (
              <Fragment>
                <ProcessedContainerPanel {...core.processedContainerPanel} />
                {displayNewYieldPanel()}
              </Fragment>
            ) : <LinearProgress />}
          </Grid>
        </Grid>
        {movementFormModalOpen && <MovementFormModal {...core.movementFormModal} />}
        {markAsCompleteModalOpen && <MarkAsCompleteModal {...core.markAsCompleteModal} />}
        {processFormModalOpen && <NewProcessFormModal {...core.editProcessFormModal} />}
        {markAsInvoicedModalOpen && <MarkAsInvoicedModal {...core.markAsInvoicedModal} />}
      </div>
      {listOfContainersToPrint && <ProcessedLabels listOfContainersToPrint={listOfContainersToPrint} />}
    </Fragment>
  );
};
const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
});


ProcessFormDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default flowright(withStyles(styles, {withTheme: true}), withRouter)(ProcessFormDetails);

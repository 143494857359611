/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import {Delete, Add, Print} from '@material-ui/icons';
import {NewYieldContainerModal, DeleteYieldContainerModal} from './NewYieldPanel/';
import {useSelector} from 'react-redux';
import {TableWithPaper} from 'components/';

const NewYieldPanel = props => {
  const [newYieldModalOpen, setNewYieldModalOpen] = useState(false);
  const [deleteYieldContainerModalOpen, setDeleteYieldContainerModalOpen] = useState(false);
  const [selectedYieldContainer, setSelectedYieldContainer] = useState(null);
  const containers = useSelector(state => state.firestore.containers);

  const displayCreatedYields = () => {
    if (props.createdYields.length) {
      return (
        <TableWithPaper {...core.newYieldTable} />
      );
    }
    else {
      return (
        <Paper style={{width: '100%', padding: '16px'}}>
          <Grid container justify='flex-end'>
            <Grid item xs={12}>
              <Typography variant='h6' paragraph>Process Yields Summary</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Add a created yield to begin populating this table.</Typography>
            </Grid>
            <Grid item>
              <Button {...core.addYieldButton}><Add style={{marginRight: '8px'}} />Add Yield Container</Button>
            </Grid>
          </Grid>
        </Paper>);
    }
  };

  const core = {
    newYieldModal: {
      createdYields: props.createdYields,
      selectedProcessForm: props.selectedProcessForm,
      processedContainers: props.processedContainers,
      close: () => {
        setNewYieldModalOpen(false);
      },
    },
    deleteYieldModal: {
      createdYields: props.createdYields,
      selectedProcessForm: props.selectedProcessForm,
      selectedYieldContainer,
      close: () => {
        setDeleteYieldContainerModalOpen(false);
        setSelectedYieldContainer(null);
      },
    },
    addYieldButton: {
      variant: 'outlined',
      onClick: () => setNewYieldModalOpen(true),
    },
    newYieldTable: {
      data: props.createdYields,
      columns: [
        {accessor: 'NumberEntered', Header: '#', type: 'numeric', filterDisable: true},
        {accessor: 'CreatedShortNo', Header: 'Short No.'},
        {accessor: 'Yield.Name', Header: 'Yield'},
        {accessor: 'ContainerCode', Header: 'Container Code'},
        {accessor: 'ContainerCodeType', Header: 'Container Type'},
        {accessor: 'TareWeight', Header: 'Tare Weight', type: 'numeric', filterDisable: true},
        {accessor: 'NetWeight', Header: 'Net Weight', type: 'numeric', filterDisable: true},
        {accessor: 'ExistingContainer', Header: 'Existing Container', type: 'boolean'},
      ],
      sortInitial: [{id: 'NumberEntered', desc: false}],
      title: {primary: 'Process Yields Summary', secondary: 'Summary table of all yield containers created on this process form'},
      paginationActive: props.printView ? false : true,
      actionsPerRow: [
        {
          icon: Print,
          tooltip: 'Print Label',
          hide: props.selectedProcessForm.Completed ? false : true,
          onClick: ({rowData}) => props.setLabelsToPrint([containers.ref[rowData.CreatedShortNo]]),
        },
        {
          icon: Delete,
          tooltip: 'Remove Container',
          hide: props.selectedProcessForm.Completed ? true : false,
          onClick: ({rowData}) => {
            setSelectedYieldContainer(rowData);
            setDeleteYieldContainerModalOpen(true);
          },
        },
      ],
      actionsBar: [
        {
          icon: Print,
          buttonProps: {style: {display: props.selectedProcessForm.Completed ? 'inline-flex' : 'none'}},
          onClick: () => props.setLabelsToPrint(props.createdYields.map(yieldDoc => containers.ref[yieldDoc.CreatedShortNo])),
          text: 'Print All Labels',
        },
        {
          icon: Add,
          buttonProps: {style: {display: props.selectedProcessForm.Completed ? 'none' : 'inline-flex'}},
          onClick: () => setNewYieldModalOpen(true),
          text: 'Add Yield Container',
        },
      ],
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {displayCreatedYields()}
      </Grid>
      {newYieldModalOpen && <NewYieldContainerModal {...core.newYieldModal} />}
      {deleteYieldContainerModalOpen && <DeleteYieldContainerModal {...core.deleteYieldModal} />}
    </Grid>
  );
};

NewYieldPanel.propTypes = {
  selectedProcessForm: PropTypes.object.isRequired,
  createdYields: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLabelsToPrint: PropTypes.func,
  printView: PropTypes.bool.isRequired,
  processedContainers: PropTypes.arrayOf(PropTypes.object),
};

export default NewYieldPanel;

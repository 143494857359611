import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, DialogContent, Grid, DialogActions, DialogContentText} from '@material-ui/core';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import {Firestore} from 'config.js';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {connect} from 'react-redux';
import moment from 'moment';
import * as yup from 'yup';
import {withRouter} from 'react-router-dom';
import flowright from 'lodash.flowright';
const {DateField, FormikForm, SubmitButton, FormButton, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const stageArray = ['basic', 'success'];
const arrayOfBools = [{value: 'False', bool: false}, {value: 'True', bool: true}];

class ConsolidationModal extends React.Component {
  state = {
    newConsolidationFormId: null,
    deleteConfirmation: false,
    deleteAllowed: true,
    deleting: false,
    stage: 0,
    formError: '',
  }
  validationSchema = yup.object().shape({
    date: yup.string().required('Date is a required field.'),
    facility: yup.object().nullable().exists('Facility is a required field.'),
    facilityUnit: yup.object().nullable().exists('Facility Unit is a required field.'),
    consolidationContainer: yup.object().nullable().exists('Consolidation Container is a required field.'),
  })
  defaultFormik = this.props.selectedConsolidationForm ? {
    date: moment(this.props.selectedConsolidationForm.Date).format('YYYY-MM-DD'),
    facility: {value: this.props.selectedConsolidationForm.Facility},
    facilityUnit: this.props.facilityUnits.ref[this.props.selectedConsolidationForm.FacilityUnitRef],
    consolidationContainer: this.props.containers.ref[this.props.selectedConsolidationForm.ConsolidationContainer],
    mixedLoad: arrayOfBools.find(boolObj => boolObj.bool === this.props.selectedConsolidationForm.MixedLoad),
  } : {
    date: moment().format('YYYY-MM-DD'),
    facility: '',
    facilityUnit: '',
    consolidationContainer: '',
    mixedLoad: arrayOfBools[0],
  }
  componentDidMount() {
    if (this.props.listOfConsolidatedContainers && this.props.listOfConsolidatedContainers.length) {
      this.setState({deleteAllowed: false});
    }
  }
  handleSubmit(values, actions) {
    const consolidationFormRef = this.props.selectedConsolidationForm ?
      Firestore.collection('Tracking-Forms').doc(this.props.selectedConsolidationForm.id) : Firestore.collection('Tracking-Forms').doc();
    if (!this.props.selectedConsolidationForm) this.setState({newConsolidationFormId: consolidationFormRef.id});
    const formData = this.createDataForFirestore(values);
    if (this.props.selectedConsolidationForm) {
      consolidationFormRef.update({
        ...formData,
        'System.UpdatedOn': new Date(),
        'System.UpdatedBy': this.props.currentUser.displayName,
      }).then(() => {
        actions.setSubmitting(false);
        this.setState({stage: this.state.stage + 1, deleteConfirmation: false});
      }).catch(error => {
        actions.setSubmitting(false);
        this.setState({formError: 'There was an error during submission. Please try again.', deleteConfirmation: false});
      });
    }
    else {
      consolidationFormRef.set({
        ...formData,
        System: {
          CreatedOn: new Date(),
          CreatedBy: this.props.currentUser.displayName,
        },
      }).then(() => {
        actions.setSubmitting(false);
        this.setState({stage: this.state.stage + 1, deleteConfirmation: false});
      }).catch(error => {
        actions.setSubmitting(false);
        this.setState({formError: 'There was an error during submission. Please try again.', deleteConfirmation: false});
      });
    }
  }
  handleDelete() {
    if (!this.state.deleteConfirmation) {
      return this.setState({deleteConfirmation: true});
    }
    this.setState({deleting: true}, () => {
      Firestore.collection('Tracking-Forms').doc(this.props.selectedConsolidationForm.id).update({
        Active: false,
      })
        .then(() => {
          this.setState({deleting: false, stage: this.state.stage + 1});
        }).catch(error => {
          this.setState({deleting: false, formError: 'There was an error during delete. Please try again.'});
        });
    });
  }
  createDataForFirestore(values) {
    return {
      Active: true,
      Completed: false,
      Date: new Date(values.date),
      Facility: values.facility.value,
      FacilityUnitRef: values.facilityUnit.FacilityId,
      ConsolidationContainer: values.consolidationContainer.ShortNo,
      MixedLoad: values.mixedLoad.bool,
      MaterialRef: values.consolidationContainer.MaterialRef,
      FormType: 'Consolidation',
    };
  }
  render() {
    const core = {
      dialog: {
        open: true,
        fullWidth: true,
        maxWidth: 'sm',
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      submitButton: formikProps => ({
        loading: formikProps.isSubmitting,
        text: 'Submit',
        color: 'primary',
        onClick: formikProps.handleSubmit,
      }),
      cancelButton: formikProps => ({
        color: 'secondary',
        onClick: this.props.close,
        disabled: formikProps.isSubmitting,
      }),
      deleteButton: formikProps => ({
        text: this.state.deleteConfirmation ? 'Confirm?' : 'Delete',
        color: 'secondary',
        loading: this.state.deleting,
        onClick: () => this.handleDelete(),
        type: 'button',
        disabled: !this.state.deleteAllowed || formikProps.isSubmitting,
      }),
      closeButton: {
        color: 'primary',
        variant: 'text',
        onClick: () => {
          if (this.state.newConsolidationFormId) {
            this.props.history.push(`/production/consolidate/${this.state.newConsolidationFormId}`);
          }
          else if (this.state.deleteConfirmation) {
            this.props.history.push('/production/consolidate');
          }
          else this.props.close();
        },
      },
      submitConfirmation: {
        text: !this.props.selectedConsolidationForm ?
          'Consolidation form successfully created. You will be redirected there upon closing this form.' :
          `Consolidation form sucessfully ${this.state.deleteConfirmation ? 'deleted' : 'updated'}.`,
        stage: stageArray[this.state.stage],
        delete: this.state.deleteConfirmation,
      },
      facilityAuto: {
        name: 'facility',
        label: 'Facility',
        required: true,
        options: facilitiesConst.map(facility => ({value: facility})),
        optionKey: 'value',
        onChange: ({form, field}) => {
          form.setFieldValue('facilityUnit', '');
          form.setFieldValue('consolidationContainer', '');
        },
      },
      facilityUnitAuto: formikProps => ({
        name: 'facilityUnit',
        label: 'Facility Unit',
        loading: !formikProps.values.facility,
        loadingText: 'Select a Facility to populate',
        disabled: !this.state.deleteAllowed || formikProps.isSubmitting,
        required: true,
        options: this.props.facilityUnits?.list.filter(unit => unit.Facility === formikProps.values.facility?.value).sort((a, b) => a.Name > b.Name ? 1 : -1),
        optionKey: 'Name',
        onChange: ({form, field}) => {
          form.setFieldValue('consolidationContainer', '');
        },
      }),
      containerAuto: formikProps => ({
        name: 'consolidationContainer',
        label: 'Consolidation Short No.',
        disabled: !this.state.deleteAllowed || formikProps.isSubmitting,
        required: true,
        loading: !formikProps.values.facilityUnit,
        loadingText: 'Select a facility unit to populate.',
        options: this.props.containers.list.filter(container => container.FacilityUnitRef === formikProps.values.facilityUnit?.FacilityId && !container.InboundContainer),
        optionKey: 'ShortNo',
      }),
      mixedLoadAuto: {
        name: 'mixedLoad',
        label: 'Mixed Materials Allowed?',
        required: true,
        options: arrayOfBools,
        optionKey: 'value',
        disableClearable: true,
      },
    };
    return (
      <Dialog {...core.dialog}>
        <FormikForm initialValues={this.defaultFormik} validationSchema={this.validationSchema}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}>
          {formikProps => (
            <Fragment>
              {stageArray[this.state.stage] !== 'success' && (
                <DialogTitle>
                  {this.props.selectedConsolidationForm ? 'Edit' : 'New'} Consolidation Form
                </DialogTitle>)}
              <DialogContent>
                <Collapse in={stageArray[this.state.stage] === 'basic'}>
                  <DialogContentText>Enter the date, facility, location, and container of the consolidation:</DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <DateField name='date' label='Date' required />
                    </Grid>
                    <Grid item xs={4}>
                      <AutoCompleteObject {...core.facilityAuto} />
                    </Grid>
                    <Grid item xs={4}>
                      <AutoCompleteObject {...core.facilityUnitAuto(formikProps)} />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoCompleteObject {...core.mixedLoadAuto} />
                    </Grid>
                    <Grid item xs={6}>
                      <AutoCompleteObject {...core.containerAuto(formikProps)} />
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(this.state.formError)} text={this.state.formError} severity='error' />
              </DialogContent>
              {stageArray[this.state.stage] === 'success' ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <FormButton {...core.closeButton}>close</FormButton>
                </DialogActions>
              ) : <DialogActions style={{justifyContent: 'space-between'}}>
                {this.props.selectedConsolidationForm ?
                  <Fragment>
                    <FormButton variant='text' color='secondary' onClick={this.handleDelete} disabled={!this.state.deleteAllowed || formikProps.isSubmitting}>
                      {this.state.deleteConfirmation ? 'Confirm?' : 'Delete'}
                    </FormButton>
                    <div>
                      <FormButton variant='text' color='secondary' onClick={this.props.close}>Cancel</FormButton>
                      <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
                    </div>
                  </Fragment> :
                  (
                    <Fragment>
                      <FormButton variant='text' color='secondary' onClick={this.props.close}>Cancel</FormButton>
                      <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
                    </Fragment>)}
              </DialogActions>}
            </Fragment>
          )}
        </FormikForm>
      </Dialog>
    );
  }
}
const mapStateToProps = state => ({
  containers: state.firestore.containers,
  facilityUnits: state.firestore.facilityUnits,
  currentUser: state.auth.currentUser,
});
ConsolidationModal.propTypes = {
  selectedConsolidationForm: PropTypes.object,
  close: PropTypes.func.isRequired,
  containers: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  facilityUnits: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    ref: PropTypes.objectOf(PropTypes.object).isRequired,
  }),
  history: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  listOfConsolidatedContainers: PropTypes.arrayOf(PropTypes.object),
};

export default flowright(connect(mapStateToProps), withRouter)(ConsolidationModal);

import React from 'react';
import PropTypes from 'prop-types';
import {SvgIcon} from '@material-ui/core';

function parseSVGString(svgString) {
  const arrayOfPaths = svgString.match(/<path\b([\s\S]*?)\/>/g);
  const viewBox = (/viewBox="([^"]+)"/.exec(svgString)||'')[1] || '0 0 24 24';
  return [arrayOfPaths.map(path => path.split(/[""]/)[1]), viewBox];
}

const DefaultIcon = ({svgStringProp, ...otherProps}) => {
  const [pathArray, viewBox] = parseSVGString(svgStringProp);

  return (
    <SvgIcon viewBox={viewBox} {...otherProps}>
      {pathArray.map((pathString, index) => (
        <path key={index} d={pathString} />
      ))}
    </SvgIcon>);
};

DefaultIcon.propTypes = {
  svgStringProp: PropTypes.string.isRequired,
};

export default DefaultIcon;

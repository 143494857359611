/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Collapse,
  DialogContentText,
} from '@material-ui/core';
import {Alert} from '@kbi/component-library';
import {SubmitButton, SubmitConfirmation} from 'components/';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {withRouter} from 'react-router-dom';
import {useSelector} from 'react-redux';

const stageArray = ['basic', 'success'];

const MarkAsCompleteModal = props => {
  const {open, close} = props;
  const [stage, setStage] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const {containers, inventoryItems} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const handleBatchOverflow = (writeCount, batchArray) => {
    if (writeCount % 499 === 0) {
      batchArray.push(Firestore.batch());
    }
  };
  const updateAllContainers = useCallback((batchArray, containers, writeCount) => {
    props.listOfLines.forEach(line => {
      line.AddedContainers.forEach(lineContainer => {
        const containerRef = Firestore.collection('Tracking-Containers').doc(lineContainer.ShortNo);
        handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].update(containerRef, {
          AccumulationStartDate: null,
          FacilityUnitRef: null,
          Active: false,
          Flag: null,
          InventoryItems: [],
          MaterialRef: null,
          NetWeight: null,
        });
        writeCount++;
        containers.ref[lineContainer.ShortNo].InventoryItems.forEach(inventoryId => {
          const inventoryRef = Firestore.collection('Tracking-Inventory').doc(inventoryId);
          handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].update(inventoryRef, {
            Billable: true,
            ContainerRef: null,
            ShipmentOutRef: props.selectedOutbound.id,
            OutboundClassification: line.Classification,
          });
          writeCount++;

          handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].set(inventoryRef.collection('Form-History').doc(), {
            Date: new Date(),
            FormId: props.selectedOutbound.id,
            SubcollectionId: null,
            FormType: 'Outbound',
            StartContainer: lineContainer.ShortNo,
            StartMaterial: inventoryItems.ref[inventoryId].Material.Name,
            StartType: inventoryItems.ref[inventoryId].Type.Name,
            StartWeight: inventoryItems.ref[inventoryId].Weight,
            StartFlag: inventoryItems.ref[inventoryId].Flag,
            EndContainer: 'End of life',
            EndMaterial: 'End of life',
            EndType: 'End of life',
            EndWeight: 'End of life',
            EndFlag: 'End of life',
          });
          writeCount++;
        });
      });
    });
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);
    const batchArray = [];
    let writeCount = 0;
    const outboundRef = Firestore.collection('Tracking-Shipments').doc(props.selectedOutbound.id);
    handleBatchOverflow(writeCount, batchArray);
    batchArray[batchArray.length - 1].update(outboundRef, {
      'ContainersShipped': props.listOfLines.map(lineContainer => lineContainer.AddedContainers.map(container => container.ShortNo)).flat(1),
      'InProgress': false,
      'IsInvoiced': false,
      'System.CompletedOn': firebase.firestore.Timestamp.now(),
      'System.CompletedBy': currentUser.displayName,
    });
    writeCount++;
    updateAllContainers(batchArray, containers, writeCount);

    Promise.all(batchArray.map(batch => batch.commit()))
      .then(() => {
        setSubmitting(true);
        setStage(stage + 1);
        Firestore.collection('Email').add({
          to: ['ar@kbirecycling.com'],
          template: {
            name: 'OutboundLoadSent',
            data: {
              link: `https://tracking.kbi.works/shipments/outbound/${props.selectedOutbound.id}`,
            },
          },
        });
      })
      .catch(e => {
        setSubmitting(false);
        setFormError('There was an error during submission. Please try again.');
      });
  };

  const core = {
    dialog: {
      open,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    cancelButton: {
      onClick: close,
      color: 'secondary',
      disabled: submitting,
    },
    submitButton: {
      loading: submitting,
      color: 'primary',
      text: 'Confirm',
      disabled: submitting,
      onClick: handleSubmit,
    },
    closeButton: {
      onClick: close,
      color: 'primary',
    },
    submitConfirmation: {
      text: 'Outbound load successfully marked as completed.',
      stage: stageArray[stage],
    },
  };
  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>Confirm Completion</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>
            {/* eslint-disable-next-line max-len */}
            This will mark the current outbound load as complete, preventing any changes to it&apos;s data. Only press submit if you are sure that this outbound in finished, and you will no longer need to update it.
          </DialogContentText>
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stageArray[stage] === 'success' ? (
        <DialogActions style={{justifyContent: 'flex-end'}}>
          <Button {...core.closeButton}>Close</Button>
        </DialogActions>
      ) : (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <Button {...core.cancelButton}>Cancel</Button>
          <SubmitButton {...core.submitButton} />
        </DialogActions>
      )}
    </Dialog>
  );
};

MarkAsCompleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  selectedOutbound: PropTypes.object.isRequired,
  listOfLines: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
};

export default withRouter(MarkAsCompleteModal);

import React, {Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, withStyles, Paper, List, ListItem, LinearProgress} from '@material-ui/core';
import {Edit, Print} from '@material-ui/icons';
import {ConsolidatedContainersPanel} from 'panels/';
import flowright from 'lodash.flowright';
import moment from 'moment';
import {Alert} from '@kbi/component-library';
import {MergeIcon, GenericPrintLabels, BackButton, DetailsFieldList, DetailsTitle} from 'components/';
import {MarkAsCompleteModal} from './ConsolidationFormDetails/';
import {ConsolidationModal} from './Consolidate/';
import {Firestore} from 'config.js';
import {withRouter} from 'react-router-dom';
import {useFacilityUnits, useConsolidationForms, useMaterials, useTypes, useInventory, useContainers} from 'hooks';

const ConsolidationFormDetails = ({classes, match, history}) => {
  const [consolidationFormModalOpen, setConsolidationFormModalOpen] = useState(false);
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);
  const [selectedConsolidationForm, setSelectedConsolidationForm] = useState(null);
  const [printView, setPrintView] = useState(false);
  const [listOfContainersToPrint, setListOfContainersToPrint] = useState(null);
  const [listOfConsolidatedContainers, setListOfConsolidatedContainers] = useState(null);
  const [consolidationContainer, setConsolidationContainer] = useState(null);

  // setting up redux listeners
  const facilityUnits = useFacilityUnits();
  const consolidationForms = useConsolidationForms();
  const materials = useMaterials();
  const containers = useContainers();
  useTypes();
  useInventory();

  // sets the selectedConsolidationForm, either through redux or a stand alone request
  useEffect(() => {
    if (consolidationForms && consolidationForms.ref[match.params.ConsolidationFormId]) {
      setSelectedConsolidationForm(consolidationForms.ref[match.params.ConsolidationFormId]);
    }
    else {
      Firestore.collection('Tracking-Forms').doc(match.params.ConsolidationFormId).get().then(doc => {
        setSelectedConsolidationForm({...doc.data(), id: doc.id, Date: doc.data().Date.toDate()});
      });
    }
  }, [consolidationForms, match.params.ConsolidationFormId]);
  // sets consolidationContainer
  useEffect(() => {
    // the purpose of this statement is when the container of the form isnt in redux.
    // this can happen when containers doesnt exist, or the container has been removed from inventory
    if (containers &&!containers?.ref[selectedConsolidationForm?.ConsolidationContainer] &&
      !consolidationContainer &&
      selectedConsolidationForm) {
      Firestore.collection('Tracking-Containers').doc(selectedConsolidationForm.ConsolidationContainer).get().then(doc => {
        setConsolidationContainer({...doc.data(), ShortNo: doc.id});
      });
    }
    else if (containers && selectedConsolidationForm && containers.ref[selectedConsolidationForm.ConsolidationContainer]) {
      setConsolidationContainer(containers.ref[selectedConsolidationForm.ConsolidationContainer]);
    }
  }, [consolidationContainer, containers, selectedConsolidationForm]);
  // sets listOfConsolidatedContainers
  useEffect(() => {
    if (containers) {
      const consolidatedContainersListener = Firestore.collection('Tracking-Forms')
        .doc(match.params.ConsolidationFormId)
        .collection('Consolidated-Containers')
        .onSnapshot(snap => {
          setListOfConsolidatedContainers(snap.docs.map(doc => (
            {...doc.data(), id: doc.id, remainingWeight: containers.ref[doc.data().OriginalShortNo]?.NetWeight || 0}
          )));
        });
      return () => {
        consolidatedContainersListener();
      };
    }
  }, [containers, match.params.ConsolidationFormId]);
  // handles print view
  useEffect(() => {
    if (printView) {
      window.print();
      setPrintView(false);
    }
  }, [printView]);
  // handles listOfContainersToPrint
  useEffect(() => {
    if (listOfContainersToPrint) {
      window.print();
      setListOfContainersToPrint(null);
    }
  }, [listOfContainersToPrint]);

  const checkIfContainerIsValid = () => {
    if (consolidationContainer?.MaterialRef === null && !selectedConsolidationForm.Completed) return false;
    else return true;
  };

  if (!selectedConsolidationForm || !listOfConsolidatedContainers || !consolidationContainer) return <LinearProgress />;

  const core = {
    paperHeader: {
      className: classes.paperHeader,
    },
    menuDividers: {
      variant: 'subtitle1',
      className: classes.menuDividers,
    },
    menuPrimaryText: {
      className: classes.menuPrimaryText,
      variant: 'caption',
    },
    markAsCompleteButton: {
      variant: 'outlined',
      color: 'secondary',
      fullWidth: true,
      className: 'hidePrint',
      disabled: !listOfConsolidatedContainers || !listOfConsolidatedContainers.length,
      onClick: () => setMarkAsCompleteModalOpen(true),
    },
    printButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setPrintView(true),
    },
    printLabel: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setListOfContainersToPrint([consolidationContainer]),
    },
    editConsolidationFormButton: {
      variant: 'outlined',
      size: 'small',
      style: {marginLeft: '8px'},
      onClick: () => setConsolidationFormModalOpen(true),
    },
    consolidationModal: {
      selectedConsolidationForm: selectedConsolidationForm,
      listOfConsolidatedContainers: listOfConsolidatedContainers,
      close: () => setConsolidationFormModalOpen(false),
    },
    consolidatedContainersPanel: {
      printView: printView,
      selectedConsolidationForm: selectedConsolidationForm,
      listOfConsolidatedContainers: listOfConsolidatedContainers,
      setListOfContainersToPrint: list => setListOfContainersToPrint(list),
      validContainer: checkIfContainerIsValid(),
    },
    markAsCompleteModal: {
      selectedConsolidationForm: selectedConsolidationForm,
      close: () => setMarkAsCompleteModalOpen(false),
    },
  };

  return (
    <Fragment>
      <div className={listOfContainersToPrint ? 'hidePrint' : ''}>
        <div className='sideScrollingContainer hidePrint'>
          <BackButton />
          {!listOfConsolidatedContainers?.length ?
            <Button {...core.editConsolidationFormButton}><Edit className={classes.extendedIcon} />Edit Consolidation Form</Button> : null
          }
          <Button {...core.printLabel}><Print className={classes.extendedIcon} />Print Container Label</Button>
          <Button {...core.printButton}><Print className={classes.extendedIcon} />Print Form</Button>
        </div>
        <Grid className={listOfContainersToPrint ? 'hidePrint' : ''} container spacing={5}
          style={{marginTop: '8px'}}
        >
          <Grid item xs={12} md={3}>
            <Paper {...core.paperHeader}>
              <MergeIcon style={{fontSize: '10em', fill: 'slategray', width: '100%', marginTop: '25px'}} />
              <DetailsTitle title='Consolidation Form' subtitle={selectedConsolidationForm.ConsolidationContainer} />
              <DetailsFieldList fields={[
                {label: 'Form Id', value: selectedConsolidationForm.id},
                {label: 'Date', value: moment(selectedConsolidationForm.Date).format('MM/DD/YYYY')},
                {label: 'Mixed?', value: selectedConsolidationForm.MixedLoad ? 'True' : 'False'},
                {label: 'Facility', value: selectedConsolidationForm.Facility},
                {label: 'Facility Unit', value: facilityUnits.ref[selectedConsolidationForm.FacilityUnitRef].Name},
                {label: 'Material', value: materials.ref[selectedConsolidationForm.MaterialRef]?.UnitDetails.MaterialName},
                {label: 'Container Weight (Tare)', value: consolidationContainer.TareWeight},
                {label: 'Container Weight (Net)', value: consolidationContainer.NetWeight || 0},
                {label: 'Weight Consolidated', value: listOfConsolidatedContainers ? listOfConsolidatedContainers
                  .reduce((prev, curr) => prev + curr.WeightConsolidated, 0) : 0},
                {label: 'Container Code', value: consolidationContainer.ContainerCode},
                {label: 'Container Code Type', visible: !!consolidationContainer.ContainerCodeType,
                  value: consolidationContainer.ContainerCodeType},
              ]} />
              <List style={{minWidth: '100%', maxWidth: '100%'}}>
                {!selectedConsolidationForm.Completed &&
                  <ListItem>
                    <Button {...core.markAsCompleteButton}>Mark As Complete</Button>
                  </ListItem>}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Alert
              in={!checkIfContainerIsValid()}
              text='This container has been removed from inventory. Please delete this form, or contact a supervisor.'
              severity='error'
            />
            {listOfConsolidatedContainers ? <ConsolidatedContainersPanel {...core.consolidatedContainersPanel} /> : <LinearProgress />}
          </Grid>
        </Grid>
        {consolidationFormModalOpen && <ConsolidationModal {...core.consolidationModal} />}
        {markAsCompleteModalOpen && <MarkAsCompleteModal {...core.markAsCompleteModal} />}
      </div>
      {listOfContainersToPrint && <GenericPrintLabels listOfContainersToPrint={listOfContainersToPrint} />}
    </Fragment>
  );
};

const styles = theme => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  menuStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'ghostwhite',
    border: '1px solid darkgray',
    margin: '8px 0px',
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  paperHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
    justifyContent: 'center',
  },
  menuDividers: {
    width: '100%',
    textAlign: 'left',
    marginLeft: '8px',
    fontWeight: '600',
  },
  menuPrimaryText: {
    lineHeight: 1.3,
    fontSize: '1.0rem',
  },
});

ConsolidationFormDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default flowright(withStyles(styles, {withTheme: true}), withRouter)(ConsolidationFormDetails);

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Formik} from '@kbi/component-library';
const {AutoCompleteObject, SelectField, TextField, DateField} = Formik;

const ConsolidationForm = ({values}) => {
  const {facilityUnits, materials} = useSelector(reduxState => reduxState.firestore);

  const core = useMemo(() => ({
    materialAutocomplete: {
      name: 'material',
      label: 'Material',
      options: materials.completeList,
      optionKey: 'UnitDetails.MaterialName',
    },
    facilityUnitAutocomplete: {
      name: 'facilityUnit',
      label: 'Facility Unit',
      loading: !values.facility,
      loadingText: 'Select a facility to populate',
      options: values.facility ? facilityUnits.list.filter(unit => unit.Facility === values.facility) : [],
      optionKey: 'Name',
    },
    facilityField: {
      name: 'facility',
      label: 'Facility',
      onChange: ({form}) => {
        form.setFieldValue('facilityUnit', '');
      },
    },
  }), [facilityUnits.list, materials.completeList, values.facility]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField name='consolidationContainer' label='Consolidation Container' placeholder='Must match exactly' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.materialAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField name='mixedLoad' label='Mixed?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField {...core.facilityField}>
          <MenuItem value='Brea-1125'>Brea-1125</MenuItem>
          <MenuItem value='Anaheim-1314'>Anaheim-1314</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoCompleteObject {...core.facilityUnitAutocomplete} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField name='formId' label='Form Id' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='startDate' label='Start Date' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField name='endDate' label='End Date' disabled={!values.startDate} />
      </Grid>
    </Grid>
  );
};

ConsolidationForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default ConsolidationForm;

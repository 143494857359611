/* eslint-disable max-len */
/* eslint-disable guard-for-in */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  DialogContentText,
  Grid,
  DialogActions,
} from '@material-ui/core';
import * as yup from 'yup';
import {SubmitConfirmation} from 'components/';
import {Alert, Formik} from '@kbi/component-library';
import {Firestore} from 'config.js';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import flowright from 'lodash.flowright';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
const {TextField, SubmitButton, FormButton, FormikForm} = Formik;

const stageArray = ['basic', 'success'];
const greaterThan1RatioString = 'The ratio of yield to processed material is greater than 1. Please reevaluate all containers on this form.';
const lessThan6RatioString = totalWeightRatio => `The ratio of yield to processed materials is less than 60% (${(totalWeightRatio * 100).toFixed(2)}%). Only proceed if you are sure this is acceptable.`;
const NiMHlessThan1String = 'The ratio of yield to processed material is less than 1, and you are on a NiMH Plates Form. Please Reevaluate all containers on this form.';

class MarkAsCompleteModal extends React.Component {
  state = {
    stage: 0,
    submitting: false,
    totalWeightRatio: null,
    yieldRatioMap: null,
    formError: '',
    formWarning: '',
    arrayOfProcessedInventoryItems: [],
    partialContainers: {},
    mapOfYieldContainers: this.splitYieldContainersByYield(),
  }
  componentDidMount() {
    // weight ratio is applied to all the items being converted to yields. This keeps item as close to their original values as can. Allowing for tracking materials from inbound to outbound.
    const [totalWeightRatio, yieldRatioMap] = this.checkWeightRatio();
    this.setWeightRatioAndRespectiveErrorsToState(totalWeightRatio, yieldRatioMap);
    this.setArrayOfProcessedInventoryItems();
  }
  componentDidUpdate(prevProps, prevState) {
    // if there is a change in the containers processed, or the yields, run the weight check again
    if (prevProps.createdYields !== this.props.createdYields || prevProps.processedContainers !== this.props.processedContainers) {
      const [totalWeightRatio, yieldRatioMap] = this.checkWeightRatio();
      if (totalWeightRatio !== this.state.totalWeightRatio) {
        this.setWeightRatioAndRespectiveErrorsToState(totalWeightRatio, yieldRatioMap);
      }
    }
    if ((prevProps.processedContainers !== this.props.processedContainers || prevProps.inventoryItems !== this.props.inventoryItems) && this.props.selectedProcessForm.Completed === false && !this.state.submitting) {
      this.setArrayOfProcessedInventoryItems();
    }
  }
  checkWeightRatio() {
    const netWeightOfProcessedItems = this.props.processedContainers.reduce((a, b) => a + (b.NetWeight - b.RemainingWeight || 0), 0);
    const yieldRatioMap = {};
    for (const yieldRef in this.state.mapOfYieldContainers) {
      const yieldedWeightOfYield = this.state.mapOfYieldContainers[yieldRef].reduce((a, b) => a + b.YieldedWeight, 0);
      yieldRatioMap[yieldRef] = yieldedWeightOfYield / netWeightOfProcessedItems;
    }
    const totalWeightRatio = Object.values(yieldRatioMap).reduce((a, b) => a + b, 0);
    return [totalWeightRatio, yieldRatioMap];
  }
  handleBatchOverflow(writeCount, batchArray) {
    if (writeCount % 499 === 0) {
      batchArray.push(Firestore.batch());
    }
  }
  // splits yields up by material. if there is only one yield on the form, this will be only only one key
  splitYieldContainersByYield() {
    const mapOfContainers = {};
    this.props.createdYields.forEach(formContainer => {
      if (mapOfContainers[formContainer.Yield.Ref]) {
        mapOfContainers[formContainer.Yield.Ref].push(formContainer);
      }
      else {
        mapOfContainers[formContainer.Yield.Ref] = [formContainer];
      }
    });
    return mapOfContainers;
  }
  // if there is a discrepancy in the weight ratio to what is expected, than an error warning gets shown
  setWeightRatioAndRespectiveErrorsToState(totalWeightRatio, yieldRatioMap) {
    let formError = '';
    let formWarning = '';
    if (this.props.selectedProcessForm.YieldRef !== '2v3GCACHNBtSMKlHX5c5') {
      if (totalWeightRatio >= 1) formError = greaterThan1RatioString;
      else if (totalWeightRatio < 0.6) formWarning = lessThan6RatioString(totalWeightRatio);
    }
    else {
      if (totalWeightRatio <= 1) formError = NiMHlessThan1String;
    }
    this.setState({
      formError,
      formWarning,
      totalWeightRatio,
      yieldRatioMap,
    });
  }
  // sets state to include all of the inventory items that went into this process form
  setArrayOfProcessedInventoryItems() {
    if (this.props.selectedProcessForm.Completed) return;
    const arrayOfInventoryItems = [];
    const partialContainers = {};
    this.props.processedContainers.forEach(formContainer => {
      if (this.props.containers.ref[formContainer.ShortNo]) {
        // if the processed container status is complete, all of its items will be put into the processed array
        if (formContainer.ProcessStatus === 'Complete') {
          this.props.containers.ref[formContainer.ShortNo].InventoryItems.forEach(itemRef => {
            arrayOfInventoryItems.push(this.props.inventoryItems.ref[itemRef]);
          });
        }
        else {
          // otherwise the array of items will be looped over, seperated into processed items, and items that remain in the container but needs to have their weight updated
          let weightRemaining = formContainer.NetWeight;
          const arrayToSplit = [...this.props.containers.ref[formContainer.ShortNo].InventoryItems];
          while (weightRemaining > formContainer.RemainingWeight) {
            if (weightRemaining - this.props.inventoryItems.ref[arrayToSplit[0]].Weight > formContainer.RemainingWeight) {
              arrayOfInventoryItems.push({...this.props.inventoryItems.ref[arrayToSplit.shift()]});
              weightRemaining -= arrayOfInventoryItems[arrayOfInventoryItems.length - 1].Weight;
            }
            else {
              const [itemToGo, itemToStay] = this.splitOneItemIntoTwo(this.props.inventoryItems.ref[arrayToSplit[0]], weightRemaining - formContainer.RemainingWeight);
              arrayToSplit.shift();
              arrayOfInventoryItems.push(itemToGo);
              partialContainers[formContainer.ShortNo] = {
                itemToStay,
                inventoryItems: [itemToStay.id, ...arrayToSplit],
                newContainerWeight: formContainer.RemainingWeight,
              };
              weightRemaining -= itemToGo.Weight;
            }
          }
        }
      }
    });
    this.setState({
      arrayOfProcessedInventoryItems: arrayOfInventoryItems,
      partialContainers,
    });
  }
  // does the conversion of processed inventory to yield inventory. This uses the yield ratio to create the weights.
  createYieldInventoryFromProcessedInventory() {
    const mapOfYieldItems = {};
    for (const yieldId in this.state.yieldRatioMap) {
      mapOfYieldItems[yieldId] = [];
      this.state.arrayOfProcessedInventoryItems.forEach(item => {
        const matchedYield = this.props.materials.ref[yieldId];
        mapOfYieldItems[yieldId].push({
          ...item,
          Billable: true,
          Classification: '',
          Weight: parseInt(item.Weight * this.state.yieldRatioMap[yieldId]),
          RCRACodes: matchedYield.WasteCodes.RCRA,
          CACodes: matchedYield.WasteCodes.CA,
          Material: {
            Name: matchedYield.UnitDetails.MaterialName,
            Ref: yieldId,
          },
          AccumulationStartDate: new Date(),
          FormHistory: [this.props.selectedProcessForm.id],
          Source: 'Process',
          id: Firestore.collection('Tracking-Inventory').doc().id,
        });
      });
    }
    return mapOfYieldItems;
  }
  // creates the containers into a map that the inventory yields will be attached to
  createYieldContainers() {
    const objectOfYieldContainers = {};
    this.props.createdYields.forEach(formYieldContainer => {
      if (objectOfYieldContainers[formYieldContainer.Yield.Ref]) {
        objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo] = {
          ContainerCode: formYieldContainer.ContainerCode,
          ContainerCodeType: formYieldContainer.ContainerCodeType,
          FacilityUnitRef: this.props.selectedProcessForm.FacilityUnitRef,
          Flag: this.props.selectedProcessForm.Flag,
          Shard: parseInt(Math.random() * 5 + 1),
          InboundContainer: false,
          MaterialRef: formYieldContainer.Yield.Ref,
          NetWeight: formYieldContainer.NetWeight,
          TareWeight: formYieldContainer.TareWeight,
          Active: true,
        };
        if (formYieldContainer.ExistingContainer) {
          const matchedContainer = this.props.containers.ref[formYieldContainer.CreatedShortNo];
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].AccumulationStartDate = matchedContainer.AccumulationStartDate;
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].InventoryItems = [...matchedContainer.InventoryItems];
        }
        else {
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].AccumulationStartDate = firebase.firestore.Timestamp.now();
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].InventoryItems = [];
        }
      }
      else {
        objectOfYieldContainers[formYieldContainer.Yield.Ref] = {};
        objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo] = {
          ContainerCode: formYieldContainer.ContainerCode,
          ContainerCodeType: formYieldContainer.ContainerCodeType,
          FacilityUnitRef: this.props.selectedProcessForm.FacilityUnitRef,
          Flag: this.props.selectedProcessForm.Flag,
          InboundContainer: false,
          Shard: parseInt(Math.random() * 5 + 1),
          MaterialRef: formYieldContainer.Yield.Ref,
          NetWeight: formYieldContainer.NetWeight,
          TareWeight: formYieldContainer.TareWeight,
          Active: true,
        };
        if (formYieldContainer.ExistingContainer) {
          const matchedContainer = this.props.containers.ref[formYieldContainer.CreatedShortNo];
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].AccumulationStartDate = matchedContainer.AccumulationStartDate;
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].InventoryItems = [...matchedContainer.InventoryItems];
        }
        else {
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].AccumulationStartDate = firebase.firestore.Timestamp.now();
          objectOfYieldContainers[formYieldContainer.Yield.Ref][formYieldContainer.CreatedShortNo].InventoryItems = [];
        }
      }
    });
    return objectOfYieldContainers;
  }
  // when an item needs to be placed into two containers, this is used to split it
  splitOneItemIntoTwo(inventoryItem, weightNeeded) {
    // splitting the item, maintaining all of the properties of the current item, but adjusting the weight to be the exact amount to fill a container.
    // also assigns a new id to the item
    const newItems = [{...inventoryItem, Weight: weightNeeded, id: Firestore.collection('Tracking-Inventory').doc().id}, {...inventoryItem, Weight: inventoryItem.Weight - weightNeeded}];
    // returns an array of the two items (the new item, with a new id and weight, and the item with the original id with an updated weight)
    return newItems;
  }
  // connects the yield inventory that was created to the yield containers created.
  assignYieldInventoryToYieldContainers(mapOfYieldItems, yieldContainers) {
    /* return the array of inventory items that has been split and sorted into each of the yield containers created on this form.
    * this array will be what gets looped over and sent to Firestore.
    * also returns the object of yield containers that have been updated with their respective inventory items list
    * this object will be looped over and sent to Firestore.
    * */
    for (const yieldId in this.state.yieldRatioMap) {
      // used to loop over the array of items that will be put into yield containers
      let inventoryIndex = 0;
      // loops over the yield containers, and pushes inventory item refs into their InventoryItems prop
      for (const shortNo in yieldContainers[yieldId]) {
      // the weight inside the current container starts off either at 0 if it is a new container, or if the container already exists, pulls its current net weight and sets it
        let weightInsideCurrentContainer = this.props.containers.ref[shortNo] ? this.props.containers.ref[shortNo].NetWeight : 0;
        // the weight needed is the difference between the weight that is already in the container, and the weight that the user put as its net weight after processing.
        let weightNeededToFillContainer = yieldContainers[yieldId][shortNo].NetWeight - weightInsideCurrentContainer;
        /* begin looping over inventory items until either the weight of the current container fills up (which happens after the else within this loop)
      * or until the list of inventory items end has been reached, at which point there will be extra weight in the weightNeedingToFillContainer variable.
      * This is due to a rounding error. at this point, all containers have been filled except the last one, and all inventory items have been accounted for.
      */
        while (weightNeededToFillContainer !== 0 && inventoryIndex < mapOfYieldItems[yieldId].length) {
        // check if the current inventory item weight is less than or equal to the amount needed to fill container
          const yieldedContainerDoc = this.state.mapOfYieldContainers[yieldId].find(containerDoc => containerDoc.CreatedShortNo === shortNo);
          if (mapOfYieldItems[yieldId][inventoryIndex].Weight <= weightNeededToFillContainer) {
          // if that is true, the InventoryItems prop on the current yield container will be appended with the current item
            yieldContainers[yieldId][shortNo].InventoryItems.push(mapOfYieldItems[yieldId][inventoryIndex].id);
            // assign the container ref to the inventory item
            mapOfYieldItems[yieldId][inventoryIndex].ContainerRef = shortNo;
            mapOfYieldItems[yieldId][inventoryIndex].Flag = yieldedContainerDoc?.Flag || '';
            mapOfYieldItems[yieldId][inventoryIndex].Type = {
              Name: yieldedContainerDoc?.Type.Name || '',
              Ref: yieldedContainerDoc?.Type.Ref || '',
            };
            // the weights use to track if the container is full will be updated.
            weightInsideCurrentContainer += mapOfYieldItems[yieldId][inventoryIndex].Weight;
            weightNeededToFillContainer -= mapOfYieldItems[yieldId][inventoryIndex].Weight;
            // and move to the next inventory item
            inventoryIndex++;
          }
          else {
          // otherwise, the current item is more weight than the current container accepts.
          /* if that is the case, the array of items will be updated with the array up until the item in question, the current item will be split into two and pushed back into the array
          * and then the rest of the array attached to the end.
          */
            mapOfYieldItems[yieldId] = [
              ...mapOfYieldItems[yieldId].slice(0, inventoryIndex),
              ...this.splitOneItemIntoTwo(mapOfYieldItems[yieldId][inventoryIndex], weightNeededToFillContainer),
              ...mapOfYieldItems[yieldId].slice(inventoryIndex + 1),
            ];
            // then the current item (which is now the exact amount that is needed to fill the container) is pushed into the container
            yieldContainers[yieldId][shortNo].InventoryItems.push(mapOfYieldItems[yieldId][inventoryIndex].id);
            // assign the container id to the inventory item
            mapOfYieldItems[yieldId][inventoryIndex].ContainerRef = shortNo;
            mapOfYieldItems[yieldId][inventoryIndex].Flag = yieldedContainerDoc?.Flag || '';
            mapOfYieldItems[yieldId][inventoryIndex].Type = {
              Name: yieldedContainerDoc?.Type.Name || '',
              Ref: yieldedContainerDoc?.Type.Ref || '',
            };
            // weights are updated
            weightInsideCurrentContainer += mapOfYieldItems[yieldId][inventoryIndex].Weight;
            weightNeededToFillContainer -= mapOfYieldItems[yieldId][inventoryIndex].Weight;
            // and moved to the next inventory item
            inventoryIndex++;
          /* at this point, it will loop back up to the while conditionals and 1 of the 2 &&'s will become false.
          * either the container will be full, or we will have reached the end of the inventory items array
          */
          }
        }
        /* the last inventory item in this process form gets the rounding error attached to it.
      *at MOST, it will be 1 pound per inventory item on this entire process form.
      */
        mapOfYieldItems[yieldId][mapOfYieldItems[yieldId].length - 1].Weight += weightNeededToFillContainer;
      }
    }
    // return the items that will be sent to firestore
    return [mapOfYieldItems, yieldContainers];
  }
  handleSubmit = (values, actions) => {
    this.setState({submitting: true, formWarning: '', formError: ''}, () => {
      const mapOfYieldContainers = this.createYieldContainers();
      const mapOfYieldItems = this.createYieldInventoryFromProcessedInventory();
      const batchArray = [];
      let writeCount = 0;
      const [yieldInventoryForDB, yieldContainersForDB] = this.assignYieldInventoryToYieldContainers(mapOfYieldItems, mapOfYieldContainers);

      for (const yieldId in this.state.yieldRatioMap) {
        for (const shortNo in yieldContainersForDB[yieldId]) {
          this.handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Containers').doc(shortNo), yieldContainersForDB[yieldId][shortNo]);
          writeCount++;
        }
        // eslint-disable-next-line no-loop-func
        yieldInventoryForDB[yieldId].forEach(item => {
          const {id, ...itemProps} = item;
          this.handleBatchOverflow(writeCount, batchArray);
          writeCount++;
          batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Inventory').doc(id), itemProps);

          this.handleBatchOverflow(writeCount, batchArray);
          writeCount++;
          batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Inventory').doc(id).collection('Form-History').doc(), {
            Date: this.props.selectedProcessForm.Date,
            FormId: this.props.selectedProcessForm.id,
            FormType: 'Process',
            StartContainer: 'Start of life',
            StartMaterial: 'Start of life',
            StartType: 'Start of life',
            StartWeight: 'Start of life',
            StartFlag: 'Start of life',
            EndContainer: itemProps.ContainerRef,
            EndMaterial: itemProps.Material.Name,
            EndType: itemProps.Type.Name,
            EndWeight: itemProps.Weight,
            EndFlag: itemProps.Flag,
          });

          this.handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Forms').doc(this.props.selectedProcessForm.id).collection('Inventory-Summary').doc(id), {
            Material: itemProps.Material.Name,
            Type: itemProps.Type.Name,
            ContainerRef: itemProps.ContainerRef,
            Flag: itemProps.Flag,
            Weight: itemProps.Weight,
            AccountRef: itemProps.AccountRef,
            IncomingPieceNo: itemProps.IncomingPieceNo,
            Yield: true,
            ItemRef: id,
          });
          writeCount++;
        });
      }
      this.handleBatchOverflow(writeCount, batchArray);
      batchArray[batchArray.length - 1].update(Firestore.collection('Tracking-Forms').doc(this.props.selectedProcessForm.id), {
        'Notes': values.Notes,
        'Completed': true,
        'IsInvoiced': false,
        'System.CompletedBy': this.props.currentUser.displayName,
        'System.CompletedOn': firebase.firestore.Timestamp.now(),
      });
      writeCount++;
      this.props.processedContainers.forEach(container => {
        const processedContainerData = this.createDataForProcessedContainer(container);
        this.handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].update(Firestore.collection('Tracking-Containers').doc(container.ShortNo), processedContainerData);
        writeCount++;
        if (container.ProcessStatus !== 'Complete') {
          this.handleBatchOverflow(writeCount, batchArray);
          batchArray[batchArray.length - 1].update(Firestore.collection('Tracking-Forms').doc(this.props.selectedProcessForm.id).collection('Processed-Containers').doc(container.id), {
            RemainingInventoryItems: processedContainerData.InventoryItems,
          });
          writeCount++;
        }
      });
      this.state.arrayOfProcessedInventoryItems.forEach(item => {
        const {id, ...itemData} = item;
        this.handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Inventory').doc(id), {
          ...itemData,
          FormHistory: [...itemData.FormHistory, this.props.selectedProcessForm.id],
          ContainerRef: null,
          Billable: true,
        }, {merge: true});
        writeCount++;

        this.handleBatchOverflow(writeCount, batchArray);
        writeCount++;
        batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Inventory').doc(id).collection('Form-History').doc(), {
          Date: this.props.selectedProcessForm.Date,
          FormId: this.props.selectedProcessForm.id,
          FormType: 'Process',
          StartContainer: itemData.ContainerRef,
          StartMaterial: itemData.Material.Name,
          StartType: itemData.Type.Name,
          StartWeight: itemData.Weight,
          StartFlag: itemData.Flag,
          EndContainer: 'End of life',
          EndMaterial: 'End of life',
          EndType: 'End of life',
          EndWeight: 'End of life',
          EndFlag: 'End of life',
        });

        this.handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].set(Firestore.collection('Tracking-Forms').doc(this.props.selectedProcessForm.id).collection('Inventory-Summary').doc(id), {
          Material: itemData.Material.Name,
          Type: itemData.Type.Name,
          ContainerRef: itemData.ContainerRef,
          Flag: itemData.Flag,
          Weight: itemData.Weight,
          AccountRef: itemData.AccountRef,
          IncomingPieceNo: itemData.IncomingPieceNo,
          Yield: false,
          ItemRef: id,
        });
        writeCount++;
      });
      Object.values(this.state.partialContainers).forEach(statePiece => {
        this.handleBatchOverflow(writeCount, batchArray);
        batchArray[batchArray.length - 1].update(Firestore.collection('Tracking-Inventory').doc(statePiece.itemToStay.id), {
          Billable: true,
          Weight: statePiece.itemToStay.Weight,
        });
        writeCount++;
      });

      Promise.all(batchArray.map(batch => batch.commit()))
        .then(() => {
          this.setState({submitting: false, stage: this.state.stage + 1});
          actions.setSubmitting(false);
        })
        .catch(error => {
          this.setState({submitting: false, formError: 'There was an error during submission. Please try again.'});
          actions.setSubmitting(false);
        });
    });
  }
  // handles updating the processed containers, either clearing them from onsite inventory, or updating their contents depending on if it was completely processed
  createDataForProcessedContainer(processContainer) {
    if (processContainer.ProcessStatus === 'Complete') {
      return {
        AccumulationStartDate: null,
        Active: false,
        FacilityUnitRef: null,
        Flag: null,
        InventoryItems: [],
        MaterialRef: null,
        NetWeight: null,
      };
    }
    else {
      return {
        InventoryItems: this.state.partialContainers[processContainer.ShortNo].inventoryItems,
        NetWeight: this.state.partialContainers[processContainer.ShortNo].newContainerWeight,
        AccumulationStartDate: this.determineNewAccumulationStartDate(processContainer.ShortNo),
      };
    }
  }
  determineNewAccumulationStartDate(shortNo) {
    let oldestDate = null;
    this.state.partialContainers[shortNo].inventoryItems.forEach((itemRef, index) => {
      if (index === 0) {
        oldestDate = this.props.inventoryItems.ref[itemRef].AccumulationStartDate;
      }
      else if (moment(oldestDate).isAfter(this.props.inventoryItems.ref[itemRef.AccumulationStartDate])) {
        oldestDate = this.props.inventoryItems.ref[itemRef.AccumulationStartDate];
      }
    });
    return oldestDate;
  }
  render() {
    const {open, close} = this.props;
    const {formError, stage, formWarning} = this.state;

    const core = {
      dialog: {
        open: open,
        maxWidth: 'sm',
        fullWidth: true,
        scroll: 'body',
        transitionDuration: {exit: 0},
      },
      closeButton: {
        variant: 'text',
        onClick: close,
        color: 'primary',
      },
      cancelButton: {
        variant: 'text',
        onClick: close,
        color: 'secondary',
      },
      submitButton: {
        variant: 'text',
        color: 'primary',
      },
      noteField: {
        name: 'Notes',
        placeholder: 'Enter anything you feel should be noted on this form',
        multiline: true,
      },
      formik: {
        initialValues: {
          Notes: '',
        },
        validationSchema: yup.object().shape({
          Notes: yup.string(),
        }),
        onSubmit: (values, actions) => {
          this.handleSubmit(values, actions);
        },
      },
      submitConfirmation: {
        text: 'Process Form successfully completed',
        stage: stageArray[stage],
      },
    };

    return (
      <Dialog {...core.dialog}>
        <FormikForm {...core.formik}>
          {formikProps => (
            <Fragment>
              {stageArray[stage] !== 'success' && <DialogTitle >Mark Process Form as Complete</DialogTitle>}
              <DialogContent>
                <Collapse in={stageArray[stage] === 'basic'}>
                  <DialogContentText>
                Only mark as complete if the shift this process form was created for is over, or there is no more processing for the selected yield. Once it is completed, you will no longer be able to add yield or processed containers to this form.
                  </DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField {...core.noteField} />
                    </Grid>
                  </Grid>
                </Collapse>
                <SubmitConfirmation {...core.submitConfirmation} />
                <Alert in={Boolean(formError)} text={formError} severity='error' />
                <Alert in={Boolean(formWarning)} text={formWarning} severity='warning' />
              </DialogContent>
              {stageArray[this.state.stage] === 'success' ? (
                <DialogActions style={{justifyContent: 'flex-end'}}>
                  <FormButton {...core.closeButton}>Close</FormButton>
                </DialogActions>
              ) : (
                <DialogActions style={{justifyContent: 'space-between'}}>
                  <FormButton {...core.cancelButton}>Cancel</FormButton>
                  <SubmitButton variant='text'>
                    Submit
                  </SubmitButton>
                </DialogActions>
              )}
            </Fragment>
          )}
        </FormikForm>
      </Dialog >
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    containers: state.firestore.containers,
    inventoryItems: state.firestore.inventoryItems,
    materials: state.firestore.materials,
    types: state.firestore.types,
  };
};

MarkAsCompleteModal.propTypes = {
  selectedProcessForm: PropTypes.object,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  containers: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  inventoryItems: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.object),
    ref: PropTypes.objectOf(PropTypes.object),
  }),
  materials: PropTypes.shape({
    ref: PropTypes.objectOf(PropTypes.object),
    materialList: PropTypes.arrayOf(PropTypes.object),
    yieldList: PropTypes.arrayOf(PropTypes.object),
    processList: PropTypes.arrayOf(PropTypes.object),
    inboundList: PropTypes.arrayOf(PropTypes.object),
    listener: PropTypes.func,
  }),
  types: PropTypes.object,
  processedContainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  createdYields: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default flowright(connect(mapStateToProps), withRouter)(MarkAsCompleteModal);

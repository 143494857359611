import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acInventoryListener} from 'state/firestore/actions.js';

const useInventory = () => {
  const dispatch = useDispatch();
  const inventoryItems = useSelector(state => state.firestore.inventoryItems);

  useEffect(() => {
    if (!inventoryItems) dispatch(acInventoryListener);
  }, [dispatch, inventoryItems]);

  return inventoryItems;
};

export default useInventory;

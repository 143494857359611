import React from 'react';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {Settings} from '@material-ui/icons';

const ViewNoSelection = ({classes, onViewChange}) => {
  const core = {
    buttonViewMaterials: {
      color: 'primary',
      variant: 'outlined',
      onClick: () => onViewChange('materials'),
    },
    buttonViewFacilityUnits: {
      color: 'primary',
      style: {marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => onViewChange('facilityUnits'),
    },
    paper: {
      className: classes.paper,
      style: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  };
  return (
    <Paper {...core.paper}>
      <Typography variant='h4' gutterBottom>
        Update Material Tracking Settings
      </Typography>
      <Settings style={{fontSize: '10em'}} />
      <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
        What would you like to add or update?
      </Typography>
      <div>
        <Button {...core.buttonViewMaterials}>Materials</Button>
        <Button {...core.buttonViewFacilityUnits}>Facility Units</Button>
      </div>
    </Paper>
  );
};

ViewNoSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  onViewChange: PropTypes.func.isRequired,
};
export default ViewNoSelection;

import React, {useMemo, useState, Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Collapse,
  DialogContentText,
  Grid,
  MenuItem,
  DialogActions,
} from '@material-ui/core';
import * as yup from 'yup';
import {Alert, Formik} from '@kbi/component-library';
import {useSelector} from 'react-redux';
import {SubmitConfirmation, facilitiesConst} from 'components/';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {Firestore} from 'config.js';
import firebase from 'firebase/app';
import 'firebase/firestore';
const {SubmitButton, FormButton, FormikForm, SelectField, DateField, TextField, AutoCompleteObject, validateAutoObject} = Formik;
validateAutoObject();

const stageArray = ['basic', 'success'];

const InboundModal = props => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [newInboundId, setNewInboundId] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  const {accounts, purchaseOrders, locations} = useSelector(state => state.firestore);
  const currentUser = useSelector(state => state.auth.currentUser);

  const arrayOfAccountsForShipment = useMemo(() => Object.values(accounts).filter(
    account =>
      purchaseOrders[account.AccountId] && purchaseOrders[account.AccountId].find(PO => PO.Active),
  )
    .sort((a, b) => (a.AccountName > b.AccountName ? 1 : -1)),
  [accounts, purchaseOrders]);
  const arrayOfTransportAccounts = useMemo(() => Object.values(accounts)
    .filter(account => account.AccountType.Transporter)
    .sort((a, b) => (a.AccountName > b.AccountName ? 1 : -1)),
  [accounts]);

  const createDataForFirestore = useCallback(values => {
    // returns an object that is in the format Firestore is expecting
    const firestoreObj = {
      Facility: values.facility,
      AccountId: values.account.AccountId,
      ShipmentTime: new Date(values.timeReceived),
      Transporter: values.transporter.AccountId,
      TransporterEpaId: values.transporterEpaId,
      InProgress: true,
      Comments: values.comments,
      Direction: 'Inbound',
    };
    if (props.selectedShipment) {
      firestoreObj.ScheduledTime = firebase.firestore.Timestamp.fromDate(
        new Date(props.selectedShipment.StartDateTime),
      );
      firestoreObj.ShipmentId = props.selectedShipment.id;
    }
    else {
      firestoreObj.ScheduledTime = null;
      firestoreObj.ScheduleId = null;
    }
    return firestoreObj;
  }, [props.selectedShipment]);

  const formik = useMemo(() => ({
    initialValues: {
      timeReceived: props.selectedInbound ? moment(props.selectedInbound.ShipmentTime.toDate).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'),
      facility: props.selectedInbound?.Facility || (props.selectedShipment ? props.selectedShipment.Facility : ''),
      transporter: accounts[props.selectedInbound?.Transporter] || '',
      transporterEpaId: props.selectedInbound?.TransporterEpaId || '',
      account: accounts[props.selectedInbound?.AccountId] || (props.selectedShipment ? accounts[props.selectedShipment.AccountId] : ''),
      comments: props.selectedInbound?.Comments ? props.selectedInbound.Comments : props.selectedShipment ? props.selectedShipment.Notes : '',
    },
    validationSchema: yup.object().shape({
      timeReceived: yup.date().required('Time Received is a required field.').max(new Date(), 'Time Received cannot be a future time.'),
      facility: yup.string().required('Facility is a required field.'),
      transporter: yup.object().nullable().exists('Transporter is a required field.'),
      transporterEpaId: yup.string().required('Transporter EPA ID is a required field.'),
      account: yup.object().nullable().exists('Account is a required field.'),
      comments: yup.string(),
    }),
    onSubmit: (values, actions) => {
      setDeleteConfirmation(false);
      const firestoreData = createDataForFirestore(values);
      const batch = Firestore.batch();
      let docToWriteRef = null;
      if (props.selectedInbound) {
        docToWriteRef = Firestore.collection('Tracking-Shipments').doc(props.selectedInbound.id);
        batch.update(docToWriteRef, {
          ...firestoreData,
          'System.UpdatedBy': currentUser.displayName,
          'System.UpdatedOn': firebase.firestore.Timestamp.now(),
        });
      }
      else {
        docToWriteRef = Firestore.collection('Tracking-Shipments').doc();
        batch.set(docToWriteRef, {
          ...firestoreData,
          System: {
            CreatedOn: new Date(),
            CreatedBy: currentUser.displayName,
            UpdatedOn: new Date(),
            UpdatedBy: currentUser.displayName,
          },
        });
        if (props.selectedShipment) {
          batch.update(
            Firestore.collection('CRM-Accounts')
              .doc(values.account.AccountId)
              .collection('Shipments')
              .doc(props.selectedShipment.id),
            {ShipmentId: docToWriteRef.id, Active: false},
          );
        }
      }
      batch
        .commit()
        .then(() => {
          actions.setSubmitting(false);
          setStage(stage + 1);
          setNewInboundId(docToWriteRef.id);
        })
        .catch(error => {
          actions.setSubmitting(false);
          setFormError('There was an error during submission. Please try again.');
        });
    },
  }), [accounts, createDataForFirestore, currentUser.displayName, props.selectedInbound, props.selectedShipment, stage]);

  const handleDelete = useCallback(values => {
    if (!deleteConfirmation) {
      setDeleteConfirmation(true);
    }
    else {
      setIsDeleting(true);
      setStage(stage + 1);
      setTimeout(() => {
        const batch = Firestore.batch();
        batch.delete(Firestore.collection('Tracking-Shipments').doc(props.selectedInbound.id));
        if (props.selectedInbound.ScheduleId) {
          batch.update(Firestore.collection('CRM-Accounts')
            .doc(values.account.AccountId)
            .collection('Shipments')
            .doc(props.selectedInbound.ScheduleId), {
            ShipmentId: null,
            Active: true,
          });
        }
        batch.commit().then(() => {
          history.push('/shipments');
        }).catch(error => {
          setIsDeleting(false);
          setStage(stage - 1);
          setFormError('There was an error during deletion. Please try again.');
        });
      }, 2000);
    }
  }, [deleteConfirmation, history, props.selectedInbound, stage]);

  const core = useMemo(() => ({
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    submitConfirmation: {
      text: deleteConfirmation ? 'Inbound deleted. You will be redirected shortly' :
        (props.selectedInbound ? 'Inbound load successfully edited.' : 'Inbound load successfully created.'),
      delete: deleteConfirmation,
      stage: stageArray[stage],
    },
    accountAutocomplete: {
      options: arrayOfAccountsForShipment,
      optionKey: 'AccountName',
      name: 'account',
      label: 'Account',
      required: true,
      disabled: !!props.selectedInbound || !!props.selectedShipment,
    },
    transportAutocomplete: {
      options: arrayOfTransportAccounts,
      optionKey: 'AccountName',
      name: 'transporter',
      label: 'Transporter',
      required: true,
      onChange: ({form, field}) => {
        form.setFieldValue('transporterEpaId', '');
      },
    },
  }), [arrayOfAccountsForShipment, arrayOfTransportAccounts, deleteConfirmation, props.selectedInbound, props.selectedShipment, stage]);

  const buttonProps = useMemo(() => ({
    closeButton: {
      color: 'primary',
      variant: 'text',
      onClick: () => {
        props.close(newInboundId);
      },
    },
    cancelButton: {
      color: 'secondary',
      onClick: props.close,
      variant: 'text',
      disabled: isDeleting,
    },
    submitButton: {
      variant: 'text',
      text: 'Submit',
      color: 'primary',
      disabled: isDeleting,
    },
    deleteButton: {
      disabled: isDeleting || !!props.listOfShippingDocument?.length || !!props.listOfWeightTickets?.length || !!props.selectedInbound?.UploadDate,
      variant: 'text',
      color: 'secondary',
      onClick: handleDelete,
    },

  }), [handleDelete, isDeleting, newInboundId, props]);

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {formikProps => (
          <Fragment>
            {stageArray[stage] !== 'success' && (
              <DialogTitle>{props.selectedInbound ? 'Edit Inbound Load' : 'Receive New Inbound Load'}</DialogTitle>
            )}
            <DialogContent>
              <Collapse in={stageArray[stage] === 'basic'}>
                <DialogContentText>Enter inbound information:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DateField name='timeReceived' label='Time Received' required type='datetime-local' />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteObject {...core.accountAutocomplete} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteObject {...core.transportAutocomplete} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='transporterEpaId' label='Transporter EPA ID' required
                      disabled={!formikProps.values.transporter || formikProps.isSubmitting}
                    >
                      {formikProps.values.transporter ? locations[formikProps.values.transporter?.AccountId].map(location => {
                        return <MenuItem value={location.EpaId} key={location.id}>{location.EpaId}</MenuItem>;
                      }) : []}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SelectField name='facility' label='Facility' required>
                      {facilitiesConst.map(facility => (
                        <MenuItem key={facility} value={facility}>
                          {facility}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField name='comments' label='Comments' multiline />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stageArray[stage] === 'success' ? (
              <DialogActions style={{justifyContent: 'flex-end'}}>
                <FormButton {...buttonProps.closeButton}>Close</FormButton>
              </DialogActions>
            ) :
              <DialogActions style={{justifyContent: 'space-between'}}>
                {props.selectedInbound ? (
                  <Fragment>
                    <FormButton {...buttonProps.deleteButton}>{deleteConfirmation ? 'Confirm?' :'Delete'}</FormButton>
                    <div>
                      <FormButton {...buttonProps.cancelButton}>Cancel</FormButton>
                      <SubmitButton {...buttonProps.submitButton}>Submit</SubmitButton>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <FormButton {...buttonProps.cancelButton}>Cancel</FormButton>
                    <SubmitButton {...buttonProps.submitButton}>Submit</SubmitButton>
                  </Fragment>
                )}
              </DialogActions>}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

InboundModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedShipment: PropTypes.object,
  selectedInbound: PropTypes.object,
  listOfWeightTickets: PropTypes.array,
  listOfShippingDocument: PropTypes.array,
};

export default InboundModal;
